import axios, { AxiosRequestConfig } from "axios";
import { merge } from "lodash-es";

import config from "@frontend/config";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const { apiUrl } = config;

export const sendGetRequest = async <T>(path: string, config: AxiosRequestConfig = {}): Promise<T> => {
  path = apiUrl + path;
  config = merge({ headers: { "x-access-token": await getAccessToken() }}, config); // prettier-ignore
  try {
    return await axios.get(path, config).then(({ data }) => data);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const sendPostRequest = async <T>(path: string, data?: unknown, config: AxiosRequestConfig = {}): Promise<T> => {
  path = apiUrl + path;
  config = merge({ headers: { "x-access-token": await getAccessToken() }}, config); // prettier-ignore
  try {
    return await axios.post(path, data, config).then(({ data }) => data);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const sendPutRequest = async <T>(path: string, data?: unknown, config: AxiosRequestConfig = {}): Promise<T> => {
  path = apiUrl + path;
  config = merge({ headers: { "x-access-token": await getAccessToken() }}, config); // prettier-ignore
  try {
    return await axios.put(path, data, config).then(({ data }) => data);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};
