export enum SurveyQuestion {
  OtherFeatures = "What other features would you like to see in Subly?",
  AppIdea = "What apps would you want to see here?",
  GuidelineSuggestion = "Suggest a guideline",
  HumanTranscription = "Human transcription interest",
  CancelReason = "Cancel reason",
  TranslateDocs = "Translate documents",
  SublyTranscription = "How was the transcription?",
  Satisfaction = "How's your experience with Subly so far?"
}

export enum ImprovementFeature {
  Transcription = "Transcription",
  Translation = "Translation",
  VideoControls = "Video controls",
  Timeline = "Timeline",
  Audiograms = "Audiograms",
  Collaboration = "Collaboration"
}
