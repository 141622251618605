import React, { RefObject, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import { useResizeDetector } from "react-resize-detector";

import {
  AspectFit,
  AspectRatio,
  calculateMediaConfigSize,
  CustomLogoConfig,
  MediaConfig,
  Transcription
} from "@getsubly/common";
import {
  useMediaPlayerCurrentTimeState,
  useMediaPlayerLoadedState,
  useMediaPlayerMutedState,
  useMediaPlayerPlayingState
} from "@media-player/state/media-player/media-player.hooks";
import { mediaPlayerRepository } from "@media-player/state/media-player/media-player.state";
import { calculateSize } from "@media-player/utils/size";

import { AudioCanvas } from "./audio-canvas";
import BasePlayer from "./base-player";
import { Border } from "./border";
import { FullWidthBackground } from "./full-width-background";
import { ImageElements } from "./image-elements";
import { Overlay, SocialMediaOverlay } from "./overlay";
import { PlaybackIcon } from "./playback-icon";
import { SkeletonLoader, SkeletonTranscribing } from "./skeleton";
import { SubtitlesCanvas } from "./subtitle-canvas";

/***
 * Media player sizes & areas explained:
 *
 * Scaled width / height: The width and height of the media file when scaled by ratio / fit.
 * Video width / height: The width and height of the media file itself.
 *
 * Container area: The area outlines the boundaries for the canvas area.
 * Canvas area: The area where the video background is displayed (includes player area).
 * Player area: The area where the video is displayed.
 *
 */

export interface MediaPlayerRef
  extends Pick<HTMLVideoElement, "videoHeight" | "videoWidth" | "playbackRate" | "play" | "pause"> {
  playerHeight: number;
  playerWidth: number;
  canvasHeight: number;
  canvasWidth: number;
  scaledHeight: number;
  scaledWidth: number;
  togglePlay: () => Promise<void>;
  toggleMute: () => Promise<void>;
  duration: number;
  setTime: (time: number) => void;
  addTime: (time: number) => void;
  removeTime: (time: number) => void;
  setPlaybackRate: (value: number) => void;
  ratio: AspectRatio;
  fit: AspectFit;
  isLoaded: boolean;
  videoRef: RefObject<HTMLVideoElement>;
}

interface MediaPlayerProps {
  mediaSrc?: string;
  audioDescriptionSrc?: string;
  playAudioDescription?: boolean;
  mediaConfig: MediaConfig;
  subtitles: Transcription;
  fit: AspectFit;
  ratio: AspectRatio;
  backgroundColor?: string;
  backgroundUrl?: string;
  fonts: string[];
  artwork?: {
    width: number;
    height: number;
    isUploading?: boolean;
    url?: string;
    uploadArtwork?: (file: File) => void;
  };
  customLogo?: CustomLogoConfig;
  overlay?: SocialMediaOverlay;
  isAudio: boolean;
  isTranscribing?: boolean;
  showSubtitles?: boolean;
}

export const MediaPlayer = React.forwardRef<MediaPlayerRef, MediaPlayerProps>(
  (
    {
      mediaSrc,
      audioDescriptionSrc,
      playAudioDescription,
      mediaConfig,
      subtitles,
      fit,
      ratio,
      backgroundColor,
      backgroundUrl,
      fonts,
      artwork,
      customLogo,
      overlay,
      isAudio,
      isTranscribing,
      showSubtitles = true
    },
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);
    const isLoaded = useMediaPlayerLoadedState();
    const isPlaying = useMediaPlayerPlayingState();
    const currentTime = useMediaPlayerCurrentTimeState();
    const { isMuted } = useMediaPlayerMutedState();

    const hasArtwork = Boolean(isAudio && artwork?.url);

    const { ref: containerRef } = useResizeDetector<HTMLDivElement>(); // default to original width

    React.useEffect(() => {
      mediaPlayerRepository.updateState({
        mediaLoaded: false
      });
    }, []);

    // ACTUAL VIDEO OR ARTWORK SIZE
    const [videoWidth, setVideoWidth] = React.useState(0);
    const [videoHeight, setVideoHeight] = React.useState(0);
    React.useEffect(() => {
      if (artwork?.width && artwork?.height) {
        setVideoWidth(artwork.width);
        setVideoHeight(artwork.height);
      } else {
        setVideoWidth(videoRef.current?.videoWidth ?? 0);
        setVideoHeight(videoRef.current?.videoHeight ?? 0);
      }
    }, [videoRef.current?.videoWidth, videoRef.current?.videoHeight, artwork?.width, artwork?.height, isLoaded]);

    // SCALED VIDEO SIZE (burned size)
    const [scaledWidth, setScaledWidth] = React.useState(0);
    const [scaledHeight, setScaledHeight] = React.useState(0);
    React.useEffect(() => {
      const { width, height } = calculateMediaConfigSize({
        videoHeight,
        videoWidth,
        ratio,
        aspectFit: fit
      });

      setScaledWidth(width);
      setScaledHeight(height);
    }, [videoWidth, videoHeight, ratio, fit]);

    // CONTAINER AREA
    const [containerWidth, setContainerWidth] = React.useState(0);
    const [containerHeight, setContainerHeight] = React.useState(0);
    React.useEffect(() => {
      setContainerHeight(containerRef.current?.clientHeight ?? 0);
      setContainerWidth(containerRef.current?.clientWidth ?? 0);
    }, [containerRef.current?.clientWidth, containerRef.current?.clientHeight]);

    // CANVAS AREA
    const [canvasWidth, canvasHeight] = React.useMemo(() => {
      const videoRatio = videoHeight > 0 ? videoWidth / videoHeight : 16 / 9;
      const isLandscape = videoRatio >= 1;
      const [clientWidth, clientHeight] = calculateSize(500, ratio, videoRatio, isLandscape);

      const clientRatio = clientWidth / clientHeight;

      const containerRatio = containerWidth / containerHeight;

      const overflowsHorizontally = clientRatio > containerRatio;

      const width = overflowsHorizontally ? containerWidth : containerHeight * clientRatio;
      const height = overflowsHorizontally ? containerWidth / clientRatio : containerHeight;

      return [width, height];
    }, [containerWidth, containerHeight, videoWidth, videoHeight, ratio, fit]);

    // PLAYER AREA
    const [playerWidth, playerHeight] = React.useMemo(() => {
      if (fit === AspectFit.Crop) {
        return [canvasWidth, canvasHeight];
      }

      const clientRatio = canvasWidth / canvasHeight;
      const videoRatio = videoHeight > 0 ? videoWidth / videoHeight : 16 / 9;

      if (clientRatio > videoRatio) {
        return [canvasHeight * videoRatio, canvasHeight];
      }
      return [canvasWidth, canvasWidth / videoRatio];
    }, [canvasWidth, canvasHeight, videoWidth, videoHeight, videoRef.current, fit]);

    const canvasStyles = React.useMemo((): React.CSSProperties => {
      const backgroundImage = backgroundUrl ? `url('${backgroundUrl}')` : undefined;

      const backgroundSize = backgroundImage ? "cover" : undefined;
      const backgroundPosition = backgroundImage ? "center" : undefined;

      return {
        backgroundColor,
        backgroundImage,
        backgroundSize,
        backgroundPosition
      };
    }, [backgroundColor, backgroundUrl]);

    useImperativeHandle(
      ref,
      () => ({
        pause: async () => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.pause();
          audioRef.current?.pause();
          mediaPlayerRepository.updateState({
            isPlaying: false
          });
        },
        play: async () => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.play();
          audioRef.current?.play();
          mediaPlayerRepository.updateState({
            isPlaying: true
          });
        },
        togglePlay: async () => {
          if (!videoRef.current) {
            return;
          }
          if (videoRef.current.paused) {
            videoRef.current.play();
            audioRef.current?.play();
            mediaPlayerRepository.updateState({
              isPlaying: true
            });
          } else {
            videoRef.current.pause();
            audioRef.current?.pause();
            mediaPlayerRepository.updateState({
              isPlaying: false
            });
          }
        },
        toggleMute: async () => {
          if (!videoRef.current) {
            return;
          }

          // See what player is playing
          const isAdPlaying = audioRef.current && videoRef.current.muted && !audioRef.current.muted;

          let isMutedState = false;
          if (isAdPlaying) {
            // Toggle audio (video should be muted)
            if (audioRef.current.muted) {
              audioRef.current.muted = false;
              isMutedState = false;
            } else {
              audioRef.current.muted = true;
              isMutedState = true;
            }
          } else {
            // toggle video (audio should be muted)
            if (videoRef.current.muted) {
              videoRef.current.muted = false;
              isMutedState = false;
            } else {
              videoRef.current.muted = true;
              isMutedState = true;
            }
          }

          mediaPlayerRepository.updateState({
            isMuted: isMutedState
          });
        },
        duration: videoRef.current?.duration ?? 0,
        setTime: (value: number) => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.currentTime = value;
          if (audioRef.current) {
            audioRef.current.currentTime = value;
          }
        },
        addTime: (value: number) => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.currentTime += value;
          if (audioRef.current) {
            audioRef.current.currentTime = videoRef.current.currentTime;
          }
        },
        removeTime: (value: number) => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.currentTime -= value;
          if (audioRef.current) {
            audioRef.current.currentTime = videoRef.current.currentTime;
          }
        },
        playbackRate: videoRef.current?.playbackRate ?? 1,
        setPlaybackRate: (value: number) => {
          if (!videoRef.current) {
            return;
          }
          videoRef.current.playbackRate = value;
          if (audioRef.current) {
            audioRef.current.playbackRate = value;
          }
        },
        scaledHeight,
        scaledWidth,
        videoHeight,
        videoWidth,
        canvasHeight,
        canvasWidth,
        playerHeight,
        playerWidth,
        ratio,
        fit,
        isLoaded,
        videoRef
      }),
      [
        scaledHeight,
        scaledWidth,
        videoHeight,
        videoWidth,
        canvasHeight,
        canvasWidth,
        playerHeight,
        playerWidth,
        ratio,
        fit,
        isLoaded
      ]
    );

    if (!mediaSrc) {
      return null;
    }

    React.useEffect(() => {
      if (!isPlaying) {
        return;
      }

      const interval = setInterval(() => {
        mediaPlayerRepository.updateState({
          currentTime: videoRef.current?.currentTime ?? 0
        });
      }, 30);

      return () => {
        clearInterval(interval);
      };
    }, [isPlaying]);

    React.useEffect(() => {
      if (audioDescriptionSrc && audioRef.current && videoRef.current) {
        videoRef.current.muted = Boolean(playAudioDescription) || isMuted;
        audioRef.current.muted = !playAudioDescription || isMuted;
      }
    }, [playAudioDescription, audioDescriptionSrc, videoRef.current, audioRef.current, isMuted]);

    const onClick = () => {
      if (!videoRef.current) {
        return;
      }
      if (videoRef.current.paused) {
        videoRef.current.play();
        mediaPlayerRepository.updateState({
          isPlaying: true
        });
      } else {
        videoRef.current.pause();
        mediaPlayerRepository.updateState({
          isPlaying: false
        });
      }
    };

    const onLoadedMetadata = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      mediaPlayerRepository.updateState({
        mediaLoaded: true,
        durationValue: (e.target as HTMLVideoElement)?.duration ?? 0
      });
    };

    const onTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      mediaPlayerRepository.updateState({
        currentTime: (e.target as HTMLVideoElement)?.currentTime ?? 0
      });
    };

    const scale = React.useMemo(() => {
      return canvasWidth / scaledWidth;
    }, [canvasWidth, scaledWidth]);

    return (
      <div
        ref={containerRef}
        className="tw-relative tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
      >
        <div
          className="tw-relative tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-transition-all tw-duration-500"
          style={{
            ...canvasStyles,
            width: isNaN(canvasWidth) ? undefined : canvasWidth,
            height: canvasHeight
          }}
          onClick={onClick}
        >
          <PlaybackIcon isLoaded={isLoaded} isPlaying={isPlaying} />
          <div
            className="tw-relative tw-flex tw-transition-all tw-duration-500"
            style={{
              width: playerWidth,
              height: playerHeight,
              maxWidth: containerWidth,
              maxHeight: containerHeight
            }}
          >
            <BasePlayer
              ref={videoRef}
              src={mediaSrc}
              className={classNames("tw-h-full tw-w-full tw-transition-all tw-duration-500", {
                "tw-object-cover": fit === AspectFit.Crop
              })}
              isAudio={isAudio}
              onLoadedMetadata={onLoadedMetadata}
              onTimeUpdate={onTimeUpdate}
            />
            {audioDescriptionSrc && <audio ref={audioRef} src={audioDescriptionSrc} controls={false} />}
            {isAudio && artwork && (
              <AudioCanvas
                isUploading={Boolean(artwork.isUploading)}
                url={artwork.url}
                isCrop={fit === AspectFit.Crop}
                onChange={artwork?.uploadArtwork}
              />
            )}
            {Boolean(isFinite(canvasWidth) && isFinite(scaledWidth)) && (
              <Border border={mediaConfig.border} scale={canvasWidth / scaledWidth} />
            )}
          </div>
          <div
            className={classNames("tw-pointer-events-none tw-absolute tw-inset-0", {
              "tw-hidden": isAudio && !hasArtwork
            })}
            style={{
              width: isNaN(canvasWidth) ? undefined : canvasWidth,
              height: canvasHeight
            }}
          >
            {Boolean(!isLoaded) && <SkeletonLoader />}
            {isTranscribing && (!isAudio || hasArtwork) && <SkeletonTranscribing />}
            {Boolean(isFinite(canvasWidth) && isFinite(scaledWidth)) && (
              <ImageElements
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
                scaledWidth={scaledWidth}
                scaledHeight={scaledHeight}
                isAudio={isAudio}
                hasArtwork={hasArtwork}
                hasWatermark={mediaConfig.showWatermark}
                customLogo={customLogo}
              />
            )}
            <Overlay overlay={overlay} ratio={ratio} />
            {!isTranscribing && showSubtitles && (
              <SubtitlesCanvas
                src={mediaSrc}
                mediaConfig={mediaConfig}
                transcription={subtitles}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                isLoaded={isLoaded}
                isAudio={isAudio}
                hasArtwork={hasArtwork}
                currentTime={currentTime}
                fonts={fonts}
              />
            )}
            {Boolean(isFinite(canvasWidth) && isFinite(scaledWidth)) && (
              <FullWidthBackground
                scale={scale}
                hasBackground={mediaConfig?.hasBackground || false}
                alignment={mediaConfig?.alignment}
                fullWidth={mediaConfig?.subtitles?.fullWidth || false}
                backgroundColor={mediaConfig?.colorBack}
                boxHeight={mediaConfig?.subtitles?.boxHeight || 0}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default MediaPlayer;
