import { useEffect, useState } from "react";
import { BehaviorSubject, combineLatest, of, switchMap, throttleTime } from "rxjs";

import { Asset } from "@core/interfaces/asset";
import { msToSec } from "@core/utils/time";
import {
  AspectFit,
  CustomLogoConfig,
  FileType,
  languageLookup,
  MediaAsset,
  RoleName,
  socialMediaOverlaysMap,
  transcriptionLanguages
} from "@getsubly/common";
import { findTemplate } from "@getsubly/common/dist/assParser/subtitle.text";
import { useEditorCurrentSubtitlesIdState } from "@media-editor/state/media-editor.hooks";
import { mediaEditorStore } from "@media-editor/state/media-editor.state";
import { mediaPlayerStore } from "@media-player/state/media-player/media-player.state";
import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";

import { assetsQuery } from "../assets/assets.query";
import { authQuery } from "../auth/auth.query";
import { userPresenceQuery } from "../user-presence";

import { EditorStore, editorStore } from "./editor.state";

const useEditorState = <T>(selector: (state: EditorStore) => T, defaultValue: T) => {
  return useObservable(editorStore.pipe(select(selector)), defaultValue);
};

export const useActiveMediaIdState = () => {
  const [mediaId] = useEditorState((state) => state.media?.mediaId, "");

  return mediaId;
};

export const useActiveMediaState = () => {
  const [media] = useEditorState((state) => state.media, undefined);

  return media;
};

export const useActiveMediaNameState = () => {
  const [name] = useEditorState((state) => state.media?.name, "");

  return name;
};

export const useActiveMediaTypeState = () => {
  const [type] = useEditorState((state) => state.media?.type, undefined);

  return type;
};

export const useActiveMediaConfigState = () => {
  const [config] = useEditorState((state) => state.media?.assConfig, undefined);

  return config;
};

export const useActiveMediaTranscriptionsMapState = () => {
  const [transcriptionsMap] = useEditorState((state) => state.media?.transcriptions, undefined);

  return transcriptionsMap;
};

export const useActiveMediaTranscribingState = () => {
  const [isTranscribing] = useEditorState((state) => state.media?.isTranscribing, false);

  return isTranscribing ?? false;
};

export const useActiveMediaHumanTranscribingState = () => {
  const [isHumanTranscribing] = useEditorState((state) => state.media?.isHumanTranscribing, false);

  return isHumanTranscribing ?? false;
};

export const useActiveMediaJobsState = () => {
  const [jobs] = useEditorState((state) => state.media?.jobs, []);

  return jobs;
};

export const useActiveMediaVideoUrlState = () => {
  const [videoUrl] = useEditorState((state) => state.media?.videoUrl, "");

  return videoUrl;
};

export const useActiveMediaLanguage = () => {
  const [languageCode] = useEditorState((state) => {
    const languageCode = state.media?.languageCode;
    if (languageCode) {
      return languageLookup(transcriptionLanguages, languageCode);
    }
  }, null);

  return languageCode;
};

export const useActiveMediaAllLanguagesState = () => {
  const [allLanguages] = useEditorState((state) => state.media?.allLanguages, []);

  return allLanguages ?? [];
};

export const useActiveMediaSharedUsersState = () => {
  const [sharedUsers] = useEditorState((state) => state.media?.sharedUsers, []);

  return sharedUsers ?? [];
};

export const useActiveMediaReplaceProgressState = () => {
  const [replaceProgress] = useEditorState((state) => state.media?.replaceProgress, undefined);

  return replaceProgress;
};

export const useActiveMediaFolderIdState = () => {
  const [folderId] = useEditorState((state) => state.media?.folderId, "");

  return folderId;
};

export const useActiveMediaReplacingState = () => {
  const [isReplacing] = useEditorState((state) => state.media?.isReplacing, false);

  return isReplacing ?? false;
};

export const useActiveMediaOwnerState = () => {
  const [owner] = useEditorState((state) => state.media?.owner, undefined);

  return owner;
};

export const useActiveMediaFilesState = () => {
  const [files] = useEditorState((state) => state.media?.files, []);

  return files ?? [];
};

export const useActiveMediaAudioDescriptionsState = () => {
  const [allFiles] = useEditorState((state) => state.media?.files, []);
  const audioDescriptionFiles = (allFiles || []).filter(
    (f) => f.type === FileType.Audio && f.metadata?.isAudioDescription // <----
  );

  return {
    audioDescriptionFiles,
    hasAudioDescriptions: audioDescriptionFiles.length > 0
  };
};

export const useActiveMediaAudioDescriptionUploadState = () => {
  const [isUploadingAudioDescription] = useEditorState((state) => state.media?.isUploadingAudioDescription, false);

  return isUploadingAudioDescription ?? false;
};

export const useMediaIsOverHourLongState = () => {
  const [isOverHourLong] = useObservable(
    editorStore.pipe(select((state) => (state.media?.duration || 0) >= 3600)),
    false
  );

  return isOverHourLong;
};

export const useMediaHasTranslationsState = () => {
  const [hasTranslations] = useObservable(
    editorStore.pipe(select((state) => (state.media?.transcriptions?.subtitlesTranslations || []).length > 0)),
    false
  );

  return hasTranslations;
};

export const useMediaIsOwnerState = () => {
  const [isOwner] = useObservable(
    combineLatest([authQuery.selectUser(), editorStore.pipe(select((state) => state.media?.owner?.email))]).pipe(
      switchMap(([user, ownerEmail]) => {
        return of(user?.email === ownerEmail);
      })
    ),
    false
  );

  return isOwner;
};

export const useMediaTranslationsState = () => {
  const [mediaTranslations] = useObservable(
    editorStore.pipe(select((state) => state.media?.transcriptions?.subtitlesTranslations)).pipe(
      switchMap((translations = []) => {
        const languages = translations?.map((t) => t.language) ?? [];
        return of(languages);
      })
    ),
    []
  );

  return mediaTranslations;
};

export const useMediaTranslationsCodeState = () => {
  const [mediaTranslationsCodes] = useObservable(
    editorStore.pipe(select((state) => state.media?.transcriptions?.subtitlesTranslations)).pipe(
      switchMap((translations = []) => {
        const languages = translations?.map((t) => t.languageCode) ?? [];
        return of(languages);
      })
    ),
    []
  );

  return mediaTranslationsCodes;
};

export const useActiveMediaAudioState = () => {
  const [isAudio] = useObservable(
    editorStore.pipe(select((state) => state.media?.type)).pipe(
      switchMap((type) => {
        return of(type === FileType.Audio);
      })
    ),
    false
  );

  return isAudio;
};

export const useMediaAccountIdState = () => {
  const [accountId] = useObservable(
    combineLatest([
      authQuery.select((u) => u.accountId),
      editorStore.pipe(select((state) => state.media?.accountId))
    ]).pipe(
      switchMap(([accountId, mediaAccountId]) => {
        if (mediaAccountId) {
          return of(mediaAccountId);
        }

        return of(accountId);
      })
    )
  );

  return accountId;
};

export const useActiveMediaWaveformIdState = () => {
  const [waveformId] = useObservable(
    editorStore.pipe(select((state) => state.media?.files?.find((f) => f.type === FileType.Waveform)?.id))
  );

  return waveformId;
};

export const useActiveMediaOverlayState = () => {
  const [overlay] = useObservable(
    editorStore.pipe(select((state) => state.media?.assConfig?.overlay ?? socialMediaOverlaysMap.NoOverlay))
  );

  return overlay;
};

export const useActiveMediaIsSharedUserState = (): boolean => {
  const [isSharedUser] = useObservable(
    editorStore.pipe(
      select((state) => {
        const media = state.media;
        if (!media) {
          return false;
        }

        const user = authQuery.user;
        if (!user) {
          return false;
        }
        if (user.accountId === media.accountId) {
          return false;
        }

        const sharedUsers = media.sharedUsers ?? [];

        const sharedUser = sharedUsers.find((u) => u.email === user.email);

        return Boolean(sharedUser);
      })
    ),
    false
  );

  return isSharedUser;
};

export const useActiveMediaPublicShareIdState = () => {
  const [publicShareId] = useEditorState((state) => state.media?.publicShareId, "");

  return publicShareId;
};

const selectLockedUser = () => {
  return editorStore.pipe(select((state) => state.media?.mediaId)).pipe(
    switchMap((id) => {
      if (!id) {
        return of(undefined);
      }
      return userPresenceQuery.selectActiveUser(id.toString());
    })
  );
};

export const selectActiveUser = () => {
  return combineLatest([editorStore.pipe(select((state) => state.loadedAt)), selectLockedUser()]).pipe(
    switchMap(([loadedAt, lockUser]) => {
      if (!loadedAt) {
        return of(undefined);
      }
      if (!lockUser || !lockUser?.joinedAt) {
        return of(undefined);
      }
      if (lockUser.role === RoleName.Viewer) {
        return of(undefined);
      }
      if (lockUser.joinedAt.valueOf() < loadedAt.valueOf()) {
        return of(lockUser);
      }
      return of(undefined);
    })
  );
};

export const useActiveMediaActiveUserState = () => {
  const [activeUser] = useObservable(
    combineLatest([editorStore.pipe(select((state) => state.loadedAt)), selectLockedUser()]).pipe(
      switchMap(([loadedAt, lockUser]) => {
        if (!loadedAt) {
          return of(undefined);
        }
        if (!lockUser || !lockUser?.joinedAt) {
          return of(undefined);
        }
        if (lockUser.role === RoleName.Viewer) {
          return of(undefined);
        }
        if (lockUser.joinedAt.valueOf() < loadedAt.valueOf()) {
          return of(lockUser);
        }
        return of(undefined);
      })
    )
  );
  return activeUser;
};

export const useActiveMediaHasOriginalSubtitlesState = () => {
  const [hasOriginalSubtitles] = useObservable(
    combineLatest([
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalSubtitlesId)),
      mediaEditorStore.pipe(select((state) => state.transcriptions))
    ]).pipe(
      switchMap(([originalId, transcriptions]) => {
        if (!originalId || !transcriptions) {
          return of(false);
        }

        const hasSubtitles = Boolean(transcriptions[originalId]?.length);
        return of(hasSubtitles);
      })
    ),
    false
  );

  return hasOriginalSubtitles;
};

export const useActiveMediaOriginalSubtitlesIdState = () => {
  const [originalSubtitlesId] = useEditorState((state) => state.media?.transcriptions?.originalSubtitlesId, "");

  return originalSubtitlesId;
};

export const useActiveMediaHasOriginalTranscriptionState = () => {
  const [hasOriginalTranscription] = useObservable(
    combineLatest([
      editorStore.pipe(select((state) => state.media?.transcriptions?.originalTranscriptionId)),
      mediaEditorStore.pipe(select((state) => state.transcriptions))
    ]).pipe(
      switchMap(([originalTranscriptionId, transcriptions]) => {
        if (!originalTranscriptionId || !transcriptions) {
          return of(false);
        }

        const hasTranscription = Boolean(transcriptions[originalTranscriptionId]?.length);
        return of(hasTranscription);
      })
    ),
    false
  );

  return hasOriginalTranscription;
};

export const useActiveMediaOriginalTranscriptionIdState = () => {
  const [originalTranscriptionId] = useEditorState((state) => state.media?.transcriptions?.originalTranscriptionId, "");

  return originalTranscriptionId;
};

export const useActiveMediaFirstTranslationIdState = (fileType: FileType) => {
  const [translationId] = useEditorState((state) => {
    const { subtitlesTranslations, transcriptionTranslations } = state.media?.transcriptions ?? {};

    return fileType === FileType.Transcription
      ? transcriptionTranslations?.[0]?.fileId
      : subtitlesTranslations?.[0]?.fileId;
  }, "");

  return translationId;
};

export const useActiveMediaHasTranscriptionState = (transcriptionId: string) => {
  const [transcriptionId$] = useState(() => new BehaviorSubject(transcriptionId));

  useEffect(() => {
    transcriptionId$.next(transcriptionId);
  }, [transcriptionId, transcriptionId$]);

  const [hasTranscription] = useObservable(
    combineLatest([transcriptionId$, mediaEditorStore.pipe(select((state) => state.transcriptions))]).pipe(
      switchMap(([id, transcriptions]) => {
        return of(Boolean(transcriptions?.[id]?.length));
      })
    ),
    false
  );

  return hasTranscription;
};

export const useActiveMediaTranscriptionState = (transcriptionId: string) => {
  const [transcriptionId$] = useState(() => new BehaviorSubject(transcriptionId));

  useEffect(() => {
    transcriptionId$.next(transcriptionId);
  }, [transcriptionId, transcriptionId$]);

  const [transcription] = useObservable(
    combineLatest([transcriptionId$, mediaEditorStore.pipe(select((state) => state.transcriptions))]).pipe(
      switchMap(([id, transcriptions]) => {
        return of(transcriptions?.[id] ?? []);
      })
    ),
    []
  );

  return transcription ?? [];
};

export const useActiveMediaHasArtworkState = () => {
  const [hasArtwork] = useObservable(
    editorStore
      .pipe(select((state) => state.media?.assConfig))
      .pipe(switchMap((mediaConfig) => of(mediaConfig?.artwork?.id)))
  );

  return Boolean(hasArtwork);
};

export const useActiveMediaVideoSizeState = () => {
  const [videoSize] = useObservable(
    editorStore.pipe(select((state) => state.media?.assConfig)).pipe(
      switchMap((mediaConfig) => {
        return of({
          width: mediaConfig?.playResX ?? 0,
          height: mediaConfig?.playResY ?? 0
        });
      })
    ),
    { width: 0, height: 0 }
  );

  return videoSize;
};

export const useActiveMediaAspectRatioState = () => {
  const [aspectRatio] = useObservable(
    combineLatest([
      editorStore.pipe(select((state) => state.media?.assConfig)),
      assetsQuery.selectBackgroundAssets()
    ]).pipe(
      switchMap(([mediaConfig, backgroundAssets = []]) => {
        const originalRatio = mediaConfig?.originalRatio;

        const aspectRatio = mediaConfig?.templateConfig?.enabled
          ? findTemplate(mediaConfig.templateConfig.activeTemplateId)?.aspectRatio
          : mediaConfig?.aspectRatio;

        const ratio = aspectRatio?.ratio;
        const fit = aspectRatio?.fit ?? AspectFit.Border;
        const color = aspectRatio?.color ?? "#000000";
        const backgroundImage =
          aspectRatio?.backgroundImage?.visible && aspectRatio?.backgroundImage?.url
            ? {
                ...aspectRatio.backgroundImage,
                url: getAssetUrl(aspectRatio.backgroundImage, backgroundAssets)
              }
            : undefined;
        const isBorder = fit === AspectFit.Border;
        const isCrop = !isBorder;
        const isOriginal = ratio === originalRatio;

        return of({
          ratio,
          fit,
          color,
          isBorder,
          isCrop,
          isOriginal,
          backgroundImage
        });
      })
    ),
    {
      ratio: undefined,
      fit: AspectFit.Border,
      color: "#000000",
      isBorder: true,
      isCrop: false,
      isOriginal: false,
      backgroundImage: undefined
    }
  );

  return aspectRatio;
};

export const useActiveMediaHasWatermarkState = () => {
  const [hasWatermark] = useEditorState((state) => state.media?.assConfig?.showWatermark, false);

  return hasWatermark ?? false;
};

export const useActiveMediaBorderState = () => {
  const [border] = useEditorState((state) => state.media?.assConfig?.border, undefined);

  return border;
};

export const useActiveMediaArtworkState = () => {
  const [artwork] = useObservable(
    combineLatest([
      editorStore.pipe(select((state) => state.media?.assConfig)),
      assetsQuery.selectArtworkAssets()
    ]).pipe(
      switchMap(([mediaConfig, artworkAssets = []]) => {
        const artwork = mediaConfig?.artwork;

        if (!artwork) {
          return of(undefined);
        }

        if (artwork.sublyArtwork) {
          return of(artwork);
        }

        return of({
          ...artwork,
          url: getAssetUrl(artwork, artworkAssets)
        });
      })
    )
  );

  return artwork;
};

export const useActiveCustomLogoState = (): CustomLogoConfig | undefined => {
  const [customLogo] = useObservable(
    combineLatest([editorStore.pipe(select((state) => state.media?.assConfig)), assetsQuery.selectLogoAssets()]).pipe(
      switchMap(([mediaConfig, logoAssets = []]) => {
        const customLogo = mediaConfig?.customLogo;
        const url = getAssetUrl(customLogo, logoAssets);

        if (!url || !customLogo) {
          return of(undefined);

          // TODO: We used to do this when the logo was not found, this hooks might not be the best place to do this clean up

          // Unset custom logo if public url not found. This fixes an bug where
          // deleting the logo on another video, results in the logo no longer
          // pulling through on this video, but still gets burnt on download.
          // assetsStore.remove(mediaConfig.customLogo.id);
          // const styles = parseMediaConfig({
          //   ...mediaConfig,
          //   customLogo: undefined
          // });
          // handleUpdateStyles(styles);
        }

        return of({
          ...customLogo,
          url: getAssetUrl(customLogo, logoAssets)
        });
      })
    )
  );

  return customLogo;
};

export const useCurrentMediaLanguage = () => {
  const { currentSubtitlesId } = useEditorCurrentSubtitlesIdState();
  const transcriptionsMap = useActiveMediaTranscriptionsMapState();
  let mediaLanguages = transcriptionsMap?.subtitlesTranslations ?? [];
  if (transcriptionsMap?.originalSubtitles) {
    mediaLanguages = [...mediaLanguages, transcriptionsMap.originalSubtitles];
  }
  const currentMediaLanguage = mediaLanguages.find((l) => l?.fileId === currentSubtitlesId);
  return currentMediaLanguage;
};

// Experimental hook
// It returns based on the transcription, the active cue id based on the current time of the player
export const useActiveCueIdState = (transcriptionId: string) => {
  const [transcriptionId$] = useState(() => new BehaviorSubject(transcriptionId));

  useEffect(() => {
    transcriptionId$.next(transcriptionId);
  }, [transcriptionId, transcriptionId$]);

  const [cueId] = useObservable(
    combineLatest([
      transcriptionId$,
      mediaEditorStore.pipe(select((state) => state.transcriptions)),
      mediaPlayerStore.pipe(select((state) => state.currentTime)).pipe(throttleTime(100))
    ]).pipe(
      switchMap(([transcriptionId, transcriptions = {}, currentTime]) => {
        const transcription = transcriptions[transcriptionId];

        if (!transcription) {
          return of(undefined);
        }

        // get cues at currentTime
        const activeCues = transcription.filter((cue) => {
          // Bug fix: we convert cue times to seconds and round to 2 decimal
          // places to accurately compare to currentTime.

          const cueStart = msToSec(cue.start);
          const cueEnd = msToSec(cue.end);

          return cueStart <= currentTime && cueEnd >= currentTime;
        });

        // always select last activeCue
        const cueId = activeCues?.slice(-1)[0]?.id;

        return of(cueId);
      })
    )
  );

  return cueId;
};

export const useMediaConfigEditorModeState = () => {
  const [editorMode] = useEditorState((state) => state.media?.assConfig?.editorMode, "subtitles");

  return editorMode ?? "subtitles";
};

// Shared function
const getAssetUrl = (assetConfig?: MediaAsset, assets: Asset[] = []) => {
  if (!assetConfig) {
    return "";
  }

  const asset = assets.find(({ id }) => id === assetConfig.id);

  return asset ? asset.publicUrl || asset.s3Uri : "";
};
