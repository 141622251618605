import React, { useState } from "react";
import classNames from "classnames";

import { getAccountTeam } from "@frontend/api/account.service";
import { acceptNewRoleRequest, declineNewRoleRequest } from "@frontend/api/members.service";
import { Alert } from "@frontend/components/alert/alert";
import { ProfileImageIcon } from "@frontend/components/profile-image-icon/profile-image-icon";
import { SubscriptionModal } from "@frontend/containers/modals/subscription-modal";

import { Button } from "@components/button";
import { ExclamationTriangleIcon, HeroCheckIcon as CheckIcon, UserPlusIcon, XMarkIcon } from "@components/icons";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useModal } from "@core/hooks/use-modal";
import { AccountMember } from "@core/interfaces/account";
import { ModalType } from "@core/interfaces/modal-type";

interface MembersRequestsProps {
  members?: AccountMember[];
  hasAvailableSeats: boolean;
  className: string;
  onChangeMembers: (members: AccountMember[]) => void;
}

export const MemberRequests: React.FC<MembersRequestsProps> = ({
  members = [],
  hasAvailableSeats,
  className,
  onChangeMembers
}) => {
  const onClickDismiss = async (userId: string) => {
    onChangeMembers(members.filter((m) => m.id !== userId));
  };

  return (
    <div
      className={classNames(
        "tw-rounded-10 tw-border tw-border-neutral-200 tw-bg-neutral-50 tw-px-3 tw-py-4",
        className
      )}
    >
      <h3 className="tw-text-md tw-font-semibold">Seat requests</h3>
      <ul>
        {members.map((member) => (
          <li key={member.id}>
            <MemberRequest member={member} hasAvailableSeats={hasAvailableSeats} onClickDismiss={onClickDismiss} />
          </li>
        ))}
      </ul>
    </div>
  );
};

interface MemberRequestProps {
  member: AccountMember;
  hasAvailableSeats: boolean;
  onClickDismiss: (userId: string) => void;
}
export const MemberRequest: React.FC<MemberRequestProps> = ({ member, hasAvailableSeats, ...props }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideModal] = useModal(ModalType.Subscription);
  const [isLoadingAccept, setLoadingAccept] = useState(false);
  const [isLoadingDecline, setLoadingDecline] = useState(false);
  const [actionStatus, setActionStatus] = useState<"accepted" | "declined" | false>(false);
  const canDismiss = actionStatus === "accepted" || actionStatus === "declined";

  const onClickAcceptAndAssign = async () => {
    setLoadingAccept(true);
    await acceptNewRoleRequest(member.id);
    await getAccountTeam();
    setLoadingAccept(false);
    setActionStatus("accepted");
  };

  const onClickDecline = async () => {
    setLoadingDecline(true);
    await declineNewRoleRequest(member.id);
    setLoadingAccept(false);
    setActionStatus("declined");
  };

  const onClickDismiss = () => {
    props.onClickDismiss(member.id);
  };

  return (
    <div className="tw-relative tw-mt-4 tw-border-t tw-border-neutral-200 tw-pt-4">
      <div className="tw-mb-3 tw-flex tw-items-center">
        <ProfileImageIcon name={member.name[0]} size="32px" twoLetters className="tw-mr-2 tw-text-xs" />
        <span className="tw-text-xs tw-font-medium tw-text-neutral-600">{member.email}</span>
      </div>
      {canDismiss && (
        <button onClick={onClickDismiss} className="tw-absolute tw-right-0 tw-top-2">
          <XMarkIcon className="tw-h-4 tw-w-4 tw-text-neutral-400 hover:tw-text-neutral-600" />
        </button>
      )}
      <p className="tw-mb-3 tw-text-sm tw-font-semibold">{member.name} has requested to become an editor</p>
      <div>
        {actionStatus === false ? (
          <div className="tw-flex">
            <AcceptButton
              className="tw-mr-3"
              hasSeats={hasAvailableSeats}
              loading={isLoadingAccept}
              onClickAcceptAndAssign={onClickAcceptAndAssign}
              onClickBuyAndAssign={() => {
                trackEventWithAuth("Open check out modal");
                showModal(<SubscriptionModal hideModal={hideModal} />);
              }}
            />
            <DeclineButton onClick={onClickDecline} loading={isLoadingDecline} />
          </div>
        ) : null}
        {actionStatus === "accepted" ? (
          <Alert success className="tw-m-0 tw-inline-flex tw-h-9 tw-items-center tw-px-3 tw-text-sm">
            <CheckIcon className="tw-mr-2 tw-h-4 tw-w-4 tw-font-semibold" strokeWidth={2.7} />
            <span className="tw-text-sm">Seat added</span>
          </Alert>
        ) : null}
        {actionStatus === "declined" ? (
          <Alert warning className="tw-m-0 tw-inline-flex tw-h-9 tw-items-center tw-px-3 tw-text-sm">
            <ExclamationTriangleIcon className="tw-mr-2 tw-h-4 tw-w-4 tw-font-semibold" strokeWidth={2.7} />
            <span className="tw-text-sm">Seat request declined</span>
          </Alert>
        ) : null}
      </div>
    </div>
  );
};

type AcceptButtonProps = {
  loading: boolean;
  hasSeats?: boolean;
  className?: string;
  onClickAcceptAndAssign: () => void;
  onClickBuyAndAssign: () => void;
};
export const AcceptButton: React.FC<AcceptButtonProps> = ({
  loading,
  hasSeats = true,
  className,
  onClickAcceptAndAssign,
  onClickBuyAndAssign
}) => {
  return hasSeats ? (
    <Button
      variant="primary"
      className={classNames("tw-pl-2 tw-pr-3", className)}
      onClick={onClickAcceptAndAssign}
      loading={loading}
      size="36"
    >
      <UserPlusIcon className="tw-mr-1.5 tw-h-5 tw-w-5" /> Accept and assign seat
    </Button>
  ) : (
    <Button
      variant="primary"
      className={classNames("tw-pl-2 tw-pr-3", className)}
      onClick={onClickBuyAndAssign}
      size="36"
    >
      <UserPlusIcon className="tw-mr-1.5 tw-h-5 tw-w-5" /> Buy and assign seat
    </Button>
  );
};

type DeclineButtonProps = {
  loading: boolean;
  onClick: () => void;
};
export const DeclineButton: React.FC<DeclineButtonProps> = ({ loading, onClick }) => {
  return (
    <Button variant="secondary" className="tw-pl-2 tw-pr-3" onClick={onClick} loading={loading} size="36">
      <XMarkIcon className="tw-mr-1.5 tw-h-5 tw-w-5" /> Decline seat request
    </Button>
  );
};
