import React from "react";

import config from "@frontend/config";
import { useAnalytics } from "@frontend/contexts/analytics.context";

import { usePlan } from "@core/hooks/use-plan";
import { useUser } from "@core/hooks/use-user";
import { SublyPlan } from "@core/interfaces/billing";
import { UnknownObject } from "@core/interfaces/types";
import { getPlanNameLabel } from "@core/utils/plans";

import { useAccounts } from "./use-accounts";

type AnalyticsWithAuth = (eventName: string, data?: UnknownObject | undefined) => void;

export const useAnalyticsWithAuth = (): {
  trackEventWithAuth: AnalyticsWithAuth;
  analyticsData: UnknownObject;
} => {
  const { isPayg, isPersonal, isBusiness, isEnterprise, isTrial, plan: userPlan } = usePlan();
  const { isLoaded } = useAccounts();
  const { user } = useUser();
  const { trackEvent } = useAnalytics();
  const [buffer, setBuffer] = React.useState<Array<[string, UnknownObject]>>([]);

  // Force Pay as you go plan if it is pay as you go
  const plan = isPayg ? SublyPlan.PAYG : userPlan;
  const label = getPlanNameLabel(plan);

  // Force trial label to plan name
  const planName = isTrial ? `${label} (trial)` : label;

  // Forces account to be loaded before tracking
  React.useEffect(() => {
    if (!isLoaded || !buffer.length) {
      return;
    }

    buffer.forEach(([eventName, data]) => {
      trackEventWithAuth(eventName, { ...data, buffer: true });
    });

    setBuffer([]);
  }, [isLoaded, buffer.length]);

  React.useEffect(() => {
    if (!isLoaded) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clarity = (window as any)?.clarity;

    if (clarity == null) {
      return;
    }

    if (isTrial) {
      clarity("set", "isTrial", "true");
    }

    clarity("set", "planName", planName);
  }, [isLoaded, planName, isTrial]);

  const analyticsData = {
    plan,
    isTrial,
    isPersonal,
    isBusiness,
    isEnterprise,
    planName
  };

  const isProduction = config.isProduction;

  const trackEventWithAuth = (eventName: string, data: UnknownObject = {}) => {
    if (isProduction && user?.email.includes("getsubly.com")) {
      return;
    }

    // If account is not loaded but signed in (user), add events to the
    // buffer array to fire once the account loading finishes.
    if (!isLoaded && user) {
      setBuffer([...buffer, [eventName, data]]);
      return;
    }

    // If account is not loaded and there's no user, send events without auth data
    if (!isLoaded && !user) {
      sendEvent(eventName, data);
      return;
    }

    sendEvent(eventName, {
      ...analyticsData,
      ...data
    });
  };

  const sendEvent = (eventName: string, data: UnknownObject = {}) => {
    if (isProduction) {
      trackEvent(eventName, data);
    } else {
      console.info("[MIXPANEL]", eventName, data); // eslint-disable-line no-console
    }
  };

  return {
    trackEventWithAuth,
    analyticsData
  };
};
