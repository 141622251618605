import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Alert } from "@frontend/components/alert/alert";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { useAccounts } from "@core/hooks/use-accounts";
import { parseCreditToText } from "@core/utils/plans";
import { RiErrorWarningFill } from "@remixicon/react";

interface UploadModalProps {
  availableCredit: number;
  price: number;
  onNavigate?: () => void;
}

export const CreditSection: React.FC<UploadModalProps> = ({ availableCredit, price, onNavigate }) => {
  const { isOwner } = useAccounts();
  const navigate = useNavigate();

  const totalDurationLabel = parseCreditToText(price, false, false, true);
  const minutesLeft = parseCreditToText(availableCredit, false, false);
  const exceedsAvailableCredits = price > availableCredit;

  const handleClickAddMore = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(SETTINGS_BILLING_PATH);
    onNavigate?.();
  };

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-justify-between tw-text-sm tw-text-neutral-600">
        <span>Available credits:</span>
        <strong className="tw-font-medium tw-text-neutral-900">{minutesLeft}</strong>
      </div>
      <div className="tw-flex tw-justify-between tw-text-sm tw-text-neutral-600">
        <span>Cost:</span>
        <strong
          className={classNames("tw-font-medium", {
            "tw-text-neutral-900": !exceedsAvailableCredits,
            "tw-text-destructive-500": exceedsAvailableCredits
          })}
        >
          {totalDurationLabel}
        </strong>
      </div>
      {exceedsAvailableCredits && (
        <Alert danger className="tw-mt-6 tw-px-6">
          <div className="tw-flex tw-items-center tw-gap-4">
            <RiErrorWarningFill className="tw-h-5 tw-w-5 tw-min-w-0" />
            <div className="tw-flex-1">
              You don't have enough credits to complete this action.{" "}
              {isOwner && (
                <>
                  Please{" "}
                  <a
                    href="#"
                    onClick={handleClickAddMore}
                    className="tw-text-primary-500 hover:tw-text-primary-500 hover:tw-underline"
                  >
                    purchase additional credits.
                  </a>
                </>
              )}
            </div>
          </div>
        </Alert>
      )}
    </div>
  );
};
