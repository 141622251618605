import React from "react";

import { mediaHistory, mediaHistoryRepository } from "@core/state/editor-history/editor-history.state";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { mediaEditorStateRepository } from "@media-editor/state/media-editor.state";
import { useObservable } from "@mindspace-io/react";

interface UndoRedoContext {
  hasPast: boolean;
  hasFuture: boolean;
  handleUndo: () => void;
  handleRedo: () => void;
}

const UndoRedoContext = React.createContext<UndoRedoContext | null>(null);

interface UndoRedoProviderProps {
  children: React.ReactNode;
}
export const UndoRedoProvider: React.FC<UndoRedoProviderProps> = ({ children }) => {
  const { handleUndoRedo } = useMediaEditor();

  // This enables tracking changes for undo/redo
  useObservable(mediaHistoryRepository.trackTranscriptionChanges$);
  useObservable(mediaHistoryRepository.trackConfigChanges$);

  const [hasPast] = useObservable(mediaHistory.hasPast$, false);
  const [hasFuture] = useObservable(mediaHistory.hasFuture$, false);

  const afterHistoryChange = () => {
    const { mediaConfig, transcriptions } = mediaHistoryRepository;

    handleUndoRedo(transcriptions, mediaConfig);
    mediaEditorStateRepository.resetTranscriptionEditor$.next(true);
  };

  const handleUndo = () => {
    if (hasPast) {
      mediaHistory.undo();
      afterHistoryChange();
    }
  };

  const handleRedo = () => {
    if (hasFuture) {
      mediaHistory.redo();
      afterHistoryChange();
    }
  };

  return (
    <UndoRedoContext.Provider
      value={{
        hasPast,
        hasFuture,
        handleUndo,
        handleRedo
      }}
    >
      {children}
    </UndoRedoContext.Provider>
  );
};

export const useUndoRedo = (): UndoRedoContext => {
  const context = React.useContext(UndoRedoContext);

  if (context === null) {
    throw new Error("useUndoRedo must be used within a UndoRedoProvider");
  }

  return context;
};
