import React from "react";
import { useForm } from "react-hook-form";

import { createUserGroup, ICreateUserGroup } from "@frontend/api/user-groups.service";
import { notificationSuccess } from "@frontend/components/notification";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

import { UserGroup } from "@core/interfaces/user-group";

interface CreateUserGroupModalProps {
  onDismiss: () => void;
  onCreate?: (userGroup: UserGroup) => void;
}
export const CreateUserGroupModal: React.FC<CreateUserGroupModalProps> = ({ onCreate, onDismiss }) => {
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm<ICreateUserGroup>();

  const handleCreateUserGroup = async (data: ICreateUserGroup) => {
    setLoading(true);
    const userGroup = await createUserGroup(data);
    notificationSuccess(`"${data.name}" has been created successfully`);
    setLoading(false);
    onCreate?.(userGroup);
    onDismiss();
  };

  return (
    <NewModal onDismiss={onDismiss} showCloseButton>
      <form onSubmit={handleSubmit(handleCreateUserGroup)}>
        <div className="tw-flex tw-flex-col tw-gap-5 tw-pb-8">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <h1 className="tw-text-lg tw-font-semibold">Create a new group</h1>
            <h2 className="tw-text-sm">Add a new group of people for easy management</h2>
          </div>
          <Input label="Name" placeholder="What's the name of the group?" {...register("name")} autoFocus />
        </div>
        <div className="tw-flex tw-items-center tw-justify-end">
          <Button loading={loading} type="submit">
            Create group
          </Button>
        </div>
      </form>
    </NewModal>
  );
};
