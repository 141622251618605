import React from "react";
import { Link } from "react-router-dom";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { pluralize } from "@core/utils/strings";

interface UploadModalAlertProps {
  credit?: boolean;
  storage?: boolean;
  count: number;
  onUpgrade: () => void;
}
export const UploadModalAlert: React.FC<UploadModalAlertProps> = ({ credit, storage, count, onUpgrade }) => {
  if (!credit && !storage) {
    return null;
  }

  const { plan, isTrial } = usePlan();
  const { deal } = useAccounts();

  //hide if user is on a paid plan
  if (plan && !isTrial && !deal) {
    return null;
  }

  let message = "";

  const ending =
    count > 0
      ? `process ${count > 1 ? "these" : "this"}
  ${pluralize(count, "file")}`
      : "upload files";

  if (credit && storage) {
    message = `Oops, looks like you don't have enough credit and storage left to ${ending}. Get more credit and storage, `;
  } else if (credit) {
    message = `Oops, looks like you don't have enough credit left to ${ending}. Get more minutes, `;
  } else if (storage) {
    message = `Oops, looks like you don't have enough storage left to ${ending}. Get more storage, `;
  }

  if (!message) {
    return null;
  }

  return (
    <div>
      <p className="tw-m-0 tw-text-sm">
        <>
          {message}
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} onClick={onUpgrade}>
            upgrade now
          </Link>
        </>
      </p>
    </div>
  );
};
