import React from "react";

import { updateUserSettings } from "@frontend/api/auth.service";
import { saveSurveyResult } from "@frontend/api/survey.service";
import { Alert } from "@frontend/components/alert/alert";
import { FeedbackModal, FeedbackSentModal } from "@frontend/containers/modals/feedback-modal";

import { Button } from "@components/button";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { ModalType } from "@core/interfaces/modal-type";
import { SurveyQuestion } from "@core/interfaces/survey";
import { SurveyType } from "@getsubly/common/dist/interfaces/survey";

export type FeedbackOption = {
  id: number;
  label: string;
  modalTitle: string;
  modalTextareaPlaceholder: string;
  successModalTitle: string;
  successModalDescription: string;
};

const options: FeedbackOption[] = [
  {
    id: 1,
    label: "🙁 Not satisfied",
    modalTitle: "Thanks for your feedback!<br/>How can we improve?",
    modalTextareaPlaceholder: "E.g., Missing features, performance issues, confusing layout",
    successModalTitle: "Thank you for your feedback!",
    successModalDescription: "We value your input and will use it to improve Subly. Stay tuned for updates!"
  },
  {
    id: 2,
    label: "😐 Neutral",
    modalTitle: "Thanks for your feedback!<br/>How can we improve?",
    modalTextareaPlaceholder: "E.g., Missing features, performance issues, confusing layout",
    successModalTitle: "Thank you for your feedback!",
    successModalDescription: "We value your input and will use it to improve Subly. Stay tuned for updates!"
  },
  {
    id: 3,
    label: "😄 Very satisfied",
    modalTitle: "Awesome! What's working well for you?",
    modalTextareaPlaceholder: 'E.g., Helpful features, great support, smooth experience"',
    successModalTitle: "Thank you for your feedback!",
    successModalDescription: "We're glad to hear you're enjoying Subly. Your input helps us keep up the good work."
  }
];

type DashboardFeedbackProps = {
  className?: string;
};

export const DashboardFeedback: React.FC<DashboardFeedbackProps> = ({ className }) => {
  const [showFeedbackModal, hideFeedbackModal] = useModal(ModalType.FeedbackModal);
  const [showFeedbackSentModal, hideFeedbackSentModal] = useModal(ModalType.FeedbackSentModal);
  const { user, currentAccount } = useAccounts();
  const { plan } = usePlan();

  const loginCount = React.useMemo(() => user?.settings?.loginCount ?? 0, [user?.settings]);
  const hasPreviouslyAnswered = React.useMemo(() => {
    const answeredSurveys = user?.settings?.answeredSurveys ?? [];
    return answeredSurveys.some((survey) => {
      return survey.surveyId === SurveyQuestion.Satisfaction;
    });
  }, [user?.settings]);

  // Only show this survey if the user has logged in more than two times and and not previously filled out the survey
  const showSurvey = React.useMemo(() => {
    return loginCount >= 2 && !hasPreviouslyAnswered;
  }, [loginCount, hasPreviouslyAnswered]);

  if (!showSurvey) return;

  const handleClick = (feedbackOption: FeedbackOption) => {
    const handleSubmit = async (message: string) => {
      if (!user || !plan || !currentAccount) return;

      const success = await saveSurveyResult({
        surveyId: SurveyQuestion.Satisfaction,
        surveyType: SurveyType.Satisfaction,
        result: {
          satisfaction: feedbackOption.label,
          satisfactionId: feedbackOption.id,
          message,
          userId: user.id,
          userEmail: user.email,
          userRole: currentAccount.role,
          accountId: currentAccount.accountId,
          accountPlan: plan
        }
      });

      updateUserSettings({
        ...user.settings,
        answeredSurveys: [
          ...(user.settings?.answeredSurveys ?? []),
          { surveyId: SurveyQuestion.Satisfaction, action: "responded" }
        ]
      });

      if (success) {
        showFeedbackSentModal(
          <FeedbackSentModal
            feedbackOption={feedbackOption}
            onSubmit={handleSubmit}
            closeModal={hideFeedbackSentModal}
          />
        );
      }
    };

    showFeedbackModal(
      <FeedbackModal feedbackOption={feedbackOption} onSubmit={handleSubmit} closeModal={hideFeedbackModal} />
    );
  };

  const handleReject = () => {
    if (user) {
      updateUserSettings({
        ...user.settings,
        answeredSurveys: [
          ...(user.settings?.answeredSurveys ?? []),
          { surveyId: SurveyQuestion.Satisfaction, action: "skipped" }
        ]
      });
    }
  };

  if (!user || !currentAccount || !plan) return;

  return (
    <Alert
      primary
      className={className}
      innerClassName="tw-flex tw-justify-between tw-items-center"
      closable
      onClose={handleReject}
    >
      {SurveyQuestion.Satisfaction}
      <ul className="tw-float-right tw-ml-2 tw-mr-4 tw-flex tw-gap-2">
        {options.map((option) => (
          <li key={option.id}>
            <Button onClick={() => handleClick(option)} variant="secondary" size="32" raised>
              {option.label}
            </Button>
          </li>
        ))}
      </ul>
    </Alert>
  );
};
