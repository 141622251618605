import React from "react";
import classNames from "classnames";

interface ToolbarButtonProps {
  active?: boolean;
  className?: string;
  children: React.ReactNode;
  disableTabIndex?: boolean;
  onClick: () => void;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  active,
  children,
  onClick,
  className,
  disableTabIndex,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        className,
        "tw-inline-flex tw-h-8 tw-w-8 tw-select-none tw-items-center tw-justify-center tw-rounded-3 tw-border-none tw-font-medium tw-outline-none focus:tw-border-none",
        {
          "tw-bg-primary-100 tw-text-primary-600": active,
          "tw-bg-transparent tw-text-neutral-900 hover:tw-bg-neutral-100 hover:tw-text-primary-500": !active
        },
        "focus-visible:tw-ring-2 focus-visible:tw-ring-primary-500"
      )}
      tabIndex={disableTabIndex ? -1 : 0}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
