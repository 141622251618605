import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface UsageOverview {
  totalMinutes: number;
  usedMinutes: number;
  usedStorageBytes: number;
  totalStorageBytes: number;
}

export const getUsageOverview = async ({ accountId }: { accountId: string }): Promise<UsageOverview | undefined> => {
  try {
    const { data } = await axios.get<UsageOverview>(`/${accountId}/usage/overview`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return {
      totalMinutes: data.totalMinutes,
      usedMinutes: data.usedMinutes,
      usedStorageBytes: data.usedStorageBytes,
      totalStorageBytes: data.totalStorageBytes
    };
  } catch (e) {
    handleError(e);
  }
};

export type UsageTransactionReason = "transcribe" | "translate" | "subscription-start";

export type UsageTransaction = {
  name?: string; // the file name
  type: UsageTransactionReason; // the transaction type
  description: string; // a human friendly description of the transaction
  amount: number; // the credit difference
  createdAt: string; // the transaction date
};

export type PaginationMeta = {
  from: string;
  to: string;
  limit: number;
  skip: number;
  total_items: number;
};

export const getUsageHistory = async ({
  from,
  to,
  reason,
  sort,
  order,
  limit,
  skip
}: {
  from?: string;
  to?: string;
  sort?: "createdAt" | "amount";
  order?: "ASC" | "DESC" | "asc" | "desc";
  reason?: UsageTransactionReason | UsageTransactionReason[];
  limit?: number;
  skip?: number;
}): Promise<{ data: UsageTransaction[]; meta: PaginationMeta } | undefined> => {
  const accountId = getAccountId();

  try {
    const { data } = await axios.get<{
      data: UsageTransaction[];
      meta: PaginationMeta;
    }>(`/${accountId}/usage/history`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() },
      params: { from, to, reason, sort, order, limit, skip }
    });

    return data;
  } catch (e) {
    handleError(e);
  }
};
