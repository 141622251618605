export enum AlignmentLabel {
  "Left",
  "Center",
  "Right"
}

export enum PositionLabel {
  "Bottom",
  "Middle",
  "Top"
}
