import React from "react";
import classNames from "classnames";

import { BurnPosition, CustomLogoConfig, CustomLogoSize } from "@getsubly/common";
import watermark from "@media-player/assets/watermark.png";
import { useMediaPlayerLoadedState } from "@media-player/state/media-player/media-player.hooks";

import { calculateLogoPosition, calculateLogoSize } from "../utils/elements";

export interface ImageElementsProps {
  canvasHeight: number;
  canvasWidth: number;
  scaledHeight: number;
  scaledWidth: number;
  isAudio: boolean;
  hasArtwork: boolean;
  hasWatermark?: boolean;
  customLogo?: CustomLogoConfig;
}

export const ImageElements: React.FC<ImageElementsProps> = ({
  canvasHeight,
  canvasWidth,
  scaledHeight,
  scaledWidth,
  isAudio,
  hasArtwork,
  hasWatermark,
  customLogo
}) => {
  const mediaLoaded = useMediaPlayerLoadedState();

  const elements = React.useMemo(() => {
    const watermarkLogo: CustomLogoConfig = {
      visible: hasWatermark || false,
      id: "watermark",
      position: BurnPosition.TopRight,
      opacity: 100,
      size: CustomLogoSize.Medium,
      url: watermark,
      dimensions: {
        width: 709,
        height: 413
      },
      padding: {
        x: 10,
        y: 10
      }
    };

    return [watermarkLogo, customLogo];
  }, [hasWatermark, customLogo]);

  if (isAudio && !hasArtwork) {
    return null;
  }

  const renderLogos = elements.map((options, i) => {
    if (!mediaLoaded || !options || !options?.visible || !options?.url) {
      return null;
    }

    const [imgWidth, imgHeight] = calculateLogoSize(options, scaledWidth);

    const width = Math.floor(imgWidth * (canvasWidth / scaledWidth));
    const height = Math.floor(imgHeight * (canvasHeight / scaledHeight));

    const [left, top] = calculateLogoPosition(options, {
      canvasSize: { width: canvasWidth, height: canvasHeight },
      logoSize: { width, height },
      videoSize: { width: scaledWidth, height: scaledHeight }
    });
    const opacity = options?.opacity / 100 ?? 1;

    return (
      <img
        src={options.url}
        className={"tw-absolute tw-transition-all tw-duration-500"}
        style={{ left, top, width, height, opacity }}
        key={options.id + i}
      />
    );
  });

  return (
    <div className={classNames("tw-pointer-events-none tw-absolute tw-inset-0 tw-overflow-hidden")}>{renderLogos}</div>
  );
};
