import React from "react";

import { CustomGlossaryTool } from "@frontend/components/custom-glossary/custom-glossary";
import { LockFeature } from "@frontend/components/lock-feature/lock-feature";
import { TranslationGlossary } from "@frontend/components/translation-glossary/translation-glossary";
import config from "@frontend/config";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { usePlan } from "@core/hooks/use-plan";
import { SublyPlan } from "@core/interfaces/billing";

export const GlossaryPage: React.FC = () => {
  const { isBusinessOrHigher } = usePlan();

  return (
    <LockFeature minPermission={SublyPlan.Personal}>
      <SettingsPageHeader
        title="Glossary"
        description="Create your custom list of vocabulary words"
        className="tw-mb-6"
      />
      <CustomGlossaryTool disabled={!isBusinessOrHigher} className="tw-mb-6" />
      {config.features.glossary && <TranslationGlossary disabled={!isBusinessOrHigher} />}
    </LockFeature>
  );
};
