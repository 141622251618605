import React from "react";
import classNames from "classnames";
import ReactSkeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonLoader: React.FC = () => {
  return (
    <div className="tw-flex tw-h-full tw-w-full">
      <SkeletonTheme baseColor="#c2c2c2" highlightColor="#d2d2d2">
        <div className="tw-h-full tw-w-full">
          <ReactSkeleton height="100%" className="tw-rounded-none tw-leading-[inherit]" />
        </div>
      </SkeletonTheme>
    </div>
  );
};

export const SkeletonTranscribing: React.FC = () => {
  return (
    <div
      className={classNames(
        "tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-pb-12 tw-pt-8"
      )}
    >
      <SkeletonTheme baseColor="rgba(0, 0, 0, 0.5)" highlightColor="rgba(0, 0, 0, 0.2)">
        <div className="tw-absolute tw-bottom-[5%] tw-left-0 tw-right-0 tw-z-20">
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
            <ReactSkeleton containerClassName="tw-w-[60%]" height="40px" />
            <ReactSkeleton containerClassName="tw-w-[80%]" height="40px" />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};
