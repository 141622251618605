import React from "react";
import { useForm } from "react-hook-form";

import { setupApiAccount } from "@frontend/api/api.service";
import { EN } from "@frontend/assets/i18n/en";
import { notificationError } from "@frontend/components/notification";
import { API_DOCS_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Fieldset, Input } from "@components/form-controls";

interface APISetupForm {
  accountName: string;
}

interface APISetupProps {
  accountId: string;
  isEnterprise: boolean;
}

export const APISetup: React.FC<APISetupProps> = ({ accountId }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid }
  } = useForm<APISetupForm>();
  const [isLoading, setLoading] = React.useState(false);

  const handleSetupAPIAccount = async (data: APISetupForm) => {
    try {
      setLoading(true);

      await setupApiAccount({ ...data, accountId });
    } catch (error) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tw-h-full tw-flex-col tw-items-center">
      <p className="tw-text-sm tw-font-medium tw-text-neutral-600">To get started, you'll need to setup the API.</p>
      <p className="tw-mt-3 tw-text-sm tw-font-medium tw-text-neutral-600">
        The API Documentation is available{" "}
        <a href={API_DOCS_URL} target="_blank" rel="noreferrer" className="tw-text-aux-500">
          here
        </a>
      </p>

      <div className="tw-mt-3 tw-w-full tw-max-w-md tw-rounded-lg tw-border tw-border-neutral-200 tw-p-4">
        <form onSubmit={handleSubmit(handleSetupAPIAccount)} className="tw-w-full tw-max-w-md">
          <Fieldset title="Company Name" className="tw-mb-4">
            <Input type="text" {...register("accountName", { required: true })} />
          </Fieldset>
          <div className="tw-mt-3 tw-flex tw-justify-end">
            <Button variant="primary" type="submit" loading={isLoading} disabled={!isValid} size="36">
              Setup API
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
