import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { Integration } from "@frontend/api/apps.service";
import { AppModal } from "@frontend/components/app-modal/app-modal";
import { useAppIntegration } from "@frontend/contexts/app-integration.context";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useModal } from "@core/hooks/use-modal";
import { App } from "@core/interfaces/app";
import { ModalType } from "@core/interfaces/modal-type";

import { CategoryButton } from "../drives/category-button";
import { MenuItem } from "../sidebar-nav/sidebar-nav";

interface SourceItemProps {
  apps: App[];
  integrations: Integration[];
}

const SourceItems: React.FC<SourceItemProps> = ({ apps, integrations }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();

  return (
    <ul>
      {apps
        .filter((a) => a.showOnSidebar && !a.hidden)
        .map((app) => {
          const renderIcon = () => {
            return React.cloneElement(app.icon, {
              className: "tw-h-5 tw-w-5 tw-shrink-0"
            });
          };

          return (
            <li key={app.appId}>
              <MenuItem
                icon={renderIcon()}
                text={app.name}
                onClick={() => {
                  trackEventWithAuth(`Sidebar / ${app.name}`);

                  const connected = integrations.find((i) => i.appId === app.appId);
                  if (connected) {
                    navigate(app.link);
                  } else {
                    showModal(<AppModal app={app} closeModal={hideModal} />);
                  }
                }}
              />
            </li>
          );
        })}
    </ul>
  );
};

export const SourcesNav: React.FC = () => {
  const [showSources, onToggleCategory] = React.useState(true);
  const { apps, integrations, isLoading, fetchIntegrations } = useAppIntegration();

  React.useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <CategoryButton description="Sources" onClick={() => onToggleCategory(!showSources)}>
        Sources
      </CategoryButton>
      {!isLoading && showSources && <SourceItems apps={apps} integrations={integrations} />}
      {isLoading && showSources && <Skeleton className="tw-h-4 tw-w-24" />}
    </div>
  );
};
