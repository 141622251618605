import React from "react";

interface SelectedFilesContext {
  selectedFiles: string[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;
}

const SelectedFilesContext = React.createContext<SelectedFilesContext | null>(null);

interface SelectedFilesProviderProps {
  children: React.ReactNode;
}
export const SelectedFilesProvider: React.FC<SelectedFilesProviderProps> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);

  return (
    <SelectedFilesContext.Provider
      value={{
        selectedFiles,
        setSelectedFiles
      }}
    >
      {children}
    </SelectedFilesContext.Provider>
  );
};

export const useSelectedFiles = (): SelectedFilesContext => {
  const context = React.useContext(SelectedFilesContext);

  if (context === null) {
    throw new Error("useSelectedFiles must be used within a SelectedFilesProvider");
  }

  return context;
};
