import React from "react";
import classNames from "classnames";

import { Avatar, AvatarSize } from "@components/avatar/avatar";
import { ButtonSize, ButtonVariant } from "@components/button";
import { Dropdown, DropdownButton, DropdownMenu } from "@components/dropdown";
import { EyeDropperIcon } from "@components/icons";

import { ColorPicker, ColorPickerProps } from "./color-picker";

interface ColorPickerIconProps extends ColorPickerProps {
  className?: string;
  eyeDropperIcon?: boolean;
  children?: JSX.Element;
  buttonSize?: ButtonSize;
  buttonVariant?: ButtonVariant;
  avatarSize?: AvatarSize;
  onClick?: (event: React.MouseEvent) => void;
  withHexPreview?: boolean;
  label?: string;
}

export const ColorPickerIcon: React.FC<ColorPickerIconProps> = ({
  className,
  eyeDropperIcon = false,
  onClick,
  buttonSize,
  buttonVariant = "ghost",
  avatarSize = "24",
  withHexPreview,
  label,
  ...props
}) => {
  const hexPreview = React.useMemo(() => {
    if (withHexPreview) {
      return props.color.slice(0, -2);
    }
    return null;
  }, [withHexPreview, props.color]);

  return (
    <Dropdown>
      <DropdownButton
        variant={buttonVariant}
        size={buttonSize}
        className={classNames("tw-w-full tw-justify-between", className)}
        onClick={onClick}
        showArrow={false}
      >
        {Boolean(hexPreview) && <span className="tw-w-[8ch]">{hexPreview}</span>}

        {Boolean(label) && <span>{label}</span>}

        <Avatar color={eyeDropperIcon ? "#54a6fc" : props.color} size={avatarSize} className="tw-flex">
          {eyeDropperIcon && <EyeDropperIcon />}
        </Avatar>
      </DropdownButton>
      <DropdownMenu>
        <ColorPicker {...props} />
      </DropdownMenu>
    </Dropdown>
  );
};
