import React from "react";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

interface PromptProps {
  title?: string;
  description?: string;
  confirmButtonText?: string;
  placeholder?: string;
  defaultValue?: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
}
export const Prompt: React.FC<PromptProps> = ({
  title,
  description,
  confirmButtonText = "Submit",
  placeholder,
  defaultValue,
  onSubmit,
  onCancel
}) => {
  return (
    <NewModal
      onDismiss={onCancel}
      disableBackgroundDismiss
      size="465"
      className="tw-top-1/2 tw--translate-y-1/2 !tw-pt-4"
      headerClassName="tw-px-4"
      title={title}
      description={description}
    >
      <PromptContainer
        confirmButtonText={confirmButtonText}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </NewModal>
  );
};

const PromptContainer: React.FC<PromptProps> = ({
  confirmButtonText,
  placeholder,
  defaultValue = "",
  onSubmit,
  onCancel
}) => {
  const [value, setValue] = React.useState<string>(defaultValue);
  const [isConfirming, setConfirming] = React.useState<boolean>(false);

  return (
    <div className="tw-flex tw-flex-col tw-p-4 tw-pt-0 tw-text-center">
      <Input
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        value={value}
        variant="outline"
        className="tw-mb-4"
        autoFocus
      />
      <div className="tw-flex tw-justify-end tw-gap-2">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            setConfirming(true);
            await onSubmit(value);
            setConfirming(false);
          }}
          disabled={!value}
          loading={isConfirming}
        >
          {confirmButtonText}
        </Button>
      </div>
    </div>
  );
};
