import React from "react";
import { uniqueId } from "lodash-es";

import { getAccountTeam } from "@frontend/api/account.service";
import { loadFolders } from "@frontend/api/dashboard.service";
import { createFolder, updateFolder } from "@frontend/api/folders.service";
import { addMembersToUserGroup, getUserGroups, removeMemberFromUserGroup } from "@frontend/api/user-groups.service";
import { notificationSuccess } from "@frontend/components/notification";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Avatar } from "@components/avatar/avatar";
import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu, DropdownToggleType } from "@components/dropdown";
import { Input } from "@components/form-controls";
import { NewBadge } from "@components/new-badge/new-badge";
import { NewModal } from "@components/new-modal";
import { Select, SelectOption } from "@components/select";
import { TextButton } from "@components/text-button";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { AccountMember } from "@core/interfaces/account";
import { ModalType } from "@core/interfaces/modal-type";
import { User } from "@core/interfaces/user";
import { UserGroup } from "@core/interfaces/user-group";
import { Folder } from "@core/state/dashboard/dashboard.store";
import { useUserGroups } from "@core/state/user-groups/user-groups.hooks";
import {
  RiAddLine,
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiGlobalLine,
  RiTeamLine
} from "@remixicon/react";

import { CreateUserGroupModal } from "../settings/team/user-groups/create-user-group-modal";

import { AttachUserGroupConfirmation } from "./attach-user-group-confirmation-modal";
import { DetachUserGroupConfirmation } from "./detach-user-group-confirmation-modal";

const DEFAULT_TEAMSPACE: Folder = {
  // `id`, `parentId`, `depth`, `order`, `createdAt`, `updatedAt` are placeholder properties. The BE will overwrite these.
  id: String(uniqueId()),
  name: "New Team folder",
  isPublic: true,
  groupId: null,
  parentId: null,
  depth: 1,
  order: 0,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
};

type Visibility = "global" | "userGroup";

interface TeamspaceModalProps {
  folder?: Folder;
  closeModal: () => void;
}
export const TeamspaceModal: React.FC<TeamspaceModalProps> = ({ folder, closeModal }) => {
  const { isEnterprise } = usePlan();

  const userAction = folder ? "edit" : "create";
  const userGroups = useUserGroups() ?? [];

  React.useEffect(() => {
    getAccountTeam();
  }, []);

  React.useEffect(() => {
    getUserGroups();
  }, []);

  return (
    <NewModal
      onDismiss={closeModal}
      disableBackgroundDismiss
      size="608"
      className="!tw-p-0"
      title={userAction === "edit" ? "Manage Team folder" : "Create a new Team folder"}
      description="Teamspaces are where you organise your workspace files."
      headerClassName="!tw-mb-4 tw-pt-6 tw-px-6"
      showCloseButton={true}
    >
      <TeamspaceModalContainer
        teamspace={folder ?? DEFAULT_TEAMSPACE}
        closeModal={closeModal}
        userAction={userAction}
        userGroups={userGroups}
      />
      {!isEnterprise && (
        <div className="tw-flex tw-items-center tw-border-t tw-border-t-neutral-200 tw-bg-neutral-50 tw-px-6 tw-py-4">
          <div className="tw-r-auto tw-flex tw-items-center">
            <NewBadge label="Enterprise" variant="aux" corner="rounded" className="tw-mr-2" />
            <span className="tw-text-sm tw-text-neutral-900">Create and manage private team folders</span>
          </div>
          <TextButton className="tw-ml-auto" type="link" to={SETTINGS_BILLING_PATH} onClick={closeModal}>
            Upgrade
          </TextButton>
        </div>
      )}
    </NewModal>
  );
};

const TeamspaceModalContainer: React.FC<
  TeamspaceModalProps & {
    teamspace: Folder;
    userAction: "edit" | "create";
    userGroups: UserGroup[];
  }
> = ({ teamspace: initialTeamspace, userAction, userGroups, closeModal }) => {
  const [teamspace, setTeamspace] = React.useState<Folder>(initialTeamspace);
  const [visibility, setVisibility] = React.useState<Visibility>(teamspace.groupId ? "userGroup" : "global");

  const selectedUserGroup = React.useMemo<UserGroup | null>(
    () => userGroups.find((userGroup) => userGroup.id === teamspace.groupId) ?? null,
    [userGroups, teamspace.groupId]
  );

  const canSave = React.useMemo(() => {
    if (visibility === "global") return Boolean(teamspace.name);
    return Boolean(teamspace.name) && Boolean(teamspace.groupId);
  }, [teamspace.name, visibility, teamspace.groupId]);

  const isAttachingGroup = React.useMemo(() => {
    return Boolean(userAction === "edit" && !initialTeamspace.groupId && teamspace.groupId);
  }, [initialTeamspace, teamspace.groupId]);

  const isDetachingGroup = React.useMemo(() => {
    return Boolean(userAction === "edit" && initialTeamspace.groupId && !teamspace.groupId);
  }, [initialTeamspace, teamspace.groupId]);

  const handleRemoveUserGroup = React.useCallback(() => {
    setTeamspace({ ...teamspace, groupId: null });
    setVisibility("global");
  }, [teamspace]);

  const handleChangeName = React.useCallback(async (name: string) => setTeamspace({ ...teamspace, name }), [teamspace]);

  const handleChangeVisibility = React.useCallback(
    async (visibility: "userGroup" | "global") => {
      setVisibility(visibility);
      if (visibility === "global") {
        setTeamspace({ ...teamspace, groupId: null });
      }
    },
    [teamspace]
  );

  const handleSelectUserGroup = React.useCallback(
    (userGroup: UserGroup) => setTeamspace({ ...teamspace, groupId: userGroup.id }),
    [teamspace]
  );

  return (
    <form className="tw-flex tw-flex-col tw-gap-4 tw-px-6 tw-pb-6" action="#">
      <div>
        <h3 className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Name</h3>
        <NameInput value={teamspace.name ?? ""} onChange={handleChangeName} />
      </div>

      <div className="tw-flex tw-flex-col">
        <h3 className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Who can see it?</h3>
        <VisibilityPicker value={visibility} onChange={handleChangeVisibility} />
      </div>

      {visibility === "userGroup" && (
        <div className="tw-flex tw-flex-col">
          <h3 className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Members</h3>
          {selectedUserGroup ? (
            <PortableUserGroupManager userGroup={selectedUserGroup} onRemoveUserGroup={handleRemoveUserGroup} />
          ) : (
            <UserGroupPicker userGroups={userGroups} onSelectUserGroup={handleSelectUserGroup} />
          )}
        </div>
      )}

      <TeamspaceAction
        type={userAction}
        teamspace={teamspace}
        canSave={canSave}
        isAttachingGroup={isAttachingGroup}
        isDetachingGroup={isDetachingGroup}
        onSave={closeModal}
      />
    </form>
  );
};

type NameInputProps = {
  value: string;
  onChange: (value: string) => void;
};

const NameInput: React.FC<NameInputProps> = ({ value, onChange }) => {
  return (
    <Input
      title="Name"
      type="text"
      id="name"
      variant="outline"
      placeholder="Accessibility folder"
      value={value}
      onChange={(e) => onChange(e.target.value as string)}
      autoFocus
    />
  );
};

type VisibilityPickerProps = {
  value: "userGroup" | "global";
  onChange: (value: "userGroup" | "global") => void;
};

const VisibilityPicker: React.FC<VisibilityPickerProps> = ({ value = "global", onChange }) => {
  const { isEnterprise } = usePlan();

  const visibilityOptions = {
    global: {
      label: "Everyone",
      description: "Everyone in the workspace can see it.",
      icon: <RiGlobalLine className="tw-h-5 tw-w-5 tw-shrink-0" />
    },
    userGroup: {
      label: "Group",
      description: "It will only be visible to members of a group.",
      icon: <RiTeamLine className="tw-h-5 tw-w-5 tw-shrink-0" />
    }
  };

  const { label, description, icon } = visibilityOptions[value];

  return (
    <Dropdown>
      <DropdownButton
        size="wrap"
        variant="secondary"
        className="tw-flex tw-gap-2 tw-rounded-lg tw-border !tw-border-neutral-100 tw-px-2 tw-py-[7px] tw-shadow-xs"
      >
        {icon}
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-text-left">
          <h3 className="tw-whitespace-normal tw-text-sm tw-font-normal tw-text-neutral-900">{label}</h3>
          <p className="tw-text-xs tw-font-normal tw-leading-4 tw-text-neutral-600">{description}</p>
        </div>
      </DropdownButton>
      <DropdownMenu placement="bottom-start" showInPortal className="tw-z-[2000] !tw-w-[556px]">
        <DropdownItem
          onClick={() => onChange("global")}
          className="tw-px-4 tw-py-2.5"
          showToggle={DropdownToggleType.CHECKMARK}
          checked={value === "global"}
        >
          <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-text-left">
            <h3 className="tw-whitespace-normal tw-text-sm tw-font-normal tw-text-neutral-900">
              {visibilityOptions.global.label}
            </h3>
            <p className="tw-text-xs tw-font-normal tw-leading-4 tw-text-neutral-600">
              {visibilityOptions.global.description}
            </p>
          </div>
        </DropdownItem>
        <DropdownItem
          onClick={() => onChange("userGroup")}
          className="tw-px-4 tw-py-2.5"
          showToggle={DropdownToggleType.CHECKMARK}
          checked={value === "userGroup"}
          disabled={!isEnterprise}
        >
          <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-text-left">
            <h3 className="tw-flex tw-whitespace-normal tw-text-sm tw-font-normal tw-text-neutral-900">
              {visibilityOptions.userGroup.label}
              {!isEnterprise && <NewBadge label="Enterprise" variant="aux" corner="rounded" className="tw-ml-2" />}
            </h3>
            <p className="tw-text-xs tw-font-normal tw-leading-4 tw-text-neutral-600">
              {visibilityOptions.userGroup.description}
            </p>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

type UserGroupPickerProps = {
  userGroups: UserGroup[];
  onSelectUserGroup: (userGroup: UserGroup) => void;
};

const UserGroupPicker: React.FC<UserGroupPickerProps> = ({ userGroups, onSelectUserGroup }) => {
  const [showCreateUserGroupPrompt, hideCreateUserGroupPrompt] = useModal(ModalType.CreateUserGroup);

  const options = React.useMemo(() => {
    return [
      {
        id: "groups",
        label: "Groups",
        options: userGroups.map((userGroup) => ({
          label: userGroup.name,
          value: userGroup.id,
          extra: userGroup
        }))
      }
    ];
  }, [userGroups]);

  const handleCreateUserGroup = () => {
    showCreateUserGroupPrompt(
      <CreateUserGroupModal
        onDismiss={hideCreateUserGroupPrompt}
        onCreate={(userGroup: UserGroup) => onSelectUserGroup(userGroup)}
      />
    );
  };

  return (
    <Select
      options={options}
      onChange={(_, userGroup) => userGroup && onSelectUserGroup(userGroup)}
      placeholder="Select a group"
      formatOptionLabel={(option) => {
        return (
          <>
            <Avatar
              size="24"
              initials={getInitials(option.label, true, false)}
              withBorder={false}
              color="#000"
              rounded="rounded"
            />
            {option.label}
          </>
        );
      }}
      dropdownFooter={({ dismiss }) => {
        return (
          <SelectOption
            value={{ label: "New group", value: "new-group" }}
            onClick={() => {
              dismiss();
              handleCreateUserGroup();
            }}
            className="tw-font-medium tw-text-primary-500"
          >
            <RiAddLine className="tw-h-5 tw-w-5" />
            New group
          </SelectOption>
        );
      }}
    />
  );
};

type PortableGroupManagerProps = {
  userGroup: UserGroup;
  onRemoveUserGroup: () => void;
};

const PortableUserGroupManager: React.FC<PortableGroupManagerProps> = ({ userGroup, onRemoveUserGroup }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div>
      <header className="tw-flex tw-h-12 tw-items-center tw-gap-2 tw-px-2">
        {isExpanded ? (
          <RiArrowDownSLine
            className="tw-h-5 tw-w-5 tw-shrink-0 tw-cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        ) : (
          <RiArrowRightSLine
            className="tw-h-5 tw-w-5 tw-shrink-0 tw-cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        )}
        <Avatar
          size="32"
          initials={getInitials(userGroup.name, true, false)}
          withBorder={false}
          color="#000"
          rounded="rounded"
        />
        <p className="tw-w-full tw-text-sm tw-text-neutral-700">{userGroup.name}</p>
        <p className="tw-mr-2 tw-whitespace-nowrap tw-text-xs tw-font-medium tw-text-neutral-800">
          {userGroup.members.length} members
        </p>
        <RiCloseLine className="tw-h-4 tw-w-4 tw-shrink-0 tw-cursor-pointer" onClick={() => onRemoveUserGroup()} />
      </header>
      {isExpanded && <PortableGroupTable userGroup={userGroup} />}
    </div>
  );
};

type PortableUserGroupTableProps = {
  userGroup: UserGroup;
};

const PortableGroupTable: React.FC<PortableUserGroupTableProps> = ({ userGroup }) => {
  const { accountTeam } = useAccounts();
  const accountMembers = accountTeam?.members ?? [];

  return (
    <ul className="tw-flex tw-flex-col tw-gap-1 tw-border-b tw-border-t tw-border-neutral-200">
      {userGroup.members.map((member) => (
        <PortableUserGroupTableRow userGroup={userGroup} member={member} key={member.id} />
      ))}
      <li className="tw-flex tw-h-12 tw-items-center tw-pl-14">
        <UserGroupMemberSelector userGroup={userGroup} accountMembers={accountMembers} />
      </li>
    </ul>
  );
};

type PortableUserGroupTableRowProps = {
  userGroup: UserGroup;
  member: Pick<User, "id" | "name" | "email">;
};

const PortableUserGroupTableRow: React.FC<PortableUserGroupTableRowProps> = ({ userGroup, member }) => {
  const [isRemoving, setRemoving] = React.useState(false);
  const handleRemoveMember = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      setRemoving(true);
      await removeMemberFromUserGroup(userGroup.id, member.id);
      notificationSuccess(`"${member.name}" was removed from "${userGroup.name}"`);
    } finally {
      setRemoving(false);
    }
  };
  return (
    <li className="tw-flex tw-h-12 tw-items-center tw-gap-2 tw-pl-16 tw-pr-3  tw-text-neutral-700">
      <Avatar size="24" initials={getInitials(member.name, true, false)} withBorder={false} color="#000" />
      <p className="tw-flex-1 tw-text-sm tw-text-neutral-700">{member.name}</p>
      <Button size="20" variant="text-plain-destructive" onClick={handleRemoveMember} loading={isRemoving}>
        <span className="tw-font-normal">Remove</span>
      </Button>
    </li>
  );
};

type UserGroupMemberSelectorProps = {
  userGroup: UserGroup;
  accountMembers: AccountMember[];
};

const UserGroupMemberSelector: React.FC<UserGroupMemberSelectorProps> = ({ userGroup, accountMembers }) => {
  const [isSelectorVisible, setSelectorVisible] = React.useState(false);
  const [selectedMembers, setSelectedMembers] = React.useState<string[]>([]);
  const [isAddingMembers, setIsAddingMembers] = React.useState(false);

  const options = React.useMemo(() => {
    return accountMembers.map((member) => {
      return {
        label: member.name,
        value: member.id,
        disabled: userGroup.members.some((m) => m.id === member.id)
      };
    });
  }, [accountMembers, userGroup.members]);

  const handleAddMembers = async (selectedUserIds: string[]) => {
    try {
      setIsAddingMembers(true);
      const selectedMembers = accountMembers.filter((member) => selectedUserIds.includes(member.id));
      await addMembersToUserGroup(userGroup.id, selectedUserIds);
      setSelectedMembers([]);
      setSelectorVisible(false);

      if (selectedMembers.length === 1) {
        notificationSuccess(`"${selectedMembers[0].name}" was added to "${userGroup.name}"`);
      } else {
        notificationSuccess(`"${selectedMembers.length}" members were added to "${userGroup.name}"`);
      }
    } finally {
      setIsAddingMembers(false);
    }
  };

  if (!isSelectorVisible) {
    return (
      <Button icon={<RiAddLine />} size="28" variant="text-plain-primary" onClick={() => setSelectorVisible(true)}>
        Add members
      </Button>
    );
  }

  return (
    <div className="tw-flex tw-w-full tw-gap-2">
      <Select
        size="28"
        multiple
        value={selectedMembers}
        options={options}
        onChange={setSelectedMembers}
        className="tw-flex-1"
        placeholder="Add user"
      />
      <Button
        size="28"
        variant="secondary"
        onClick={() => {
          handleAddMembers(selectedMembers);
        }}
        disabled={!selectedMembers.length}
        loading={isAddingMembers}
      >
        Add
      </Button>
    </div>
  );
};

type TeamspaceActionProps = {
  type: "edit" | "create";
  teamspace: Folder;
  canSave: boolean;
  isAttachingGroup: boolean;
  isDetachingGroup: boolean;
  onSave: () => void;
};

const TeamspaceAction: React.FC<TeamspaceActionProps> = ({
  type,
  teamspace,
  isAttachingGroup,
  isDetachingGroup,
  canSave,
  onSave
}) => {
  const [showDetachUserGroupConfirmPrompt, hideDetachUserGroupConfirmPrompt] = useModal(ModalType.DetachUserGroup);
  const [showAttachUserGroupConfirmPrompt, hideAttachUserGroupConfirmPrompt] = useModal(ModalType.AttachUserGroup);

  const [isSavingTeamspace, setSavingTeamspace] = React.useState(false);

  const handleCreateTeamspace = React.useCallback(async () => {
    setSavingTeamspace(true);
    try {
      await createFolder({
        name: teamspace.name,
        groupId: teamspace.groupId,
        isPublic: teamspace.isPublic
      });
      await loadFolders();
      notificationSuccess(`"${teamspace.name}" has been created successfully`);
      onSave();
    } catch (error) {
      console.error(error);
    } finally {
      setSavingTeamspace(false);
    }
  }, [teamspace]);

  const handleSaveTeamspace = React.useCallback(async () => {
    setSavingTeamspace(true);

    const saveTeamspace = async () => {
      try {
        await updateFolder(teamspace);
        await loadFolders();
        notificationSuccess(`"${teamspace.name}" has been updated successfully`);
        onSave();
      } catch (error) {
        console.error(error);
      } finally {
        setSavingTeamspace(false);
      }
    };

    if (isDetachingGroup) {
      showDetachUserGroupConfirmPrompt(
        <DetachUserGroupConfirmation
          onCancel={hideDetachUserGroupConfirmPrompt}
          onConfirm={() => {
            hideDetachUserGroupConfirmPrompt();
            saveTeamspace();
          }}
        />
      );
    } else if (isAttachingGroup) {
      showAttachUserGroupConfirmPrompt(
        <AttachUserGroupConfirmation
          onCancel={hideAttachUserGroupConfirmPrompt}
          onConfirm={() => {
            hideAttachUserGroupConfirmPrompt();
            saveTeamspace();
          }}
        />
      );
    } else {
      saveTeamspace();
    }
  }, [teamspace]);

  return type === "edit" ? (
    <Button
      type="submit"
      className="tw-ml-auto"
      onClick={handleSaveTeamspace}
      disabled={!canSave}
      loading={isSavingTeamspace}
      size="32"
    >
      Save
    </Button>
  ) : (
    <Button
      type="submit"
      className="tw-ml-auto"
      onClick={handleCreateTeamspace}
      disabled={!canSave}
      loading={isSavingTeamspace}
      size="32"
    >
      Create
    </Button>
  );
};
