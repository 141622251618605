export const SensitiveContentIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="48" height="48" rx="24" fill="#F0F2F4" />
    <path
      d="M16.5199 17.9336L13.3929 14.8076L14.8079 13.3926L34.6069 33.1926L33.1919 34.6066L29.8819 31.2966C28.1231 32.4121 26.0826 33.0029 23.9999 32.9996C18.6079 32.9996 14.1219 29.1196 13.1809 23.9996C13.6109 21.6703 14.7825 19.5425 16.5209 17.9336H16.5199ZM26.7569 28.1716L25.2929 26.7076C24.733 26.9755 24.1037 27.0631 23.4918 26.9584C22.88 26.8536 22.3157 26.5616 21.8768 26.1227C21.4378 25.6838 21.1459 25.1195 21.0411 24.5076C20.9364 23.8958 21.024 23.2665 21.2919 22.7066L19.8279 21.2426C19.1916 22.2041 18.9072 23.3559 19.0227 24.5031C19.1383 25.6502 19.6468 26.7222 20.462 27.5375C21.2773 28.3527 22.3493 28.8612 23.4964 28.9767C24.6436 29.0923 25.7954 28.8079 26.7569 28.1716ZM19.9739 15.7596C21.2209 15.2696 22.5799 14.9996 23.9999 14.9996C29.3919 14.9996 33.8779 18.8796 34.8189 23.9996C34.5125 25.6653 33.8238 27.2372 32.8069 28.5916L28.9469 24.7316C29.0616 23.9592 28.9937 23.1707 28.7488 22.4292C28.5039 21.6878 28.0888 21.014 27.5366 20.4618C26.9845 19.9097 26.3107 19.4945 25.5693 19.2497C24.8278 19.0048 24.0393 18.9369 23.2669 19.0516L19.9739 15.7606V15.7596Z"
      fill="#4B5563"
    />
  </svg>
);

export const LoudSoundsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="48" height="48" rx="24" fill="#F0F2F4" />
    <path
      d="M29.5097 20.6853C29.4556 18.1503 27.603 16.0768 25.1426 15.855C22.6635 15.6314 20.4638 17.324 20.021 19.7946C20.0032 19.8919 19.9871 19.9902 19.9592 20.0841C19.8584 20.4237 19.5469 20.6396 19.2217 20.5718C18.8577 20.4965 18.6265 20.2586 18.6756 19.8843C18.7366 19.4263 18.8229 18.9614 18.977 18.5279C19.9727 15.7322 22.8354 14.0879 25.7818 14.5968C28.6394 15.0896 30.7696 17.6398 30.7891 20.5752C30.8035 22.7749 30.0296 24.6215 28.3693 26.0812C27.8181 26.5655 27.2542 27.0354 26.7055 27.5222C25.8699 28.2631 25.3712 29.1343 25.3432 30.3112C25.3043 31.947 23.9708 33.2576 22.3197 33.4397C20.7424 33.6132 19.2099 32.582 18.7578 31.0427C18.6274 30.5999 18.784 30.2435 19.1633 30.1156C19.5299 29.9928 19.8711 30.1986 20.0303 30.6397C20.4071 31.6837 21.319 32.2628 22.318 32.0926C23.3324 31.9199 24.0004 31.0978 24.0283 29.9878C24.0554 28.9116 24.4373 27.9659 25.1722 27.1946C25.7352 26.6044 26.3559 26.0668 26.9748 25.5334C27.9722 24.6748 28.8764 23.7511 29.2075 22.4269C29.3497 21.8562 29.4107 21.2661 29.5081 20.6844L29.5097 20.6853Z"
      fill="#4B5563"
    />
    <path
      d="M22.6618 20.8285C22.5467 21.207 22.7524 21.3933 23.1537 21.5702C24.2366 22.0461 24.8598 23.2687 24.6617 24.3821C24.4415 25.6225 23.4949 26.5276 22.2723 26.6681C21.7338 26.7299 21.3655 26.4971 21.3241 26.0687C21.2843 25.6597 21.5687 25.3955 22.1115 25.3354C22.8108 25.2584 23.3383 24.6928 23.351 24.0078C23.3637 23.3296 22.8515 22.7327 22.164 22.6244C21.4375 22.5092 21.313 22.3746 21.3232 21.6388C21.3317 21.0766 21.3054 20.5043 21.3994 19.9539C21.6856 18.2834 23.1994 17.1218 24.8987 17.2158C26.5565 17.3072 27.9231 18.6382 28.0696 20.3045C28.1187 20.8607 27.8977 21.2121 27.4718 21.257C27.0485 21.301 26.7792 20.9996 26.7343 20.4323C26.6497 19.357 25.7437 18.5374 24.6583 18.5535C23.5838 18.5688 22.7016 19.4349 22.661 20.5136C22.6576 20.5983 22.661 20.6829 22.661 20.8294L22.6618 20.8285Z"
      fill="#374151"
    />
  </svg>
);

export const PhotosensitiveWarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="48" height="48" rx="24" fill="#F0F2F4" />
    <path d="M25 22H32L23 35V26H16L25 13V22Z" fill="#4B5563" />
  </svg>
);

export const EyeOffIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.4666 14.8339L3.64914 7.01895L7.18664 3.48145L56.6841 52.9814L53.1466 56.5164L44.8716 48.2414C40.4747 51.0303 35.3735 52.5073 30.1666 52.4989C16.6866 52.4989 5.47164 42.7989 3.11914 29.9989C4.19421 24.1757 7.12321 18.8562 11.4691 14.8339H11.4666ZM37.0591 40.4289L33.3991 36.7689C31.9993 37.4387 30.4261 37.6578 28.8965 37.3959C27.3669 37.134 25.9562 36.4041 24.8588 35.3068C23.7615 34.2094 23.0316 32.7987 22.7697 31.2691C22.5078 29.7395 22.7269 28.1663 23.3966 26.7664L19.7366 23.1064C18.1459 25.5102 17.4348 28.3898 17.7237 31.2577C18.0126 34.1256 19.2838 36.8055 21.3219 38.8437C23.3601 40.8818 26.04 42.153 28.9079 42.4419C31.7758 42.7308 34.6554 42.0197 37.0591 40.4289ZM20.1016 9.39895C23.2191 8.17395 26.6166 7.49895 30.1666 7.49895C43.6466 7.49895 54.8616 17.1989 57.2141 29.9989C56.4481 34.1632 54.7263 38.0929 52.1841 41.4789L42.5341 31.8289C42.8208 29.898 42.6512 27.9267 42.039 26.0731C41.4267 24.2194 40.3888 22.535 39.0085 21.1546C37.6281 19.7743 35.9436 18.7364 34.09 18.1241C32.2364 17.5119 30.2651 17.3423 28.3341 17.6289L20.1016 9.40145V9.39895Z"
      fill="currentColor"
    />
  </svg>
);

export const HeadphoneIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.167 30H17.667C18.9931 30 20.2648 30.5268 21.2025 31.4645C22.1402 32.4021 22.667 33.6739 22.667 35V47.5C22.667 48.8261 22.1402 50.0979 21.2025 51.0355C20.2648 51.9732 18.9931 52.5 17.667 52.5H10.167C8.84091 52.5 7.56914 51.9732 6.63146 51.0355C5.69378 50.0979 5.16699 48.8261 5.16699 47.5V30C5.16699 16.1925 16.3595 5 30.167 5C43.9745 5 55.167 16.1925 55.167 30V47.5C55.167 48.8261 54.6402 50.0979 53.7025 51.0355C52.7648 51.9732 51.4931 52.5 50.167 52.5H42.667C41.3409 52.5 40.0691 51.9732 39.1315 51.0355C38.1938 50.0979 37.667 48.8261 37.667 47.5V35C37.667 33.6739 38.1938 32.4021 39.1315 31.4645C40.0691 30.5268 41.3409 30 42.667 30H50.167C50.167 24.6957 48.0599 19.6086 44.3091 15.8579C40.5584 12.1071 35.4713 10 30.167 10C24.8627 10 19.7756 12.1071 16.0249 15.8579C12.2741 19.6086 10.167 24.6957 10.167 30Z"
      fill="currentColor"
    />
  </svg>
);

export const FlashlightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M32.667 25H50.167L27.667 57.5V35H10.167L32.667 2.5V25Z" fill="currentColor" />
  </svg>
);
