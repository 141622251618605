/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transcription } from "@getsubly/common";
import { parseToTranscription } from "@getsubly/common/dist/assParser";

export const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      if (event.target.error) {
        reject(event.target.error);
      }

      resolve(event.target.result);
    };

    reader.readAsText(file);
  });

export const readFileAsTranscription = async (file: File): Promise<Transcription | undefined> => {
  try {
    const fileBody = await readFileAsText(file);
    if (!fileBody) {
      return undefined;
    }

    return parseToTranscription(fileBody);
  } catch (error) {
    return undefined;
  }
};

export const readCsvFile = async (file: File): Promise<string[][] | undefined> => {
  try {
    const fileBody = await readFileAsText(file);
    if (!fileBody) {
      return undefined;
    }

    const records = fileBody.split("\n");
    const rows = records.map((r) => r.split(","));
    return rows;
  } catch (error) {
    return undefined;
  }
};
