import React from "react";
import classNames from "classnames";

import { Button, ButtonProps } from "./button";

import styles from "./icon-button.module.scss";

export type IconButtonProps = ButtonProps & {
  children?: never;
  rIcon?: never;
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ icon, ...props }, ref) => {
  const classes = classNames(props.className, styles["icon-button"], {
    [styles["size--16"]]: props.size === "16",
    [styles["size--20"]]: props.size === "20",
    [styles["size--24"]]: props.size === "24",
    [styles["size--28"]]: props.size === "28",
    [styles["size--32"]]: props.size === "32",
    [styles["size--36"]]: props.size === "36",
    [styles["size--40"]]: props.size === "40",
    [styles["size--wrap"]]: props.size === "wrap"
  });

  const renderIcon = (icon: JSX.Element, className: string) => {
    className = classNames(icon.props.className, className);
    const props = { ...icon.props, className };
    return <icon.type {...props} />;
  };

  const iconClasses = props.size === "wrap" ? "tw-shrink-0" : "tw-w-full tw-h-full tw-align-middle tw-shrink-0";

  return (
    <Button {...props} ref={ref} className={classes}>
      {!props.loading ? <div className={styles.inner}>{icon ? renderIcon(icon, iconClasses) : null}</div> : null}
    </Button>
  );
});
