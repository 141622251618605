import React from "react";
import classNames from "classnames";

interface AlertProps {
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  primary?: boolean;
  title?: string;
  closable?: boolean;
  onClose?: () => void;
  className?: string;
  innerClassName?: string;
  children?: React.ReactNode;
}
export const Alert: React.FC<AlertProps> = ({
  success,
  danger,
  warning,
  primary,
  title,
  closable,
  onClose,
  className,
  innerClassName,
  children
}) => {
  return (
    <div
      className={classNames(
        "tw-relative tw-mb-1 tw-rounded tw-border tw-px-5 tw-py-3",
        {
          "tw-border-transparent": Boolean(!success && !danger && !warning && !primary),
          "tw-border-success-200 tw-bg-success-50 tw-text-success-800": success,
          "tw-border-destructive-200 tw-bg-destructive-50 tw-text-destructive-800": danger,
          "tw-border-warning-200 tw-bg-warning-50 tw-text-warning-800": warning,
          "tw-border-primary-200 tw-bg-primary-50 tw-text-primary-800": primary
        },
        className
      )}
      role="alert"
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-flex-col tw-justify-between">
          {title && <h6 className="tw-text-h6">{title}</h6>}

          <div className={classNames("tw-mb-0", innerClassName)}>{children}</div>
        </div>
        {closable && (
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
    </div>
  );
};
