import React from "react";
import classNames from "classnames";

interface FeaturedIconProps {
  className?: string;
  icon: JSX.Element;
  size?: "24" | "32" | "40" | "48" | "64" | "80";
  corners?: "rounded" | "circle";
  theme?: "primary" | "secondary" | "tertiary" | "aux-hard" | "primary-hard";
}
export const FeaturedIcon: React.FC<FeaturedIconProps> = ({
  className,
  icon,
  size,
  corners = "rounded",
  theme = "primary"
}) => {
  const renderIcon = (icon: JSX.Element, className: string) => {
    className = classNames(icon.props.className, className);
    const props = { ...icon.props, className };
    return <icon.type {...props} />;
  };

  return (
    <div
      className={classNames(
        "tw-flex tw-shrink-0 tw-items-center tw-justify-center",
        {
          "tw-h-6 tw-w-6": size === "24",
          "tw-h-8 tw-w-8": size === "32",
          "tw-h-10 tw-w-10": size === "40",
          "tw-h-12 tw-w-12": size === "48",
          "tw-h-16 tw-w-16": size === "64",
          "tw-h-20 tw-w-20": size === "80",
          "tw-rounded-full": corners === "circle",
          "tw-rounded-4": size === "24" && corners === "rounded",
          "tw-rounded-6": size === "32" && corners === "rounded",
          "tw-rounded-8": size === "40" && corners === "rounded",
          "tw-rounded-10": size === "48" && corners === "rounded",
          "tw-rounded-12": size === "64" && corners === "rounded",
          "tw-rounded-16": size === "80" && corners === "rounded",
          "tw-bg-primary-600 tw-text-white": theme === "primary",
          "tw-bg-white tw-text-black tw-shadow-NEW-sm": theme === "secondary",
          "tw-bg-neutral-50 tw-text-black": theme === "tertiary",
          "tw-bg-aux-50 tw-text-aux-800": theme === "aux-hard",
          "tw-bg-primary-50 tw-text-primary-800": theme === "primary-hard"
        },
        className
      )}
    >
      {renderIcon(
        icon,
        classNames("tw-shrink-0", {
          "tw-w-4 tw-h-4": size === "24" || size === "32",
          "tw-w-5 tw-h-5": size === "40",
          "tw-w-6 tw-h-6": size === "48",
          "tw-w-8 tw-h-8": size === "64",
          "tw-w-12 tw-h-12": size === "80"
        })
      )}
    </div>
  );
};
