import React from "react";

interface NavContext {
  showSidebar: boolean;
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}
const NavContext = React.createContext<NavContext | null>(null);

interface NavProviderProps {
  children: React.ReactNode;
}
export const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
  const [showSidebar, toggleSidebar] = React.useState(false);

  return (
    <NavContext.Provider
      value={{
        showSidebar,
        toggleSidebar
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNav = (): NavContext => {
  const context = React.useContext(NavContext);

  if (context === null) {
    throw new Error("useNav must be used within a NavProvider");
  }

  return context;
};
