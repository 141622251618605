import React from "react";
import classNames from "classnames";
import { LinkProps } from "react-router-dom";

import { AnyOnClickHandler } from "@core/interfaces/types";

import styles from "./action-button.module.scss";

export type ActionIconButtonVariant = "for-white-background" | "for-light-grey-background";

export type ActionIconButtonSize = "16" | "20" | "24";

export type ActionIconButtonActionType = "submit" | "button";

export type ActionIconButtonActionState = "default" | "active" | "hover" | "focus" | "disabled";

export type ButtonActionProps = {
  id?: string;
  variant?: ActionIconButtonVariant;
  size?: ActionIconButtonSize;
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  download?: string | boolean;
  form?: string;
  tabIndex?: number;
  icon?: JSX.Element;
  isBlank?: boolean;
  state?: ActionIconButtonActionState;
  linkState?: unknown;
  role?: string;
  tooltipAnchorId?: string;
  hideTooltip?: boolean;
  style?: React.CSSProperties;
  to?: LinkProps["to"] | string;
  type?: "button" | "submit";
  onClick?: AnyOnClickHandler;
  onFocus?: AnyOnClickHandler;
  onKeyDown?: AnyOnClickHandler;
  onKeyUp?: AnyOnClickHandler;
  onMouseDown?: AnyOnClickHandler;
  onMouseMove?: AnyOnClickHandler;
  onPointerDown?: AnyOnClickHandler;
  onPointerEnter?: AnyOnClickHandler;
  onPointerLeave?: AnyOnClickHandler;
};

export const ActionIconButton = React.forwardRef<HTMLButtonElement, ButtonActionProps>(
  (
    {
      id,
      variant = "for-light-grey-background",
      size = "24",
      loading,
      className,
      autoFocus,
      form,
      type = "button",
      tabIndex,
      icon,
      disabled,
      state,
      tooltipAnchorId,
      hideTooltip,
      role,
      onClick,
      onFocus,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseMove,
      onPointerDown,
      onPointerEnter,
      onPointerLeave
    },
    ref
  ) => {
    const isDisabled = disabled || loading;
    const classes = classNames([
      styles["action-button"],
      {
        [styles["size--16"]]: size === "16",
        [styles["size--20"]]: size === "20",
        [styles["size--24"]]: size === "24",

        [styles["type--button"]]: type === "button",
        [styles["type--submit"]]: type === "submit",

        [styles["variant--for-light-grey-background"]]: variant === "for-light-grey-background",
        [styles["variant--for-white-background"]]: variant === "for-white-background",

        [styles.disabled]: isDisabled
      },
      state, // assert state
      className
    ]);

    const loadingIcon = loading ? (
      <LoadingIcon className={classNames(styles["loading-icon"], "tw-h-full tw-w-full")} />
    ) : null;

    const renderIcon = (icon: JSX.Element, className: string) => {
      className = classNames(icon.props.className, styles["icon"], className);
      const props = { ...icon.props, className };
      return <icon.type {...props} />;
    };

    const iconClasses = "tw-w-full tw-h-full tw-align-middle tw-shrink-0";

    return (
      <button
        id={id}
        className={classes}
        data-tooltip-id={tooltipAnchorId}
        data-tooltip-hidden={hideTooltip}
        disabled={isDisabled}
        autoFocus={autoFocus}
        form={form}
        type={type}
        tabIndex={tabIndex}
        onClick={onClick}
        onFocus={onFocus}
        role={role}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        ref={ref}
      >
        {loading ? loadingIcon : <div className={styles.inner}>{icon ? renderIcon(icon, iconClasses) : null}</div>}
      </button>
    );
  }
);

interface LoadingIconProps {
  className?: string;
}
const LoadingIcon: React.FC<LoadingIconProps> = ({ className }) => {
  return (
    <div role="status" className={classNames(className, "tw-flex")}>
      <svg className="tw-animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="tw-opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};
