import React from "react";
import classNames from "classnames";

import { getFullApiDetails } from "@frontend/api/api.service";
import { API_DOCS_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { EditPencilIcon } from "@components/icons";

import { useModal } from "@core/hooks/use-modal";
import { ModalType } from "@core/interfaces/modal-type";

import { useAPI } from "./api.context";
import { APIUpdateBucketModal } from "./api-update-bucket-modal";

export const APIDetails: React.FC = () => {
  const { apiAccount, apiKey, bucket, isApiKeyFetching } = useAPI();
  const [showModal, hideModal] = useModal(ModalType.UpdateBucketName);

  const getApiKey = () => {
    if (!apiAccount) {
      return;
    }

    getFullApiDetails(apiAccount.accountId);
  };

  const handleEditBucket = () => {
    if (!apiAccount) {
      return;
    }

    showModal(
      <APIUpdateBucketModal
        bucket={bucket}
        accountId={apiAccount.accountId}
        onDismiss={hideModal}
        showCloseButton
        disableBackgroundDismiss
      />
    );
  };

  return (
    <>
      <p className="tw-mt-3 tw-text-sm tw-font-medium tw-text-neutral-600">
        The API Documentation is available{" "}
        <a href={API_DOCS_URL} target="_blank" rel="noreferrer" className="tw-text-aux-500">
          here
        </a>
      </p>
      <div className="tw-mt-3 tw-flex tw-w-full tw-max-w-md tw-flex-col tw-items-start tw-space-y-4 tw-rounded-lg tw-border tw-border-neutral-200 tw-p-4">
        <Input
          label="API key"
          value={apiKey ?? "************"}
          wrapperClassName="tw-w-full"
          rightIcon={
            Boolean(apiKey) ? undefined : (
              <Button
                disabled={Boolean(apiKey)}
                size="32"
                onClick={getApiKey}
                className={classNames("tw-relative", {
                  "tw-right-14": isApiKeyFetching,
                  "tw-w-9": true,
                  "-tw-left-8": !isApiKeyFetching
                })}
                style={{ width: "fit-content" }}
                loading={isApiKeyFetching}
              >
                Show
              </Button>
            )
          }
          readOnly
        />

        {bucket && (
          <>
            <Input
              label="AWS S3 Bucket"
              value={bucket}
              wrapperClassName="tw-w-full"
              rightIcon={<EditPencilIcon className="tw-cursor-pointer" onClick={handleEditBucket} />}
              readOnly
            />

            <hr className="tw-h-0.25 tw-w-full tw-border-0 tw-bg-neutral-200" />
            <p className="tw-text-sm tw-font-medium">We need an S3 bucket so the API can read and write files</p>
          </>
        )}
      </div>
    </>
  );
};
