import logoImage4 from "@frontend/assets/images/auth/BITC-logo.jpg";
import logoImage2 from "@frontend/assets/images/auth/collaborasaurus-logo.jpg";
import logoImage from "@frontend/assets/images/auth/customer-company-logo.svg";
import image2 from "@frontend/assets/images/auth/Jess-collaborasaurus.jpg";
import logoImage3 from "@frontend/assets/images/auth/KVM-logo.jpg";
import image from "@frontend/assets/images/auth/lewis-A12.jpg";
import image4 from "@frontend/assets/images/auth/Nathalie-BITC.jpg";
import image3 from "@frontend/assets/images/auth/Oyinkro-KVM.jpg";

export interface Testimonial {
  name: string;
  role: string;
  testimony: string;
  photoURL: string;
  logoURL: string;
  company: string;
}

const TESTIMONY_ONE: Testimonial = {
  name: "Lewis Burgess",
  role: "Content Creator",
  testimony: "Subly has saved me countless hours. Captions used to take 2 hours and now it takes 2 minutes.",
  photoURL: image,
  logoURL: logoImage,
  company: "Arrow Twelve"
};

const TESTIMONY_TWO: Testimonial = {
  name: "Jessica Ruhfus",
  role: "Founder and CEO",
  testimony:
    "A fabulous tool to get the most out of video and podcast content. It's so fast and easy to use, plus the flexibility with caption designs is wonderful. Highly recommended!",
  photoURL: image2,
  logoURL: logoImage2,
  company: "Collabosaurus"
};

const TESTIMONY_THREE: Testimonial = {
  name: "Oyinkro Kagbala",
  role: "Founder and Creative Director",
  testimony:
    "A tool that everyone should have and use for speed, efficiency and accuracy. I can only 100% recommend that as soon as you stop reading this you start using Subly!",
  photoURL: image3,
  logoURL: logoImage3,
  company: "K-OS Visuals"
};

const TESTIMONY_FOUR: Testimonial = {
  name: "Nathalie Pavone",
  role: "Digital Marketing Manager",
  testimony:
    "I love Subly! It has made our team's job so much easier. We're able to add subtitles in record times. Now our sustainability content is accessible too!",
  photoURL: image4,
  logoURL: logoImage4,
  company: "Business in the Community Ireland"
};

export const TESTIMONIALS = [TESTIMONY_ONE, TESTIMONY_TWO, TESTIMONY_THREE, TESTIMONY_FOUR];
