import { BurnPosition, CustomLogoConfig, CustomLogoSize } from "@getsubly/common";

interface IDimension {
  width: number;
  height: number;
}

interface ICalculateLogoPositionParams {
  canvasSize: IDimension;
  videoSize: IDimension;
  logoSize: IDimension;
}

export const calculateLogoPosition = (
  { position, padding }: CustomLogoConfig,
  { canvasSize, videoSize, logoSize }: ICalculateLogoPositionParams
): [number, number] => {
  const widthRatio = canvasSize.width / videoSize.width;
  const heightRatio = canvasSize.height / videoSize.height;
  const yPadding = padding.y * heightRatio;
  const xPadding = padding.x * widthRatio;

  switch (position) {
    case BurnPosition.TopRight:
      return [canvasSize.width - logoSize.width - xPadding, yPadding];

    case BurnPosition.TopLeft:
      return [xPadding, yPadding];

    case BurnPosition.BottomRight:
      return [canvasSize.width - logoSize.width - xPadding, canvasSize.height - logoSize.height - yPadding];

    case BurnPosition.BottomLeft:
      return [xPadding, canvasSize.height - logoSize.height - yPadding];
    case BurnPosition.BottomCenter:
      return [(canvasSize.width - logoSize.width) * 0.5, canvasSize.height - logoSize.height - yPadding];
  }
};

export const calculateLogoSize = ({ size, dimensions }: CustomLogoConfig, videoWidth: number): [number, number] => {
  // This ratio works well for the watermark
  const imageRatio = 300 / 1280;

  const imgWidth = videoWidth * imageRatio;
  const imgHeight = (imgWidth * dimensions.height) / dimensions.width;

  const mediumRatio = 0.75;
  const smallRatio = 0.5;

  switch (size) {
    case CustomLogoSize.Large:
      return [imgWidth, imgHeight];
    case CustomLogoSize.Medium:
      return [imgWidth * mediumRatio, imgHeight * mediumRatio];
    case CustomLogoSize.Small:
      return [imgWidth * smallRatio, imgHeight * smallRatio];
    default:
      return [imgWidth, imgHeight];
  }
};
