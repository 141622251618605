import React, { useEffect } from "react";
import classNames from "classnames";

import { RiCloseLine } from "@remixicon/react";

import styles from "./dialog.module.scss";

export enum DialogAlignment {
  Top = "Top",
  Center = "Center"
}

export enum DialogTransition {
  None = "None",
  FadeUp = "FadeUp",
  SlideUp = "SlideUp"
}

const TIMEOUT = {
  [DialogTransition.None]: 0,
  [DialogTransition.FadeUp]: 250,
  [DialogTransition.SlideUp]: 350
};

export type DialogSize = "465" | "608" | "930";

export interface DialogProps {
  className?: string;
  alignment?: DialogAlignment;
  transition?: DialogTransition;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  size?: DialogSize;
  children?: React.ReactNode;
  onDismiss?: () => void;
}
export const Dialog: React.FC<DialogProps> = ({
  className,
  alignment = DialogAlignment.Center,
  transition = DialogTransition.FadeUp,
  showCloseButton,
  closeOnClickOutside,
  size = "465",
  children,
  onDismiss
}) => {
  const dialogRef = React.useRef(null);

  const fadeOutAndDismiss = () => {
    const dialog = dialogRef.current as null | HTMLDialogElement;
    dialog?.classList.remove("enter");
    setTimeout(() => onDismiss?.(), TIMEOUT[transition]);
  };

  useEffect(() => {
    const dialog = dialogRef.current as any as HTMLDialogElement;

    dialog.showModal();

    setTimeout(() => dialog.classList.add("enter"), 1);

    const handleClick = (event: MouseEvent) => {
      const dialog = dialogRef.current as null | HTMLDialogElement;
      if (closeOnClickOutside && event.target === dialog && (event.target as Node)?.nodeName === "DIALOG") {
        fadeOutAndDismiss();
      }
    };

    dialog?.addEventListener("cancel", fadeOutAndDismiss);
    dialog?.addEventListener("click", handleClick);

    return () => {
      dialog?.removeEventListener("cancel", fadeOutAndDismiss);
      dialog?.removeEventListener("click", handleClick);
      dialog?.close();
    };
  }, []);

  return (
    <dialog
      ref={dialogRef}
      className={classNames(
        transition === DialogTransition.FadeUp && styles.fadeUp,
        transition === DialogTransition.SlideUp && styles.slideUp,
        "tw-m-auto tw-max-h-[calc(100vh_-_48px)] tw-max-w-[calc(100vw_-_48px)] tw-bg-transparent tw-outline-none",
        { "tw-mt-6": alignment === DialogAlignment.Top },
        { "tw-w-[465px]": size === "465" },
        { "tw-w-[608px]": size === "608" },
        { "tw-w-[930px]": size === "930" },
        className
      )}
    >
      {showCloseButton && (
        <RiCloseLine
          className="tw-absolute tw-right-3 tw-top-3 tw-h-6 tw-w-6 tw-cursor-pointer tw-rounded-full tw-bg-transparent tw-p-1 tw-text-neutral-600 tw-transition hover:tw-bg-neutral-100"
          onClick={fadeOutAndDismiss}
        />
      )}
      <div className="tw-w-full tw-rounded-2xl tw-bg-white tw-p-6 tw-shadow-md">{children}</div>
    </dialog>
  );
};
