import React from "react";
import classNames from "classnames";

import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { useEditorLoadedState } from "@media-editor/state/media-editor.hooks";

export interface LockFeatureMessageProps {
  minPermission: SublyPlan;
  className?: string;
  heading: string | React.ReactNode;
  description: string | React.ReactNode;
}

export const LockFeatureMessage: React.FC<LockFeatureMessageProps> = ({
  minPermission,
  className,
  heading,
  description
}) => {
  const { hasPermission } = usePlanPermissions(minPermission);
  const { loaded } = useEditorLoadedState();
  const { settings } = useMediaEditor();

  if (!settings.features.isIframe || hasPermission || !loaded) {
    return null;
  }

  return (
    <div
      className={classNames(
        "tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-border tw-border-aux-200 tw-bg-aux-50 tw-px-5 tw-py-3",
        className
      )}
    >
      {/* <PlanIcon plan={getPlanData(minPermission, accountQuery.planVersion, accountQuery.currency)} /> */}
      <p className="tw-text-sm tw-font-medium tw-text-aux-800">{heading}</p>
      <p className="tw-text-sm tw-text-aux-700">{description}</p>
    </div>
  );
};
