import React from "react";
import { Point } from "slate";

export type FoundOccurrence = {
  // The start point in the transcription.
  anchor: Point;
  // The end point in the transcription.
  focus: Point;
  // Details about the occurrence
  meta: {
    // The amount of characters in the selection.
    charLength: number;
    // The position of this occurrence in the found occurrences.
    position: number | null;
  };
};

interface TranscriptionOccurrencesContext {
  activeOccurrence: FoundOccurrence | null;
  activeOccurrenceIndex: number;
  setActiveOccurrenceIndex: (index: number) => void;
  occurrences: FoundOccurrence[];
  setOccurrences: (occurrences: FoundOccurrence[]) => void;
  navPrev: () => void;
  navNext: () => void;
}

const TranscriptionOccurrencesContext = React.createContext<TranscriptionOccurrencesContext | null>(null);

interface TranscriptionOccurrencesProviderProps {
  children: React.ReactNode;
}
export const TranscriptionOccurrencesProvider: React.FC<TranscriptionOccurrencesProviderProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const [occurrences, setOccurrences] = React.useState<FoundOccurrence[]>([]);

  const setActiveOccurrenceIndex = (activeIndex: number) => {
    const lastIndex = Math.max(occurrences.length - 1, 0);

    // cap min `0`
    if (activeIndex < 0) {
      activeIndex = 0;
    }
    // cap max `last index`
    else if (activeIndex > lastIndex) {
      activeIndex = lastIndex;
    }

    setActiveIndex(activeIndex);
  };

  React.useEffect(() => {
    setActiveOccurrenceIndex(activeIndex);
  }, [occurrences]);

  const activeOccurrence = React.useMemo<FoundOccurrence | null>(() => {
    return occurrences?.[activeIndex] ?? null;
  }, [activeIndex, occurrences]);

  const getPrevIndex = () => {
    if (!occurrences.length) return 0;
    if (!activeIndex) return occurrences.length - 1;
    return activeIndex - 1;
  };

  const getNextIndex = () => {
    if (!occurrences.length) return 0;
    if (activeIndex + 1 >= occurrences.length) return 0;
    return activeIndex + 1;
  };

  const navPrev = () => {
    setActiveOccurrenceIndex(getPrevIndex());
  };

  const navNext = () => {
    setActiveOccurrenceIndex(getNextIndex());
  };

  return (
    <TranscriptionOccurrencesContext.Provider
      value={{
        activeOccurrence,
        activeOccurrenceIndex: activeIndex,
        setActiveOccurrenceIndex,
        occurrences,
        setOccurrences,
        navPrev,
        navNext
      }}
    >
      {children}
    </TranscriptionOccurrencesContext.Provider>
  );
};

export const useTranscriptionOccurrences = (): TranscriptionOccurrencesContext => {
  const context = React.useContext(TranscriptionOccurrencesContext);

  if (context === null) {
    throw new Error("useTranscriptionOccurrences must be used within a TranscriptionOccurrencesProvider");
  }

  return context;
};
