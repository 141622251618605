import React from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

import { LockFeature } from "@frontend/components/lock-feature/lock-feature";

import { Button } from "@components/button";
import { FolderPlusIcon } from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useFileUploadQueue } from "@core/hooks/use-file-upload-queue";
import { useModal } from "@core/hooks/use-modal";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { VirtualFolderId } from "@core/state/dashboard/dashboard.store";
import { ACCEPTED_DROPZONE_FORMATS } from "@core/utils/mime-types";
import { RiAddLine } from "@remixicon/react";

import { DashboardUploadModal } from "../dashboard-upload-modal/dashboard-upload-modal";

type DashboardUploadProps = {
  className?: string;
};

export const DashboardUpload: React.FC<DashboardUploadProps> = ({ className }) => {
  const fileRef = React.useRef<HTMLInputElement>(null);
  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);
  const { isViewer } = useAccounts();

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { selectedFolderId } = useDashboard();
  const { handleAddFilesToUploadQueue } = useFileUploadQueue();
  const [showModal, hideModal] = useModal(ModalType.Upload);
  const showUploadCard = selectedFolderId !== VirtualFolderId.AllSharedWithMe;

  const onDrop = React.useCallback(
    async (droppedFiles: File[]) => {
      if (hasPermission && !isViewer && droppedFiles.length > 0) {
        showModal(<DashboardUploadModal closeModal={hideModal} />);

        handleAddFilesToUploadQueue(droppedFiles, selectedFolderId ?? undefined);
      }
    },
    [hasPermission, selectedFolderId]
  );

  const { getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => onDrop(files),
    accept: ACCEPTED_DROPZONE_FORMATS,
    minSize: 0,
    multiple: true,
    noClick: true
  });

  if (!showUploadCard) {
    return null;
  }

  return (
    <LockFeature
      minPermission={SublyPlan.Pro}
      className={classNames("tw-flex tw-h-auto tw-flex-1 tw-flex-col tw-justify-center", className)}
    >
      <div className="tw-flex tw-h-full tw-min-h-[240px] tw-cursor-default tw-items-center tw-justify-center tw-space-y-5">
        <div className="tw-mx-auto tw-flex tw-max-w-md tw-flex-col tw-items-center tw-justify-center tw-gap-2">
          <input {...getInputProps()} ref={fileRef} />

          <div
            className={classNames(
              "tw-flex tw-h-[98px] tw-w-[98px] tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-50",
              { "!tw-bg-neutral-50": isDragActive }
            )}
          >
            <FolderPlusIcon />
          </div>

          <h3 className="tw-text-lg tw-font-semibold tw-text-neutral-900">Start by uploading a file</h3>

          <div className="tw-mb-4 tw-text-center tw-text-sm tw-text-neutral-600">
            Click the button below or drag to upload
            <br />
            one or multiple files.
          </div>

          <Button
            size="40"
            onClick={() => {
              fileRef.current?.click();
              trackEventWithAuth("Dashboard / Upload button");
            }}
            disabled={isViewer}
            className="tw-w-full"
          >
            Upload
          </Button>
        </div>
      </div>
    </LockFeature>
  );
};

type DashboardUploadButtonProps = {
  disabled?: boolean;
};
export const DashboardUploadButton: React.FC<DashboardUploadButtonProps> = ({ disabled }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideModal] = useModal(ModalType.Upload);

  const handleClickUpload = () => {
    trackEventWithAuth("Dashboard / Upload button");
    showModal(<DashboardUploadModal closeModal={() => hideModal()} />);
  };

  return (
    <LockFeature minPermission={SublyPlan.Pro}>
      <Button icon={<RiAddLine />} size="32" onClick={handleClickUpload} disabled={disabled} raised>
        Upload
      </Button>
    </LockFeature>
  );
};
