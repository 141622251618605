import React from "react";
import classNames from "classnames";

import styles from "./progress-bar.module.scss";

type ProgressBarTheme = "purple" | "blue";

interface ProgressBarProps {
  progress: number;
  loading?: boolean;
  className?: string;
  theme?: ProgressBarTheme;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, loading, className, theme = "purple" }) => {
  if (loading) {
    return (
      <div className={classNames(styles["progress"], className)}>
        <div
          className={classNames("progress-bar progress-bar-striped progress-bar-animated", {
            "!tw-bg-aux-500": theme === "purple",
            "!tw-bg-primary-500": theme === "blue"
          })}
          role="progressbar"
          style={{ width: "100%" }}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles["progress"],
        {
          "!tw-bg-[#f2e5ff]": theme === "purple",
          "!tw-bg-neutral-300": theme === "blue"
        },
        className
      )}
    >
      <div
        className={classNames("progress-bar", {
          "!tw-bg-aux-500": theme === "purple",
          "!tw-bg-primary-500": theme === "blue"
        })}
        role="progressbar"
        style={{ width: `${progress ?? 0}%` }}
      />
    </div>
  );
};
