import React from "react";
import classNames from "classnames";

import { amountRule, lowercaseRule, numberRule, symbolRule, uppercaseRule } from "@core/utils/regex-patterns";
import { mdiCheckCircle, mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";

import "./password-rules.scss";

interface PasswordRulesProps {
  value: string;
  className?: string;
}
export const PasswordRules: React.FC<PasswordRulesProps> = ({ value, className }) => {
  return (
    <div className={classNames("tw-flex tw-w-full tw-flex-row tw-flex-wrap", className)}>
      <div className="tw-flex tw-w-[50%] tw-flex-col">
        <PasswordRule label="one lowercase character" rule={lowercaseRule} value={value} />
        <PasswordRule label="one uppercase character" rule={uppercaseRule} value={value} />
        <PasswordRule label="one number" rule={numberRule} value={value} />
      </div>
      <div className="tw-flex tw-w-[50%] tw-flex-col">
        <PasswordRule label="one special character" rule={symbolRule} value={value} />
        <PasswordRule label="8 character minimum" rule={amountRule} value={value} />
      </div>
    </div>
  );
};

interface PasswordRuleProps {
  value: string;
  label: string;
  rule: RegExp;
}
const PasswordRule: React.FC<PasswordRuleProps> = ({ label, rule, value }) => {
  const isValid = rule.test(value);
  const icon =
    value && isValid ? (
      <Icon path={mdiCheckCircle} className="icon success" size="1rem" />
    ) : (
      <Icon path={mdiInformationOutline} className="icon warning" size="1rem" />
    );

  return (
    <span className="rule">
      {icon} {label}
    </span>
  );
};
