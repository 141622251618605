import React, { useState } from "react";
import classNames from "classnames";

import audioThumbnail from "@frontend/assets/images/audio-thumbnail.jpg";
import uploadThumbnail from "@frontend/assets/images/upload-thumbnail.png";

import { FileType } from "@getsubly/common";

import styles from "./thumbnail-image.module.scss";

interface ThumbnailImageProps {
  src?: string;
  alt?: string;
  mediaType?: FileType;
  className?: string;
}
export const ThumbnailImage: React.FC<ThumbnailImageProps> = ({ src, alt, mediaType = FileType.Video, className }) => {
  const [loaded, setLoaded] = useState(false);

  if (!src) {
    if (mediaType === FileType.Audio) {
      src = audioThumbnail;
    } else {
      src = uploadThumbnail;
    }
  }

  return (
    <img
      src={src}
      className={classNames(styles.thumbnail, className, {
        [styles["loaded"]]: loaded
      })}
      onLoad={() => setLoaded(true)}
      alt={alt}
    />
  );
};
