import { combineLatest, map, Observable } from "rxjs";

import { EnrichedMediaListItem, MediaActiveJob } from "@core/interfaces/media";
import { buildTree, isTeamspaceWithGroup } from "@core/utils/folders";
import { createStore, select, withProps } from "@ngneat/elf";

export enum VirtualFolderId {
  All = "all", // `media.filter(media => media.userId === myUserId)` - All media except the ones shared with me.
  AllPrivate = "allPrivate", // `media.filter(media => !media.isPublic && media.userId === myUserId)` - All my private media.
  AllShared = "allShared", // `media.filter(media => media.isPublic)` - All public media in the workspace aka “team” media.
  AllSharedWithMe = "allSharedWithMe" // media.filter(media => media.sharedWithMe && media.userId !== myUserId) - All media shared with me.
}

export type FolderId = VirtualFolderId | string | null;

export interface Folder {
  id: string;
  name: string;
  isPublic?: boolean;
  isFavourite?: boolean;
  sharedWithMe?: boolean;
  count?: number;
  userId?: string;
  parentId: string | null;
  groupId: string | null;
  order: number;
  depth: number;
  customDepth?: number; // Use this to change the depth of the folder in the folder tree menu.
  createdAt: string;
  updatedAt: string;
  children?: Folder[];
}

export type DashboardCategory = "workspace" | "private" | "sharedWithMe";

export interface DashboardStore {
  isLoadingFolders: boolean;
  hasLoadedFolders: boolean;
  isLoadingFolderMedia: boolean;
  hasLoadedFolderMedia: boolean;
  teamspaces: Folder[];
  folders: Folder[];
  refreshMediaListKey?: number;
  activeCategory: DashboardCategory;
  activeSpaceId: string | null;
  selectedFolderId: string | null;
  lastPath: string | null;
  media: EnrichedMediaListItem[];
  totalMediaCount: number;
  error?: string;
}

export const dashboardStore = createStore(
  { name: "dashboard" },
  withProps<DashboardStore>({
    isLoadingFolders: false,
    hasLoadedFolders: false,
    isLoadingFolderMedia: true,
    hasLoadedFolderMedia: false,
    teamspaces: [],
    folders: [],
    activeCategory: "workspace",
    activeSpaceId: null,
    selectedFolderId: null,
    lastPath: null,
    media: [],
    totalMediaCount: 0
  })
);

class DashboardRepository {
  folders$: Observable<Folder[]> = dashboardStore.pipe(
    select((state) => {
      const folders = state.folders;

      return folders.map((folder) => {
        const isPublicRoot = !folder.parentId && folder.isPublic && folder.depth === 0;
        const isPrivateRoot = !folder.parentId && !folder.isPublic && folder.depth === 0;

        if (isPublicRoot) {
          return { ...folder, name: "Team folder" };
        } else if (isPrivateRoot) {
          return { ...folder, name: "Personal folder" };
        }

        return folder;
      });
    })
  );
  folderTree$: Observable<Folder[]>;
  teamspacesDrive$: Observable<Folder | null>;
  privateDrive$: Observable<Folder | null>;
  favouritesDrive$: Observable<Folder | null>;
  favouriteFolders$: Observable<Folder[]>;
  teamspaces$: Observable<Folder[]>;
  dashboardExplorerFolders$: Observable<Folder[]>;

  constructor() {
    this.folderTree$ = this.folders$.pipe(map((folders) => buildTree(folders)));

    this.teamspacesDrive$ = this.folderTree$.pipe(
      map((folderTree): Folder | null => {
        const rootFolder = folderTree.find((folder) => Boolean(folder.isPublic) && !folder.parentId);

        if (!rootFolder) return null;

        return {
          ...rootFolder,
          children: folderTree.filter((folder) => folder.isPublic && folder.parentId && folder.depth === 1)
        };
      })
    );

    this.privateDrive$ = this.folderTree$.pipe(
      map((folderTree): Folder | null => {
        const rootFolder = folderTree.find((folder) => !folder.isPublic && !folder.parentId);

        if (!rootFolder) return null;

        return {
          ...rootFolder,
          children: folderTree.filter((folder) => !folder.isPublic && folder.parentId && folder.depth === 1)
        };
      })
    );

    function computeCustomDepth(folder: Folder, depth = 1): Folder {
      return {
        ...folder,
        customDepth: depth,
        children: folder?.children?.map((child) => computeCustomDepth(child, depth + 1)) ?? []
      };
    }

    this.favouritesDrive$ = this.folderTree$.pipe(
      map((folderTree): Folder | null => {
        const rootFolder = folderTree.find((folder) => Boolean(folder.isPublic) && !folder.parentId);

        if (!rootFolder) return null;

        const favouriteFolders = folderTree
          .filter((folder) => folder.isFavourite && folder.depth > 0)
          .map((folder) => (folder.depth > 0 ? computeCustomDepth(folder, 0) : folder));

        return {
          name: "Favourites",
          id: "favourites",
          parentId: null,
          groupId: null,
          order: -1,
          depth: -1,
          children: favouriteFolders,
          updatedAt: "",
          createdAt: ""
        };
      })
    );

    const isFavourite = (folder: Folder) => folder.isFavourite;

    this.favouriteFolders$ = this.folders$.pipe(map((folders) => folders.filter(isFavourite)));
    this.teamspaces$ = this.folders$.pipe(map((folders) => folders.filter(isTeamspaceWithGroup)));

    // get the current folders
    this.dashboardExplorerFolders$ = combineLatest([
      dashboardStore.pipe(select((state) => state.selectedFolderId))
    ]).pipe(
      map(([selectedFolderId]) => {
        if (selectedFolderId) {
          return [];
        }

        return [];
      })
    );

    this.dashboardExplorerFolders$.subscribe();
  }

  get media(): EnrichedMediaListItem[] {
    return dashboardStore.getValue().media;
  }

  updateState = (props: Partial<DashboardStore>) => {
    dashboardStore.update((store) => ({ ...store, ...props }));
  };

  updateMediaActiveJob = ({
    mediaId,
    jobId,
    data
  }: {
    mediaId: string;
    jobId: string;
    data: Partial<MediaActiveJob>;
  }) => {
    const media = dashboardStore.getValue().media.find((m) => m.mediaId === mediaId);
    if (!media) {
      return;
    }

    const job = media.activeJobs.find((j) => j.id === jobId);
    if (!job) {
      return;
    }

    const updatedJob = { ...job, ...data };

    const updatedMedia: EnrichedMediaListItem = {
      ...media,
      activeJobs: [...media.activeJobs.filter((j) => j.id !== jobId), updatedJob],
      latestJob: media.latestJob?.id === jobId ? updatedJob : media.latestJob
    };

    this.updateMedia(mediaId, updatedMedia);
  };

  addMediaTranslations = ({ mediaId, languages }: { mediaId: string; languages?: string[] }) => {
    if (!languages?.length) {
      return;
    }

    const media = dashboardStore.getValue().media.find((m) => m.mediaId === mediaId);
    if (!media) {
      return;
    }

    const updatedLanguages = [...new Set([...media.translationLanguages, ...languages])];
    this.updateMedia(mediaId, { translationLanguages: updatedLanguages });
  };

  prependMedia = (media: EnrichedMediaListItem) => {
    dashboardStore.update((store) => ({ ...store, media: [media, ...store.media] }));
  };

  updateMedia = (mediaId: string, data: Partial<EnrichedMediaListItem>) => {
    dashboardStore.update((store) => {
      const mediaIndex = store.media.findIndex((m) => m.mediaId === mediaId);
      if (mediaIndex === -1) {
        return store;
      }

      const updatedMedia: EnrichedMediaListItem = {
        ...store.media[mediaIndex],
        ...data
      };

      const updatedMediaList = store.media.map((mediaItem, index) => (index === mediaIndex ? updatedMedia : mediaItem));

      return {
        ...store,
        media: updatedMediaList
      };
    });
  };

  removeMedia = (mediaId: string) => {
    dashboardStore.update((store) => {
      return {
        ...store,
        media: store.media.filter((m) => m.mediaId !== mediaId)
      };
    });
  };

  refreshMediaList = () => {
    this.updateState({ refreshMediaListKey: Math.random() });
  };

  resetState = () => {
    dashboardStore.reset();
  };
}

export const dashboardRepository = new DashboardRepository();
