import React from "react";
import classNames from "classnames";

import { DEFAULT_PRIMARY_COLORS_V2 } from "@core/interfaces/account";
import { compareStrings } from "@core/utils/strings";
import { hexToRgba, isValidHex, rgbaToHex } from "@getsubly/common/dist/assParser/colors";
import { RgbaColor, RgbaColorPicker } from "@react-colorful";

import { AddColorElement } from "./add-color-element";
import { ColorElement } from "./color-element";
import { ColorInputs } from "./color-inputs";

import styles from "./color-picker.module.scss";

export interface ColorPickerProps {
  color: string; // Expect hex color
  onChange: (color: string) => void;
  showCustomColors?: boolean;
  customColors?: string[];
  addCustomColor?: (color: string) => void;
  deleteCustomColor?: (color: string) => void;
  showDefaultColors?: boolean;
  showAlpha?: boolean;
}
export const ColorPicker: React.FC<ColorPickerProps> = ({
  color: propsColor,
  onChange,
  showCustomColors,
  customColors: additionalColors,
  addCustomColor,
  deleteCustomColor,
  showDefaultColors = true,
  showAlpha
}) => {
  const [color, setColor] = React.useState(propsColor || "#000000");
  const [edit, setEdit] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const handleChange = (rgba: RgbaColor) => {
    const hex = rgbaToHex(rgba);
    const newHex = showAlpha ? hex : hex.slice(0, 7);
    handleHexChange(newHex);
  };

  const handleHexChange = (hex: string) => {
    setColor(hex);
  };

  React.useEffect(() => {
    if (propsColor !== color) {
      if (isValidHex(color)) {
        onChange(color);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [color]);

  const toggleEdit = () => {
    if (edit) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  };

  const handleAddColor = () => {
    if (!isValid) return;

    addCustomColor?.(color);
  };

  const renderDefaultColors = React.useMemo(() => {
    return (
      <div className="tw-mx-[5px] tw-grid tw-grid-cols-8 tw-justify-center tw-gap-2">
        {DEFAULT_PRIMARY_COLORS_V2?.map((c) => {
          return (
            <ColorElement
              key={c}
              color={c}
              selected={compareStrings(c, color?.slice(0, 7))}
              onClick={() => handleHexChange(c)}
            />
          );
        })}
      </div>
    );
  }, [color]);

  const renderCustomColors = React.useMemo(() => {
    if (!showCustomColors) {
      return null;
    }

    const customColors = additionalColors?.filter(
      (c) => isValidHex(c) && !DEFAULT_PRIMARY_COLORS_V2.includes(c.slice(0, 7))
    );

    return (
      <div className="tw-mx-[5px] tw-grid tw-grid-cols-8 tw-justify-center tw-gap-2">
        {addCustomColor && <AddColorElement onClick={handleAddColor} disabled={!isValid || edit} />}
        {customColors?.map((c) => {
          return (
            <ColorElement
              key={c}
              color={c}
              selected={compareStrings(c, color?.slice(0, 7))}
              onClick={() => handleHexChange(c)}
              onDelete={() => deleteCustomColor?.(c)}
              trash={edit}
            />
          );
        })}
      </div>
    );
  }, [additionalColors, color, isValid, edit, addCustomColor]);

  return (
    <div className="tw-flex tw-w-[270px]">
      <div
        className={classNames(
          styles["react-colorful"],
          {
            [styles["hide-alpha"]]: !showAlpha
          },
          "tw-flex tw-w-[270px] tw-flex-col tw-justify-center tw-gap-4 tw-p-4"
        )}
      >
        <RgbaColorPicker color={hexToRgba(color)} onChange={handleChange} />

        <ColorInputs
          color={color}
          onChange={(c) => setColor(c.color)}
          showAlpha={showAlpha}
          // showAlpha
        />

        {Boolean(showDefaultColors) && renderDefaultColors}

        {showCustomColors && (
          <div className="tw-mx-[2px] tw-flex tw-items-center tw-justify-between tw-text-xs">
            <p className="tw-text-xs tw-font-medium tw-text-neutral-800">Saved colors:</p>
            <p
              className="tw-cool-gray-500 tw-cursor-pointer tw-text-xs tw-font-medium tw-text-neutral-500"
              onClick={toggleEdit}
            >
              {edit ? "Done" : "Edit"}
            </p>
          </div>
        )}

        {renderCustomColors}
      </div>
    </div>
  );
};
