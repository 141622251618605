import { IMediaInfo } from "@core/hooks/use-media-info";
import { Store, StoreConfig } from "@datorama/akita";
import { Transcription } from "@getsubly/common";

import { VirtualFolderId } from "../dashboard/dashboard.store";

export enum UploadingStatus {
  Analyzing = "analyzing", // The initial status of the file. During this phase, the file undergoes validation and normalization for upload.
  AnalysisError = "error", // This status is set if the analysis fails.
  Staged = "staged", // The status changes to 'staged' if the analysis is successful. If the analysis fails, it changes to 'error'.
  Queued = "queued", // The status changes to 'queued' when the file is submitted for upload.
  Uploading = "uploading", // This status is set immediately before the files are sent to the backend for upload.
  Uploaded = "uploaded", // This status is intended to be used when the file has been successfully uploaded. Currently, it is not being used or set anywhere in the code.
  Complete = "complete" // This status is set when the file has been successfully uploaded.
}

export interface UploadFileBase {
  uploadId: string; // A temporary local id for tracking file upload.
  mediaId?: string; // Actual id return by the back after file has completed upload.
  folderId?: string;
  error?: UploadErrorType | string;
  warning?: string;
  mediaInfo?: IMediaInfo;
  filename: string;
  mediaName: string;
  needsTrim?: boolean; // UploadFile only, when uploading transcription files that exceed the media duration.
  uploadingStatus: UploadingStatus;
  uploadProgress: number;
  languageCode?: string;
}

export interface UploadFile extends UploadFileBase {
  file: File;
}

export interface UploadUrlFile extends UploadFileBase {
  url: string;
  authorization?: string;
  isUrl: true;
}

export interface UploadOneDriveUrlFile extends UploadUrlFile {
  itemUrl: string;
  integration: string;
  drive: string;
  uploadPath: string;
}

export interface UploadZoomFile extends UploadFileBase {
  meetingId: number;
  fileId: string;
  isZoom: true;
}

export type UploadFileType = UploadFile | UploadUrlFile | UploadOneDriveUrlFile | UploadZoomFile;

export const isUploadFile = (file: UploadFileType): file is UploadFile => Boolean((file as UploadFile)?.file);
export const isUploadUrlFile = (file: UploadFileType): file is UploadUrlFile => Boolean((file as UploadUrlFile)?.isUrl);
export const isUploadOneDriveFile = (file: UploadFileType): file is UploadOneDriveUrlFile =>
  Boolean((file as UploadOneDriveUrlFile)?.drive);
export const isUploadZoomFile = (file: UploadFileType): file is UploadZoomFile =>
  Boolean((file as UploadZoomFile)?.isZoom); // eslint-disable-line

export enum UploadErrorType {
  File = "Error loading this file. Try again.",
  Credit = "Insufficient credits.",
  Storage = "Insufficient storage.",
  Duration = "The file is over maximum duration of 4 hours.",
  FileSize = "The file is too large.",
  FileSizeMax512MB = "The file is too large (max 512MB).",
  FileSizeMax1GB = "The file is too large (max 1GB).",
  FileSizeMax2GB = "The file is too large (max 2GB).",
  FileSizeMax5GB = "The file is too large. Please upload a file under 5GB.",
  Language = "Select a language."
}

export interface UploadState {
  isCheckingFile: boolean;
  thumbnail?: string;
  mediaName: string;
  language: string;
  folderId?: string;
  transcription?: Transcription;
  queue: UploadFileType[];
  isUploading: boolean;
  customVocabulary: string[];
  notifyUser: boolean;
}

export const createInitialState = (): UploadState => ({
  isCheckingFile: false,
  mediaName: "",
  language: "",
  folderId: VirtualFolderId.All,
  transcription: undefined,
  queue: [],
  isUploading: false,
  customVocabulary: [],
  notifyUser: false
});

@StoreConfig({ name: "upload", resettable: true })
export class UploadStore extends Store<UploadState> {
  constructor() {
    super(createInitialState());
  }

  clearUploadsWithErrors = (): void => {
    this.update((state) => ({
      queue: state.queue.filter((file) => !file.error)
    }));
  };

  addFilesToQueue = (files: UploadFileType[], folderId?: string): UploadState => {
    const uploadIds = files.map((file) => file.uploadId);

    files = files.map((file) => ({
      ...file,
      folderId: folderId ?? file.folderId
    }));

    this.update((state) => {
      const filteredQueueFiles = state.queue.filter((file) => !uploadIds.includes(file.uploadId));
      const updatedQueue = [...filteredQueueFiles, ...files];
      return { ...state, queue: updatedQueue };
    });

    return this.getValue();
  };

  updateFileInQueue = (uploadId: string, updatedFile: Partial<UploadFileType>): void => {
    const queue = this.getValue().queue;
    const updatedQueue = queue.map((file) => (file.uploadId === uploadId ? { ...file, ...updatedFile } : file));
    this.update((state) => ({
      ...state,
      queue: updatedQueue,
      isUploading: state.isUploading
    }));
  };

  handleCheckLanguages = (): void => {
    const queue = this.getValue().queue;
    for (const file of queue) {
      if (!file.error && !file.languageCode) {
        this.updateFileInQueue(file.uploadId, {
          error: UploadErrorType.Language
        });
      } else if (file.languageCode && file.error === UploadErrorType.Language) {
        this.updateFileInQueue(file.uploadId, {
          error: undefined
        });
      }
    }
  };

  removeFileByUploadId = (uploadId: string): void => {
    this.update((state) => ({
      ...state,
      queue: [...state.queue.filter((file) => file.uploadId !== uploadId)]
    }));
  };

  removeFileByMediaId = (mediaId: string): void => {
    this.update((state) => {
      return {
        queue: [...state.queue.filter((file) => file.mediaId !== mediaId)]
      };
    });
  };

  // VERSION AGNOSTIC METHODS

  setCheckingFile = (isCheckingFile: boolean): void => {
    this.update((state) => ({ ...state, isCheckingFile }));
  };

  setTranscription = (transcription?: Transcription): void => {
    this.update((state) => ({ ...state, transcription }));
  };

  resetUpload = (): void => {
    this.update(createInitialState());
  };

  addWordsToVocabulary = (words: string[]): void => {
    this.update((state) => ({ ...state, customVocabulary: words }));
  };

  setNotifyUser = (notifyUser: boolean): void => {
    this.update((state) => ({ ...state, notifyUser }));
  };
}

export const uploadStore = new UploadStore();
