import React from "react";

import { Button } from "@components/button";

// We provide a list of errors to opt-in to displaying the error page on. This
// ensures that we don't accidentally show the error page on a benign error.
const HANDLE_LIST: string[] = ["[Slate] initialValue is invalid! Expected a list of elements but got"];

const shouldHandle = (error: Error) => {
  return HANDLE_LIST.some((rule) => error.message.startsWith(rule));
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: shouldHandle(error), error };
  }

  render() {
    const reload = () => location.reload();

    if (this.state.hasError) {
      return (
        <div className="tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center tw-text-center">
          <h1 className="tw-mb-2 tw-text-h1">Something went wrong</h1>
          <p className="tw-mb-4">
            Please reload the page or{" "}
            <a
              href="https://www.getsubly.com/contact/support"
              target="_blank"
              rel="noreferrer"
              className="tw-text-primary-600"
            >
              contact support@getsubly.com
            </a>{" "}
            if the problem persists.
          </p>
          <Button type="button" variant="primary" onClick={reload} size="36">
            Reload
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
