import axios from "axios";

import config from "@frontend/config";

import { IFullApiDetails, UserAccount } from "@core/interfaces/account";
import { accountStore } from "@core/state/account";
import { authStore } from "@core/state/auth/auth.store";

import { updateAccountInStore } from "./account.service";
import { getAccessToken } from "./auth.service";
import { getAccount } from "./billing.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface ISetupApiAccount {
  accountId: string;
  accountName: string;
}
export const setupApiAccount = async (data: ISetupApiAccount): Promise<UserAccount> => {
  accountStore.update((s) => ({
    ...s,
    isCreatingApi: true
  }));

  try {
    // Setup API
    await setupApiKey(data.accountId, data.accountName);
  } catch (error) {
    handleError(error);
  }

  // Get account details
  const { account: updatedApiAccount } = await getAccount(data.accountId);

  updateAccountInStore(updatedApiAccount);
  accountStore.update((s) => ({
    ...s,
    ...updatedApiAccount,
    isCreatingApi: false
  }));

  return updatedApiAccount;
};

const setupApiKey = async (accountId: string, apiName: string): Promise<void> => {
  await axios.post(
    `/accounts/${accountId}/setup-api`,
    { apiName },
    {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

interface IFullDetailsResponse {
  apiAccountDetails: IFullApiDetails[];
}
export const getFullApiDetails = async (accountId: string): Promise<void> => {
  accountStore.update({
    apiKeyDetails: {
      isLoading: true,
      details: []
    }
  });

  try {
    const { data } = await axios.get<IFullDetailsResponse>(`/accounts/${accountId}/api-details`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.update({
      apiKeyDetails: {
        details: data.apiAccountDetails,
        isLoading: false
      }
    });
  } catch (error) {
    handleError(error);
  } finally {
    accountStore.update((s) => ({
      apiKeyDetails: { ...s.apiKeyDetails, isLoading: false }
    }));
  }
};

export const updateAPIBucket = async (accountId: string, bucket: string): Promise<void> => {
  try {
    await axios.put(
      `/accounts/${accountId}/api/bucket`,
      { bucket },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    authStore.update((s) => {
      if (!s.user?.accounts) {
        return s;
      }

      const userAccounts = s.user?.accounts ?? [];
      const accounts = userAccounts.map((a) => {
        if (a.accountId === accountId) {
          a.apiAccountDetails?.map((d) => {
            if (d.stage === config.publicApi.apiStage) {
              d.apiBucket = bucket;
            }

            return d;
          });
        }

        return a;
      });

      return {
        ...s,
        user: {
          ...s.user,
          accounts
        }
      };
    });
  } catch (error) {
    handleError(error);
  }
};
