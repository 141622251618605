import React from "react";
import classNames from "classnames";

import { RiCloseLine } from "@remixicon/react";

type BannerTheme =
  | "hard-blue"
  | "hard-red"
  | "hard-yellow"
  | "hard-green"
  | "hard-neutral"
  | "hard-white"
  | "hard-purple"
  | "soft-blue"
  | "soft-red"
  | "soft-yellow"
  | "soft-green"
  | "soft-neutral"
  | "soft-purple";

interface SingleLineBannerProps {
  firstButton?: React.ReactNode;
  secondButton?: React.ReactNode;
  theme?: BannerTheme;
  hideClose?: boolean;
  onClose?: () => void;
  rounded?: boolean;
  align?: "left" | "center";
  className?: string;
  innerClassName?: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
}

export const SingleLineBanner: React.FC<SingleLineBannerProps> = ({
  theme = "soft-blue",
  align = "left",
  firstButton,
  secondButton,
  hideClose = false,
  rounded = true,
  className = "",
  innerClassName = "",
  onClose,
  children,
  icon
}) => {
  const { textStyle, backgroundStyle, borderStyle } = {
    "hard-blue": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-primary-500",
      borderStyle: "tw-border tw-border-primary-500"
    },
    "hard-red": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-destructive-600",
      borderStyle: "tw-border tw-border-destructive-600"
    },
    "hard-yellow": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-warning-500",
      borderStyle: "tw-border tw-border-warning-500"
    },
    "hard-green": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-success-600",
      borderStyle: "tw-border tw-border-success-600"
    },
    "hard-neutral": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-neutral-900",
      borderStyle: "tw-border tw-border-neutral-900"
    },
    "hard-white": {
      textStyle: "tw-text-black",
      backgroundStyle: "tw-bg-neutral-100",
      borderStyle: "tw-border tw-border-neutral-100"
    },
    "hard-purple": {
      textStyle: "tw-text-white",
      backgroundStyle: "tw-bg-aux-500",
      borderStyle: "tw-border tw-border-aux-500"
    },
    "soft-blue": {
      textStyle: "tw-text-primary-900",
      backgroundStyle: "tw-bg-primary-50",
      borderStyle: "tw-border tw-border-primary-100"
    },
    "soft-red": {
      textStyle: "tw-text-destructive-800",
      backgroundStyle: "tw-bg-destructive-200",
      borderStyle: "tw-border tw-border-destructive-300"
    },
    "soft-yellow": {
      textStyle: "tw-text-warning-800",
      backgroundStyle: "tw-bg-warning-100",
      borderStyle: "tw-border tw-border-warning-400"
    },
    "soft-green": {
      textStyle: "tw-text-success-800",
      backgroundStyle: "tw-bg-success-200",
      borderStyle: "tw-border tw-border-success-400"
    },
    "soft-neutral": {
      textStyle: "tw-text-neutral-800",
      backgroundStyle: "tw-bg-neutral-50",
      borderStyle: "tw-border tw-border-neutral-600"
    },
    "soft-purple": {
      textStyle: "tw-text-aux-900",
      backgroundStyle: "tw-bg-aux-50",
      borderStyle: "tw-border tw-border-aux-100"
    }
  }[theme as BannerTheme];

  const renderIcon = (icon: JSX.Element, className: string) => {
    className = classNames(icon.props.className, className);
    const props = { ...icon.props, className };
    return <icon.type {...props} />;
  };

  const LeftIcon = icon ? renderIcon(icon, "tw-mr-2 tw-h-5 tw-w-5 tw-shrink-0") : null;

  return (
    <div
      className={classNames(
        "tw-flex tw-min-h-[48px] tw-w-full tw-items-center tw-border tw-px-2.5 tw-py-2 tw-text-sm",
        rounded ? "tw-rounded-lg" : "tw-rounded-none",
        textStyle,
        backgroundStyle,
        borderStyle,
        className
      )}
    >
      <div
        className={classNames("tw-flex tw-w-full tw-items-center", {
          "tw-justify-center": align === "center"
        })}
      >
        {LeftIcon}
        <div className={classNames("tw-text-sm", innerClassName)}>{children}</div>
      </div>
      {(firstButton || secondButton) && (
        <div className="tw-flex tw-items-center tw-gap-1.5">
          {firstButton}
          {firstButton && secondButton && <span className="tw-opacity-60">∙</span>}
          {secondButton}
        </div>
      )}
      {!hideClose && (
        <button className="tw-ml-2" onClick={onClose}>
          <RiCloseLine className="tw-h-5 tw-w-5" />
        </button>
      )}
    </div>
  );
};
