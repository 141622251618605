import { dashboardRepository, Folder } from "@core/state/dashboard/dashboard.store";

import { handleError } from "./handle-error";
import restApi from "./rest-api";

export const loadFolders = async (): Promise<{
  folders: Folder[];
}> => {
  try {
    dashboardRepository.updateState({ isLoadingFolders: true });

    const folders = await restApi.GET_v2_dashboard_folders().then((data) => data.folders);

    dashboardRepository.updateState({
      folders: folders,
      isLoadingFolders: false,
      hasLoadedFolders: true
    });

    return { folders };
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const toggleFavouriteFolder = async (folderId: string): Promise<void> => {
  try {
    await restApi.POST_v2_dashboard_favourite_toggle(folderId);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};
