import React from "react";
import classNames from "classnames";

import { ChevronDownIcon, XMarkIcon } from "@components/icons";
import { InlineTagsInput, Tag } from "@components/inline-tags-input";

import { useBlur } from "@core/hooks/use-blur";

import { ISelectOption, SelectSize } from "../select";
import { useSelect } from "../select.context";

type SelectInputProps = {
  className?: string;
  variant?: "flat" | "outline";
  size?: SelectSize;
  placeholder?: string;
  activeOption?: ISelectOption; // the active option when navigating up and down with arrow keys
  selectedOptions: ISelectOption[]; // array containing one selection on single input or multiple selections on multi input
  searchQuery: string; // current search query
  multiple?: boolean; // toggle single (text) or multi (tags) input
  hasError?: boolean; // display red border
  clearable?: boolean; // display an "X" button which will clear selections
  disabled?: boolean; // disable the input
  isOpen: boolean; // dropdown open state
  onNavUp: () => void; // fires when pressing up on keyboard
  onNavDown: () => void; // fires when pressing down on keyboard
  onEnter: () => void; // fires when input is filled and enter is pressed
  onEscape: () => void; // fires when input is focused an escape key is pressed
  onTabAway: () => void; // fires when tabbing away from the input
  onChange: (options: ISelectOption[]) => void; // fires when selected options change
  onSearch: (searchQuery: string) => void; // fires when user enters a query
  onClear?: () => void; // fires when user presses the "x" button
  formatInputSelectedLabel?: (
    option: ISelectOption
  ) => string | number | number | boolean | React.ReactNode | React.ReactNode[];
};

export const SelectInput: React.FC<SelectInputProps> = ({
  className,
  variant = "flat",
  size = "40",
  searchQuery,
  placeholder = "",
  activeOption,
  selectedOptions,
  multiple,
  hasError,
  clearable,
  disabled,
  isOpen,
  onChange,
  onNavUp,
  onNavDown,
  onEnter,
  onEscape,
  onTabAway,
  onSearch,
  onClear,
  formatInputSelectedLabel
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const isSingle = !multiple;
  const hasQuery = Boolean(searchQuery);
  const hasValue = selectedOptions.length > 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      onNavUp?.();
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      onNavDown?.();
    } else if (e.key === "Enter" && activeOption) {
      e.preventDefault();
      onEnter?.();
    } else if (e.key === "Escape") {
      onEscape?.();
    } else if (e.key === "Backspace" && isSingle && !hasQuery && hasValue) {
      onClear?.();
    }
  };

  // refocus the input when clicking on the toggle dropdown action
  const handleClickDropdownAction = () => {
    ref.current?.querySelector("input")?.focus();
  };

  // only submit the active tag
  const handleBeforeAddTag = () => {
    // returning a tag will transform the submitted tag
    if (activeOption) {
      return activeOption;
    }

    // returning false will stop the tag being submitted
    return false;
  };

  const { setReference } = useSelect();

  React.useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const updateReference = () => {
      setReference({
        getBoundingClientRect: () => element.getBoundingClientRect(),
        getClientRects: () => element.getClientRects()
      });
    };

    const resizeObserver = new ResizeObserver(updateReference);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  return (
    <div className={classNames(className, { "tw-pointer-events-none": disabled })} ref={ref}>
      <div ref={setReference}>
        {!multiple ? (
          <SingleInput
            className={className}
            variant={variant}
            size={size}
            searchQuery={searchQuery}
            selectedOption={selectedOptions[0] ?? null}
            placeholder={placeholder}
            clearable={clearable}
            hasError={hasError}
            disabled={disabled}
            isOpen={isOpen}
            onSearch={onSearch}
            onTabAway={onTabAway}
            onKeyDown={handleKeyDown}
            onClearAction={onClear}
            onClickDropdownAction={handleClickDropdownAction}
            formatInputSelectedLabel={formatInputSelectedLabel}
          />
        ) : (
          <MultiInput
            className={className}
            variant={variant}
            size={size}
            value={selectedOptions}
            placeholder={placeholder}
            searchQuery={searchQuery}
            clearable={clearable}
            hasError={hasError}
            disabled={disabled}
            isOpen={isOpen}
            onChange={onChange}
            onSearch={onSearch}
            onTabAway={onTabAway}
            onKeyDown={handleKeyDown}
            onClearAction={onClear}
            onClickDropdownAction={handleClickDropdownAction}
            onBeforeAddTag={handleBeforeAddTag}
          />
        )}
      </div>
    </div>
  );
};

type SingleInputProps = {
  className?: string;
  variant?: "flat" | "outline";
  size: SelectSize;
  searchQuery?: string;
  selectedOption?: ISelectOption | null;
  placeholder?: string;
  hasError?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  isOpen: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearch?: (searchQuery: string) => void;
  onTabAway?: () => void;
  onClearAction?: () => void;
  onClickDropdownAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  formatInputSelectedLabel?: (
    option: ISelectOption
  ) => string | number | number | boolean | React.ReactNode | React.ReactNode[];
};
export const SingleInput: React.FC<SingleInputProps> = ({
  className,
  variant = "flat",
  size,
  searchQuery,
  selectedOption,
  placeholder,
  hasError,
  clearable,
  disabled,
  isOpen,
  onKeyDown,
  onSearch,
  onTabAway,
  onClearAction,
  onClickDropdownAction,
  formatInputSelectedLabel
}) => {
  const [isFocused, setFocused] = React.useState<boolean>(false);
  const ref = useBlur<HTMLInputElement>({ onTabAway });
  placeholder = selectedOption ? "" : placeholder;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  };

  return (
    <div
      className={classNames(
        "tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-3 tw-rounded-lg tw-border tw-border-transparent focus-within:tw-shadow-focus-ring",
        {
          // Size
          "tw-min-h-[46px]": size === "48",
          "tw-min-h-[42px]": size === "44",
          "tw-min-h-[38px]": size === "40",
          "tw-min-h-[34px]": size === "36",
          "tw-min-h-[30px]": size === "32",
          "tw-min-h-[26px]": size === "28",

          // Font size
          "tw-px-4 tw-text-md": ["48", "44", "40"].includes(size),
          "tw-px-2 tw-text-sm": ["36", "32", "28"].includes(size),

          // Flat - error
          "tw-bg-destructive-50 focus-within:tw-border-destructive-200": hasError,
          // Flat - not disabled
          "tw-cursor-text tw-text-neutral-500": !disabled,
          // Flat - default
          "tw-bg-neutral-50 focus-within:tw-border-neutral-300": variant === "flat" && !hasError,
          // Flat - disabled
          "tw-text-neutral-300 tw-opacity-80": variant === "flat" && disabled,

          // Outline - default
          "tw-border-1 tw-border !tw-border-neutral-100 tw-bg-white tw-shadow-NEW-xs focus-within:tw-border-neutral-300":
            variant === "outline" && !hasError,
          // Outline - disabled
          "tw-border tw-border-neutral-100 !tw-bg-neutral-50 tw-text-neutral-300": variant === "outline" && disabled
        },
        className
      )}
      onClick={() => ref.current?.focus()}
    >
      <div className="tw-relative tw-flex tw-min-w-0 tw-grow">
        <input
          className={classNames(
            "tw-m-0 tw-flex tw-min-w-0 tw-flex-grow tw-bg-transparent tw-px-0 tw-text-md focus:tw-outline-none",
            {
              // Size
              "tw-min-h-[46px]": size === "48",
              "tw-min-h-[42px]": size === "44",
              "tw-min-h-[38px]": size === "40",
              "tw-min-h-[34px]": size === "36",
              "tw-min-h-[30px]": size === "32",
              "tw-min-h-[26px]": size === "28",

              // Font size
              "tw-text-md": ["48", "44", "40"].includes(size),
              "tw-text-sm": ["36", "32", "28"].includes(size),

              // Default
              "tw-text-neutral-900 placeholder:tw-text-neutral-400 placeholder:focus:tw-text-neutral-600":
                !disabled && !hasError,
              // Error & not disabled
              "tw-text-destructive-500 placeholder:tw-text-destructive-400 placeholder:focus:tw-text-destructive-600":
                !disabled && hasError,
              // Disabled
              "tw-text-neutral-300": disabled && !hasError
            }
          )}
          type="text"
          value={searchQuery}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={onKeyDown}
          disabled={disabled}
          ref={ref}
        />
        {!searchQuery && selectedOption && (
          <div
            className={classNames(
              "tw-pointer-events-none tw-absolute tw-left-0 tw-top-1/2 tw-flex tw-w-full tw-flex-grow tw--translate-y-1/2 tw-transform tw-items-center tw-gap-2 tw-truncate tw-text-ellipsis tw-p-0 tw-text-md",
              {
                // Default
                "tw-text-neutral-900": !disabled && !hasError,
                // Error & not disabled
                "tw-text-destructive-500 ": !disabled && hasError,
                // Disabled
                "tw-text-neutral-300": disabled && !hasError
              }
            )}
          >
            {formatInputSelectedLabel ? formatInputSelectedLabel(selectedOption) : selectedOption.label}
          </div>
        )}
      </div>
      <InputActions
        isFocused={isFocused}
        clearable={clearable}
        isOpen={isOpen}
        onClearAction={onClearAction}
        onClickDropdownAction={onClickDropdownAction}
      />
    </div>
  );
};

type MultiInputProps = {
  variant?: "flat" | "outline";
  value?: ISelectOption[];
  searchQuery?: string;
  className?: string;
  size: SelectSize;
  placeholder?: string;
  hasError?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  isOpen: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (value: ISelectOption[]) => void;
  onSearch?: (value: string) => void;
  onTabAway?: () => void;
  onClearAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDropdownAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBeforeAddTag?: (tag: Tag) => void | Tag | boolean;
};
export const MultiInput: React.FC<MultiInputProps> = ({
  className,
  value = [],
  variant = "flat",
  size,
  searchQuery,
  placeholder,
  hasError,
  clearable,
  disabled,
  isOpen,
  onKeyDown,
  onChange,
  onSearch,
  onTabAway,
  onClearAction,
  onClickDropdownAction,
  onBeforeAddTag
}) => {
  const [isFocused, setFocused] = React.useState<boolean>(false);
  placeholder = value.length > 0 ? "" : placeholder;

  return (
    <InlineTagsInput
      className={className}
      variant={variant}
      size={size}
      value={value}
      placeholder={placeholder}
      after={
        <InputActions
          isFocused={isFocused}
          clearable={clearable}
          isOpen={isOpen}
          onClearAction={onClearAction}
          onClickDropdownAction={onClickDropdownAction}
          className="tw-mr-1.5"
        />
      }
      searchQuery={searchQuery}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onSearch={onSearch}
      onTabAway={onTabAway}
      onBeforeAddTag={onBeforeAddTag}
      hasError={hasError}
      disabled={disabled}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

type InputActions = {
  isFocused?: boolean;
  clearable?: boolean;
  className?: string;
  isOpen: boolean;
  onClickDropdownAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClearAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
export const InputActions: React.FC<InputActions> = ({
  isFocused,
  clearable,
  className,
  isOpen,
  onClickDropdownAction,
  onClearAction
}) => {
  const handleClickClearAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClearAction?.(e);
  };

  const handleClickDropdownAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClickDropdownAction?.(e);
  };

  return (
    <div
      className={classNames("tw-flex tw-cursor-default tw-flex-row tw-items-center tw-gap-1", className, {
        "tw-text-neutral-600": isFocused,
        "tw-text-neutral-500": !isFocused
      })}
    >
      {clearable && (
        <button
          type="button"
          className={classNames(
            "tw-inline-flex tw-h-full tw-items-center tw-justify-center tw-p-1 hover:tw-text-neutral-700"
          )}
          onClick={handleClickClearAction}
        >
          <XMarkIcon className="tw-w-4" strokeWidth="3" />
        </button>
      )}
      <button
        type="button"
        className={classNames(
          // <Select /> checks for `select-toggle` in the internal click event handler
          // to determine whether to close the dropdown, not pretty, sorry
          "select-toggle",
          "tw-flex tw-h-full tw-items-center tw-justify-center tw-p-1 hover:tw-text-neutral-700"
        )}
        onClick={handleClickDropdownAction}
      >
        <ChevronDownIcon className={classNames("tw-w-4", { "tw-rotate-180": isOpen })} strokeWidth="3" />
      </button>
    </div>
  );
};
