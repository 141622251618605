import React from "react";
import classNames from "classnames";

import { getWcagLevel } from "@frontend/utils/accessibility.utils";
import { getWcagContrastRatio } from "@frontend/utils/color.utils";

import { NewBadge } from "@components/new-badge/new-badge";

import { RiCheckboxCircleFill, RiErrorWarningFill } from "@remixicon/react";

type ColorContrastCheckerProps = {
  textColor: string;
  backgroundColor: string;
  defaultAAA?: boolean;
};

export const ColorContrastChecker: React.FC<ColorContrastCheckerProps> = (props) => {
  const ratio = React.useMemo(
    () => getWcagContrastRatio(props.textColor, props.backgroundColor),
    [props.textColor, props.backgroundColor]
  );

  const complianceConfig = {
    AAA: {
      Icon: RiCheckboxCircleFill,
      color: "tw-text-success-900",
      bgColor: "tw-bg-success-200",
      title: "Meets AAA Standards",
      body: "Your contrast levels meet AAA accessibility standards, providing the highest level of readability."
    },
    AA: {
      Icon: RiCheckboxCircleFill,
      color: "tw-text-success-900",
      bgColor: "tw-bg-success-100",
      title: "Meets AA Standards",
      body: "Your contrast levels meet AA accessibility standards, ensuring readability across a broad audience."
    },
    NonCompliant: {
      Icon: RiErrorWarningFill,
      color: "tw-text-destructive-900",
      bgColor: "tw-bg-destructive-100",
      title: "Non compliant",
      body: "Current contrast does not meet AA standards. Adjustments needed to meet accessibility requirements."
    }
  };

  const level = ratio !== null ? getWcagLevel(ratio) : null;
  const config = level !== null ? complianceConfig[level] : null;

  if (config === null || ratio === null) {
    return (
      <div
        className={classNames(
          `tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded-lg tw-border tw-p-2 tw-text-destructive-600`
        )}
      >
        <header className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-space-x-2">
            <RiErrorWarningFill className={`tw-h-5 tw-w-5`} />
            <h2 className="tw-text-lg tw-font-semibold">Invalid Color Values</h2>
          </div>
        </header>
        <p className="tw-text-sm tw-font-medium">Please provide valid hex color codes (e.g., #FFFFFF).</p>
      </div>
    );
  }

  const { Icon } = config;

  return (
    <div
      className={classNames(
        `tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded-lg tw-p-2 ${config.color}`,
        config.bgColor
      )}
    >
      <header className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Icon className={`tw-h-5 tw-w-5`} />
          <h2 className="tw-text-lg tw-font-semibold">{config.title}</h2>
        </div>
      </header>
      <p className="tw-text-sm tw-font-medium">
        <NewBadge
          variant="secondary-soft"
          label={Number(ratio.toFixed(1)) + ":1"}
          className="tw-float-right tw-ml-2 tw-bg-white"
        />
        {config.body}
      </p>
    </div>
  );
};

export default ColorContrastChecker;
