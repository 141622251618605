import React from "react";

import { ToolTip } from "@frontend/containers/tooltip/tooltip";

type FlagSize = "12" | "16" | "20" | "24";

const config: Record<FlagSize, { border: number; className: string }> = {
  "12": { border: 1.5, className: "tw-h-3" },
  "16": { border: 1, className: "tw-h-4" },
  "20": { border: 1.5, className: "tw-h-5" },
  "24": { border: 1.5, className: "tw-h-6" }
};

interface FlagSymbolProps {
  flag: { flagCode: string; label: string }; // this should be flag country code. e.g. "us" | "gb" | "ca" | "mx", see for reference: https://flagicons.lipis.dev/
  size: FlagSize; // the size of the symbol
  className?: string;
  tooltip?: string;
}

export const FlagSymbol: React.FC<FlagSymbolProps> = ({ flag, size, tooltip }) => {
  const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  const { border } = config[size];

  return (
    <div className="tw-flex">
      <span
        className={`fi fi-${flag.flagCode} tw-box-content tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full tw-border-neutral-100 tw-bg-cover`}
        style={{
          borderWidth: `${border}px`,
          width: `${size}px`,
          height: `${size}px`
        }}
        data-tooltip-id={`flag-${uniqueId}`}
      />
      {tooltip && <ToolTip text={tooltip} anchorId={`flag-${uniqueId}`} place="top" delayShow={500} />}
    </div>
  );
};
