import axios from "axios";

import config from "@frontend/config";

import { Survey } from "@getsubly/common/dist/interfaces/survey";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const saveSurveyResult = async (surveyData: Survey): Promise<boolean> => {
  try {
    await axios.post(`${baseURL}/user/survey`, surveyData, {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};
