import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { renameMedia, setMediaFileLanguage } from "@frontend/api/media.service";
import { Fieldset } from "@frontend/components/form-controls/fieldset";
import settings from "@frontend/config/settings/settings";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { Modal } from "@components/modal";
import { ISelectOption, Select } from "@components/select";

import styles from "./rename-media-modal.module.scss";

type RenameForm = { name: string };

interface RenameMediaModalProps {
  mediaId: string;
  mediaName: string;
  mediaLanguage?: string;
  hideModal: () => void;
}
export const RenameMediaModal: React.FC<RenameMediaModalProps> = ({ mediaId, mediaName, mediaLanguage, hideModal }) => {
  const { languages } = settings.transcription;
  const [selectedLanguageCode, setSelectedLanguageCode] = React.useState<string | undefined>(
    mediaLanguage || undefined
  );
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RenameForm>({
    defaultValues: { name: mediaName }
  });

  const handleSave = async ({ name }: RenameForm) => {
    setLoading(true);

    if (mediaName !== name) {
      await renameMedia(mediaId, name);
    }

    if (selectedLanguageCode && mediaLanguage !== selectedLanguageCode) {
      await setMediaFileLanguage(mediaId, { languageCode: selectedLanguageCode });
    }

    hideModal();
  };

  const handleSelectedLanguage = (languageCode?: string) => {
    setSelectedLanguageCode(languageCode);
    setError(false);
  };

  const formatLabel = (option: ISelectOption) => (
    <>
      <span className={`fi fi-${option.extra.flagCode} tw-shrink-0`} />
      {option.label}
    </>
  );

  return (
    <Modal
      onDismiss={hideModal}
      showCloseButton
      closeIconClassName={styles["close-icon"]}
      className={styles.modal}
      disableBackgroundDismiss
    >
      <h5 className="tw-mb-4 tw-flex tw-items-center tw-text-h5">Media settings</h5>

      <form onSubmit={handleSubmit(handleSave)}>
        <Fieldset title="Media name" className="tw-mb-4">
          <Input {...register("name", { required: true })} hasError={!!errors.name} autoFocus />
        </Fieldset>

        <Fieldset
          title="Media language"
          className={classNames("tw-my-4", {
            "tw-text-destructive-500": hasError
          })}
        >
          <Select
            placeholder="Select language"
            defaultValue={selectedLanguageCode}
            options={languages.map((language) => ({
              label: language.language,
              value: language.languageCode,
              extra: { flagCode: language.flagCode }
            }))}
            formatOptionLabel={(option) => formatLabel(option)}
            formatInputSelectedLabel={(option) => formatLabel(option)}
            onChange={(l) => handleSelectedLanguage(l)}
            hasError={hasError}
          />
          {hasError && (
            <p className="tw-text-sm tw-font-medium tw-text-destructive-500">
              Please select the language of your media
            </p>
          )}
        </Fieldset>

        <div className="tw-flex tw-justify-end">
          <Button variant="secondary" onClick={hideModal} size="40">
            Cancel
          </Button>
          <Button variant="primary" className="tw-ml-2 tw-px-6" loading={isLoading} type="submit" size="40">
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};
