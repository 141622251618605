import React from "react";

import { LoadingIcon } from "@components/loading-icon";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";

import { useAPI } from "./api.context";
import { APIDetails } from "./api-details";
import { APISetup } from "./api-setup";
import { ApiUpgrade } from "./api-upgrade";

export const APIPageContainer: React.FC = () => {
  const { isLoading: isAccountLoading, currentAccount } = useAccounts();
  const { apiAccount, isLoading, shouldSetup } = useAPI();
  const { isEnterprise } = usePlan();

  if (!isEnterprise && !apiAccount) {
    // Show upgrade
    return <ApiUpgrade />;
  }

  if (isLoading || isAccountLoading || !currentAccount) {
    return <LoadingIcon className="tw-mr-2" />;
  }

  if (shouldSetup) {
    return <APISetup accountId={currentAccount.accountId} isEnterprise={isEnterprise} />;
  }

  return <APIDetails />;
};
