import React from "react";

import config from "@frontend/config";

import { FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-react";
import * as Sentry from "@sentry/react";

interface FingerprintContext {
  getFingerprint: () => Promise<string>;
}

export const FingerprintContext = React.createContext<FingerprintContext | null>(null);

interface FingerprintProviderProps {
  children: React.ReactNode;
}
export const FingerprintProvider: React.FC<FingerprintProviderProps> = ({ children }) => {
  return (
    <FingerprintContext.Provider
      value={{
        getFingerprint: async () => {
          if (!config.fingerprint.enabled) {
            return "";
          }

          try {
            const fp = await FingerprintJSPro.load({
              apiKey: config.fingerprint.apiKey,
              region: config.fingerprint.region as "eu" | "us" | "ap"
            });
            const result = await fp.get();
            return result?.visitorId;
          } catch (error) {
            console.error("Error getting fingerprint", error);
            Sentry.captureException(error);
            return "";
          }
        }
      }}
    >
      {children}
    </FingerprintContext.Provider>
  );
};

export const useFingerprint = (): FingerprintContext => {
  const context = React.useContext(FingerprintContext);

  if (context === null) {
    throw new Error("useFingerprint must be used within a FingerprintProvider");
  }

  return context;
};
