import { throttle } from "lodash-es";

// Get the height of the scrollable container
export function getElementSize(container: HTMLElement | typeof window) {
  const height =
    container === window ? window.innerHeight : parseInt(getComputedStyle(container as HTMLElement).height);
  const width = container === window ? window.innerWidth : parseInt(getComputedStyle(container as HTMLElement).width);

  return {
    height,
    width
  };
}

// Get the scroll position of container
export function getScrollOffset(element: HTMLElement | null) {
  let scrollTop = 0;
  let currentElement = element;

  while (currentElement && currentElement !== document.body) {
    scrollTop += currentElement.scrollTop;
    currentElement = currentElement.parentElement;
  }

  scrollTop += window.scrollY || window.pageYOffset;

  return scrollTop;
}

// Observe scroll events on a given element
export function observeScroll(element: HTMLElement | Window, callback: () => void) {
  const handleScroll = throttle(callback, 200);
  element.addEventListener("scroll", handleScroll);
  return () => element?.removeEventListener("scroll", handleScroll);
}

// Observe resize events on a given element
export function observeSize(
  element: HTMLElement | Window,
  callback: (size: { width: number; height: number }) => void
) {
  // observe window height
  if (element === window) {
    let previousHeight = window.innerHeight;
    let previousWidth = window.innerWidth;
    callback({ height: window.innerHeight, width: window.innerWidth });
    const handleResize = throttle((event: UIEvent) => {
      const currentHeight = (event.target as Window)?.innerHeight;
      const currentWidth = (event.target as Window)?.innerWidth;
      if (currentHeight !== previousHeight || currentWidth !== previousWidth) {
        previousHeight = currentHeight;
        previousWidth = currentWidth;
        callback({ height: currentHeight, width: currentWidth });
      }
    }, 50);
    element.addEventListener("resize", handleResize);
    return () => element?.removeEventListener("resize", handleResize);
  }
  // observe element height
  else {
    callback(getElementSize(element as HTMLElement));

    function handleResize(entries: ResizeObserverEntry[]) {
      for (const entry of entries) {
        const { height, width } = entry.contentRect;
        callback({ height, width });
      }
    }

    const resizeObserver = new ResizeObserver(throttle(handleResize, 50));

    resizeObserver.observe(element as HTMLElement);

    return () => resizeObserver.disconnect();
  }
}
