export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlPattern =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;

// Password rules
export const uppercaseRule = /^(?=.*[A-Z])/;
export const lowercaseRule = /^(?=.*[a-z])/;
export const numberRule = /^(?=.*[0-9])/;
export const symbolRule = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`-])/;
export const amountRule = /^(?=.{8,})/;

export const passwordPattern = new RegExp(
  uppercaseRule.source + lowercaseRule.source + numberRule.source + symbolRule.source + amountRule.source
);
