import React from "react";
import { useForm } from "react-hook-form";

import { createFolder } from "@frontend/api/folders.service";
import { Fieldset } from "@frontend/components/form-controls/fieldset";
import { notificationSuccess } from "@frontend/components/notification";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

import { ModalType } from "@core/interfaces/modal-type";

interface CreateFolderModalProps {
  parentId?: string;
  isPublic?: boolean;
  onBeforeSave?: () => Promise<void> | void;
  onCompleteSave?: () => Promise<void> | void;
  hideModal: (modal: ModalType) => void;
}

export const CreateFolderModal: React.FC<CreateFolderModalProps> = ({
  parentId,
  isPublic,
  onBeforeSave,
  onCompleteSave,
  hideModal
}) => {
  const closeModal = () => hideModal(ModalType.CreateFolder);

  return (
    <NewModal onDismiss={closeModal} showCloseButton disableBackgroundDismiss title="Create new folder:">
      <CreateFolderContainer
        parentId={parentId}
        isPublic={isPublic}
        closeModal={closeModal}
        onBeforeSave={onBeforeSave}
        onCompleteSave={onCompleteSave}
      />
    </NewModal>
  );
};

type RenameForm = { name: string };
interface RenameFolderFolderContainerProps {
  parentId?: string;
  isPublic?: boolean;
  onBeforeSave?: () => Promise<void> | void;
  onCompleteSave?: () => Promise<void> | void;
  closeModal: () => void;
}
const CreateFolderContainer: React.FC<RenameFolderFolderContainerProps> = ({
  parentId,
  isPublic,
  onBeforeSave,
  onCompleteSave,
  closeModal
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm<RenameForm>({
    defaultValues: { name: "New folder" }
  });

  const handleSave = async ({ name }: RenameForm) => {
    setLoading(true);
    await onBeforeSave?.();
    await createFolder({ parentId, name, isPublic });
    await onCompleteSave?.();
    notificationSuccess(`Created "${name}"`);
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Fieldset>
        <Input {...register("name", { required: true })} autoFocus />
      </Fieldset>

      <div className="tw-mt-6 tw-flex tw-justify-end">
        <Button variant="secondary" onClick={closeModal} size="36">
          Cancel
        </Button>
        <Button variant="primary" className="tw-ml-2 tw-px-6" loading={isLoading} type="submit" size="36">
          Create
        </Button>
      </div>
    </form>
  );
};
