import React from "react";

import { Fieldset } from "@components/form-controls/fieldset";

import { Folder } from "@core/state/dashboard/dashboard.store";

import { ProjectLocationDropdown } from "../upload-modal/project-location-dropdown";

interface UploadModalProps {
  folder?: Folder | null;
  className?: string;
  setFolder: (folder?: Folder | null) => void;
  showInPortal?: boolean;
}

export const SaveLocationSection: React.FC<UploadModalProps> = ({ folder, className, setFolder }) => {
  return (
    <div className={className}>
      <Fieldset title="Save to folder">
        <ProjectLocationDropdown setFolder={setFolder} folder={folder} />
      </Fieldset>
    </div>
  );
};
