import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AccountType, BillingDetailsParams, setBillingDetails } from "@frontend/api/billing.service";
import { notificationError, notificationSuccess } from "@frontend/components/notification";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";

import { useAccounts } from "@core/hooks/use-accounts";
import { getTaxIdTypeSelectOptions, TaxIdTypeSelectOption } from "@core/interfaces/billing";
import { accountQuery } from "@core/state/account";

import { BillingDetailsForm } from "./billing-details-form";

export interface BillingDetailsForm {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  taxIdNumber?: string;
  taxIdType?: TaxIdTypeSelectOption;
  purchaseOrderNumber?: string;
}

export const AddBillingDetailsForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { billing } = useAccounts();
  const [isBusiness, setBusiness] = React.useState(billing?.details?.accountType === AccountType.Business);
  const form = useForm<BillingDetailsForm>();
  const watchTaxIdType = form.watch("taxIdType");
  const taxIdTypeSelectOptions = getTaxIdTypeSelectOptions();
  const navigate = useNavigate();

  useEffect(() => {
    const account$ = accountQuery.select(["loading", "billing"]).subscribe(({ loading, billing }) => {
      setLoading(loading || !!billing?.loading);
      const name = billing?.details?.name;
      const address = billing?.details?.address;
      if (name && address) {
        let taxIdTypeSelectOption: TaxIdTypeSelectOption | undefined;

        if (billing?.details?.taxId?.type) {
          taxIdTypeSelectOption = taxIdTypeSelectOptions?.find(
            (x) => x.code === billing?.details?.taxId.type && x.country === billing?.details?.taxId.country
          );
        }
        const initForm: BillingDetailsForm = {
          name: name,
          address: address.line1,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country: address.country,
          taxIdNumber: billing?.details?.taxId?.value,
          taxIdType: taxIdTypeSelectOptions.find((x) => x.value === taxIdTypeSelectOption?.value),
          purchaseOrderNumber:
            billing.details?.invoiceCustomFields?.find((el) => el.name === "Purchase Order #")?.value ?? ""
        };

        form.reset(initForm);

        if (billing?.details?.accountType === AccountType.Business) {
          setBusiness(true);
        }
      }
    });

    return () => account$.unsubscribe();
  }, [form.reset]);

  const onSubmit = async ({
    name,
    address: line1,
    city,
    postalCode,
    state,
    country,
    taxIdNumber,
    taxIdType,
    purchaseOrderNumber
  }: BillingDetailsForm) => {
    try {
      const billingDetails: BillingDetailsParams = {
        name,
        line1,
        city,
        postalCode,
        state,
        country,
        taxIdNumber: taxIdNumber ?? undefined,
        taxIdType: taxIdType?.code,
        accountType: isBusiness ? AccountType.Business : AccountType.Personal,
        purchaseOrderNumber
      };

      await setBillingDetails(billingDetails);

      notificationSuccess("Your details have been updated.");

      navigate(SETTINGS_BILLING_PATH);
    } catch (e) {
      let message = "Failed to update billing details.";

      const error = e?.response?.data?.error;

      if (error?.code == "InvalidTaxNumber") {
        message += ` (Invalid tax number - it should follow this format: ${watchTaxIdType?.format})`;
      }

      notificationError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BillingDetailsForm className="edit-account-form tw-max-w-[435px]" useForm={form} onSubmit={onSubmit}>
      <div className="tw-mt-4 tw-flex tw-justify-end">
        <Button variant="secondary" type="link" to={SETTINGS_BILLING_PATH} className="tw-mr-2" size="32">
          Cancel
        </Button>
        <Button variant="primary" type="submit" className="tw-mr-0" loading={loading} size="32">
          Save changes
        </Button>
      </div>
    </BillingDetailsForm>
  );
};
