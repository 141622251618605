import React from "react";
import classNames from "classnames";

import { PlusIcon } from "@components/icons";

interface AddColorElementProps {
  onClick?: () => void;
  disabled?: boolean;
}
export const AddColorElement: React.FC<AddColorElementProps> = ({ onClick, disabled = false }) => {
  return (
    <div
      className={classNames(
        "tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-neutral-200",
        {
          "tw-cursor-pointer hover:tw-border-2": !disabled,
          "tw-cursor-not-allowed": disabled
        }
      )}
      onClick={disabled ? undefined : onClick}
    >
      <PlusIcon className="tw-w-[10px tw-h-[10px]" />
    </div>
  );
};
