export interface FormatBytes {
  size: number;
  units: string;
}

const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const k = 1024;

export const formatBytes = (bytes: number, decimals = 2, units?: string): FormatBytes => {
  if (bytes === 0) return { size: 0, units: "Bytes" };

  let i: number;
  const dm = decimals < 0 ? 0 : decimals;
  if (units && sizes.indexOf(units) !== -1) {
    i = sizes.indexOf(units);
  } else {
    i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(k));
  }
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return { size: formattedSize, units: sizes[i] };
};

export const getBytes = (size: number, units = "GB"): number => size * k ** (sizes.indexOf(units) || 0);
