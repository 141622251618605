import { AccountType } from "@frontend/api/billing.service";
import settings from "@frontend/config/settings/settings";

import { UnknownObject } from "./types";

// Order of plans matter as it is used to infer downgrades and permissions
export enum SublyPlan {
  Free = "Subly Free",
  Pro = "Subly Pro",
  ProYearly = "Subly Pro Yearly",
  Premium = "Subly Premium",
  PremiumYearly = "Subly Premium Yearly",
  PAYG = "Subly Pay As You Go",
  Personal = "Subly Personal",
  Business = "Subly Business",
  BusinessYearly = "Subly Business Yearly",
  Enterprise = "Subly Enterprise",
  API = "Subly API & iFrame"
}

export enum CurrencyCode {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD"
}

export enum CurrencySymbol {
  USD = "$",
  EUR = "€",
  GBP = "£",
  AUD = "A$"
}

export enum AdditionalItem {
  Minutes = "Additional Minutes",
  Storage = "Additional Storage",
  Seats = "Additional Seats"
}

export enum PlanInterval {
  Monthly = "month",
  Yearly = "year"
}

export interface Product {
  id: string;
  priceId: string;
  name: SublyPlan;
  description: string;
  metadata: UnknownObject;
  tier: number;
  currency: string;
  amount: number;
  units?: number;
}

export interface BillingDetails {
  name: string;
  balance: number;
  address?: BillingAddress;
  accountType: AccountType;
  taxId: BillingTaxId;
  invoiceCustomFields?: StripeCustomField[];
}

export interface BillingTaxId {
  type: string;
  value: string;
  country: string;
}

export interface BillingAddress {
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
}

export interface StripeCustomField {
  name: string;
  value: string;
}

export interface Invoice {
  id: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  attempt_count: number;
  attempted: boolean;
  auto_advance: string;
  billing_reason: string;
  collection_method: string;
  created: Date;
  currency: string;
  description: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  lines: InvoiceLine[];
  metadata: UnknownObject;
  number: string;
  paid: boolean;
  period_end: Date;
  period_start: Date;
  receipt_number: string;
  status: string;
  subtotal: number;
  tax: number;
  tax_percent: number;
  total: number;
}

export interface InvoiceLine {
  id: string;
  amount: number;
  currency: string;
  description: string;
  metadata: UnknownObject;
  quantity: number;
}

export enum SubscriptionStatus {
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Active = "active",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid"
}

export interface Coupon {
  duration_in_months: number;
  percent_off: number;
}

export interface Subscription {
  id: string;
  items: (SubscriptionPrimaryItem | SubscriptionAdditionalItem)[];
  /**
   * https://stripe.com/docs/billing/subscriptions/overview
   *
   * Possible values are `incomplete`, `incomplete_expired`, `trialing`, `active`, `past_due`, `canceled`, or `unpaid`.
   *
   * For `collection_method=charge_automatically` a subscription moves into `incomplete` if the initial payment attempt fails. A subscription in this state can only have metadata and default_source updated. Once the first invoice is paid, the subscription moves into an `active` state. If the first invoice is not paid within 23 hours, the subscription transitions to `incomplete_expired`. This is a terminal state, the open invoice will be voided and no further invoices will be generated.
   *
   * A subscription that is currently in a trial period is `trialing` and moves to `active` when the trial period is over.
   *
   * If subscription `collection_method=charge_automatically` it becomes `past_due` when payment to renew it fails and `canceled` or `unpaid` (depending on your subscriptions settings) when Stripe has exhausted all payment retry attempts.
   *
   * If subscription `collection_method=send_invoice` it becomes `past_due` when its invoice is not paid by the due date, and `canceled` or `unpaid` if it is still not paid by an additional deadline after that. Note that when a subscription has a status of `unpaid`, no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed). After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.
   */
  status: SubscriptionStatus;
  startDate: Date;
  nextInvoiceDate: Date;
  trialEndDate?: Date;

  schedule?: Schedule;
  cancelAt?: Date;

  latestInvoice?: {
    id: string;
    currency: string;
    total: number;
  };
  coupon?: Coupon;
  metadata?: {
    [name: string]: string;
  };
  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface SubscriptionPaymentError {
  id: string;
  status:
    | "canceled"
    | "processing"
    | "requires_action"
    | "requires_capture"
    | "requires_confirmation"
    | "requires_payment_method"
    | "succeeded";
  code: string;
  message: string;
}

export enum BillingErrorCode {
  AlreadySubscribed = "AlreadySubscribed",
  AlreadyOnTrial = "AlreadyOnTrial",
  AlreadyHadATrial = "AlreadyHadATrial",
  InvalidProduct = "InvalidProduct",
  InvalidPrice = "InvalidPrice",
  NoCardOnAccount = "NoCardOnAccount",
  MissingCard = "MissingCard",
  InvalidCoupon = "InvalidCoupon",
  CardDeclined = "CardDeclined",
  UnknownError = "UnknownError",
  CouponUsed = "CouponUsed",
  RequiresAction = "RequiresAction"
}

export interface SubscriptionNextAction {
  clientSecret: string;
  payment?: string;
}

export interface SubscriptionError {
  code: BillingErrorCode;
  reason?: string;
}

export interface GetSubscriptionCostItem {
  product: string;
  primary: boolean;
  units: number;
  amount: number;
}

export interface GetSubscriptionCostDiscount {
  name: string;
  amountOff?: number;
  percentOff?: number;
  durationMths?: number;
  amount: number;
}

export interface GetSubscriptionCostResult {
  currency: string;
  credit: number;
  creditEnd: number;
  renewalDate: number;
  discount?: GetSubscriptionCostDiscount;
  items: GetSubscriptionCostItem[];
  subtotal: number;
  tax: number;
  taxPercent: number;
  total: number;
  nextSubtotal: number;
  nextTax: number;
  nextTaxPercent: number;
  nextTotal: number;
  due: number;
}

export type AccessibilityPaymentResponse =
  | AccessibilityPaymentSuccessResponse
  | AccessibilityPaymentRequiresActionResponse
  | AccessibilityPaymentFailResponse;

export interface AccessibilityPaymentSuccessResponse {
  status: "success";
  id: string;
}

export interface AccessibilityPaymentRequiresActionResponse {
  status: "requires_action";
  nextAction: {
    clientSecret: string;
    payment?: string;
  };
}

export interface AccessibilityPaymentFailResponse {
  status: "fail";
  error?: SubscriptionError;
}

export interface CreateSubscriptionResponse {
  status: "success" | "requires_action" | "fail";

  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface UpdateSubscriptionResponse {
  pause_collection: { behavior: string; resumes_at: number };
  status: "success" | "requires_action" | "fail";
  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface PauseSubscriptionResponse {
  status: "success" | "requires_action" | "fail";
  pause_collection: { behavior: string; resumes_at: number };
  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface PurchaseResponse {
  status: "success" | "requires_action" | "fail";

  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export type Interval = "day" | "month" | "week" | "year";

export type PlanProductVersion = "2020-09-21" | "2023-01-09" | "2023-06-01" | "2024-01-02";

export interface SubscriptionPrimaryItem {
  itemId: string;
  productId: string;
  name: string;
  plan: SublyPlan;
  product: SublyPlan;
  price: string;
  units: number;
  interval: Interval;
  isPrimary: true;
  // old pricing indicator
  version: PlanProductVersion;
}

export interface SubscriptionAdditionalItem {
  itemId: string;
  productId: string;
  name: string;
  product: AdditionalItem;
  price: string;
  units: number;
  isPrimary: false;
  interval: Interval;
}

export interface Schedule {
  id: string;
  current: {
    start: Date;
    end: Date;
  };
  next: SchedulePhase[];
}

export interface SchedulePhase {
  start: Date;
  end: Date;
  items: ScheduleItem[];
}

export interface ScheduleItem {
  name: string;
  version: PlanProductVersion;
  product: SublyPlan | AdditionalItem;
  productId: string;
  interval: Interval;
  price: string;
  units?: number;
}

export interface Deal {
  id: string;
  accountId: string;
  invoiceId: string;
  status: DealStatus;
  description: string;
  isPaid: boolean;
  isTrial: boolean;
  teamCapacity: number;
  creditSeconds: number;
  createdAt: Date;
  updatedAt: Date;
  startedAt?: Date;
  endingAt?: Date;
}

export enum DealStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Void = "VOID",
  Deleted = "DELETED"
}

export interface Source {
  id: string;
  name: string;
  brand: string;
  last4: string;
  funding: string;
}

export interface PaymentMethod {
  id: string;
  last4: string;
  brand: string;
  exp_month: number | string;
  exp_year: number | string;
  default: boolean;
}

export interface BasicStripeCustomer {
  id: string;
  accountType: AccountType;
  details: BillingDetails;
  subscription?: Subscription;
  invoices: Invoice[];
  paymentMethods: PaymentMethod[];
  upcomingInvoice?: Invoice;
  creditFreeSeconds?: number;
  creditPaidSeconds?: number;
  creditExtraSeconds?: number;
  creditPaygSeconds?: number;
  lastPlan?: string;
}

export interface TaxIdType {
  country: string;
  code: string;
  format: string;
  name: string;
}

//used in Select form controls
export interface TaxIdTypeSelectOption extends TaxIdType {
  label: string;
  value: string;
}

//taxIdType codes are not unique, neither are countries. The combination of these is unique
const getUniqueIdForTaxIdType = (taxIdType: TaxIdType | undefined) => {
  if (!taxIdType) {
    return "";
  }

  return taxIdType.code + "|" + taxIdType.country;
};

export const getTaxIdTypeSelectOptions = (): TaxIdTypeSelectOption[] => {
  //zero-width space ensures empty option has full height
  const taxIdTypeSelectOptions: TaxIdTypeSelectOption[] = [
    { label: "\u200B", value: "", code: "", name: "", format: "", country: "" }
  ].concat(
    settings.stripe.taxIdTypes.map((taxIdType) => {
      return {
        label: taxIdType.name,
        value: getUniqueIdForTaxIdType(taxIdType),
        code: taxIdType.code,
        format: taxIdType.format,
        country: taxIdType.country,
        name: taxIdType.name
      };
    })
  );

  return taxIdTypeSelectOptions.sort((a, b) => a.name.localeCompare(b.name));
};
