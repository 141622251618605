import {
  Coupon,
  Interval,
  PlanProductVersion,
  Schedule,
  SublyPlan,
  SubscriptionPrimaryItem
} from "@core/interfaces/billing";
import { AccountCredit, accountQuery } from "@core/state/account";
import { useObservable } from "@mindspace-io/react";

import { useAccounts } from "./use-accounts";

// TODO: Implement this across the app, and remove it from the AuthContext
interface UsePlan {
  plan: SublyPlan;
  primaryItem?: SubscriptionPrimaryItem;
  interval?: Interval;
  isYearly: boolean;
  isMonthly: boolean;
  hasPaymentOverdue: boolean;
  isFree: boolean;
  isProOrHigher: boolean;
  isPremiumOrHigher: boolean;
  isBusinessOrHigher: boolean;
  isPro: boolean;
  isPremium: boolean;
  isPersonal: boolean;
  isBusiness: boolean;
  isEnterprise: boolean;
  isTrial: boolean;
  isUpfrontCredit: boolean;
  trialEndDate?: Date;
  hasTrialed: boolean;
  isPayg: boolean;
  credit: AccountCredit;
  storageBytesUsed: number;
  totalStorageLabel: string;
  hasCustomUploadLimit: boolean;
  maxUploadLimit: number;
  availableStorage: number;
  totalStorage: number;
  storageUsagePercentage: number;
  isStorageLow: boolean;
  availableSeconds: number;
  storageUnits: number;
  pausedAt?: Date;
  pausedUntil?: Date;
  isPaused: boolean;
  planVersion: PlanProductVersion;
  discountCoupon?: Coupon;
  scheduledPlan?: Schedule;
}

export function usePlan(): UsePlan {
  const { user, settings } = useAccounts();
  const [plan] = useObservable(accountQuery.selectCurrentPlan(), accountQuery.currentPlan);
  const [primaryItem] = useObservable(accountQuery.selectPrimaryItem(), accountQuery.primaryItem);
  const [interval] = useObservable(accountQuery.selectInterval(), accountQuery.interval);
  const [isYearly] = useObservable(accountQuery.selectIsYearly(), accountQuery.isYearly);
  const [isMonthly] = useObservable(accountQuery.selectIsMonthly(), accountQuery.isMonthly);
  const [hasPaymentOverdue] = useObservable(accountQuery.selectHasPaymentOverdue(), accountQuery.hasPaymentOverdue);
  const [isProOrHigher] = useObservable(accountQuery.selectIsProOrHigher(), accountQuery.isProOrHigher);
  const [isPremiumOrHigher] = useObservable(accountQuery.selectIsPremiumOrHigher(), accountQuery.isPremiumOrHigher);
  const [isBusinessOrHigher] = useObservable(accountQuery.selectIsBusinessOrHigher(), accountQuery.isBusinessOrHigher);
  const [isPro] = useObservable(accountQuery.selectIsPro(), accountQuery.isPro);
  const [isPremium] = useObservable(accountQuery.selectIsPremium(), accountQuery.isPremium);
  const [isPersonal] = useObservable(accountQuery.selectIsPersonal(), accountQuery.isPersonal);
  const [isBusiness] = useObservable(accountQuery.selectIsBusiness(), accountQuery.isBusiness);
  const [isTrial] = useObservable(accountQuery.selectIsTrial(), accountQuery.isTrial);
  const [isUpfrontCredit] = useObservable(accountQuery.selectIsUpfrontCredit(), accountQuery.isUpfrontCredit);
  const [trialEndDate] = useObservable(accountQuery.selectTrialEndDate(), accountQuery.trialEndDate);
  const [isPayg] = useObservable(accountQuery.selectIsPayg(), accountQuery.isPayg);
  const [credit] = useObservable(accountQuery.selectCredit(), accountQuery.credit);
  const [storageBytesUsed] = useObservable(accountQuery.selectStorageBytesUsed(), accountQuery.storageBytesUsed);
  const [totalStorageLabel] = useObservable(accountQuery.selectTotalStorageLabel(), "");
  const [availableStorage] = useObservable(accountQuery.selectAvailableStorage(), accountQuery.availableStorage);
  const [totalStorage] = useObservable(accountQuery.selectTotalStorage(), accountQuery.totalStorage);
  const [storageUnits] = useObservable(accountQuery.selectStorageUnits(), accountQuery.storageUnits);
  const [pausedAt] = useObservable(accountQuery.selectPausedAt(), accountQuery.pausedAt);
  const [pausedUntil] = useObservable(accountQuery.selectPausedUntil(), accountQuery.pausedUntil);
  const [isPaused] = useObservable(accountQuery.selectIsPaused(), accountQuery.isPaused);
  const [discountCoupon] = useObservable(accountQuery.selectDiscountCoupon(), accountQuery.discountCoupon);
  const [scheduledPlan] = useObservable(accountQuery.selectScheduledPlan(), accountQuery.scheduledPlan);
  const [uploadLimitBytes] = useObservable(accountQuery.selectUploadLimitBytes(), accountQuery.uploadLimitBytes);
  const [isEnterprise] = useObservable(accountQuery.selectIsEnterprise(), accountQuery.isEnterprise);
  const [customUploadLimitBytes] = useObservable(
    accountQuery.selectCustomUploadLimitBytes(),
    accountQuery.customUploadLimitBytes
  );
  const [planVersion] = useObservable(accountQuery.selectPlanVersion(), accountQuery.planVersion);
  const hasAlreadyTrialled = Boolean(user?.settings?.hasAlreadyTrialled || settings?.hasAlreadyTrialled);
  const hasTrialed = isTrial || hasAlreadyTrialled;

  const isFree = plan === SublyPlan.Free;

  const hasCustomUploadLimit = Boolean(customUploadLimitBytes);
  const maxUploadLimit = hasCustomUploadLimit ? customUploadLimitBytes : uploadLimitBytes;

  const availableSeconds = credit?.total ?? 0;
  const storageUsagePercentage = totalStorage === 0 ? 100 : Math.round((storageBytesUsed / totalStorage) * 100);
  const isStorageLow = storageUsagePercentage >= 80;

  return {
    plan,
    primaryItem,
    interval,
    isYearly,
    isMonthly,
    hasPaymentOverdue,
    isFree,
    isPro,
    isProOrHigher,
    isPremiumOrHigher,
    isBusinessOrHigher,
    isPremium,
    isPersonal,
    isBusiness,
    isEnterprise,
    isTrial,
    isUpfrontCredit,
    trialEndDate,
    hasTrialed,
    isPayg,
    credit,
    storageBytesUsed,
    totalStorageLabel,
    hasCustomUploadLimit,
    maxUploadLimit,
    availableStorage,
    totalStorage,
    storageUsagePercentage,
    isStorageLow,
    availableSeconds,
    storageUnits,
    pausedAt,
    pausedUntil,
    isPaused,
    discountCoupon,
    scheduledPlan,
    planVersion
  };
}
