import React from "react";

import { cancelSchedule, updateSubscriptionSchedule } from "@frontend/api/billing.service";
import { handleError } from "@frontend/api/handle-error";
import { notificationSuccess } from "@frontend/components/notification";

import { NewModal } from "@components/new-modal";

import { useAccountMembers } from "@core/hooks/use-account-members";
import { useAccounts } from "@core/hooks/use-accounts";
import { AdditionalItem, SublyPlan } from "@core/interfaces/billing";
import { accountQuery } from "@core/state/account";
import { pluralize } from "@core/utils/strings";

interface CancelSeatRemovalModalProps {
  onDismiss: () => void;
}
export const CancelSeatRemovalModal: React.FC<CancelSeatRemovalModalProps> = ({ onDismiss }) => {
  const { subscription } = useAccounts();
  const { totalSeats, seatsToBeRemoved, nextSchedulePhase } = useAccountMembers();

  const [loading, setLoading] = React.useState(false);

  const handleCancelSeatRemoval = async () => {
    setLoading(true);

    try {
      if (!subscription?.schedule || !nextSchedulePhase) {
        return;
      }

      const scheduleId = subscription.schedule.id;

      const isAdditionalItem = (item: SublyPlan | AdditionalItem) =>
        [AdditionalItem.Minutes, AdditionalItem.Seats, AdditionalItem.Storage].includes(item as AdditionalItem);

      const hasAdditionItems = nextSchedulePhase.items.some((item) => isAdditionalItem(item.product));

      // If it has additional items, we need to update the subscription schedule
      // to cancel the removal of seats.
      if (hasAdditionItems) {
        const items = nextSchedulePhase.items.map((i) => {
          if (i.product === AdditionalItem.Seats) {
            return {
              price: i.price,
              quantity: subscription.items?.find((item) => item.product === i.product)?.units ?? totalSeats
            };
          }

          if (isAdditionalItem(i.product)) {
            return {
              price: i.price,
              quantity: i.units ?? 0
            };
          }

          return {
            price: i.price,
            quantity: accountQuery.primaryItem?.units ?? totalSeats
          };
        });

        await updateSubscriptionSchedule(subscription.id, {
          scheduleId,
          items
        });
      } else {
        await cancelSchedule(subscription.id);
      }

      setLoading(false);
      notificationSuccess(
        `Scheduled ${seatsToBeRemoved} ${pluralize(seatsToBeRemoved, "seat")} removal has been cancelled`
      );

      onDismiss();
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  const seatsLabel = pluralize(seatsToBeRemoved, "seat");

  return (
    <NewModal
      showCloseButton
      primaryAction={`Yes, add ${seatsLabel}`}
      onClickPrimary={handleCancelSeatRemoval}
      isPrimaryWarning
      secondaryAction={`No, remove ${seatsLabel}`}
      onClickSecondary={onDismiss}
      onDismiss={onDismiss}
      primaryActionLoading={loading}
    >
      <p className="tw-my-5">
        Are you sure you want to add {seatsToBeRemoved} {seatsLabel} back?
      </p>
    </NewModal>
  );
};
