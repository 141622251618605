import React from "react";

import { DASHBOARD_PATH } from "@frontend/routes";

import { Button } from "@components/button";

export const Page404: React.FC = () => {
  return (
    <div className="tw-container tw-mx-auto tw-mt-24">
      <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
        <div className="tw-text-center">
          <h2 className="tw-mb-12 tw-text-center tw-text-[100px] tw-text-h2 tw-leading-[120px] tw-text-aux-600">404</h2>
          <h2 className="tw-text-center tw-text-h2">Whoops! Looks like you got lost</h2>
          <p className="tw-mt-2 tw-text-center">We couldn't find what you were looking for.</p>
          <Button
            variant="primary"
            to={DASHBOARD_PATH}
            type="link"
            className="tw-mx-auto tw-mt-12 tw-w-full tw-max-w-[300px]"
            size="36"
          >
            Go Back
          </Button>
        </div>
      </div>

      <p className="tw-mt-24 tw-text-center">
        Copyright © {new Date().getFullYear()}{" "}
        <a href="https://getsubly.com" rel="noopener noreferrer" target="_blank">
          Subly
        </a>
        . All rights reserved.
      </p>
    </div>
  );
};
