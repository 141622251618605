import React from "react";

import { ISelectGroup } from "../select";

type GroupHeaderProps = {
  className?: string;
  group: ISelectGroup;
};

export const GroupHeader: React.FC<GroupHeaderProps> = ({ group }) => {
  return (
    <header className="tw-flex tw-px-6 tw-pb-2 tw-pt-4 tw-text-xs tw-uppercase tw-text-neutral-500">
      <div className="tw-mr-4 tw-min-w-0 tw-flex-1 tw-overflow-hidden tw-text-ellipsis">{group.label}</div>
      <div>{group.options.length}</div>
    </header>
  );
};
