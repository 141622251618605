import React, { useEffect, useMemo } from "react";
import classNames from "classnames";

import {
  addWordToVocabulary,
  deleteWordFromVocabulary,
  getCustomVocabularyWords
} from "@frontend/api/vocabulary.service";
import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import { Accordion } from "@components/accordion";
import { InformationCircleIcon } from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";

import { Fieldset } from "../form-controls/fieldset";
import { SettingsCard } from "../settings-card/settings-card";
import { TagsInput } from "../tags-input/tags-input";

interface CustomGlossaryInputProps {
  value: string[];
  className?: string;
  disabled?: boolean;
  variant?: "flat" | "outline";
  onChange?: (value: string[]) => void;
  onAddWord?: (tag: string) => void;
  onRemoveWord?: (tag: string) => void;
}

export const CustomGlossaryInput: React.FC<CustomGlossaryInputProps> = ({
  value = [],
  className,
  disabled,
  variant,
  onChange,
  onAddWord,
  onRemoveWord
}) => {
  const [showRules, setShowRules] = React.useState(false);

  const handleClickViewRules = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowRules(!showRules);
  };

  return (
    <Fieldset
      title={
        <div className="tw-inline-flex tw-w-full">
          Custom glossary
          <ToolTip text="Words added here will be used to improve the accuracy of your transcription.">
            <InformationCircleIcon className="tw-ml-1 tw-cursor-pointer tw-stroke-neutral-500" width={20} height={20} />
          </ToolTip>
          {/* <BusinessBadge className="tw-ml-2" /> */}
          <div
            className={classNames("tw-ml-auto tw-text-xs tw-font-normal hover:tw-text-neutral-900", {
              "tw-text-neutral-900": showRules,
              "tw-text-neutral-500": !showRules
            })}
          >
            (
            <a
              className={classNames("tw-cursor-pointer tw-underline hover:tw-underline", {
                "tw-underline": !showRules,
                "hover:tw-underline": !showRules
              })}
              href="#"
              onClick={handleClickViewRules}
            >
              view rules
            </a>
            )
          </div>
        </div>
      }
      className={classNames("tw-gap-2", className)}
    >
      {showRules ? (
        <ul className="tw-mb-1 tw-flex tw-list-disc tw-flex-col tw-gap-1 tw-rounded-lg tw-bg-neutral-50 tw-p-3 tw-pl-6 tw-text-sm">
          <li>Custom glossary can be single words or a sentence up to 6 words</li>
          <li>No punctuations or numbers, except apostrophes</li>
          <li>
            Each word should be in spoken form, for example: <span className="tw-font-bold">triple a</span> vs{" "}
            <span className="tw-font-bold">aaa</span> and <span className="tw-font-bold">iPhone seven</span> vs{" "}
            <span className="tw-font-bold">iPhone 7</span>
          </li>
          <li>
            Acronyms should have no spaces between letters, for example: <span className="tw-font-bold">ABC</span> vs{" "}
            <span className="tw-font-bold">A B C</span>
          </li>
        </ul>
      ) : null}
      <TagsInput
        value={value}
        contentClassName="tw-max-h-48"
        disabled={disabled}
        onAddTag={onAddWord}
        onRemoveTag={onRemoveWord}
        onChange={onChange}
        placeholder="Add words here to improve accuracy"
        variant={variant}
      />
    </Fieldset>
  );
};

export const GlossaryRules: React.FC = () => {
  return (
    <ul className="tw-flex tw-list-disc tw-flex-col tw-gap-1 tw-pl-3 tw-text-sm">
      <li>Custom glossary can be single words or a sentence up to 6 words</li>
      <li>No punctuations or numbers, except apostrophes</li>
      <li>
        Each word should be in spoken form, for example: <span className="tw-font-bold">triple a</span> vs{" "}
        <span className="tw-font-bold">aaa</span> and <span className="tw-font-bold">iPhone seven</span> vs{" "}
        <span className="tw-font-bold">iPhone 7</span>
      </li>
      <li>
        Acronyms should have no spaces between letters, for example: <span className="tw-font-bold">ABC</span> vs{" "}
        <span className="tw-font-bold">A B C</span>
      </li>
    </ul>
  );
};

type CustomGlossaryToolProps = {
  disabled?: boolean;
  className?: string;
};
export const CustomGlossaryTool: React.FC<CustomGlossaryToolProps> = ({ disabled, className }) => {
  const { customVocabularyWords } = useAccounts();

  useEffect(() => {
    if (disabled) return;
    getCustomVocabularyWords();
  }, [disabled]);

  const customWords = useMemo(() => {
    if (disabled) return [];
    return customVocabularyWords?.map((word) => word.word) || [];
  }, [customVocabularyWords]);

  const onAddWord = (word: string) => {
    if (!customVocabularyWords.find((w) => w.word === word)) {
      return addWordToVocabulary(word);
    }
  };

  const onRemoveWord = (word: string) => {
    const wordId = customVocabularyWords.find((w) => w.word === word)?.id;
    if (wordId) {
      return deleteWordFromVocabulary(wordId);
    }
  };

  return (
    <SettingsCard className={className}>
      <SettingsCard.Header>
        <div>
          <div className="tw-mb-1">Custom glossary</div>
          <p className="tw-text-sm tw-font-normal tw-text-neutral-600">
            Boost the likelihood of specific words to be transcribed
          </p>
        </div>
      </SettingsCard.Header>
      <SettingsCard.Body className="tw-flex tw-flex-col tw-gap-6 !tw-pt-0">
        <Accordion className="tw-rounded-lg tw-bg-neutral-50">
          <Accordion.AccordionTab title="Rules" titleClassName="tw-h-11" open={false}>
            <GlossaryRules />
          </Accordion.AccordionTab>
        </Accordion>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-sm tw-font-medium">Add custom glossary for all projects:</p>
          <TagsInput value={customWords} onAddTag={onAddWord} onRemoveTag={onRemoveWord} disabled={disabled} />
        </div>
      </SettingsCard.Body>
    </SettingsCard>
  );
};
