import { Observable } from "rxjs";

import { MediaComment } from "@core/interfaces/media";
import { Order, QueryConfig, QueryEntity } from "@datorama/akita";

import { CommentsState, CommentsStore, commentsStore } from "./comments.store";

@QueryConfig({
  sortBy: "uploadedAt",
  sortByOrder: Order.DESC
})
export class CommentsQuery extends QueryEntity<CommentsState> {
  get isLoading(): boolean {
    return this.getValue().loading;
  }

  get comments(): MediaComment[] {
    return this.getAll({ sortByOrder: Order.ASC, sortBy: "time" });
  }

  constructor(protected store: CommentsStore) {
    super(store);
  }

  selectComments(): Observable<MediaComment[]> {
    return this.selectAll({ sortByOrder: Order.ASC, sortBy: "time" });
  }
}

export const commentsQuery = new CommentsQuery(commentsStore);
