import React from "react";
import classNames from "classnames";

type SymbolGroupSize = "12" | "16" | "20" | "24";

interface SymbolGroupProps {
  size: SymbolGroupSize;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Container: React.FC<SymbolGroupProps> = ({ children, size, className, onClick }) => {
  const isSymbolItem = (child: React.ReactNode): child is React.ReactElement => {
    return React.isValidElement(child) && child.type === SymbolGroup.Item;
  };

  if (!React.Children.toArray(children).every(isSymbolItem)) {
    console.warn(`[SymbolGroup]: Invalid child detected. Each child of SymbolGroup must be a SymbolItem.`);
  }

  const offsetClass = {
    "12": "-tw-ml-[7px]",
    "16": "-tw-ml-2",
    "20": "-tw-ml-[11px]",
    "24": "-tw-ml-[11px]"
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClick?.();
  };

  return (
    <div className={classNames("tw-flex", className)} onClick={handleClick}>
      {React.Children.toArray(children).map((child, i) => {
        return React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement<SymbolItemProps>, {
              className: `${child.props.className ?? ""} ${i > 0 ? offsetClass[size] : ""}`
            })
          : child;
      })}
    </div>
  );
};

interface SymbolItemProps {
  children: React.ReactNode;
  className?: string;
}

export const Item: React.FC<SymbolItemProps> = ({ children, className }) => {
  return <div className={classNames("tw-relative tw-flex", className)}>{children}</div>;
};

export const SymbolGroup = Object.assign(Container, { Item });
