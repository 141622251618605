import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAnalytics } from "@frontend/contexts/analytics.context";

export const HubspotAnalytics: React.FC = () => {
  const { trackPage } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    trackPage();
  }, [location]);

  return null;
};
