import React from "react";
import classNames from "classnames";

import style from "./loading-icon.module.scss";

interface LoadingIconProps {
  loading?: boolean;
  primary?: boolean;
  className?: string;
}
export const LoadingIcon: React.FC<LoadingIconProps> = ({ loading = true, primary, className }) => {
  if (!loading) {
    return null;
  }

  return (
    <span
      className={classNames(style["loading-icon"], className, {
        "tw-text-white": primary
      })}
    />
  );
};
