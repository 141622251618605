import React from "react";
import classNames from "classnames";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";

import { PlanUpgradeModal } from "../upgrade-modal/plan-upgrade-modal";

import styles from "./lock-feature.module.scss";

export interface LockFeatureProps {
  forceLock?: boolean;
  minPermission?: SublyPlan;
  className?: string;
  lockClassName?: string;
  transition?: "none" | "fade";
  children?: React.ReactNode;
}

export const LockFeature: React.FC<LockFeatureProps> = ({
  forceLock,
  minPermission = SublyPlan.Pro,
  transition = "none",
  className,
  lockClassName,
  children
}) => {
  const { isLoaded } = useAccounts();
  const [showModal, hideModal] = useModal(ModalType.TrialUpgrade);
  const { hasPermission } = usePlanPermissions(minPermission);

  const isLocked = !hasPermission || forceLock;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    // Don't show anything if account is not loaded yet
    if (!isLoaded || forceLock) {
      return;
    }

    showModal(<PlanUpgradeModal upgradePlan={minPermission} closeModal={hideModal} />);
  };

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles["container--disabled"]]: isLocked,
          [styles["container--fade"]]: transition === "fade"
        },
        className
      )}
    >
      {children}
      {isLocked ? <div className={classNames(styles.lock, lockClassName)} onClick={handleClick} /> : null}
    </div>
  );
};
