import React from "react";
import classNames from "classnames";
import { PlacesType } from "react-tooltip";

import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import style from "./range.module.scss";

interface RangeProps {
  className?: string;
  value: number;
  onChange: (value: number) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  min?: number;
  max?: number;
  step?: number;
  trackColor?: string;
  progressColor?: string;
  handleBgColor?: string;
  handleBorderColor?: string;
  revealHandleOnHover?: boolean;
  hasLabel?: boolean;
  label?: string;
  hasTooltip?: boolean;
  tooltipOffset?: number;
  formatTooltip?: (value: number) => string;
  getTooltipPlace?: (value: number) => PlacesType;
  expandOnHover?: boolean;
  onIsDragging?: (isDragging: boolean) => void;
}
export const Range: React.FC<RangeProps> = ({
  value,
  className,
  onChange,
  onClick,
  min = 0,
  max = 100,
  step,
  trackColor = "var(--color-neutral-300)",
  progressColor = "var(--color-primary-500)",
  handleBgColor = "#fff",
  handleBorderColor = "var(--color-neutral-200)",
  revealHandleOnHover = false,
  hasLabel = false,
  hasTooltip = false,
  tooltipOffset = -24,
  expandOnHover = false,
  label = "%",
  onIsDragging,
  formatTooltip,
  getTooltipPlace
}) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const snapToStep = (value: number, step: number, max: number) => {
    let snappedValue = Math.floor(value / step) * step;
    if (snappedValue !== value) {
      snappedValue += step;
    }
    return Math.min(snappedValue, max);
  };
  const computedValue = typeof step === "number" ? snapToStep(value, step, max) : value;

  const percentage = Math.ceil((100 * (computedValue - min)) / (max - min));

  const handleRadius = 8;
  const percentageOffset =
    percentage < 50 ? (-1 + percentage / 50) * handleRadius : ((percentage - 50) / 50) * 1 * handleRadius;

  const cssVars: Record<string, string | number | null> = {
    "--track-color": trackColor,
    "--progress-color": progressColor,
    "--handle-bg-color": handleBgColor,
    "--handle-border-color": handleBorderColor,
    "--percentage": `${percentage}%`,
    "--percentage-offset": `${percentageOffset}px`
  };

  // only show tooltip when hovering or dragging slider

  const handleMouseUp = () => {
    onIsDragging?.(false);
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseDown = () => {
    onIsDragging?.(true);
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className={classNames("tw-group tw-relative tw-flex tw-flex-grow tw-items-center tw-gap-2 tw-text-xs", className)}
    >
      {hasTooltip && (
        <div
          className={classNames([
            "tw-absolute tw--top-8 tw--translate-x-1/2 tw-transform tw-opacity-0 !tw-transition-opacity !tw-ease-out group-hover:tw-opacity-100",
            { "!tw-opacity-100": isDragging }
          ])}
          style={{ left: `calc(${percentage}% - ${percentageOffset}px)` }}
        >
          <ToolTip
            text={formatTooltip?.(computedValue) ?? String(computedValue)}
            place={getTooltipPlace?.(computedValue) ?? "top"}
            isOpen
            style={{ left: `calc(${percentage}% - ${percentageOffset}px)` }}
            className="tw-text-center"
            offset={tooltipOffset}
          />
        </div>
      )}
      <input
        className={classNames(
          style.range,
          expandOnHover && "!tw-transition-all !tw-ease-out hover:tw-h-1.5 group-hover:tw-h-1.5",
          revealHandleOnHover && style["range--reveal-handle-on-hover"]
        )}
        type="range"
        value={computedValue}
        onChange={(e) => onChange(+e.target.value)}
        style={cssVars}
        min={min}
        max={max}
        step={step}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={onClick}
      />

      {hasLabel && (
        <span className="tw-w-10 tw-shrink-0 tw-text-center">
          {parseInt(`${percentage}`)}
          {label}
        </span>
      )}
    </div>
  );
};
