import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { ProgressRing } from "@frontend/components/progress-ring/progress-ring";
import { MEDIA_PATH } from "@frontend/routes";

import { IconButton } from "@components/button";
import { AudioIcon, SuccessStatusIcon, TextIcon } from "@components/icons";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useUpload } from "@core/hooks/use-upload";
import { UploadingStatus, uploadQuery } from "@core/state/upload";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiFilmLine } from "@remixicon/react";

import styles from "./upload-queue.module.scss";

type LocalQueueItem = {
  uploadId: string; // this is a temp id generated locally to track upload
  mediaId?: string; // this is the id generated by the backend
  name: string;
  uploadProgress: number;
  uploadingStatus: UploadingStatus;
  type: "audio" | "video";
};

export const UploadQueue = () => {
  const [show, setShow] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [localQueue, setLocalQueue] = React.useState<LocalQueueItem[]>([]);

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { uploadQueue } = useUpload();

  // Show this component whenever a new upload is added to the queue
  React.useEffect(() => {
    const shouldShow = localQueue.length > 0;
    if (shouldShow) setShow(true);
  }, [localQueue.length]);

  // Sync v1/v2 upload queues with this component. This effect converts both queues into a local common format.
  React.useEffect(() => {
    const updatedLocalQueue = [...localQueue];

    // When the files have been submitted for upload, this loop diffs and adds
    // the files that have been selected for upload to the local queue.

    const normalisedQueue: LocalQueueItem[] = uploadQueue.map((item) => ({
      uploadId: item.uploadId,
      mediaId: item.mediaId,
      name: item.mediaName ?? "",
      uploadProgress: item.uploadProgress,
      uploadingStatus: item.uploadingStatus,
      type: ("file" in item && item.file.type.includes("audio") ? "audio" : "video") as "audio" | "video"
    }));

    // Diff the global render queues against the local queue and update local queue state accordingly
    for (const item of normalisedQueue) {
      const localQueueIndex = updatedLocalQueue.findIndex((_item) => _item.uploadId === item.uploadId);

      if (updatedLocalQueue[localQueueIndex]) {
        updatedLocalQueue[localQueueIndex] = item;
      } else if (uploadQuery.isUploading) {
        updatedLocalQueue.push(item);
      }
    }

    // Mark items as complete if they are not in either queue
    updatedLocalQueue.forEach((upload) => {
      if (
        upload.uploadingStatus !== UploadingStatus.Complete &&
        !uploadQueue.some((item) => item.uploadId === upload.uploadId)
      ) {
        trackEventWithAuth("Upload success");
        upload.uploadingStatus = UploadingStatus.Complete;
      }
    });

    setLocalQueue(updatedLocalQueue);
  }, [uploadQueue]);

  const hasIncompleteItems = React.useMemo(() => {
    return localQueue.some((upload) => upload.uploadingStatus !== UploadingStatus.Complete);
  }, [localQueue]);

  const headingText = React.useMemo(() => {
    const { incompleteCount, completeCount } = localQueue.reduce(
      (acc, item) => {
        if (item.uploadingStatus === UploadingStatus.Complete) {
          return { ...acc, completeCount: acc.completeCount + 1 };
        } else {
          return { ...acc, incompleteCount: acc.incompleteCount + 1 };
        }
      },
      { incompleteCount: 0, completeCount: 0 }
    );

    if (incompleteCount > 0) {
      return `Uploading ${incompleteCount} file${incompleteCount > 1 ? "s" : ""}`;
    } else {
      return `${completeCount} upload${completeCount > 1 ? "s" : ""} complete`;
    }
  }, [localQueue]);

  const handleClose = () => {
    setShow(false);
    setLocalQueue([]);
  };

  return (
    <span
      className={classNames(
        "tw-fixed tw-bottom-0 tw-right-8 tw-z-10 tw-flex tw-w-[360px] tw-translate-y-0 tw-transform tw-flex-col tw-overflow-hidden tw-rounded-t-lg tw-border-l tw-border-r tw-border-t tw-border-neutral-200 tw-bg-white tw-opacity-100 tw-shadow-lg tw-transition-all",
        { "tw-translate-y-full !tw-opacity-0": !show }
      )}
    >
      <header className="tw-flex tw-w-full tw-border-b tw-border-b-neutral-200 tw-bg-neutral-100 tw-p-4">
        <span className="tw-mr-auto tw-font-semibold tw-text-neutral-900">{headingText}</span>
        <IconButton
          variant="ghost"
          size="wrap"
          icon={
            expanded ? (
              <RiArrowDownSLine className="tw-h-6 tw-w-6 tw-shrink-0" />
            ) : (
              <RiArrowUpSLine className="tw-h-6 tw-w-6 tw-shrink-0" />
            )
          }
          className="tw--my-2 tw-h-10 tw-w-10 tw-rounded-full"
          onClick={() => setExpanded(!expanded)}
        />
        <IconButton
          variant="ghost"
          size="wrap"
          icon={<RiCloseLine className="tw-h-6 tw-w-6 tw-shrink-0" />}
          className={classNames("tw--my-2 tw--mr-2 tw-h-10 tw-w-10 tw-rounded-full", {
            "!tw-border-transparent !tw-bg-transparent": hasIncompleteItems
          })}
          onClick={handleClose}
          disabled={hasIncompleteItems}
        />
      </header>
      {expanded && (
        <ul className="tw-flex tw-max-h-[300px] tw-flex-col tw-overflow-y-auto">
          {localQueue.map((item) => {
            const uploadingStatus =
              item.uploadingStatus === UploadingStatus.Complete ? (
                <SuccessStatusIcon className="tw-mr-[-5px] tw-inline-flex tw-h-6 tw-w-6 tw-shrink-0 tw-text-white" />
              ) : item.uploadProgress === 100 ? (
                <TranscribingLoading />
              ) : (
                <ProgressRing
                  className="tw-mr-[-3px] tw-shrink-0"
                  progress={item.uploadProgress ?? 0}
                  labelled={false}
                  radius={10}
                  stroke={5}
                  theme="primary"
                />
              );

            return (
              <li key={item.uploadId} className="tw-flex tw-w-full">
                <Link
                  to={item.uploadingStatus === UploadingStatus.Complete ? `${MEDIA_PATH}/${item.mediaId}` : "#"}
                  className={classNames("tw-flex tw-w-full tw-items-center tw-gap-2 tw-p-4 tw-pr-[21px] ", {
                    "tw-pointer-events-none": item.uploadingStatus !== UploadingStatus.Complete,
                    "hover:tw-bg-neutral-200 ": item.uploadingStatus === UploadingStatus.Complete
                  })}
                >
                  <FeaturedIcon disabled={item.uploadingStatus !== UploadingStatus.Complete} type={item.type} />
                  <span
                    className={classNames("tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap", {
                      "tw-opacity-50": item.uploadingStatus !== UploadingStatus.Complete
                    })}
                  >
                    {item.name}
                  </span>
                  {uploadingStatus}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </span>
  );
};

type FeaturedIconProps = {
  disabled?: boolean;
  type: "audio" | "video" | "srt";
};
const FeaturedIcon: React.FC<FeaturedIconProps> = ({ disabled, type }) => {
  return (
    <div className="tw-inline-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded tw-bg-neutral-50">
      {type === "video" && (
        <RiFilmLine
          className={classNames("tw-h-4 tw-w-4 tw-shrink-0", {
            "tw-opacity-50": disabled
          })}
        />
      )}
      {type === "audio" && (
        <AudioIcon
          className={classNames("tw-h-4 tw-w-4 tw-shrink-0", {
            "tw-opacity-50": disabled
          })}
        />
      )}
      {type === "srt" && (
        <TextIcon
          className={classNames("tw-h-4 tw-w-4 tw-shrink-0", {
            "tw-opacity-50": disabled
          })}
        />
      )}
    </div>
  );
};

export const TranscribingLoading = () => {
  return (
    <>
      <div className="tw-mr-[-5px] tw-inline-flex tw-w-6 tw-shrink-0 tw-items-center tw-justify-center">
        <div className="tw-flex tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-primary-600 tw-p-1">
          <div className={styles.bars} />
        </div>
      </div>
    </>
  );
};
