import { useObservable } from "@mindspace-io/react";
import { createStore, select, withProps } from "@ngneat/elf";

interface TranslationsEditorState {
  leftPaneId?: string;
  rightPaneId?: string;
}

const translationsEditorStore = createStore({ name: "translations-editor" }, withProps<TranslationsEditorState>({}));

const leftPaneId$ = translationsEditorStore.pipe(select((s) => s.leftPaneId));
const rightPaneId$ = translationsEditorStore.pipe(select((s) => s.rightPaneId));

export const resetTranslationsEditorState = () => {
  translationsEditorStore.reset();
};

export const updateLeftPaneId = (id?: string) => {
  translationsEditorStore.update((s) => ({ ...s, leftPaneId: id }));
};

export const updateRightPaneId = (id?: string) => {
  translationsEditorStore.update((s) => ({ ...s, rightPaneId: id }));
};

export const useTranslationEditorState = (): TranslationsEditorState => {
  const [leftPaneId] = useObservable(leftPaneId$);
  const [rightPaneId] = useObservable(rightPaneId$);

  return {
    leftPaneId: leftPaneId || "",
    rightPaneId: rightPaneId || ""
  };
};
