import React from "react";
import classNames from "classnames";
import { isEqual } from "lodash-es";

import { updateAccountGuidelinePreset } from "@frontend/api/account.service";
import { GuidelineOptions, guidelinesMedia, LetterCase } from "@frontend/api/media.service";
import { EN } from "@frontend/assets/i18n/en";
import { LockFeature } from "@frontend/components/lock-feature/lock-feature";
import { notificationError, notificationSuccess } from "@frontend/components/notification";
import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from "@components/dropdown";
import { Fieldset, Input, Toggle } from "@components/form-controls";
import { ArrowPathIcon, ChatBubbleOvalLeftEllipsisIcon, InformationCircleIcon } from "@components/icons";
import { NewModal } from "@components/new-modal";

import { useDebounce } from "@core/hooks/use-debounce";
import { useModal } from "@core/hooks/use-modal";
import { SublyPlan } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { accountQuery } from "@core/state/account";
import { useActiveMediaIdState, useCurrentMediaLanguage } from "@core/state/editor/editor.hooks";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { getGuidelineName, GUIDELINE_TEMPLATES, GuidelineTemplate } from "@core/utils/guidelines";
import { msToSec, secToMs } from "@core/utils/time";
import { AspectRatio, Transcription } from "@getsubly/common";
import { ToolbarButton } from "@media-editor/components/buttons/toolbar-button";
import { useEditorCurrentSubtitlesIdState } from "@media-editor/state/media-editor.hooks";

import { SuggestGuidelineModal } from "./guidelines-modal";

interface GuideLinesProps {
  handleUpdateTranscription: (transcription: Transcription, transcriptionId: string) => void;
}

export const GuideLines: React.FC<GuideLinesProps> = ({ handleUpdateTranscription }) => {
  const mediaId = useActiveMediaIdState();

  const { currentSubtitlesId } = useEditorCurrentSubtitlesIdState();
  const currentLanguage = useCurrentMediaLanguage();

  const [loading, setLoading] = React.useState(false);
  const [currentGuideline, setCurrentGuideline] = React.useState<GuidelineTemplate | undefined>(
    accountQuery.guidelineSettings
  );

  const [showModal, hideModal] = useModal(ModalType.SuggestNewGuideline);

  const debouncedGuidelines = useDebounce(currentGuideline, 3000);

  React.useEffect(() => {
    if (debouncedGuidelines) {
      updateAccountGuidelinePreset({
        presetName: debouncedGuidelines.presetName,
        guideline: debouncedGuidelines.guideline
      });
    }
  }, [debouncedGuidelines]);

  if (!currentSubtitlesId || !mediaId) {
    return null;
  }

  const updateValue = <K extends keyof GuidelineOptions, V extends GuidelineOptions[K]>(k: K, v: V) => {
    const newGuideline = {
      ...currentGuideline?.guideline,
      [k]: v
    };

    const newGuidelineTemplate: GuidelineTemplate = {
      presetName: currentGuideline?.presetName ?? "Custom",
      guideline: newGuideline
    };

    newGuidelineTemplate.presetName = getGuidelineName(newGuidelineTemplate);

    setCurrentGuideline(newGuidelineTemplate);
  };

  const applyGuideline = async () => {
    const mediaConfig = editorStateRepository.assConfig;

    if (!mediaId || !mediaConfig || !currentGuideline || !currentSubtitlesId) {
      return;
    }

    try {
      setLoading(true);

      const transcript = await guidelinesMedia({
        mediaId,
        transcriptionId: currentSubtitlesId,
        guidelines: currentGuideline.guideline,
        height: mediaConfig.playResY,
        width: mediaConfig.playResX,
        ratio: mediaConfig.aspectRatio?.ratio ?? AspectRatio.Landscape,
        languageCode: currentLanguage?.languageCode
      });

      if (transcript) {
        handleUpdateTranscription(transcript, currentSubtitlesId);
      }

      await updateAccountGuidelinePreset({
        presetName: currentGuideline.presetName,
        guideline: currentGuideline.guideline
      });
      setLoading(false);

      notificationSuccess("Subtitles were reprocessed.");
    } catch (error) {
      notificationError(EN.error.defaultMessage);
    }
  };

  const renderPresetGuidelines = GUIDELINE_TEMPLATES.map((g) => {
    const isSelected = isEqual(g.guideline, currentGuideline?.guideline);

    return (
      <DropdownItem selected={isSelected} onClick={() => setCurrentGuideline(g)} key={g.presetName}>
        {g.presetName}
      </DropdownItem>
    );
  });

  const currentPreset = currentGuideline?.presetName ?? "Custom";
  const isCustomPreset = currentPreset === "Custom";

  return (
    <LockFeature minPermission={SublyPlan.Personal} className="tw-w-full">
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-6">
        <p className="tw-font-semibold tw-text-neutral-900">Guidelines</p>
        <Dropdown>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <FeatureHeader
              title="Subtitle guidelines"
              tooltipText={`Pick preset templates to \nautomatically set guideline subtitles,\nor choose custom to customize.`}
            />
            <DropdownButton className="!tw-w-full">{currentPreset}</DropdownButton>
          </div>
          <DropdownMenu className="!tw-w-full">
            {renderPresetGuidelines}
            {isCustomPreset && <DropdownItem selected={isCustomPreset}>{currentPreset}</DropdownItem>}

            <DropdownDivider />
            <DropdownItem
              className="tw-flex tw-flex-row tw-items-center tw-gap-2"
              onClick={() => showModal(<SuggestGuidelineModal onClose={hideModal} />)}
            >
              <ChatBubbleOvalLeftEllipsisIcon width={20} height={20} />
              Suggest a guideline standard
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <div className="tw-col-1 tw-grid tw-gap-1">
          <FeatureHeader title="Maximum lines" tooltipText="Set maximum subtitle lines." />
          <LinesOption init={currentGuideline?.guideline.maxLines ?? 0} onChange={(v) => updateValue("maxLines", v)} />
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <Fieldset
            title="Max. chars per line"
            tooltipText={`Set the maximum characters allowed per line.\nRecommended length is between\n40-50 characters per line.`}
          >
            <Input
              type="number"
              value={currentGuideline?.guideline.maxCharsLine ?? 0}
              onChange={({ target }) => {
                updateValue("maxCharsLine", Number(target.value));
              }}
              after="CPL"
            />
          </Fieldset>
          <Fieldset title="Max. words per line" tooltipText="Set the maximum words per line.">
            <Input
              type="number"
              value={currentGuideline?.guideline.maxLineWords ?? 0}
              onChange={({ target }) => {
                updateValue("maxLineWords", Number(target.value));
              }}
            />
          </Fieldset>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <Fieldset title="Max. chars per second" tooltipText="Set the maximum characters allowed per second.">
            <Input
              value={currentGuideline?.guideline.maxCharsSec ?? 0}
              onChange={({ target }) => {
                updateValue("maxCharsSec", Number(target.value));
              }}
              after="CPS"
            />
          </Fieldset>
          <Fieldset title="Max. duration (seconds)" tooltipText="Maximum duration of a cue per second.">
            <Input
              type="number"
              value={msToSec(currentGuideline?.guideline.maxCueDuration) ?? 0}
              onChange={({ target }) => {
                updateValue("maxCueDuration", secToMs(Number(target.value)));
              }}
            />
          </Fieldset>
        </div>

        <Fieldset title="Transform text" tooltipText="Change the case of subtitles.">
          <TransformTextOption
            value={currentGuideline?.guideline.transformLetterCase}
            onChange={(value) => updateValue("transformLetterCase", value)}
          />
        </Fieldset>

        <div className="tw-flex tw-items-center">
          <Toggle
            label="Create a new line after punctuation"
            checked={Boolean(currentGuideline?.guideline.newLineAfterPunctuation)}
            onChange={(e) => updateValue("newLineAfterPunctuation", e.target.checked)}
          />

          <FeatureHeader tooltipText="Add line breaks after period(.), exclamation mark(!), question mark(?), ellipsis(...)" />
        </div>

        <Button
          variant="secondary"
          className="tw-flex tw-flex-row tw-gap-2"
          onClick={applyGuideline}
          loading={loading}
          size="40"
        >
          {!loading && <ArrowPathIcon height={15} width={15} />}
          {!loading ? "Reprocess" : "Reprocessing"} subtitles
        </Button>
      </div>
    </LockFeature>
  );
};

interface FeatureHeaderProps {
  title?: string;
  tooltipText: string;
}

const FeatureHeader: React.FC<FeatureHeaderProps> = ({ title, tooltipText }) => {
  return (
    <div className={classNames("tw-flex tw-items-center", { "tw-mb-1 ": title })}>
      {title && <p className="tw-text-sm tw-font-medium">{title}</p>}
      <ToolTip text={tooltipText} place="top">
        <InformationCircleIcon className="tw-ml-2 tw-cursor-pointer tw-stroke-neutral-500" width={16} height={16} />
      </ToolTip>
    </div>
  );
};

interface LinesOptionProp {
  init?: number;
  onChange: (v: number) => void;
}
export const LinesOption: React.FC<LinesOptionProp> = ({ init, onChange }) => {
  const [maxLines, setMaxLines] = React.useState<number>();

  React.useEffect(() => {
    if (init) {
      setMaxLines(init);
    }
  }, [init]);

  const handleChange = (v: number) => {
    if (maxLines !== v) {
      setMaxLines(v);
      onChange(v);
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-3 tw-gap-3">
      <LineBox lines={1} selected={maxLines === 1} onSelect={handleChange} />
      <LineBox lines={2} selected={maxLines === 2} onSelect={handleChange} />
      <LineBox lines={3} selected={maxLines === 3} onSelect={handleChange} />
    </div>
  );
};

interface LineBoxProps {
  lines: number;
  selected: boolean;
  onSelect: (v: number) => void;
}
const LineBox: React.FC<LineBoxProps> = ({ lines, selected, onSelect }) => {
  return (
    <div
      className={classNames(
        "tw-group tw-grid tw-h-16 tw-grow tw-cursor-pointer tw-grid-rows-2 tw-gap-1 tw-rounded-md tw-px-[10px] tw-py-2",
        {
          "tw-bg-primary-400 tw-text-white": selected,
          "tw-bg-neutral-50 tw-text-neutral-500 hover:tw-bg-primary-100": !selected
        }
      )}
      onClick={() => onSelect(lines)}
    >
      <p className="tw-flex tw-justify-center tw-text-xs tw-font-medium tw-leading-[20px]">
        {
          {
            1: "One line",
            2: "Two lines",
            3: "Three lines"
          }[lines]
        }
      </p>
      <div className="tw-mx-auto tw-flex tw-w-[117px] tw-flex-col tw-items-center tw-gap-[3px]">
        {lines === 1 && <Line width="81px" isSelected={selected} />}
        {lines === 2 && (
          <>
            <Line width="65px" isSelected={selected} />
            <Line width="81px" isSelected={selected} />
          </>
        )}
        {lines === 3 && (
          <>
            <Line width="81px" isSelected={selected} />
            <Line width="65px" isSelected={selected} />
            <Line width="81px" isSelected={selected} />
          </>
        )}
      </div>
    </div>
  );
};

type LineProps = {
  width: string;
  isSelected: boolean;
};

const Line: React.FC<LineProps> = ({ width, isSelected }) => {
  return (
    <div
      style={{ width }}
      className={classNames("tw-h-1 tw-rounded-xl", {
        "tw-bg-white": isSelected,
        "tw-bg-neutral-400": !isSelected
      })}
    />
  );
};

interface TransformTextOptionProp {
  value?: null | LetterCase;
  onChange: (value: null | LetterCase) => void;
}
const TransformTextOption: React.FC<TransformTextOptionProp> = ({ value, onChange }) => {
  return (
    <div className="tw-inline-flex tw-w-fit tw-gap-1 tw-rounded tw-border tw-border-neutral-200 tw-p-1">
      <ToolTip text="Apply none">
        <ToolbarButton active={!value} onClick={() => onChange(null)}>
          -
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Uppercase">
        <ToolbarButton active={value === LetterCase.uppercase} onClick={() => onChange?.(LetterCase.uppercase)}>
          AG
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Lowercase">
        <ToolbarButton active={value === LetterCase.lowercase} onClick={() => onChange?.(LetterCase.lowercase)}>
          aa
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Sentence case">
        <ToolbarButton active={value === LetterCase.capitalise} onClick={() => onChange?.(LetterCase.capitalise)}>
          Ag
        </ToolbarButton>
      </ToolTip>
    </div>
  );
};

interface GuidelineModalProps extends GuideLinesProps {
  closeModal: () => void;
}

export const GuidelinesModal: React.FC<GuidelineModalProps> = ({ closeModal, handleUpdateTranscription }) => {
  return (
    <NewModal onDismiss={closeModal} showCloseButton className="!tw-w-full !tw-max-w-[608px] !tw-p-6">
      <GuideLines handleUpdateTranscription={handleUpdateTranscription} />
    </NewModal>
  );
};

interface DefaultModalProps {
  closeModal: () => void;
}

export const CpsCpl: React.FC<DefaultModalProps> = ({ closeModal }) => {
  const [currentGuideline, setCurrentGuideline] = React.useState<GuidelineTemplate | undefined>(
    accountQuery.guidelineSettings
  );
  const [loading, setLoading] = React.useState(false);

  const onSave = async () => {
    if (!currentGuideline) {
      return;
    }

    setLoading(true);
    await updateAccountGuidelinePreset(currentGuideline);
    setLoading(false);
    closeModal();
  };

  const updateValue = <K extends keyof GuidelineOptions, V extends GuidelineOptions[K]>(k: K, v: V) => {
    const newGuideline = {
      ...currentGuideline?.guideline,
      [k]: v
    };

    const newGuidelineTemplate: GuidelineTemplate = {
      presetName: currentGuideline?.presetName ?? "Custom",
      guideline: newGuideline
    };

    newGuidelineTemplate.presetName = getGuidelineName(newGuidelineTemplate);

    setCurrentGuideline(newGuidelineTemplate);
  };

  return (
    <div className=" tw-mt-4 tw-flex tw-flex-col tw-gap-4">
      <Fieldset
        title="Max. chars per line"
        tooltipText={`Set the maximum characters allowed per line.\nRecommended length is between\n40-50 characters per line.`}
      >
        <Input
          type="number"
          value={currentGuideline?.guideline.maxCharsLine ?? 0}
          onChange={({ target }) => {
            updateValue("maxCharsLine", Number(target.value));
          }}
          after="CPL"
        />
      </Fieldset>
      <Fieldset title="Max. chars per second" tooltipText="Set the maximum characters allowed per second.">
        <Input
          value={currentGuideline?.guideline.maxCharsSec ?? 0}
          onChange={({ target }) => {
            updateValue("maxCharsSec", Number(target.value));
          }}
          after="CPS"
        />
      </Fieldset>

      <div className="tw-mt-2 tw-flex tw-justify-between">
        <Button variant="secondary" onClick={closeModal} disabled={loading} size="36">
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} loading={loading} size="36">
          Save
        </Button>
      </div>
    </div>
  );
};

export const CpsCplModal: React.FC<DefaultModalProps> = ({ closeModal }) => {
  return (
    <NewModal
      onDismiss={closeModal}
      showCloseButton
      size="465"
      title="Adjust alert limit"
      description="Set your CPS and CPL alert thresholds."
    >
      <CpsCpl closeModal={closeModal} />
    </NewModal>
  );
};
