/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import MediaInfo, { MediaInfo as MediaInfoInterface } from "mediainfo.js";
import { Track } from "mediainfo.js/dist/types";
import { v4 } from "uuid";

import { getFileFromURL } from "@frontend/api/file.service";
import { getVideoPlatformFileInfo, UrlInfo } from "@frontend/api/media.service";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { accountQuery } from "@core/state/account";
import {
  UploadErrorType,
  UploadFile,
  UploadFileBase,
  UploadingStatus,
  UploadUrlFile,
  UploadZoomFile
} from "@core/state/upload";
import { maxFileDuration } from "@core/utils/plans";
import { addHttps, capitalize, formatMediaName } from "@core/utils/strings";
import { UploadLimit } from "@getsubly/common/dist/interfaces/account";

const readChunk =
  (file: File) =>
  (chunkSize: number, offset: number): Promise<Uint8Array> | Uint8Array =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        if (event.target.error) {
          reject(event.target.error);
        }

        resolve(new Uint8Array(event.target.result));
      };

      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });

let mediaInfo: MediaInfoInterface;

export interface IMediaInfo {
  duration: number;
  fileSize: number;
  isAudio: boolean;
  warning?: string;
}

interface ICheckFileErrors {
  duration: number;
  size: number;
  skipDurationCheck?: boolean;
}

interface UseMediaInfoInterface {
  loading: boolean;
  getMediaFileInfo: (file?: File) => Promise<IMediaInfo>;
  getRemoteMediaFileInfo: (url: string, pathname: string) => Promise<UrlInfo>;
  checkFileErrors: (info: ICheckFileErrors) => UploadErrorType | undefined;
  convertFileToUploadFile: (file: File) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
  convertUrlToUploadFile: (
    url: string,
    languageCode?: string
  ) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
  convertZoomToUploadFile: ({
    meetingId,
    fileId,
    filename,
    fileSize,
    fileType,
    duration,
    isAudio
  }: {
    meetingId: number;
    fileId: string;
    filename: string;
    fileSize: number;
    fileType?: string;
    duration: number;
    isAudio?: boolean;
  }) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
}
export function useMediaInfo(): UseMediaInfoInterface {
  const [loading, setLoading] = React.useState(false);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  // Loads MediaInfo.js into memory, only the first time this hook runs.
  React.useEffect(() => {
    if (mediaInfo) {
      return;
    }

    MediaInfo().then((m) => (mediaInfo = m as MediaInfoInterface));
  }, []);

  /**
   * Retrieves information about a media file.
   *
   * @param {File} file - The media file to analyze.
   * @returns {Promise<IMediaInfo>} - A promise that resolves to an object containing the media file's information.
   * @throws Will throw an error if no file is provided or if the file can't be read.
   */
  const getMediaFileInfo = async (file?: File): Promise<IMediaInfo> => {
    try {
      setLoading(true);

      if (!file) {
        throw Error("No file");
      }

      if (!mediaInfo) {
        mediaInfo = (await MediaInfo()) as MediaInfoInterface;
      }

      const result = await mediaInfo.analyzeData(() => file.size, readChunk(file));

      if (typeof result === "string" || !result) {
        throw Error("Can't read file");
      }

      const general: any = result.media.track.find((r: Track) => r["@type"] === "General");

      const duration = general?.Duration ?? 0;
      const fileSize = general?.FileSize ?? 0;

      const videoCount = general?.VideoCount ?? 0;
      const isVideo = +videoCount > 0;
      const isAudio = !isVideo;

      const videoTrack: any = result.media.track.find((r) => r["@type"] === "Video");
      let warning = undefined;
      if (videoTrack?.CodecID === "hvc1" && videoTrack?.Format === "HEVC") {
        warning = "HEVC video may have limited colour range";
      } else if (videoTrack?.CodecID === "av01" && videoTrack?.Format === "AV1") {
        warning = "AV1 encoded video may not be supported";
      }

      return {
        duration: Number(duration),
        fileSize: Number(fileSize),
        isAudio,
        warning
      };
    } catch (e) {
      return {
        duration: 0,
        fileSize: 0,
        isAudio: false
      };
    } finally {
      setLoading(false);
    }
  };

  const getRemoteMediaFileInfo = async (url: string, pathname: string): Promise<UrlInfo> => {
    const filename = capitalize(pathname.split("/").pop() ?? pathname);
    const file = await getFileFromURL(url, filename);

    const mediaInfo = await getMediaFileInfo(file);

    return {
      url,
      filename,
      duration: mediaInfo.duration,
      fileSize: mediaInfo.fileSize
    };
  };

  const checkFileErrors = ({
    duration,
    size: sizeBytes,
    skipDurationCheck = false
  }: ICheckFileErrors): UploadErrorType | undefined => {
    const availableSeconds = accountQuery.credit?.total ?? 0;
    const availableStorage = accountQuery.availableStorage;
    const maxUploadLimitBytes = accountQuery.uploadLimitBytes;

    const maxUploadLimitGb = maxUploadLimitBytes / 1024 / 1024 / 1024;
    const sizeGb = sizeBytes / 1024 / 1024 / 1025;

    if (availableStorage < sizeBytes) {
      return UploadErrorType.Storage;
    } else if (!skipDurationCheck && availableSeconds < duration) {
      return UploadErrorType.Credit;
    } else if (duration > maxFileDuration) {
      return UploadErrorType.Duration;
    } else if (maxUploadLimitGb < sizeGb) {
      switch (maxUploadLimitGb) {
        case UploadLimit.FileSizeMax512MB:
          return UploadErrorType.FileSizeMax512MB;
        case UploadLimit.FileSizeMax1GB:
          return UploadErrorType.FileSizeMax1GB;
        case UploadLimit.FileSizeMax2GB:
          return UploadErrorType.FileSizeMax2GB;
        case UploadLimit.FileSizeMax5GB:
          return UploadErrorType.FileSizeMax5GB;
        default:
          // The user should never reach this point. If they do, it likely indicates a custom upload limit
          // that needs to be updated to one of the predefined `UploadLimit` values. If additional limits
          // are necessary, they should be added to `UploadLimit` and documented accordingly.
          return UploadErrorType.FileSize;
      }
    }

    return undefined;
  };

  // validates file, extracts details, returns a normalised UploadFile object (normalises remote, url and zoom files)
  const convertFileToUploadFile = async (
    file: File
  ): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      const { duration, fileSize, isAudio, warning } = await getMediaFileInfo(file);

      return makeUploadFile({
        filename: file.name,
        duration,
        fileSize,
        file,
        isAudio,
        warning
      });
    } catch (error) {
      console.error(error);
    }
  };

  const convertUrlToUploadFile = async (
    url: string,
    languageCode?: string
  ): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      const { pathname } = new URL(addHttps(url));
      const hasExtension = /\.[A-Za-z0-9]{3,4}$/.test(pathname);

      const urlInfo = hasExtension
        ? // If url points to a direct media file, get the info directly
          await getRemoteMediaFileInfo(url, pathname)
        : // If url is a YouTube or Vimeo link, get the info from the server
          await getVideoPlatformFileInfo(url);

      if (!urlInfo) {
        return;
      }

      return makeUploadFile({ ...urlInfo, languageCode });
    } catch (error) {
      throw error;
    }
  };

  const convertZoomToUploadFile = async ({
    meetingId,
    fileId,
    filename,
    fileSize,
    duration,
    isAudio
  }: {
    meetingId: number;
    fileId: string;
    filename: string;
    fileSize: number;
    duration: number;
    isAudio?: boolean;
  }): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      return makeUploadFile({
        filename,
        fileSize,
        duration,
        isAudio,
        meetingId,
        fileId
      });
    } catch (error) {
      console.error(error);
    }
  };

  const makeUploadFile = ({
    filename,
    fileSize,
    duration,
    warning,
    error,
    file,
    url,
    isAudio,
    meetingId,
    fileId,
    languageCode
  }: {
    filename: string;
    fileSize: number;
    duration: number;
    fileType?: string;
    warning?: string;
    error?: string;
    file?: File;
    url?: string;
    isAudio?: boolean;
    meetingId?: number;
    fileId?: string;
    languageCode?: string;
  }): UploadFile | UploadUrlFile | UploadZoomFile | undefined => {
    error = error || checkFileErrors({ duration, size: fileSize });

    duration = Math.ceil(duration);

    try {
      const fileSizeMB = parseFloat(((fileSize ?? 0) / 1000 ** 2).toFixed(2));
      const durationMinutes = parseFloat(((duration ?? 0) / 60).toFixed(2));
      const mediaName = formatMediaName(filename).slice(0, 129);

      const base: UploadFileBase = {
        uploadId: v4(),
        mediaName,
        filename,
        error,
        uploadingStatus: UploadingStatus.Analyzing,
        uploadProgress: 0,
        languageCode,
        mediaInfo: {
          duration,
          fileSize: fileSize || 0,
          isAudio: Boolean(isAudio),
          warning
        }
      };

      if (file) {
        return { ...base, file };
      } else if (url) {
        return { ...base, url, isUrl: true };
      } else if (meetingId && fileId) {
        return {
          ...base,
          mediaName: filename,
          meetingId: meetingId,
          fileId: fileId,
          isZoom: true
        };
      }

      trackEventWithAuth("Upload single file", {
        filename,
        fileSize,
        fileSizeMB,
        duration,
        durationMinutes,
        isAudio,
        isGoogleDrive: false,
        isUrl: Boolean(url),
        url,
        warning,
        error
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    convertUrlToUploadFile,
    convertFileToUploadFile,
    convertZoomToUploadFile,
    getMediaFileInfo,
    getRemoteMediaFileInfo,
    checkFileErrors,
    loading
  };
}
