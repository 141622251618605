import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { createAccount, getDiscoverableAccounts, joinAccount } from "@frontend/api/account.service";
import { getAccessToken } from "@frontend/api/auth.service";
import { AccountType } from "@frontend/api/billing.service";
import { handleError } from "@frontend/api/handle-error";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { DASHBOARD_PATH } from "@frontend/routes";

import { Avatar } from "@components/avatar/avatar";
import { Button, ButtonVariant } from "@components/button";
import { Input } from "@components/form-controls";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useQuery } from "@core/hooks/use-query";
import { useUser } from "@core/hooks/use-user";
import { DomainAccount } from "@core/interfaces/account";
import { User, UserInvitation } from "@core/interfaces/user";
import { RiBuilding4Line } from "@remixicon/react";

export const CreateOrJoinPage: React.FC = () => {
  const { user } = useUser();
  const { signOut } = useAuthProvider();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { queryParams } = useQuery<{ invite?: string }>();
  const [domainAccounts, setDomainAccounts] = React.useState<DomainAccount[]>([]);

  const navigate = useNavigate();

  // Redirect to the dashboard if the user has one or more accounts
  React.useEffect(() => {
    if (user?.accounts.length && !queryParams.invite) {
      navigate(DASHBOARD_PATH);
    }
  }, [user]);

  React.useEffect(() => {
    if (user) {
      (async () => {
        const domainAccounts = await getDiscoverableAccounts(user.email.split("@")[1]);
        setDomainAccounts(domainAccounts.filter((domainAccount) => domainAccount.accountId !== getAccountId()));
      })();
    }
  }, []);

  const handleClickSignOut = () => {
    trackEventWithAuth("Create or join workspace / sign out");
    signOut();
  };

  const invitations = React.useMemo(() => user?.invitations ?? [], [user]);
  const isInviteLink = React.useMemo(() => Boolean(queryParams.invite), [queryParams.invite]);

  if (isInviteLink) {
    return <InviteLinkView invitations={invitations} invite={String(queryParams.invite)} onClickSignOut={handleClickSignOut} /> // eslint-disable-line
  } else {
    return (
      <DefaultView
        invitations={invitations}
        domainAccounts={domainAccounts}
        user={user}
        onClickSignOut={handleClickSignOut}
      />
    );
  }
};

type DefaultViewProps = {
  invitations: UserInvitation[];
  domainAccounts: DomainAccount[];
  user?: User;
  onClickSignOut: () => void;
};

export const DefaultView: React.FC<DefaultViewProps> = (props) => {
  const [isCreatingWorkspace, setCreatingWorkspace] = React.useState(false);

  const title = React.useMemo(() => {
    if (props.invitations.length) {
      return "Join or create a workspace";
    }
    return "Create a workspace";
  }, [props.invitations]);

  const handleCreateWorkspace = async (workspaceName: string) => {
    setCreatingWorkspace(true);
    try {
      await createAccount({ accountName: workspaceName, accountType: AccountType.Personal });
      window.location.href = DASHBOARD_PATH;
    } catch (error) {
      setCreatingWorkspace(false);
      handleError(error);
    }
  };

  return (
    <div className="tw-flex tw-h-screen tw-items-center tw-justify-center tw-p-6">
      <section className=" tw-rounded-[12px] tw-border tw-border-neutral-200 tw-p-8 tw-shadow-[0px_6px_12px_rgba(47,_43,_67,_0.1)]">
        <h1 className="tw-mb-14 tw-text-center tw-text-[36px] tw-font-medium">{title}</h1>
        {/* Join workspace */}
        {(props.invitations.length > 0 || props.domainAccounts.length > 0) && (
          <div className="tw-mb-6 tw-w-[520px] tw-rounded-[8px] tw-border tw-border-neutral-200">
            {props.invitations.map((invitation, i) => (
              <InviteSection key={i} invitation={invitation} />
            ))}
            {props.user &&
              props.domainAccounts.map((domainAccount, i) => (
                <DomainAccountSection key={i} user={props.user!} domainAccount={domainAccount} />
              ))}
          </div>
        )}

        {/* Create workspace */}
        {props.user && (
          <WorkspaceSection
            user={props.user}
            isSubmitting={isCreatingWorkspace}
            onSubmit={handleCreateWorkspace}
            defaultOpen={!props.invitations.length}
          />
        )}

        <p className="tw-text-sm tw-text-neutral-500">You can join multiple workspaces later</p>
      </section>
      <Button variant="secondary" className="tw-absolute tw-right-6 tw-top-6" onClick={props.onClickSignOut}>
        Sign out
      </Button>
    </div>
  );
};

type InviteViewProps = {
  invite: string;
  invitations: UserInvitation[];
  onClickSignOut: () => void;
};

export const InviteLinkView: React.FC<InviteViewProps> = (props) => {
  const invitation = React.useMemo(() => {
    return props.invitations?.find((invitation) => invitation.id === props.invite);
  }, [props.invitations, props.invite]);

  const title = React.useMemo(() => {
    if (invitation) return `${invitation.accountName} Invitation`;
    return "Workspace Invitation";
  }, [invitation]);

  return (
    <div className="tw-flex tw-h-screen tw-items-center tw-justify-center tw-p-6">
      <section className="tw-rounded-[12px] tw-border tw-border-neutral-200 tw-p-8 tw-shadow-[0px_6px_12px_rgba(47,_43,_67,_0.1)]">
        <h1 className="tw-text-center tw-text-[36px] tw-font-medium">{title}</h1>
        {invitation ? (
          <div className="tw-mb-6 tw-mt-14 tw-w-[520px] tw-rounded-[8px] tw-border tw-border-neutral-200">
            <InviteSection invitation={invitation} />
          </div>
        ) : (
          <div className="tw-mt-4 tw-w-[520px] tw-text-center tw-text-destructive-600">
            This invitation is either invalid or has expired.
          </div>
        )}
      </section>
      <Button variant="secondary" className="tw-absolute tw-right-6 tw-top-6" onClick={props.onClickSignOut}>
        Sign out
      </Button>
    </div>
  );
};

type ButtonOptionProps = {
  title: string;
  description: string;
  loading?: boolean;
  buttonText: string;
  buttonVariant?: ButtonVariant;
  onClick: () => void;
};
const ButtonOption: React.FC<ButtonOptionProps> = (props) => {
  return (
    <div className="tw-flex tw-flex-1">
      <div>
        <div className="tw-max-w-[271px] tw-overflow-hidden tw-text-ellipsis">{props.title}</div>
        <div className="tw-text-sm tw-text-neutral-700">{props.description}</div>
      </div>
      <Button className="tw-ml-auto" variant={props.buttonVariant} onClick={props.onClick} loading={props.loading}>
        {props.buttonText}
      </Button>
    </div>
  );
};

type InputOptionProps = {
  title: string;
  defaultValue: string;
  hasError?: boolean;
  isLoading: boolean;
  submitButtonText: string;
  onSubmit: (value: string) => void;
};
const InputOption: React.FC<InputOptionProps> = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit(value);
  };

  return (
    <form className="tw-flex tw-flex-1 tw-items-end tw-gap-2" onSubmit={handleSubmit}>
      <fieldset className="tw-flex-1">
        <label className="tw-mb-0.5 tw-font-medium tw-text-neutral-900">{props.title}</label>
        <Input
          className="tw-w-[310px]"
          required
          value={value}
          onChange={({ target }) => setValue(target.value)}
          hasError={props.hasError}
          variant="outline"
        />
      </fieldset>
      <Button variant="secondary" type="submit" loading={props.isLoading}>
        {props.submitButtonText}
      </Button>
    </form>
  );
};

type InviteSectionProps = {
  invitation: UserInvitation;
};
const InviteSection: React.FC<InviteSectionProps> = (props) => {
  const [isJoining, setIsJoining] = React.useState(false);
  const handleClickJoin = async () => {
    setIsJoining(true);
    try {
      const { data } = await axios.put(`/invitation/${props.invitation.id}/accept`, null, {
        baseURL: `${config.apiUrl}/api/v1`,
        headers: { "x-access-token": await getAccessToken() }
      });
      window.location.href = "/open-workspace/" + data.joinedAccount.accountId + "?joined=true";
    } catch (error) {
      handleError(error);
      setIsJoining(false);
    }
  };
  return (
    <div className="tw-flex tw-gap-4  tw-p-4">
      <Avatar
        initials={getInitials(props.invitation.accountName, true, false)}
        size="48"
        rounded="rounded"
        color="#e4f2ff"
        withBorder={false}
      />
      <ButtonOption
        title={props.invitation.accountName}
        description={`${props.invitation.memberCount} member${props.invitation.memberCount === 1 ? "" : "s"}`}
        loading={isJoining}
        buttonText="Accept invite"
        onClick={handleClickJoin}
      />
    </div>
  );
};

type DomainAccountProps = {
  domainAccount: DomainAccount;
  user: User;
};
const DomainAccountSection: React.FC<DomainAccountProps> = (props) => {
  const [isJoining, setIsJoining] = React.useState(false);
  const handleClickJoin = async () => {
    setIsJoining(true);
    try {
      const data = await joinAccount(props.domainAccount.accountId, props.user.id);
      window.location.href = "/open-workspace/" + data.accountId + "?joined=true"; // refresh and redirect
    } catch (error) {
      handleError(error);
      setIsJoining(false);
    }
  };
  return (
    <div className="tw-flex tw-gap-4  tw-p-4">
      <Avatar
        initials={getInitials(props.domainAccount.accountName, true, false)}
        size="48"
        rounded="rounded"
        color="#e4f2ff"
        withBorder={false}
      />
      <ButtonOption
        title={props.domainAccount.accountName}
        description={`${props.domainAccount.memberCount} member${props.domainAccount.memberCount === 1 ? "" : "s"}`}
        loading={isJoining}
        buttonText="Join"
        buttonVariant="secondary"
        onClick={handleClickJoin}
      />
    </div>
  );
};

type WorkspaceSectionProps = {
  user: User;
  isSubmitting: boolean;
  defaultOpen: boolean;
  onSubmit: (workspaceName: string) => void;
};
const WorkspaceSection: React.FC<WorkspaceSectionProps> = (props) => {
  const [hasError, setHasError] = useState(false);
  const [isOpen, setOpen] = useState(props.defaultOpen);

  const handleSubmit = (workspaceName: string) => {
    setHasError(false);

    if (workspaceName) {
      props.onSubmit(workspaceName);
    } else {
      setHasError(true);
    }
  };

  return (
    <div className="tw-mb-2 tw-flex tw-w-[520px] tw-gap-2 tw-rounded-[8px] tw-border tw-border-neutral-200 tw-p-4">
      <div className="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-[12px] tw-bg-neutral-200">
        <RiBuilding4Line className="tw-h-8 tw-w-8" />
      </div>
      <div className="tw-flex tw-w-full tw-items-center">
        {isOpen ? (
          <InputOption
            title="Your workspace name"
            defaultValue={props.user.givenName + "'s Personal"}
            hasError={hasError}
            isLoading={props.isSubmitting}
            submitButtonText="Create"
            onSubmit={handleSubmit}
          />
        ) : (
          <ButtonOption
            title="New workspace"
            description="for you and your team"
            buttonText="Create a new workspace"
            buttonVariant="secondary"
            onClick={() => setOpen(true)}
          />
        )}
      </div>
    </div>
  );
};
