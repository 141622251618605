import React from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Custom hook to manage query parameters.
 */
export function useQuery<T = any>() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = React.useMemo(() => {
    return Object.fromEntries(searchParams.entries()) as T;
  }, [searchParams]);

  const setQueryParams = (params: Record<string, T>) => {
    const newSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && value !== null) {
        newSearchParams.set(key, String(value));
      }
    }
    setSearchParams(newSearchParams);
  };

  const updateQueryParams = (params: Record<string, T>) => {
    const updatedParams = { ...queryParams, ...params };

    Object.keys(updatedParams).forEach((key) => {
      if (updatedParams[key] === undefined || updatedParams[key] === null) {
        delete updatedParams[key];
      }
    });

    const newSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(updatedParams)) {
      newSearchParams.set(key, String(value));
    }

    setSearchParams(newSearchParams);
  };

  return {
    queryParams,
    setQueryParams,
    updateQueryParams
  };
}
