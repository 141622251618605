import React, { CSSProperties } from "react";
import classNames from "classnames";

import styles from "./toggle.module.scss";

interface ToggleProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  scale?: "sm" | "md" | "lg";
  label?: string;
  leftChildren?: React.ReactNode;
  leftLabel?: string;
  containerClassName?: string;
  children?: React.ReactNode;
  containerStyle?: CSSProperties;
}

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, leftChildren, leftLabel, containerClassName, containerStyle, ...props }, ref) => {
    const { checked, defaultChecked, label, scale = "md", disabled } = props;

    const [toggled, setToggled] = React.useState(defaultChecked || checked);

    React.useEffect(() => {
      setToggled(checked);
    }, [checked]);

    return (
      <label
        htmlFor={props.id}
        className={classNames(
          // leave this class here so we can remotely identify when the toggle is being clicked.
          "toggle-input",
          "tw-m-0 tw-flex tw-flex-row tw-items-center tw-font-medium",
          {
            "tw-cursor-pointer tw-text-neutral-900": !disabled,
            "tw-cursor-not-allowed tw-text-neutral-300": disabled,
            "tw-gap-2": scale !== "lg",
            "tw-gap-3": scale === "lg"
          },
          containerClassName
        )}
        style={containerStyle}
      >
        {Boolean(leftChildren || leftLabel) && (
          <>
            {leftChildren}
            <p
              className={classNames({
                "tw-text-sm": scale !== "lg",
                "tw-text-md": scale === "lg"
              })}
            >
              {leftLabel}
            </p>
          </>
        )}

        {/*
          Fix: This component caused an overflow issue on the style panel's scroll container due to
          the checkbox using `tw-sr-only`, which sets `position: absolute; width: 1px;`. This combo
          caused the component to break out and disrupt the scroll container. Two ways this can be
          fixed is by setting the width to `0px` or wrapping the class in position relative. The
          latter option was chosen, assuming that Tailwind had a good reason for setting
          `width: 1px`. ~ francis
        */}

        <div className="tw-relative tw-flex tw-items-center">
          <div
            className={classNames(styles["toggle"], {
              [styles["sm"]]: scale === "sm",
              [styles["lg"]]: scale === "lg",
              [styles["checked"]]: toggled,
              "!tw-bg-neutral-200 after:!tw-bg-neutral-100": disabled
            })}
          />
          <input
            type="checkbox"
            className="tw-sr-only"
            {...props}
            ref={ref}
            onChange={(e) => {
              if (typeof props.checked !== "boolean") {
                setToggled(e.target.checked);
              }
              props.onChange?.(e);
            }}
            checked={toggled}
          />
        </div>
        {Boolean(children || label) && (
          <>
            {children}
            {label && (
              <p
                className={classNames({
                  "tw-text-sm": scale !== "lg",
                  "tw-text-md": scale === "lg"
                })}
              >
                {label}
              </p>
            )}
          </>
        )}
      </label>
    );
  }
);
