import React from "react";
import classNames from "classnames";

// add as many themes as you want. remember to name it `{theme}-{weight}`.
export type CounterTheme = "aux-2-100";
export type CounterVariant = "rectangle" | "circle";
export type CounterSize = "12" | "14" | "16" | "18";

interface CounterProps {
  count: number;
  theme?: CounterTheme;
  variant?: CounterVariant;
  size?: CounterSize;
  abbreviateLongNumbers?: boolean;
}
export const Counter: React.FC<CounterProps> = ({
  count,
  theme = "aux-2-100",
  variant = "rectangle",
  size = "14",
  abbreviateLongNumbers = true
}) => {
  const getCount = React.useMemo(() => {
    if (variant === "circle" && abbreviateLongNumbers && count > 9) {
      return "+9";
    } else if (variant === "rectangle" && abbreviateLongNumbers && count > 99) {
      return "+99";
    }
    return count;
  }, [count]);

  const classes = {
    "12": {
      circle: "tw-w-[12px] tw-h-[12px] tw-rounded-[50%] !tw-p-0",
      rectangle: "tw-h-[12px] tw-px-1 tw-rounded-[2.5px]"
    },
    "14": {
      circle: "tw-w-[14px] tw-h-[14px] tw-rounded-[50%] !tw-p-0",
      rectangle: "tw-h-[14px] tw-py-[1px] tw-px-[5px] tw-rounded-3"
    },
    "16": {
      circle: "tw-w-[16px] tw-h-[16px] tw-rounded-[50%] !tw-p-0",
      rectangle: "tw-h-[16px] tw-py-[2px] tw-px-[6px] tw-rounded-4"
    },
    "18": {
      circle: "tw-w-[18px] tw-h-[18px] tw-rounded-[50%] !tw-p-0",
      rectangle: "tw-h-[18px] tw-py-[3px] tw-px-[7px] tw-rounded-4"
    }
  }[size][variant];

  const className = classNames(
    "tw-text-[10px] tw-leading-[1.2] tw-inline-flex tw-items-center tw-justify-center tw-font-semibold",
    classes,
    { "tw-bg-aux-2-100 tw-text-aux-2-700": theme === "aux-2-100" }
  );
  return <div className={className}>{getCount}</div>;
};
