import React from "react";
import classNames from "classnames";

export const FolderNotFound: React.FC = () => {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-[654px] tw-cursor-default tw-items-center tw-justify-center tw-space-y-5 tw-rounded-lg tw-border-[3px] tw-border-dashed tw-border-transparent tw-p-5"
      )}
    >
      <div className="tw-mx-auto tw-flex tw-max-w-md tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-h-[98px] tw-w-[98px] tw-items-center tw-justify-center tw-rounded-full tw-bg-destructive-50">
          <img src="/icons/folder_404.png" alt="404 folder" className="tw-h-12 tw-w-12" />
        </div>

        <h3 className="tw-text-lg tw-font-semibold tw-text-neutral-900">Folder not found</h3>

        <div className="tw-mb-6 tw-text-center tw-text-sm tw-text-neutral-600">
          Either this folder doesn’t exist or you don’t have
          <br />
          permission to access it.
        </div>
      </div>
    </div>
  );
};
