import React from "react";

import {
  autoUpdate,
  offset,
  ReferenceType,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles
} from "@floating-ui/react";

interface SidecarContext {
  showSidecar: boolean;
  floatingStyles: React.CSSProperties;
  context: any; // eslint-disable-line
  setShowSidecar: (showSidecar: boolean) => void;
  setReference: (node: ReferenceType | null) => void;
  setFloating: (node: HTMLElement | null) => void;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
}

const SidecarContext = React.createContext<SidecarContext | null>(null);

type SidecarProviderProps = {
  children: React.ReactNode;
};

export const SidecarProvider: React.FC<SidecarProviderProps> = ({ children }) => {
  const [showSidecar, setShowSidecar] = React.useState<boolean>(false);

  const { refs, floatingStyles, context } = useFloating({
    open: showSidecar,
    onOpenChange: setShowSidecar,
    placement: "right",
    middleware: [offset({ mainAxis: 34, crossAxis: -4 }), shift({ crossAxis: true, padding: { top: 68, bottom: 24 } })],
    whileElementsMounted: autoUpdate
  });

  const { styles: transitionStyles } = useTransitionStyles(context);

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  return (
    <SidecarContext.Provider
      value={{
        showSidecar,
        floatingStyles: { ...transitionStyles, ...floatingStyles },
        context: context,
        setShowSidecar,
        setReference: refs.setReference,
        setFloating: refs.setFloating,
        getFloatingProps: getFloatingProps
      }}
    >
      {children}
    </SidecarContext.Provider>
  );
};

export const useSidecar = (): SidecarContext => {
  const context = React.useContext(SidecarContext);

  if (context === null) {
    throw new Error("useSidecar must be used within a SidecarProvider");
  }

  return context;
};
