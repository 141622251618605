import React from "react";
import classNames from "classnames";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

interface AudioCanvasProps {
  isUploading: boolean;
  url?: string;
  isCrop?: boolean;
  onChange?: (file: File) => void;
}
export const AudioCanvas: React.FC<AudioCanvasProps> = ({ isUploading, url, isCrop, onChange }) => {
  if (isUploading) {
    return (
      <div className="tw-flex tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-p-8">
        <h4 className="tw-text-center tw-text-h4 tw-text-neutral-400">Uploading your image...</h4>
        <Icon path={mdiLoading} spin size="1.8rem" className="tw-text-text-disabled" />
      </div>
    );
  }

  if (!url) {
    const fileRef = React.useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files ?? [];

      if (files.length > 0) {
        onChange && onChange(files[0]);
      }
    };

    return (
      <div
        onClick={() => fileRef.current?.click()}
        className="tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-400 tw-p-8 tw-text-center"
      >
        <input
          type="file"
          accept="image/png,image/jpg,image/jpeg"
          style={{ display: "none" }}
          ref={fileRef}
          onChange={handleChange}
        />
        <h4 className="tw-text-h4 tw-text-neutral-400">No image</h4>
        <h6 className="tw-text-h6 tw-text-neutral-400">Click here to upload image and convert audio to video file</h6>
      </div>
    );
  }

  return (
    <img
      src={url}
      className={classNames("tw-h-full tw-w-full", {
        "tw-object-cover": isCrop,
        "tw-object-contain": !isCrop
      })}
    />
  );
};
