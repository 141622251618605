import React from "react";

import { Folder } from "@core/state/dashboard/dashboard.store";

interface DashboardFolderIconProps {
  folder: Folder;
  className?: string;
}

export const DashboardFolderIcon: React.FC<DashboardFolderIconProps> = ({ folder, className }) => {
  let imgSrc = "/icons/folder_default.png";

  if (folder.groupId && folder.isPublic) {
    imgSrc = "/icons/folder_scoped_teamspace.png";
  } else if (!folder.groupId && folder.isPublic && folder.depth === 1) {
    imgSrc = "/icons/folder_global_teamspace.png";
  }

  return <img src={imgSrc} className={className} />;
};
