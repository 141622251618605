import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { isEqual } from "lodash";
import numeral from "numeral";
import { Controller, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import countries from "typed-countries";

import {
  AccountType,
  billingSetTaxNumber,
  Coupon,
  createSubscription,
  dontCancelSubscription,
  FlatProduct,
  getCoupon,
  getIntent,
  getInvoiceCost,
  GetInvoiceCostBody,
  getProducts,
  getSubscriptionCost,
  GetSubscriptionCostBody,
  Product,
  purchasePersonal,
  setBillingDetails,
  updateSubscription
} from "@frontend/api/billing.service";
import { createZendeskTicket } from "@frontend/api/zendesk.service";
import { EN } from "@frontend/assets/i18n/en";
import { Alert } from "@frontend/components/alert/alert";
import { Divider } from "@frontend/components/divider/divider";
import { notificationError, notificationSuccess } from "@frontend/components/notification";
import { CancelModal } from "@frontend/components/plans/cancel-modal";
import { ProgressBar } from "@frontend/components/progress-bar/progress-bar";
import config from "@frontend/config";

import { Button, IconButton } from "@components/button";
import { Input, Textarea } from "@components/form-controls";
import {
  ArrowLeftIcon,
  CheckmarkFullIcon,
  InformationCircleIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon
} from "@components/icons";
import { Tag } from "@components/inline-tags-input";
import { Modal } from "@components/modal";
import { NewBadge } from "@components/new-badge/new-badge";
import { SegmentedControl } from "@components/segmented-control";
import { Select } from "@components/select";

import { useAccountMembers } from "@core/hooks/use-account-members";
import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useDebounce } from "@core/hooks/use-debounce";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { useUser } from "@core/hooks/use-user";
import {
  AdditionalItem,
  CurrencyCode,
  GetSubscriptionCostItem,
  GetSubscriptionCostResult,
  getTaxIdTypeSelectOptions,
  PaymentMethod,
  SublyPlan,
  Subscription,
  TaxIdTypeSelectOption
} from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { getCardImage } from "@core/utils/card-images";
import { formatPrice, pluralize } from "@core/utils/strings";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { RiPriceTag3Line } from "@remixicon/react";
import * as Sentry from "@sentry/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

import { LegacyPlanCard } from "../settings/legacy-plan-card/legacy-plan-card";
import { PlanCard } from "../settings/plan-card/plan-card";
import { ToolTip } from "../tooltip/tooltip";

interface BaseUnits {
  minutes: number;
  users: number;
  storage: number;
  hasCustomisedMinutes: boolean;
  hasCustomisedUsers: boolean;
  hasCustomisedStorage: boolean;
}

interface SubscriptionModalProps {
  hideModal: () => void;
}
export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ hideModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const closeModal = () => {
    if (searchParams.has("checkout")) {
      searchParams.delete("checkout");
      setSearchParams(searchParams);
    }
    hideModal();
  };

  return (
    <Modal
      onDismiss={closeModal}
      showCloseButton
      className="!tw-top-0 tw-my-[50px] !tw-max-w-4xl !tw-rounded-lg !tw-p-0"
      size="896"
      modalClassName="!tw-overflow-y-auto"
      backgroundClassName="tw-min-h-full !tw-fixed"
      disableBackgroundDismiss
    >
      <SubscriptionModalContainer closeModal={closeModal} />
    </Modal>
  );
};

interface BillingInfo {
  accountType: AccountType;
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  taxIdType?: string;
  taxIdNumber?: string;
  purchaseOrderNumber?: string;
}

interface SubscriptionModalContainerProps {
  closeModal: () => void;
}
export const SubscriptionModalContainer: React.FC<SubscriptionModalContainerProps> = ({ closeModal }) => {
  const { user } = useUser();
  const { billing, paymentMethods = [], subscription } = useAccounts();
  const { primaryItem } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [productId, setProductId] = React.useState<string>(
    primaryItem?.version === "2024-01-02" ? primaryItem.productId : ""
  );
  const [units, setUnits] = React.useState<BaseUnits>({
    minutes: 0,
    users: 0,
    storage: 0,
    hasCustomisedMinutes: false,
    hasCustomisedUsers: false,
    hasCustomisedStorage: false
  });
  const [step, setStep] = React.useState(0);
  const [productsLoaded, setProductsLoaded] = React.useState(false);
  const [productsIsLoading, setProductsIsLoading] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [billingInfo, setBillingInfo] = React.useState<BillingInfo>({
    accountType: billing?.details?.accountType || AccountType.Personal,
    name: billing?.details?.name || "",
    address: billing?.details?.address?.line1 || "",
    city: billing?.details?.address?.city || "",
    state: billing?.details?.address?.state || "",
    postalCode: billing?.details?.address?.postalCode || "",
    country: billing?.details?.address?.country || "",
    purchaseOrderNumber: billing?.details?.invoiceCustomFields?.find((f) => f.name === "Purchase Order #")?.value || ""
  });
  const [taxNumber, setTaxNumber] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);

  const handleBillingInfoChange = ({ billingInfo, isValid }: { billingInfo: BillingInfo; isValid: boolean }) => {
    setBillingInfo(billingInfo);
    setIsValid(isValid);
  };

  const [purchaseLoading, setPurchaseLoading] = React.useState(false);
  const [card, setCard] = React.useState<{
    update: boolean;
    complete: boolean;
    cardholderName?: string;
  }>();
  const [cardLoading, setCardLoading] = React.useState(false);
  const [summaryError, setSummaryError] = React.useState<string>();

  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    const loadProducts = async () => {
      if (productsLoaded || productsIsLoading) {
        return;
      }

      setProductsIsLoading(true);
      trackEventWithAuth("Open check out modal");

      try {
        const data = await getProducts();
        setProducts(data);
      } catch (error) {
        console.error(error);
      } finally {
        setProductsIsLoading(false);
        setProductsLoaded(true);
      }
    };

    loadProducts();
  }, []);

  const shouldUpdateAddress = React.useMemo(() => {
    const billingDetails = billing?.details;

    if (billingInfo.accountType === AccountType.Personal) {
      return !isEqual(
        {
          accountType: billingDetails?.accountType,
          name: billingDetails?.name,
          address: billingDetails?.address?.line1,
          city: billingDetails?.address?.city,
          state: billingDetails?.address?.state,
          postalCode: billingDetails?.address?.postalCode,
          country: billingDetails?.address?.country
        },
        {
          accountType: billingInfo.accountType,
          name: billingInfo.name,
          address: billingInfo.address,
          city: billingInfo.city,
          state: billingInfo.state,
          postalCode: billingInfo.postalCode,
          country: billingInfo.country
        }
      );
    }

    return !isEqual(
      {
        accountType: billingDetails?.accountType,
        name: billingDetails?.name,
        address: billingDetails?.address?.line1,
        city: billingDetails?.address?.city,
        state: billingDetails?.address?.state,
        postalCode: billingDetails?.address?.postalCode,
        country: billingDetails?.address?.country,
        purchaseOrderNumber: billingDetails?.invoiceCustomFields?.find((f) => f.name === "Purchase Order #")?.value
      },
      {
        accountType: billingInfo.accountType,
        name: billingInfo.name,
        address: billingInfo.address,
        city: billingInfo.city,
        state: billingInfo.state,
        postalCode: billingInfo.postalCode,
        country: billingInfo.country,
        purchaseOrderNumber: billingInfo.purchaseOrderNumber
      }
    );
  }, [billingInfo, billing?.details, billing?.loaded]);

  const isValidBillingDetails = React.useMemo(() => {
    return (
      isValid &&
      Boolean((card?.update && card?.complete && card?.cardholderName) || Boolean(!card?.update && paymentMethods[0]))
    );
  }, [isValid, card?.update, card?.complete, card?.cardholderName, paymentMethods]);

  const getElementCard = (): StripeCardElement | undefined => {
    if (!elements) {
      setSummaryError("There is an issue please contact support@getsubly.com");
      return;
    }

    const canComplete = Boolean(card?.cardholderName) && Boolean(card?.complete);
    if (!canComplete) {
      setSummaryError("Some information is missing.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setSummaryError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    return cardElement;
  };

  const handleNewCard = async (): Promise<boolean> => {
    if (!stripe) {
      setSummaryError("There is an issue, please contact support@getsubly.com.");
      return false;
    }

    const cardElement = getElementCard();

    if (!cardElement) {
      setSummaryError("There is an issue, please contact support@getsubly.com.");
      return false;
    }

    setSummaryError("");
    setCardLoading(true);

    try {
      const cardToken = await stripe.createToken(cardElement);

      if (cardToken.token?.card?.funding === "prepaid") {
        setSummaryError("We don't accept prepaid cards.");
        return false;
      }

      const {
        data: { intent }
      } = await getIntent();

      const { error, setupIntent } = await stripe.confirmCardSetup(intent, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: card?.cardholderName
          }
        }
      });

      if (error) {
        setSummaryError(error.message);
        return false;
      }

      if (!setupIntent || !setupIntent.payment_method) {
        setSummaryError("There was an unknown error");
        return false;
      }
    } catch (error) {
      setSummaryError("There was an error. Please try again or contact support.");
      Sentry.captureException(error);
      return false;
    } finally {
      setCardLoading(false);
    }

    return true;
  };

  const handleClick = async ({
    items,
    oneOff,
    taxCountry,
    coupon,
    promoId
  }: {
    items: {
      price: string;
      quantity: number;
    }[];
    oneOff: boolean;
    taxCountry?: string;
    coupon?: Coupon;
    promoId?: string;
  }) => {
    if (step === 0) {
      trackEventWithAuth("Check out / Continue to payment");
      setStep(1);
      return;
    }

    if (!isValid) {
      setSummaryError("Please fill in all required fields");
      return;
    }

    trackEventWithAuth("Check out / Purchase");
    if (units.hasCustomisedMinutes) {
      trackEventWithAuth("Check out / Select minutes", {
        value: units.minutes
      });
    }
    if (units.hasCustomisedUsers) {
      trackEventWithAuth("Check out / Select users", { value: units.users });
    }
    if (units.hasCustomisedStorage) {
      trackEventWithAuth("Check out / Select storage", {
        value: units.storage
      });
    }

    if (!stripe) {
      setSummaryError("There is an issue, please contact support");
      return;
    }

    setPurchaseLoading(true);
    try {
      if (card?.update) {
        const addCardSuccess = await handleNewCard();
        if (!addCardSuccess) {
          // setCardError("Unable to add card please try again.");
          return;
        }
      }
      if (shouldUpdateAddress) {
        const businessProps =
          billingInfo.accountType === AccountType.Business
            ? {
                purchaseOrderNumber: billingInfo.purchaseOrderNumber
              }
            : {};
        await setBillingDetails(
          {
            accountType: billingInfo.accountType,
            name: billingInfo.name,
            line1: billingInfo.address,
            city: billingInfo.city,
            postalCode: billingInfo.postalCode,
            state: billingInfo.state,
            country: billingInfo.country,
            ...businessProps
          },
          { force: false }
        );
      }

      let response;
      if (oneOff) {
        response = await purchasePersonal({
          items,
          taxCountry,
          coupon: coupon?.id ? coupon.id : undefined
        });
      } else if (subscription) {
        response = await updateSubscription(subscription.id, {
          items,
          promoId
        });
      } else {
        response = await createSubscription({
          items,
          promoId
        });
      }

      if (response?.status === "requires_action" && response.nextAction) {
        const { error } = await stripe.confirmCardPayment(response.nextAction.clientSecret, {
          payment_method: response.nextAction.payment
        });

        if (error?.message) {
          setSummaryError(error.message);
          return;
        }
      } else if (response?.status === "fail") {
        setSummaryError(
          `${
            response?.error?.reason || "There was an issue purchasing the plan, please try again or contact support."
          } - (${response?.error?.code})`
        );
        return;
      }

      notificationSuccess("You have successfully upgraded your plan");
      closeModal();
    } catch (err) {
      if (err?.response?.data?.error?.reason) {
        notificationError(err?.response?.data?.error?.reason);
        setSummaryError(err?.response?.data?.error?.reason);
      } else {
        notificationError(err?.message);
        setSummaryError(err?.message);
      }
    } finally {
      setPurchaseLoading(false);
    }
  };

  const handleContactUs = async (params: { description: string; amount: string }) => {
    setPurchaseLoading(true);
    try {
      const subject = config.isProduction
        ? `[Sales Enterprise request] for ${user?.email}`
        : `[DEV - Sales Enterprise request] for ${user?.email}`;

      await createZendeskTicket({
        subject,
        body: [`Description: ${params.description}`, `Amount: ${params.amount}`].join("\n"),
        requester: { name: user?.name, email: user?.email }
      });

      notificationSuccess("You have successfully contacted sales, we will get back to you shortly.");
      closeModal();
    } catch (err) {
      notificationError(err?.message);
    } finally {
      setPurchaseLoading(false);
    }
  };

  const productChange = (productId: string) => {
    const product = products.find((p) => p.id === productId);
    if (!product) {
      return;
    }
    trackEventWithAuth(`Checkout - ${product.name} clicked`, { product });
    setProductId(productId);
  };

  const unitsChange = (params: Partial<BaseUnits>) => {
    setUnits((u) => ({ ...u, ...params }));
  };

  return (
    <div className="tw-flex tw-flex-row">
      <SubscriptionPlans
        products={products}
        productsLoading={productsIsLoading}
        className={classNames("tw-px-4 tw-py-6", {
          "tw-hidden": step === 1
        })}
        selectedId={productId}
        units={units}
        onProductChange={productChange}
        onUnitsChange={unitsChange}
      />
      <SubscriptionBilling
        className={classNames("tw-px-4 tw-py-6", {
          "tw-hidden": step === 0
        })}
        onBack={() => {
          setSummaryError("");
          setStep(0);
        }}
        billingInfo={billingInfo}
        taxInfo={billing?.details?.taxId}
        onBillingInfoChange={handleBillingInfoChange}
        onTaxNumberChange={(n) => setTaxNumber(n)}
        paymentMethod={paymentMethods[0]}
        onCardChange={(v) => setCard(v)}
        cardLoading={cardLoading}
      />
      <SubscriptionSummary
        className="tw-w-[400px] tw-rounded-r-xl tw-bg-neutral-25 tw-px-4 tw-py-6"
        productsLoading={productsIsLoading}
        product={products.find((p) => p.id === productId)}
        taxCountry={billingInfo.country}
        taxNumber={taxNumber}
        subscription={subscription}
        units={units}
        purchaseLoading={purchaseLoading}
        step={step}
        isValidBillingDetails={isValidBillingDetails}
        error={summaryError}
        onClick={handleClick}
        onContactUs={handleContactUs}
      />
    </div>
  );
};

interface SubscriptionPlansProps {
  className?: string;
  products: Product[];
  productsLoading: boolean;
  selectedId?: string;
  units: BaseUnits;
  onProductChange: (productId: string) => void;
  onUnitsChange: (units: Partial<BaseUnits>) => void;
}
export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  className,
  products,
  productsLoading,
  selectedId,
  units,
  onProductChange,
  onUnitsChange
}) => {
  const selectedProduct = products.find((p) => p.id === selectedId);
  const otherProducts = products.filter((p) => p.id !== selectedId);

  return (
    <div className={classNames("tw-flex tw-flex-grow tw-flex-col tw-gap-4", className)}>
      <LegacyPlanCard hideActions />
      <PlanCard hideActions />

      {productsLoading && (
        <div className="tw-flex tw-flex-col tw-gap-2">
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </div>
      )}

      {!productsLoading && (
        <>
          {selectedProduct && (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-text-lg tw-font-semibold">Your selection</div>
              <SelectedProductItem product={selectedProduct} units={units} onChange={onUnitsChange} />
            </div>
          )}

          <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-text-lg tw-font-semibold">{selectedProduct ? "Other plans" : "Select a plan"}</div>

            {otherProducts.map((product, i) => (
              <ProductItem key={i} product={product} onClick={() => onProductChange(product.id)} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

interface SelectedProductItemProps {
  className?: string;
  product: Product;
  units: BaseUnits;
  onChange: (details: Partial<BaseUnits>) => void;
}
export const SelectedProductItem = ({ product, units, onChange }: SelectedProductItemProps) => {
  const { subscription } = useAccounts();
  const { primaryItem } = usePlan();

  const minutesProduct = product.additional.find((p) => p.metadata.type === "extra_minutes");
  const usersProduct = product.additional.find((p) => p.metadata.type === "named_users");
  const storageProduct = product.additional.find((p) => p.metadata.type === "extra_storage");

  const MINUTES_MIN = 60;

  const mins = React.useMemo(() => {
    const baseMinutes = (Number(product.metadata.seconds) || 0) / 60;
    const baseUsers = Number(product.metadata.users) || 1;
    const baseStorage = (Number(product.metadata.bytes) || 0) / 1024 / 1024 / 1024;

    // Set base values from existing plan
    let existingMinutes = 0,
      existingUsers = 0,
      existingStorage = 0;
    if (primaryItem?.productId === product.id) {
      existingMinutes = subscription?.items.find((i) => i.product === AdditionalItem.Minutes)?.units || 0;
      existingUsers = subscription?.items.find((i) => i.product === AdditionalItem.Seats)?.units || 0;
      existingStorage = subscription?.items.find((i) => i.product === AdditionalItem.Storage)?.units || 0;
    }

    const metaMinutes = Number(minutesProduct?.metadata.min || 0);
    const metaUsers = Number(usersProduct?.metadata.min || 0);
    const metaStorage = Number(storageProduct?.metadata.min || 0);

    const storagePerNewUser = (Number(usersProduct?.metadata.bytes) || 0) / 1024 / 1024 / 1024;
    const userStorage = storagePerNewUser * ((Number(units?.users) || 1) - baseUsers);

    return {
      minutes: baseMinutes + metaMinutes + existingMinutes,
      users: baseUsers + metaUsers + existingUsers,
      storage: baseStorage + metaStorage + userStorage + existingStorage
    };
  }, [product, minutesProduct, usersProduct, storageProduct, units?.users]);

  const priceText = React.useMemo(() => {
    const price = product.prices.find((p) => p.default) || product.prices[0];
    if (!price) {
      return "---";
    }

    const currency = price.currency.toUpperCase() as CurrencyCode;
    if (price.type === "recurring" && price.amount > 0) {
      const isYearly = price.interval === "year";
      const priceAmount = (price.amount || 0) / (isYearly ? 12 : 1);

      return formatPrice(priceAmount, currency as CurrencyCode, "0");
    }

    if (price.amount === 0) {
      const minutesProduct = product.additional.find((p) => p.metadata.type === "extra_minutes");
      const minutesPrice = minutesProduct?.prices.find((p) => p.default) || minutesProduct?.prices[0];
      if (minutesPrice) {
        return formatPrice(minutesPrice.amount, currency as CurrencyCode, "0.00");
      }
    }

    return formatPrice(price.amount, currency as CurrencyCode, "0.00");
  }, [product]);

  return (
    <div className="tw-flex tw-flex-col tw-rounded-md tw-border-2 tw-border-primary-500">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-2">
        <div className="tw-flex tw-flex-grow tw-flex-col">
          <div className="tw-flex tw-flex-row tw-gap-1">
            <div className="tw-text-lg tw-font-semibold" style={{ color: product.metadata?.colour || "#000000" }}>
              {product.name}
            </div>
            {product.metadata?.badge && <NewBadge variant="success-soft" label={product.metadata.badge} size="28" />}
          </div>
          <p className="tw-text-sm tw-font-medium tw-text-neutral-600">{product.metadata?.subtitle}</p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <h6 className="tw-text-h6 tw-font-semibold">{priceText}</h6>
          <div className="tw-text-xs tw-font-normal tw-text-neutral-600">
            {product.metadata?.priceDescription}
            <br /> {product.metadata?.pricePeriodDescription}
          </div>
        </div>
      </div>
      <Divider className="!tw-my-0" />
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-2">
        <div className="tw-text-xs tw-font-medium tw-text-neutral-700">Included</div>
        <div className="tw-flex tw-flex-row tw-gap-6">
          {product?.metadata?.seconds && (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
              <div className="tw-text-xs tw-font-normal">{(product?.metadata?.seconds || 0) / 60} minutes</div>
            </div>
          )}
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
            <div className="tw-text-xs tw-font-normal">
              {product.metadata?.users || 1} {pluralize(product.metadata?.users || 0, "user")}
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
            <div className="tw-text-xs tw-font-normal">{(product.metadata?.bytes || 0) / 1024 / 1024 / 1024} GB</div>
          </div>
        </div>
      </div>
      <Divider className="!tw-my-0" />
      <div className="tw-flex tw-flex-col tw-px-4 tw-py-2">
        <div className="tw-text-sm tw-font-semibold">How much do you need?</div>
        <div className="tw-flex tw-flex-col tw-divide-y tw-divide-neutral-100">
          <AdditionalInput
            title="Minutes"
            className="tw-py-0.5"
            product={minutesProduct}
            defaultValue={units.minutes}
            min={mins.minutes}
            onChange={(v) => {
              const hasCustomisedMinutes = v !== mins.minutes;
              onChange?.({ minutes: v, hasCustomisedMinutes });
            }}
            error={
              Boolean(units.minutes !== mins.minutes && units.minutes - mins.minutes < MINUTES_MIN)
                ? `Requires a minimum of ${MINUTES_MIN} minutes`
                : undefined
            }
          />
          <AdditionalInput
            title="Users"
            className="tw-py-0.5"
            product={usersProduct}
            defaultValue={units.users}
            min={mins.users}
            onChange={(v) => {
              const hasCustomisedUsers = v !== mins.users;
              onChange?.({ users: v, hasCustomisedUsers });
            }}
          />

          <AdditionalInput
            title="Storage"
            className="tw-py-0.5"
            product={storageProduct}
            defaultValue={units.storage}
            min={mins.storage}
            onChange={(v) => {
              const hasCustomisedStorage = v !== mins.storage;
              onChange?.({ storage: v, hasCustomisedStorage });
            }}
            after={storageProduct?.metadata?.unit || "GB"}
          />
        </div>
      </div>
    </div>
  );
};

interface AdditionalInputProps {
  className?: string;
  product?: FlatProduct;
  title: string;
  defaultValue?: number;
  min: number;
  after?: string;
  error?: string;
  onChange: (value: number) => void;
}
export const AdditionalInput: React.FC<AdditionalInputProps> = ({
  className,
  product,
  title,
  defaultValue,
  min,
  after,
  error,
  onChange
}) => {
  const [value, setValue] = React.useState<number | undefined>(defaultValue);
  const step = Number(product?.metadata?.inc || 1);

  const applyStep = (s: number) => {
    let newValue = Number(value || min) + s;
    newValue = newValue < min ? min : newValue;
    onChange(newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    const nextValue = Math.max(value || 0, min);
    setValue(nextValue);
    onChange(nextValue);
  }, [min]);

  if (!product) {
    return null;
  }

  return (
    <div className={classNames("tw-flex tw-flex-col", className)}>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
          <div className="tw-text-sm tw-font-normal">{title}</div>
          <p className="tw-text-xs tw-font-light">(min {min})</p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <IconButton
            variant="secondary"
            size="16"
            icon={<MinusIcon />}
            disabled={value === min}
            onClick={() => applyStep(0 - step)}
          />
          <Input
            type="number"
            sizeH="32"
            className="!tw-w-24"
            value={value ?? ""}
            after={after}
            onChange={({ target: { value } }) => {
              const nextValue = Number(value) || undefined;
              setValue(nextValue);
              if (typeof nextValue === "number") {
                onChange(nextValue);
              }
            }}
            onBlur={() => {
              if (Number(value || 0) < min) {
                setValue(min);
                onChange(min);
              }
            }}
            step={step}
          />
          <IconButton variant="secondary" size="16" icon={<PlusIcon />} onClick={() => applyStep(step)} />
        </div>
      </div>
      {error && <p className="tw-text-xs tw-font-light tw-text-destructive-500">{error}</p>}
    </div>
  );
};

interface ProductItemProps {
  product: Product;
  onClick: () => void;
}
export const ProductItem: React.FC<ProductItemProps> = ({ product, onClick }) => {
  const priceText = React.useMemo(() => {
    const price = product.prices.find((p) => p.default) || product.prices[0];
    if (!price) {
      return "---";
    }

    const currency = price.currency.toUpperCase() as CurrencyCode;
    if (price.type === "recurring" && price.amount > 0) {
      const isYearly = price.interval === "year";
      const priceAmount = (price.amount || 0) / (isYearly ? 12 : 1);

      return formatPrice(priceAmount, currency as CurrencyCode, "0");
    }

    if (price.amount === 0) {
      const minutesProduct = product.additional.find((p) => p.metadata.type === "extra_minutes");
      const minutesPrice = minutesProduct?.prices.find((p) => p.default) || minutesProduct?.prices[0];
      if (minutesPrice) {
        return formatPrice(minutesPrice.amount, currency as CurrencyCode, "0.00");
      }
    }

    return formatPrice(price.amount, currency as CurrencyCode, "0.00");
  }, [product]);

  return (
    <div
      className="tw-flex tw-cursor-pointer tw-flex-col tw-rounded-md tw-border-2 tw-border-neutral-200 hover:tw-border-primary-300"
      onClick={onClick}
    >
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-2">
        <div className="tw-flex tw-flex-grow tw-flex-col">
          <div className="tw-flex tw-flex-row tw-gap-1">
            <div className="tw-text-lg tw-font-semibold" style={{ color: product.metadata?.colour || "#000000" }}>
              {product.name}
            </div>
            {product.metadata?.badge && <NewBadge variant="success-soft" label={product.metadata.badge} size="28" />}
          </div>
          <p className="tw-text-sm tw-font-medium tw-text-neutral-600">{product.metadata?.subtitle}</p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <h6 className="tw-text-h6 tw-font-semibold">{priceText}</h6>
          <div className="tw-text-xs tw-font-normal tw-text-neutral-600">
            {product.metadata?.priceDescription}
            <br />
            {product.metadata?.pricePeriodDescription}
          </div>
        </div>
      </div>
      <Divider className="!tw-my-0" />
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-2">
        <div className="tw-text-xs tw-font-medium tw-text-neutral-700">Included</div>
        <div className="tw-flex tw-flex-row tw-gap-6">
          {product?.metadata?.seconds && (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
              <div className="tw-text-xs tw-font-normal">{(product?.metadata?.seconds || 0) / 60} minutes</div>
            </div>
          )}
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
            <div className="tw-text-xs tw-font-normal">
              {product?.metadata?.users || 1} {pluralize(product?.metadata?.users || 1, "user")}
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
            <div className="tw-text-xs tw-font-normal">{(product?.metadata?.bytes || 0) / 1024 / 1024 / 1024} GB</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SubscriptionBillingProps {
  className?: string;
  billingInfo: BillingInfo;
  onBillingInfoChange: ({ billingInfo, isValid }: { billingInfo: BillingInfo; isValid: boolean }) => void;
  taxInfo?: { type?: string; value?: string };
  onBack?: () => void;
  onCardChange: ({
    update,
    complete,
    cardholderName
  }: {
    update: boolean;
    complete: boolean;
    cardholderName?: string;
  }) => void;
  onTaxNumberChange: (taxNumber: string) => void;
  paymentMethod?: PaymentMethod;
  cardLoading: boolean;
  cardError?: string;
}
export const SubscriptionBilling: React.FC<SubscriptionBillingProps> = ({
  className,
  billingInfo,
  onBillingInfoChange,
  taxInfo,
  onBack,
  onCardChange,
  onTaxNumberChange,
  paymentMethod,
  cardLoading,
  cardError
}) => {
  const [isBusiness, setBusiness] = React.useState(billingInfo.accountType === AccountType.Business);

  const {
    register,
    formState: { errors, isValid },
    control,
    watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: billingInfo.name,
      address: billingInfo.address,
      city: billingInfo.city,
      state: billingInfo.state,
      postalCode: billingInfo.postalCode,
      country: billingInfo.country,
      purchaseOrderNumber: billingInfo.purchaseOrderNumber
    }
  });

  const formValues = watch();

  const countryOptions = React.useMemo(() => {
    return countries.map((c) => ({
      value: c.iso,
      label: c.name
    }));
  }, [countries]);

  React.useEffect(() => {
    onBillingInfoChange({
      billingInfo: {
        accountType: isBusiness ? AccountType.Business : AccountType.Personal,
        name: formValues.name,
        address: formValues.address,
        city: formValues.city,
        state: formValues.state,
        postalCode: formValues.postalCode,
        country: formValues.country,
        purchaseOrderNumber: formValues.purchaseOrderNumber
      },
      isValid
    });
  }, [
    formValues.name,
    formValues.address,
    formValues.city,
    formValues.state,
    formValues.postalCode,
    formValues.country,
    formValues.purchaseOrderNumber,
    isValid,
    isBusiness
  ]);

  const watchCountry = watch("country");

  return (
    <div className={classNames("tw-flex tw-flex-grow tw-flex-col tw-gap-4", className)}>
      <div className="tw-flex tw-cursor-pointer tw-flex-row tw-gap-1" onClick={onBack}>
        <ArrowLeftIcon className="tw-h-5 tw-w-5" />
        <div className="tw-text-sm tw-text-neutral-700">Back</div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <h6 className="tw-text-lg tw-font-semibold">Billing details</h6>
        <div className="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-4">
          <SegmentedControl className="tw-flex-1">
            <SegmentedControl.Option key={0} selected={!isBusiness} onClick={() => setBusiness(false)}>
              Personal
            </SegmentedControl.Option>
            <SegmentedControl.Option key={1} selected={isBusiness} onClick={() => setBusiness(true)}>
              Business
            </SegmentedControl.Option>
          </SegmentedControl>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <Input label="Name" hasError={Boolean(errors.name)} {...register("name", { required: true })} />
          <Textarea label="Address" hasError={Boolean(errors.address)} {...register("address", { required: true })} />
          <div className="tw-flex tw-flex-row tw-gap-4">
            <Input
              wrapperClassName="tw-flex-grow"
              label="City"
              hasError={Boolean(errors.city)}
              {...register("city", { required: true })}
            />
            <Input
              wrapperClassName="tw-flex-grow"
              labelClassName="tw-text-xs tw-font-normal"
              label="State (optional)"
              hasError={Boolean(errors.state)}
              {...register("state")}
            />
          </div>
          <div className="tw-flex tw-flex-row tw-gap-4">
            <div className="tw-flex tw-flex-1">
              <Input
                wrapperClassName="tw-flex-grow"
                label="Postal Code"
                hasError={Boolean(errors.postalCode)}
                {...register("postalCode", { required: true })}
              />
            </div>
            <div className="tw-flex tw-min-w-0 tw-flex-1 tw-flex-grow tw-flex-col tw-gap-1">
              <label className="tw-mb-1 tw-text-sm tw-font-medium">Country</label>
              <Controller
                render={({ field: { onChange, value, disabled } }) => (
                  <Select
                    className="country-select"
                    options={countryOptions}
                    hasError={Boolean(errors.country)}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                  />
                )}
                control={control}
                rules={{ required: true }}
                name="country"
              />
            </div>
          </div>

          {isBusiness && (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <TaxNumberInput
                country={watchCountry}
                type={taxInfo?.type}
                number={taxInfo?.value}
                onUpdate={onTaxNumberChange}
              />
              <div className="tw-flex tw-w-1/2 tw-flex-col">
                <Input
                  wrapperClassName="tw-flex-grow tw-w-[224px]"
                  labelClassName="tw-text-xs tw-font-normal"
                  label="Purchase Order # (optional)"
                  placeholder="123456"
                  {...register("purchaseOrderNumber")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <PaymentMethodComponent
          loading={cardLoading}
          error={cardError}
          paymentMethod={paymentMethod}
          onCardChange={onCardChange}
        />
      </div>
    </div>
  );
};

interface TaxNumberInputProps {
  country?: string;
  type?: string;
  number?: string;
  onUpdate: (number: string) => void;
}
export const TaxNumberInput: React.FC<TaxNumberInputProps> = ({
  country: propCountry,
  type: propType,
  number: propNumber,
  onUpdate
}) => {
  const options = getTaxIdTypeSelectOptions();

  const [hasTaxNumber, setHasTaxNumber] = React.useState<boolean>(Boolean(propType && propNumber));
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

  const [type, setType] = React.useState<TaxIdTypeSelectOption | undefined>(options.find((x) => x.value === propType));
  const [number, setNumber] = React.useState<string>(propNumber || "");

  const showTaxIdNumber = React.useMemo(() => {
    return Boolean(type?.value);
  }, [type]);

  React.useEffect(() => {
    if (!isEdit) {
      if (propType && propNumber) {
        setType(options.find((x) => x.value === propType));
        setNumber(propNumber);
      }
      return;
    }

    if (!propCountry) {
      return;
    }

    const emptySelection = {
      code: "",
      country: "",
      format: "",
      label: "",
      name: "",
      value: ""
    };

    const selection = options.find((option) => option.country === propCountry);

    setType(selection ?? emptySelection);
    if (!selection) {
      setNumber("");
    }
  }, [propCountry, isEdit]);

  const handleApply = async () => {
    if (!type) return; // if this function is called, this variable should be available. this is just to signal to ts the variable exists.

    try {
      setIsUpdating(true);
      await billingSetTaxNumber({ taxIdType: type.code, taxIdNumber: number });
      onUpdate(number);
      setHasTaxNumber(true);
      setIsEdit(false);
    } catch (err) {
      // console.error("Error adding tax number", err);
    } finally {
      setIsUpdating(false);
    }
  };

  if (hasTaxNumber && !isEdit) {
    return (
      <div className="tw-flex tw-flex-col">
        <label className="tw-mb-1 tw-text-xs tw-font-normal">Tax number (optional)</label>
        <div className="tw-flex tw-flex-grow tw-flex-row tw-gap-4">
          <div className="tw-flex tw-flex-grow tw-items-center tw-gap-2">
            <p className="tw-font-medium">{number}</p>
          </div>
          <Button variant="text-plain-primary" size="36" onClick={() => setIsEdit(true)}>
            Change
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col">
      <label className="tw-mb-1 tw-text-xs tw-font-normal">Tax number (optional)</label>
      <div className="tw-flex tw-flex-grow tw-flex-row tw-items-center tw-gap-4">
        <div className="tw-flex tw-flex-grow">
          <Select
            className="tw-w-[224px]"
            options={options}
            value={type?.value}
            onChange={(v) => setType(options.find((o) => o.value === v))}
          />
        </div>

        {showTaxIdNumber && (
          <Input placeholder={type?.format} value={number} onChange={({ target }) => setNumber(target.value)} />
        )}

        <div className="tw-flex tw-flex-row tw-items-center">
          {showTaxIdNumber && (
            <Button variant="text-plain-primary" size="36" onClick={handleApply} disabled={isUpdating}>
              {isUpdating ? "Adding" : "Add"}
            </Button>
          )}
          {hasTaxNumber && !isUpdating && (
            <IconButton size="24" variant="ghost" icon={<XMarkIcon />} onClick={() => setIsEdit(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

interface PaymentMethodProps {
  className?: string;
  loading: boolean;
  paymentMethod?: PaymentMethod;
  onCardChange: ({
    cardholderName,
    update,
    complete
  }: {
    cardholderName?: string;
    update: boolean;
    complete: boolean;
  }) => void;
  error?: string;
}
export const PaymentMethodComponent: React.FC<PaymentMethodProps> = ({
  loading,
  paymentMethod,
  onCardChange,
  error
}) => {
  const [cardholderName, setCardholderName] = React.useState<string>();
  const [update, setUpdate] = React.useState(!paymentMethod);
  const [complete, setComplete] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [focus, setFocus] = React.useState(false);

  const handleUpdateChange = (value: boolean) => {
    if (Boolean(paymentMethod)) {
      setUpdate(value);
      onCardChange({ cardholderName, update: value, complete });
    }
  };

  const handleCardholderNameChange = (value: string) => {
    setCardholderName(value);
    onCardChange({ cardholderName: value, update, complete });
    setDirty(true);
  };

  const handleComplete = (value: boolean) => {
    setComplete(value);
    onCardChange({ cardholderName, update, complete: value });
  };

  if (paymentMethod && !update) {
    const expiryMonth = numeral(paymentMethod.exp_month).format("00");
    const cardImage = getCardImage(paymentMethod.brand);
    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        <h6 className="tw-text-lg tw-font-semibold">Payment method</h6>
        <div className="tw-flex tw-flex-row tw-items-start tw-gap-2">
          {cardImage && <img src={cardImage} alt={paymentMethod.brand} height="40" width="100" />}
          <div className="tw-flex tw-flex-col">
            <h5 className="tw-text-md tw-font-normal tw-capitalize tw-text-black">
              {paymentMethod.brand} **** {paymentMethod.last4}
            </h5>
            <p className="tw-text-sm">
              Expires {expiryMonth}/{paymentMethod.exp_year}
            </p>
            <p
              className="tw-text-xs tw-text-primary-600 hover:tw-cursor-pointer"
              onClick={() => handleUpdateChange(true)}
            >
              Change payment method
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <h6 className="tw-text-lg tw-font-semibold">Payment method</h6>
      {paymentMethod && (
        <div className="tw-flex tw-cursor-pointer tw-flex-row tw-gap-1" onClick={() => handleUpdateChange(false)}>
          <ArrowLeftIcon className="tw-h-5 tw-w-5" />
          <div className="tw-text-sm tw-text-neutral-700">Back</div>
        </div>
      )}
      <div className="tw-flex tw-flex-col">
        <label className="tw-font-medium tw-text-neutral-900">Name on card</label>
        <Input
          type="text"
          value={cardholderName}
          onChange={({ target }) => {
            handleCardholderNameChange(target.value);
          }}
          helper={dirty && !cardholderName ? "Required" : undefined}
          disabled={loading}
        />
      </div>
      <div className="tw-flex tw-flex-col">
        <label className="tw-font-medium tw-text-neutral-900">Credit / debit card number</label>
        <CardElement
          className={classNames(
            "tw-mb-4 tw-flex tw-h-10 tw-flex-col tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-3 tw-py-2 tw-text-sm tw-leading-7 tw-text-black tw-shadow-none tw-transition-all tw-duration-200 tw-ease-in hover:tw-border-aux-500 focus:tw-ring-2 focus:tw-ring-aux-200 focus:tw-ring-opacity-50",
            {
              focused: focus
            }
          )}
          options={{ hidePostalCode: true }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={(e) => handleComplete(e.complete)}
        />
        <div className="tw-flex">
          {error && <span className="has-error tw-text-sm tw-text-destructive-500">{error}</span>}
        </div>
      </div>
    </div>
  );
};

interface SubscriptionSummaryProps {
  className?: string;
  productsLoading: boolean;
  product?: Product;
  units?: BaseUnits;
  taxCountry?: string;
  taxNumber?: string;
  subscription?: Subscription;
  purchaseLoading: boolean;
  step: number;
  isValidBillingDetails: boolean;
  error?: string;
  onClick: ({
    items,
    oneOff,
    taxCountry,
    coupon,
    promoId
  }: {
    items: {
      price: string;
      quantity: number;
    }[];
    oneOff: boolean;
    taxCountry?: string;
    coupon?: Coupon;
    promoId?: string;
  }) => void;
  onContactUs: (params: { description: string; amount: string }) => void;
}
export const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  className,
  productsLoading,
  product,
  units,
  taxCountry,
  taxNumber,
  subscription,
  purchaseLoading,
  step,
  isValidBillingDetails,
  error,
  onClick,
  onContactUs
}) => {
  const { totalFilledSeats } = useAccountMembers();
  const { storageBytesUsed, credit } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const [loading, setLoading] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [coupon, setCoupon] = React.useState<Coupon>();
  const [couponError, setCouponError] = React.useState<string>();
  const [promoId, setPromoId] = React.useState<string>();
  const [costs, setCosts] = React.useState<GetSubscriptionCostResult>();
  const showCouponDiscountInput = config.features.showCouponDiscountInput && !subscription?.coupon && !coupon?.id;

  const minutesProduct = product?.additional?.find((p) => p.metadata.type === "extra_minutes");
  const minutesPrice = minutesProduct?.prices.find((p) => p.default) || minutesProduct?.prices[0];
  const isOneOff = minutesPrice?.type === "one_time";
  const usersProduct = product?.additional?.find((p) => p.metadata.type === "named_users");
  const storageProduct = product?.additional?.find((p) => p.metadata.type === "extra_storage");

  const mins = React.useMemo(() => {
    if (!product || !units) {
      return {
        minutes: 0,
        users: 0,
        storage: 0
      };
    }
    const baseMinutes = (Number(product.metadata.seconds) || 0) / 60;
    const baseUsers = Number(product.metadata.users) || 1;
    const baseStorage = (Number(product.metadata.bytes) || 0) / 1024 / 1024 / 1024;

    const metaMinutes = Number(minutesProduct?.metadata.min || 0);
    const metaUsers = Number(usersProduct?.metadata.min || 0);
    const metaStorage = Number(storageProduct?.metadata.min || 0);

    const storagePerNewUser = (Number(usersProduct?.metadata.bytes) || 0) / 1024 / 1024 / 1024;
    const userStorage = storagePerNewUser * (units.users - baseUsers);

    return {
      minutes: baseMinutes + metaMinutes,
      users: baseUsers + metaUsers,
      storage: baseStorage + metaStorage + userStorage
    };
  }, [product, minutesProduct, usersProduct, storageProduct, units?.users]);

  const items = React.useMemo(() => {
    if (!product || !units) {
      return [];
    }

    const items: {
      price: string;
      quantity: number;
      type: "subscription_base" | "extra_minutes" | "extra_storage" | "named_users";
    }[] = [];
    const { minutes, users, storage } = units;

    if (!isOneOff) {
      const price = product.prices.find((p) => p.default) || product.prices[0];
      items.push({
        price: price.id,
        quantity: 1,
        type: "subscription_base"
      });
    }
    const minutesPrice = minutesProduct?.prices.find((p) => p.default)?.id || minutesProduct?.prices[0]?.id;
    if (isOneOff && minutesPrice) {
      items.push({
        price: minutesPrice,
        quantity: minutes || mins.minutes,
        type: "extra_minutes"
      });
    } else if (minutes > mins.minutes && minutesPrice) {
      items.push({
        price: minutesPrice,
        quantity: minutes - mins.minutes,
        type: "extra_minutes"
      });
    }

    const usersPrice = usersProduct?.prices.find((p) => p.default)?.id || usersProduct?.prices[0]?.id;
    if (users > mins.users && usersPrice) {
      items.push({
        price: usersPrice,
        quantity: users - mins.users,
        type: "named_users"
      });
    }

    const storagePrice = storageProduct?.prices.find((p) => p.default)?.id || storageProduct?.prices[0]?.id;
    if (storage > mins.storage && storagePrice) {
      items.push({
        price: storagePrice,
        quantity: storage - mins.storage,
        type: "extra_storage"
      });
    }

    return items;
  }, [product, mins.minutes, mins.storage, mins.users, units?.minutes, units?.storage, units?.users]);

  const debounceItems = useDebounce(items, 150);

  const hasChange = React.useMemo(() => {
    if (!subscription) {
      return true;
    }
    const samePlan = subscription?.items?.find((i) => i.productId === product?.id);
    if (!samePlan) {
      return true;
    }

    const currentMinutes = subscription?.items.find((i) => i.product === AdditionalItem.Minutes)?.units || 0;
    const itemMinutes = items.find((i) => i.type === "extra_minutes")?.quantity || 0;
    if (currentMinutes !== itemMinutes) {
      return true;
    }

    const currentSeats = subscription?.items.find((i) => i.product === AdditionalItem.Seats)?.units || 0;
    const itemSeats = items.find((i) => i.type === "named_users")?.quantity || 0;
    if (currentSeats !== itemSeats) {
      return true;
    }

    const currentStorage = subscription?.items.find((i) => i.product === AdditionalItem.Storage)?.units || 0;
    const itemStorage = items.find((i) => i.type === "extra_storage")?.quantity || 0;
    if (currentStorage !== itemStorage) {
      return true;
    }

    if (subscription.status === "trialing") {
      return true;
    }

    return false;
  }, [subscription, product, debounceItems]);

  const shouldContactUs = React.useMemo(() => {
    return Boolean(product?.metadata?.contactUs);
  }, [product]);

  const btnText = React.useMemo(() => {
    if (purchaseLoading) {
      return "Processing...";
    }

    if (step === 0) {
      return "Continue to payment";
    }

    return "Purchase";
  }, [step]);

  const disabled = React.useMemo(() => {
    if (!product || !units || purchaseLoading) {
      return true;
    }

    // ENSURE THAT THERE IS A MINIMUM OF 60 MINUTES
    const MINUTES_MIN = 60;
    const currentMinutes = subscription?.items.find((i) => i.product === AdditionalItem.Minutes)?.units || 0;
    const itemMinutes = items.find((i) => i.type === "extra_minutes")?.quantity || 0;
    if (currentMinutes !== itemMinutes && itemMinutes - currentMinutes < MINUTES_MIN) {
      return true;
    }

    return false;
  }, [product, units, step, isValidBillingDetails, subscription, items]);

  const balanceAfter = React.useMemo(() => {
    if (!units) {
      return;
    }

    let minutes = units.minutes;
    if (isOneOff && subscription?.items?.find((i) => i.productId === product?.id)) {
      minutes += Math.floor((credit?.total || 0) / 60);
    }

    return {
      minutesText: `${minutes} mins`,
      minutesPercent: 0,
      usersText: `${totalFilledSeats || 1}/${usersProduct ? units.users : mins.users} users`,
      usersPercent: ((totalFilledSeats || 1) / (usersProduct ? units.users : mins.users)) * 100,
      storageText: `${Math.floor((storageBytesUsed / 1024 / 1024 / 1024) * 100) / 100}/${
        storageProduct ? units.storage : mins.storage
      } GB`,
      storagePercent: (storageBytesUsed / 1024 / 1024 / 1024 / (storageProduct ? units.storage : mins.storage)) * 100
    };
  }, [
    product?.id,
    debounceItems,
    hasChange,
    units,
    units?.minutes,
    credit,
    storageBytesUsed,
    totalFilledSeats,
    isOneOff,
    minutesProduct,
    usersProduct,
    storageProduct
  ]);

  const loadOneTimeCosts = async (params: GetInvoiceCostBody) => {
    try {
      setLoading(true);
      const costs = await getInvoiceCost(params);

      setCosts(costs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const loadSubCosts = async (params: GetSubscriptionCostBody) => {
    try {
      setLoading(true);
      const costs = await getSubscriptionCost(params);

      setCosts(costs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (debounceItems.length === 0) {
      return;
    }
    if (isOneOff) {
      const couponId =
        coupon?.applies_to?.products && product?.id && coupon.applies_to.products.includes(product.id)
          ? coupon?.id
          : undefined;
      loadOneTimeCosts({
        items: debounceItems,
        taxCountry: taxCountry || "",
        coupon: couponId
      });
    } else {
      loadSubCosts({
        items: debounceItems,
        subscriptionId: subscription?.id,
        taxCountry: taxCountry || "",
        coupon: coupon?.id
      });
    }
    if (coupon?.applies_to?.products && product?.id && !coupon.applies_to.products.includes(product.id)) {
      setCouponError("This coupon code does not apply to this product");
    } else {
      setCouponError("");
    }
  }, [debounceItems, productsLoading, subscription, taxCountry, taxNumber, coupon]);

  const handlePurchase = () => {
    const purchaseItems = debounceItems
      .filter((i) => (isOneOff && i.type !== "subscription_base") || !isOneOff)
      .map((item) => ({
        price: item.price,
        quantity: item.quantity
      }));

    onClick({
      items: purchaseItems,
      taxCountry: taxCountry || "",
      oneOff: isOneOff,
      coupon: coupon,
      promoId
    });
  };

  const handleContactUs = () => {
    const description = debounceItems
      .filter((i) => (isOneOff && i.type !== "subscription_base") || !isOneOff)
      .map((item) => `${item.type}(${item.quantity})`)
      .join(",");
    trackEventWithAuth("Check out / Talk to us");
    onContactUs({
      description,
      amount: costs?.total?.toString() || "0"
    });
  };

  const checkCoupon = async () => {
    try {
      const { coupon, promoId } = await getCoupon(couponCode);
      promoId && setPromoId(promoId);
      if (coupon.valid) {
        setCoupon(coupon);
        setCouponError("");
      }
    } catch (e) {
      setCouponError(`${couponCode} discount code is not valid`);
    }
  };

  if (productsLoading) {
    return <div className={classNames("tw-flex tw-shrink-0 tw-flex-col tw-items-stretch", className)} />;
  }

  if (!product || !units) {
    return (
      <div className={classNames("tw-flex tw-shrink-0 tw-flex-col tw-items-stretch", className)}>
        <div className="tw-flex tw-flex-grow tw-flex-col">
          <h6 className="tw-text-h6 tw-font-semibold">Select a plan</h6>
          <p className="tw-text-sm tw-font-medium tw-text-neutral-600">Please select one of the plans to continue.</p>
          <div className="tw-flex tw-flex-grow tw-flex-col tw-overflow-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("tw-flex tw-shrink-0 tw-flex-col tw-items-stretch", className)}>
      <div className="tw-flex tw-flex-grow tw-flex-col">
        <h6 className="tw-text-h6 tw-font-semibold" style={{ color: product.metadata?.colour || "#000000" }}>
          {product.name} plan
        </h6>
        <p className="tw-text-sm tw-font-medium tw-text-neutral-600">{product.metadata.subtitle}</p>
        <div className="tw-flex tw-flex-grow tw-flex-col tw-overflow-auto">
          <Divider />
          {product?.metadata?.keyFeatures && (
            <>
              <p className="tw-pb-2 tw-text-sm tw-text-neutral-600">Includes:</p>
              <div className="tw-flex tw-flex-col tw-gap-2">
                {product.metadata.keyFeatures.split(";").map((f, i) => (
                  <div key={i} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                    <CheckmarkFullIcon className="tw-w-4 tw-text-primary-400" />
                    <div className="tw-text-xs tw-font-normal">{f}</div>
                  </div>
                ))}
              </div>
              <Divider />
            </>
          )}
          {loading && !costs && <Skeleton height={40} />}
          {costs && (
            <div className="tw-flex tw-flex-col tw-gap-4">
              {costs.items
                ?.filter((item) => item.amount > 0)
                .map((item, i) => (
                  <SubscriptionSummaryItem
                    key={i}
                    product={product}
                    item={item}
                    currency={costs.currency as CurrencyCode}
                    loading={loading}
                  />
                ))}
            </div>
          )}
          <Divider />
        </div>
      </div>
      {!hasChange && <SubscriptionSummaryStatus subscription={subscription} />}
      {hasChange && (
        <div className="tw-flex tw-flex-col">
          {balanceAfter && (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-text-xs tw-font-semibold">Balance after purchase</div>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-xs tw-font-medium tw-text-neutral-500">
                <ProgressBar progress={balanceAfter.minutesPercent} />
                <div className="tw-min-w-[90px] tw-whitespace-nowrap">{balanceAfter.minutesText}</div>
              </div>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-xs tw-font-medium tw-text-neutral-500">
                <ProgressBar progress={balanceAfter.usersPercent} />
                <div className="tw-min-w-[90px] tw-whitespace-nowrap">{balanceAfter.usersText}</div>
              </div>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-xs tw-font-medium tw-text-neutral-500">
                <ProgressBar progress={balanceAfter.storagePercent} />
                <div className="tw-min-w-[90px] tw-whitespace-nowrap">{balanceAfter.storageText}</div>
              </div>
            </div>
          )}
          <Divider />
          {showCouponDiscountInput && (
            <div className="tw-mb-3 tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-w-full tw-flex-row tw-content-stretch tw-items-end tw-justify-between tw-gap-2">
                <Input
                  type="text"
                  label="Discount code"
                  className="tw-bg-white"
                  wrapperClassName="tw-flex-grow"
                  value={couponCode}
                  hasError={Boolean(couponError)}
                  onChange={({ target }) => {
                    setCouponCode(target.value);
                    couponError && setCouponError("");
                  }}
                  onKeyDown={(e) => {
                    e.key === "Enter" && checkCoupon();
                  }}
                />
                <Button variant="secondary" onClick={checkCoupon}>
                  Add
                </Button>
              </div>
            </div>
          )}
          {coupon && (
            <Tag
              key={coupon.id}
              className="tw-mb-3 tw-w-fit"
              tag={{ leftIcon: <RiPriceTag3Line className="tw-w-4" />, label: coupon.name, value: coupon.id }}
              size="28"
              onRemove={() => setCoupon(undefined)}
            />
          )}
          {couponError && (
            <Alert
              innerClassName=""
              warning
              closable
              onClose={() => setCouponError("")}
              className=" tw-mb-3 tw-rounded-lg tw-bg-warning-100"
            >
              <p className="tw-text-xs tw-font-medium">{couponError}</p>
            </Alert>
          )}
          <SubscriptionSummaryCosts
            costs={costs}
            isOneOff={isOneOff}
            shouldContactUs={shouldContactUs}
            coupon={coupon}
          />
          {!shouldContactUs && (
            <Button onClick={handlePurchase} disabled={disabled} loading={purchaseLoading} size="36">
              {btnText}
            </Button>
          )}
          {shouldContactUs && (
            <Button onClick={handleContactUs} size="36">
              Talk to us
            </Button>
          )}
          {Boolean(error) && <p className="tw-text-xs tw-text-destructive-500">{error}</p>}
          {!shouldContactUs && !isOneOff && costs?.renewalDate && (
            <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-1 tw-text-center tw-text-neutral-500">
              <div className="tw-text-sm tw-font-medium">
                Renews on {format(new Date(costs.renewalDate), "dd MMMM yyyy")}
              </div>
              <div className="tw-text-xs">
                You can cancel your subscription at anytime. You will still have access to the service until the end of
                the billing period
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface SubscriptionSummaryItemProps {
  loading?: boolean;
  item: GetSubscriptionCostItem;
  currency: CurrencyCode;
  product: Product;
}
const SubscriptionSummaryItem: React.FC<SubscriptionSummaryItemProps> = ({ loading, item, currency, product }) => {
  const prod = React.useMemo(() => {
    return product.id === item.product ? product : product.additional.find((p) => p.id === item.product);
  }, [item, product]);
  const label = React.useMemo(() => {
    switch (prod?.metadata?.type) {
      case "subscription_base":
        return `${prod?.name} plan`;
      case "extra_minutes":
        return `${item.units} Additional ${pluralize(item.units, "minute")}`;
      case "named_users":
        return `${item.units} Additional ${pluralize(item.units, "user")}`;
      case "extra_storage":
        return `${item.units} GB Additional storage`;
      default:
        return prod?.name;
    }
  }, [item, prod]);

  if (loading) {
    return <Skeleton height={item?.units > 1 ? 40 : 20} />;
  }

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-text-sm tw-font-medium">{label}</div>
        <div className="tw-text-sm tw-font-medium">{formatPrice(item.amount, currency)}</div>
      </div>
      {item.units > 1 && prod?.metadata.unit && (
        <div className="tw-text-xs tw-font-normal">
          {formatPrice(item.amount / item.units, currency)} per {prod.metadata.unit}
        </div>
      )}
    </div>
  );
};

interface SubscriptionSummaryStatusProps {
  subscription?: Subscription;
}
const SubscriptionSummaryStatus: React.FC<SubscriptionSummaryStatusProps> = ({ subscription }) => {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const [showCancelModal, hideCancelModal] = useModal(ModalType.CancelModal);

  if (!subscription) {
    return;
  }

  const handleDontCancelSubscription = async () => {
    if (!subscription?.id) {
      notificationError(EN.error.defaultMessage);
      return;
    }

    setIsCanceling(true);
    try {
      await dontCancelSubscription(subscription.id);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleOldCancelPlan = () => {
    showCancelModal(
      <CancelModal
        subscriptionId={subscription.id}
        cancelPlan={SublyPlan.Free}
        currentPlan={SublyPlan.Business}
        onDismiss={hideCancelModal}
        isTrial={false}
      />
    );
  };

  if (subscription.cancelAt) {
    return (
      <div className="tw-rounded-10 tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-p-3 tw-text-sm">
        <div className="tw-pb-1">
          Subscription will end on{" "}
          <span className="tw-font-semibold">{format(new Date(subscription.cancelAt), "dd MMMM yyyy")}</span>.
        </div>
        Don't want to cancel anymore?{" "}
        <span className="tw-cursor-pointer tw-underline" onClick={handleDontCancelSubscription}>
          {isCanceling ? <Icon path={mdiLoading} spin size="0.8rem" /> : "Click here"}
        </span>
        .
      </div>
    );
  }

  return (
    <div className="tw-rounded-10 tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-p-3 tw-text-sm">
      Don't want your subscription anymore?{" "}
      <span className="tw-cursor-pointer tw-underline" onClick={handleOldCancelPlan}>
        {isCanceling ? <Icon path={mdiLoading} spin size="0.8rem" /> : "Click here"}
      </span>
      .
    </div>
  );
};

interface SubscriptionSummaryCostsProps {
  isOneOff: boolean;
  costs?: GetSubscriptionCostResult;
  shouldContactUs: boolean;
  coupon?: Coupon;
}
const SubscriptionSummaryCosts: React.FC<SubscriptionSummaryCostsProps> = ({
  isOneOff,
  costs,
  shouldContactUs,
  coupon
}) => {
  const showDiscount = Boolean(costs?.discount?.amount && costs.discount.amount > 0 && coupon);
  const subtotalAfterDiscount =
    isOneOff && costs?.subtotal && costs?.discount?.amount
      ? costs?.subtotal - costs?.discount?.amount
      : costs?.nextSubtotal && costs?.discount?.amount && costs?.nextSubtotal - costs?.discount?.amount;
  const priceTooltip = React.useMemo(() => {
    let tooltip = "";
    if (!costs) {
      return "";
    }

    if (costs.credit) {
      tooltip += `Starting balance: ${formatPrice(costs.credit * -1, costs.currency as CurrencyCode, "0.00")}\r\n`;
    }
    if (costs.tax) {
      tooltip += `Subtotal: ${formatPrice(costs.subtotal, costs.currency as CurrencyCode, "0.00")}\r\n`;
      tooltip += `Tax: ${formatPrice(costs.tax, costs.currency as CurrencyCode, "0.00")} (${
        costs.taxPercent * 100
      }%)\r\n`;
    }
    tooltip += `Total: ${formatPrice(costs.total, costs.currency as CurrencyCode, "0.00")}\r\n`;
    if (costs.credit || costs.creditEnd) {
      tooltip += `Ending balance: ${formatPrice(
        (costs.creditEnd || 0) * -1,
        costs.currency as CurrencyCode,
        "0.00"
      )}\r\n`;
    }
    return tooltip;
  }, [costs]);

  const discountInfo = showDiscount && (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-text-xs tw-font-normal">
          {coupon?.name} ({coupon?.percent_off}% off)
        </div>
        <div className="tw-text-sm tw-font-medium">
          - {formatPrice(costs?.discount?.amount, costs?.currency as CurrencyCode)}
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div className="tw-text-sm tw-font-semibold">Subtotal after discount</div>
        <div className="tw-text-sm tw-font-medium">
          {formatPrice(subtotalAfterDiscount, costs?.currency as CurrencyCode)}
        </div>
      </div>
    </>
  );

  if (isOneOff) {
    return (
      <>
        <div className="tw-flex tw-flex-col tw-gap-4">
          {costs?.subtotal !== costs?.total && (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                <div className="tw-text-sm tw-font-semibold">Subtotal</div>
                <div className="tw-text-sm tw-font-medium">
                  {costs ? formatPrice(costs.subtotal, costs.currency as CurrencyCode) : ""}
                </div>
              </div>
              {discountInfo}
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                <div className="tw-text-xs tw-font-normal">
                  VAT {costs?.taxPercent ? `(${costs?.taxPercent * 100}%)` : ""}
                </div>
                <div className="tw-text-sm tw-font-medium">
                  {costs ? formatPrice(costs.tax, costs.currency as CurrencyCode) : ""}
                </div>
              </div>
            </div>
          )}
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-text-md tw-font-semibold">Total</div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div className="tw-text-h5 tw-font-semibold">
                {costs ? formatPrice(costs.total, costs.currency as CurrencyCode) : ""}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        {costs?.total !== costs?.due && (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-row tw-items-center">
              <div className="tw-text-sm tw-font-medium">Due now</div>
              {priceTooltip && (
                <ToolTip text={priceTooltip}>
                  <InformationCircleIcon className="tw-ml-1 tw-inline tw-h-5 tw-w-5" />
                </ToolTip>
              )}
            </div>
            <div className="tw-text-h6 tw-font-medium tw-text-neutral-800">
              {costs ? formatPrice(costs.due, costs.currency as CurrencyCode) : ""}
            </div>
          </div>
        )}
        {isOneOff && costs?.total !== costs?.due && <Divider />}
      </>
    );
  }
  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {costs?.nextSubtotal !== costs?.nextTotal && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              <div className="tw-text-sm tw-font-semibold">Subtotal</div>
              <div className="tw-text-sm tw-font-medium">
                {costs ? formatPrice(costs.nextSubtotal, costs.currency as CurrencyCode) : ""}
              </div>
            </div>
            {discountInfo}
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              <div className="tw-text-xs tw-font-normal">
                VAT {costs?.nextTaxPercent ? `(${costs?.nextTaxPercent * 100}%)` : ""}
              </div>
              <div className="tw-text-sm tw-font-medium">
                {costs ? formatPrice(costs.nextTax, costs.currency as CurrencyCode) : ""}
              </div>
            </div>
          </div>
        )}
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-text-md tw-font-semibold">Total</div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div className="tw-text-h5 tw-font-semibold">
              {costs ? formatPrice(costs.nextTotal, costs.currency as CurrencyCode) : ""}
            </div>
            <div className="tw-text-md tw-font-medium tw-text-neutral-500"> / year</div>
          </div>
        </div>
      </div>

      <Divider />
      {costs?.due !== costs?.nextTotal && !shouldContactUs && (
        <>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-row tw-items-center">
              <div className="tw-text-sm tw-font-medium">Due now</div>
              {priceTooltip && (
                <ToolTip text={priceTooltip}>
                  <InformationCircleIcon className="tw-ml-1 tw-inline tw-h-5 tw-w-5" />
                </ToolTip>
              )}
            </div>
            <div className="tw-text-h6 tw-font-medium tw-text-neutral-800">
              {costs ? formatPrice(costs.due, costs.currency as CurrencyCode) : ""}
            </div>
          </div>
          <Divider />
        </>
      )}
    </>
  );
};
