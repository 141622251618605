import { AxiosError } from "axios";

import { EN } from "@frontend/assets/i18n/en";
import { notificationError } from "@frontend/components/notification";

import * as Sentry from "@sentry/react";

export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: AxiosError<any> | any,
  urlUpload = false
): void => {
  // Ignore canceled requests (e.g. when user loads media list too quickly after changing folders)
  if (e.code == "ERR_CANCELED") {
    return;
  }

  Sentry.captureException(e);
  console.error(e);

  const responseStatus = e.response?.status;
  const errDescription = e.response?.data?.description;
  const errMessage = e.response?.data?.message;
  const errCode = e.response?.data?.code;
  const dataError = e.response?.data?.error;

  if (urlUpload && e.response?.config?.data?.includes("vimeo.com")) {
    notificationError(EN.error.VimeoURLError);
    return;
  }

  if (urlUpload && e.response?.config?.data?.includes("zoom.us")) {
    notificationError(EN.error.ZoomURLError);
    return;
  }

  if (urlUpload) {
    if (dataError?.includes("Private video")) {
      notificationError(EN.error.PrivateVideo);
      return;
    }

    if (dataError?.includes("Unsupported URL")) {
      notificationError(EN.error.UnsupportedURL);
      return;
    }
    if (dataError?.includes("URL is forbidden")) {
      notificationError(EN.error.ForbiddenURL);
      return;
    }

    notificationError(EN.error.URLError);
    return;
  }

  if (responseStatus === 422 && errDescription) {
    notificationError(errDescription);
    return;
  }

  if (dataError) {
    notificationError(dataError);
    return;
  }

  if (responseStatus === 500) {
    if (errMessage?.includes("Insufficient storage to duplicate")) {
      notificationError(EN.error.InsufficientStorageToDuplicate);
      return;
    }

    if (errCode?.includes("ERR_INVALID_ARG_TYPE'")) {
      notificationError(EN.error.enhancementError);
      return;
    }
  }

  if (responseStatus === 400 && errCode?.includes("MaxTeamCapacity")) {
    notificationError(EN.error.MaxTeamCapacity);
    return;
  }

  if (e?.message?.includes("Failed to upload media")) {
    notificationError(EN.error.UploadFileError);
    return;
  }

  notificationError(EN.error.defaultMessage);
};
