"use client";

import React from "react";

import { MediaPlayerRef } from "@media-player/components/media-player";

export interface FSDocs extends HTMLDivElement {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
}

interface MediaPlayerContext {
  mediaPlayerRef: React.RefObject<MediaPlayerRef>;
  fullscreenRef: React.RefObject<FSDocs> | null;
}

const MediaPlayerContext = React.createContext<MediaPlayerContext | null>(null);

interface MediaPlayerProviderProps {
  children: React.ReactNode;
}
export const MediaPlayerProvider: React.FC<MediaPlayerProviderProps> = ({ children }) => {
  const mediaPlayerRef = React.useRef<MediaPlayerRef>(null);
  const fullscreenRef = React.useRef<FSDocs>(null);

  return (
    <MediaPlayerContext.Provider
      value={{
        fullscreenRef,
        mediaPlayerRef
      }}
    >
      {children}
    </MediaPlayerContext.Provider>
  );
};

export const useMediaPlayer = (): MediaPlayerContext => {
  const context = React.useContext(MediaPlayerContext);

  if (context === null) {
    throw new Error("useMediaPlayer must be used within a MediaPlayerProvider");
  }

  return context;
};
