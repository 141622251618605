import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";
import { selectAllEntities } from "@ngneat/elf-entities";

import { userGroupsStore } from "./user-groups.state";

export const useUserGroups = () => {
  const [userGroups] = useObservable(userGroupsStore.pipe(selectAllEntities()));

  return userGroups ?? [];
};

export const useUserGroupsLoading = () => {
  const [loading] = useObservable(userGroupsStore.pipe(select((s) => s.loading)), false);

  return loading ?? false;
};
