import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { verificationResend, VerifyUserParams } from "@frontend/api/auth.service";
import { notificationSuccess } from "@frontend/components/notification";
import { SetPassword } from "@frontend/components/set-password/set-password";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { LOGIN_PATH } from "@frontend/routes";

import { Button } from "@components/button";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./auth.module.scss";

interface ResponseData {
  alreadyVerified?: boolean;
}

interface UserVerificationProps {
  external: boolean;
}

export const UserVerification: React.FC<UserVerificationProps> = ({ external }) => {
  const { id = "", code = "" } = useParams();
  const [needsPassword] = React.useState(external);
  const [isLoading, setLoading] = React.useState(!external);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [responseData, setResponseData] = React.useState<ResponseData>();
  const [alertMessage, setAlertMessage] = React.useState("");
  const { verifyUser } = useAuthProvider();
  const { search } = useLocation();

  const handleVerifyUser = async (params: VerifyUserParams) => {
    try {
      setLoading(true);

      const { success, data, alertMessage } = await verifyUser(params);
      setLoading(false);
      setResponseData(data);
      setAlertMessage(alertMessage);

      if (success) {
        notificationSuccess("Verification was successful. Please login.");
      }

      setError(!success);
      setSuccess(success);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  React.useEffect(() => {
    if (!needsPassword) {
      // Trigger verification
      handleVerifyUser({ code, id });
    }
  }, [code, id, needsPassword]);

  if (error) {
    return <VerificationError id={id} message={alertMessage} />;
  }

  const renderContent = () => {
    if (responseData?.alreadyVerified) {
      return (
        <>
          <p className="tw-text-center">Your email was already confirmed. Please login to your account.</p>
          <Button variant="primary" type="link" to={{ pathname: LOGIN_PATH, search }} size="36">
            Go to Login
          </Button>
        </>
      );
    }

    if (success) {
      return (
        <>
          <h4 className="tw-mb-6 tw-text-center tw-text-h4">Verification was successful</h4>
          <p className="tw-text-center">Your email was confirmed. Please login to your account.</p>
          <Navigate to={{ pathname: LOGIN_PATH, search }} />
        </>
      );
    }

    if (needsPassword) {
      return (
        <SetPassword
          loading={isLoading}
          onSubmit={async ({ password }) => {
            await handleVerifyUser({
              code,
              id,
              password
            });
          }}
        />
      );
    }

    // default is triggered message
    return (
      <>
        <h4 className="tw-mb-6 tw-text-center tw-text-h4">We are verifying your account</h4>
        <p className="tw-text-center">
          <Icon path={mdiLoading} spin size="1.2rem" className="tw-mr-2" /> Please wait a moment...
        </p>
      </>
    );
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

interface VerificationErrorProps {
  id: string;
  message: string;
}
const VerificationError: React.FC<VerificationErrorProps> = ({ id, message }) => {
  const [hasResentVerification, setResentVerification] = React.useState(false);

  const handleResendVerification = (id: string) => {
    verificationResend(id);
    setResentVerification(true);
  };

  if (hasResentVerification) {
    return (
      <VerificationErrorContainer>
        <p className="tw-text-center">We have just emailed you a new verification code, please check your email.</p>
      </VerificationErrorContainer>
    );
  }

  if (message.includes("limit exceeded") || message.includes("does not exist")) {
    return (
      <VerificationErrorContainer>
        <p className="tw-mb-6 tw-text-center">{message}</p>
        <Button variant="primary" to="mailto:support@getsubly.com" type="link" isBlank size="36">
          Contact support
        </Button>
      </VerificationErrorContainer>
    );
  }

  return (
    <VerificationErrorContainer>
      <p className="tw-mb-6 tw-text-center">{message}</p>

      <Button variant="primary" disabled={hasResentVerification} onClick={() => handleResendVerification(id)} size="36">
        Resend verification code
      </Button>
    </VerificationErrorContainer>
  );
};

interface VerificationErrorContainerProps {
  children: React.ReactNode;
}
const VerificationErrorContainer: React.FC<VerificationErrorContainerProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <h4 className="tw-mb-6 tw-text-center tw-text-h4">Verification failed</h4>
      {children}
    </div>
  );
};
