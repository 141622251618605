import React from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import { Divider } from "@frontend/components/divider/divider";

import { ScrollContainer } from "@core/components/scroll-container";
import { useDashboard } from "@core/hooks/use-dashboard";

import { AccountMenu } from "./account/account-menu";
import { SidebarDrives } from "./drives/sidebar-drives";
import { BottomSidebarNav, TopSidebarNav } from "./sidebar-nav/sidebar-nav";
import { SourcesNav } from "./sources-nav/sources-nav";
import { WorkspacePicker } from "./workspaces/workspace-picker";

export const DashboardSidebar: React.FC = () => {
  const { hasLoadedFolders } = useDashboard();
  const [isScrolled, setIsScrolled] = React.useState(false);
  const isScrolledRef = React.useRef(false);

  const handleScroll = (yOffset: number) => {
    const isScrolled = isScrolledRef.current;

    if (yOffset && !isScrolled) {
      setIsScrolled(true);
      isScrolledRef.current = true;
    } else if (!yOffset && isScrolled) {
      setIsScrolled(false);
      isScrolledRef.current = false;
    }
  };

  return (
    <div className="tw-flex tw-h-screen tw-w-[260px] tw-shrink-0 tw-flex-col tw-border-r tw-border-neutral-200 tw-bg-neutral-25">
      <div className="tw-flex tw-flex-col tw-px-2 tw-pt-2 tw-transition">
        <WorkspacePicker className="tw-mb-2" />
        <TopSidebarNav />
      </div>
      {hasLoadedFolders ? (
        <ScrollContainer
          onScrollY={handleScroll}
          className={classNames(
            "tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-border-b tw-border-t tw-border-neutral-200/0 tw-px-4",
            { "!tw-border-neutral-200": isScrolled }
          )}
        >
          <SidebarDrives />
          <Divider className="tw-w-full tw-border-neutral-300" />
          <SourcesNav />
        </ScrollContainer>
      ) : (
        <FoldersLoader className="tw-mt-3" />
      )}
      <div className="tw-flex tw-flex-col tw-px-2 tw-pt-2 tw-transition">
        <BottomSidebarNav className="tw-mb-2" />
      </div>
      <div className="tw-mt-auto tw-flex tw-px-2 tw-py-2">
        <AccountMenu />
      </div>
    </div>
  );
};

type FoldersLoaderProps = {
  className?: string;
};
const FoldersLoader: React.FC<FoldersLoaderProps> = ({ className }) => {
  return (
    <ul className={classNames("tw-flex tw-flex-col tw-gap-1 tw-px-3", className)}>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-3 tw-w-24" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-48" />
      </li>
      <li className="tw-mt-2 tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-3 tw-w-24" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-36" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-48" />
      </li>
      <li className="tw-mt-2 tw-flex tw-h-6 tw-items-center  tw-px-3">
        <Skeleton className="tw-h-3 tw-w-24" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-48" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-36" />
      </li>
      <li className="tw-flex tw-h-6 tw-items-center tw-px-3">
        <Skeleton className="tw-h-4 tw-w-32" />
      </li>
    </ul>
  );
};
