import { Observable, of, switchMap } from "rxjs";

import { Query } from "@datorama/akita";
import { RoleName } from "@getsubly/common";

import { PresenceUser, UserPresenceState, UserPresenceStore, userPresenceStore } from "./user-presence.store";

export class UserPresenceQuery extends Query<UserPresenceState> {
  constructor(protected store: UserPresenceStore) {
    super(store);
  }

  selectLockedUsers(mediaId: string): Observable<PresenceUser[]> {
    return this.select((s) => s.users.filter((u) => u?.mediaId === mediaId));
  }

  selectActiveUser(mediaId: string): Observable<PresenceUser | undefined> {
    return this.selectLockedUsers(mediaId).pipe(
      switchMap((users) => {
        if (!users.length) {
          return of(undefined);
        }

        const earliestUser = users
          .filter((user) => user.role !== RoleName.Viewer)
          .reduce((prev, curr) =>
            prev?.joinedAt && curr?.joinedAt
              ? prev?.joinedAt?.valueOf() > curr?.joinedAt?.valueOf()
                ? curr
                : prev
              : prev
          );

        return of(earliestUser);
      })
    );
  }
}

export const userPresenceQuery = new UserPresenceQuery(userPresenceStore);
