import { uniq } from "lodash-es";
import { debounceTime, Subject } from "rxjs";
import { v4 } from "uuid";

import { AccountSettings } from "@core/interfaces/account";
import { EnrichedMedia } from "@core/interfaces/media";
import { PresenceUser } from "@core/state/user-presence";
import { getTranscribingJob } from "@core/utils/media-functions";
import { secToMs } from "@core/utils/time";
import { MediaSnippet } from "@getsubly/common";
import { mediaPlayerRepository } from "@media-player/index";
import { createStore, withProps } from "@ngneat/elf";

export interface DownloadState {
  showDownloadModal: boolean;
  downloadUrl?: string;
}

export enum SavingStatus {
  Unsaved = "Unsaved changes",
  Saving = "Saving changes...",
  Saved = "All changes saved"
}

export enum SavingFileType {
  Subtitle = "SUBTITLE",
  Config = "CONFIG"
}

export interface Selection {
  start: number;
  end: number;
}

export interface Selections {
  [id: string]: Selection;
}

interface CreateSelectionProps {
  id?: string;
  startTime?: number;
  length?: number;
  useCurrentTime?: boolean;
}

export interface TranscriptionState {
  isTranscribing: boolean;
  startTime: number;
  endTime: number;
  progress: number;
  estimatedDuration: number;
}

type EditorUiStoreProps = {
  savingStatus: SavingStatus;
  download: DownloadState;
  savingIds: string[];
  savingFileTypes: SavingFileType[];
  transcription: TranscriptionState | null;
  selections: Selections;
  hoveredCueId?: string;
  accountSettings?: AccountSettings;
  lockedUsers: PresenceUser[];

  snippets: {
    checkedSnippetsIds: string[];
    selectedSnippet?: MediaSnippet;
    hoveredSnippet?: string;
  };
};

const INITIAL_STATE: EditorUiStoreProps = {
  savingStatus: SavingStatus.Saved,
  download: { showDownloadModal: false },
  savingIds: [],
  savingFileTypes: [],
  transcription: null,
  selections: {},
  hoveredCueId: undefined,
  lockedUsers: [],
  snippets: {
    checkedSnippetsIds: []
  }
};

export const editorUiStore = createStore({ name: "editor-ui" }, withProps<EditorUiStoreProps>(INITIAL_STATE));

class EditorUiStateRepository {
  transcriptionChanged$ = new Subject<true>();
  trackChanges$ = this.transcriptionChanged$.pipe(debounceTime(3000));
  resetTranscriptionEditor$ = new Subject<true>();

  get accountSettings() {
    return editorUiStore.getValue().accountSettings;
  }

  get savingFileTypes() {
    return editorUiStore.getValue().savingFileTypes ?? [];
  }

  get savingIds() {
    return editorUiStore.getValue().savingIds ?? [];
  }

  updateState = (props: Partial<EditorUiStoreProps>) => {
    editorUiStore.update((state) => ({ ...state, ...props }));
  };

  updateSavingState = ({
    savingStatus,
    savingIds = [],
    savingFileTypes = []
  }: {
    savingStatus: SavingStatus;
    savingIds?: string[];
    savingFileTypes?: SavingFileType[];
  }) => {
    editorUiStore.update((state) => ({
      ...state,
      savingStatus,
      savingIds: uniq([...state.savingIds, ...savingIds]),
      savingFileTypes: uniq([...state.savingFileTypes, ...savingFileTypes])
    }));
  };

  updateSelectedSnippet = (snippet?: MediaSnippet) => {
    editorUiStore.update((state) => ({
      ...state,
      snippets: {
        ...state.snippets,
        selectedSnippet: snippet
      }
    }));
  };

  updateCheckedSnippet = (snippetIds: string[] = []) => {
    editorUiStore.update((state) => ({
      ...state,
      snippets: {
        ...state.snippets,
        checkedSnippetsIds: snippetIds
      }
    }));
  };

  updateHoveredSnippet = (snippetId?: string) => {
    editorUiStore.update((state) => ({
      ...state,
      snippets: {
        ...state.snippets,
        hoveredSnippet: snippetId
      }
    }));
  };

  setMediaIsSaved = (): void => {
    const currentSavingStatus = editorUiStore.getValue().savingStatus;

    if (currentSavingStatus === SavingStatus.Unsaved) {
      return;
    }

    this.updateState({
      savingStatus: SavingStatus.Saved,
      savingIds: [],
      savingFileTypes: []
    });
  };

  resetState = () => {
    editorUiStore.reset();
  };

  resetDownload = (): void => {
    this.updateState({
      download: { showDownloadModal: false }
    });
  };

  updateSelectionStart = (snippetId: string, newStart = 0): void => {
    const durationInMs = mediaPlayerRepository.durationInMs;
    editorUiStore.update((state) => {
      const { end } = { ...{ ...state.selections[snippetId] } };
      let start: number;

      if (newStart < 0) {
        start = 0;
      } else if (durationInMs !== -1 && newStart > durationInMs) {
        start = durationInMs;
      } else {
        start = newStart;
      }
      const updatedSelection = { [snippetId]: { start, end } };

      return {
        ...state,
        selections: { ...state.selections, ...updatedSelection }
      };
    });
  };

  updateSelectionEnd = (snippetId: string, newEnd = 0): void => {
    const durationInMs = mediaPlayerRepository.durationInMs;
    editorUiStore.update((state) => {
      const { start } = { ...{ ...state.selections[snippetId] } };
      let end: number;

      if (newEnd < 0) {
        end = 0;
      } else if (durationInMs !== -1 && newEnd > durationInMs) {
        end = durationInMs;
      } else {
        end = newEnd;
      }
      const updatedSelection = { [snippetId]: { start, end } };

      return {
        ...state,
        selections: { ...state.selections, ...updatedSelection }
      };
    });
  };

  clearSelections = (selectionIds?: string[]): void => {
    editorUiStore.update((state) => {
      const updatedSelections: Selections = {};

      if (Array.isArray(selectionIds)) {
        for (const id in state.selections) {
          if (!selectionIds.includes(id)) {
            updatedSelections[id] = state.selections[id];
          }
        }
      }

      return { ...state, selections: updatedSelections };
    });
  };

  createSelection = ({ id, startTime, length, useCurrentTime }: CreateSelectionProps): void => {
    const currentTime = mediaPlayerRepository.currentTime;
    const durationInMs = mediaPlayerRepository.durationInMs;

    editorUiStore.update((state) => {
      const start = useCurrentTime ? secToMs(currentTime) : startTime ? startTime : 0;
      let end = start + secToMs(length ?? 5);
      if (durationInMs !== -1 && end > durationInMs) {
        end = durationInMs;
      }
      const newSelection = { [id || v4()]: { start, end } };
      return {
        ...state,
        selections: { ...state.selections, ...newSelection }
      };
    });
  };

  createSelectionFromSnippet = (snippet: MediaSnippet): void => {
    editorUiStore.update((state) => {
      const {
        id,
        time: { start, end }
      } = snippet;

      const updatedSelections = {
        ...state.selections,
        [id]: { start, end }
      };

      return {
        ...state,
        selections: updatedSelections
      };
    });
  };

  removeSelection = (id: string): void => {
    editorUiStore.update((state) => {
      const updatedSelections = { ...state.selections };
      delete updatedSelections[id];

      return { ...state, selections: updatedSelections };
    });
  };

  setHoveredCueId = (hoveredCueId: string | undefined): void => {
    this.updateState({ hoveredCueId });
  };

  updateTranscriptionProgressState = (media?: EnrichedMedia): void => {
    if (!media) {
      return;
    }

    const startDate = getTranscribingJob(media)?.startDate ?? new Date();
    const startTime = new Date(startDate).getTime();
    const isMediaEnglish = (media.languageCode ?? "en-GB").startsWith("en-");
    const timeRatio = isMediaEnglish ? 0.4 : 0.05;
    const mediaDuration = media.duration ?? 0;
    const buffer = 10; // Add 10s of extra time to the estimated duration
    const estimatedDuration = (mediaDuration * timeRatio + buffer) * 1000;
    const estimatedEnd = startTime + estimatedDuration;
    const currentTimeMs = new Date().getTime();
    const progress = Math.min(((currentTimeMs - startTime) / estimatedDuration) * 100, 100);

    this.updateState({
      transcription: {
        isTranscribing: true,
        startTime,
        endTime: estimatedEnd,
        progress,
        estimatedDuration
      }
    });
  };
}

export const editorUiStateRepository = new EditorUiStateRepository();
