import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Button } from "@components/button";
import { Modal } from "@components/modal";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { App } from "@core/interfaces/app";

import { Divider } from "../divider/divider";

import styles from "./app-modal.module.scss";

interface AppModalProps {
  app: App;
  closeModal: () => void;
}
export const AppModal: React.FC<AppModalProps> = ({ app, closeModal }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const onDismiss = () => {
    trackEventWithAuth(`${app.name} / Cancel`);
    closeModal();
  };

  const onMainCta = () => {
    trackEventWithAuth(`${app.name} / Connect`);
    closeModal();
    if (app.modal.openNewTab) {
      window.open(app.modal.link, "_blank");
    } else {
      navigate(app.modal.link);
    }
  };

  return (
    <Modal onDismiss={onDismiss} className={styles["modal"]}>
      <AppModalContainer app={app} onConnect={onMainCta} onDismiss={onDismiss} />
    </Modal>
  );
};

interface AppModalContainerProps {
  app: App;
  onConnect: () => void;
  onDismiss: () => void;
}

export const AppModalContainer: React.FC<AppModalContainerProps> = ({ app, onConnect, onDismiss }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {app.icon}
        <h5 className={classNames(styles.title, "tw-mb-0 tw-ml-6")}>{app.modal.title}</h5>
      </div>
      <Divider className={styles.divider} />
      <p className={styles.description}>{app.modal.description}</p>
      {app.modal.screenshot && (
        <img src={app.modal.screenshot} className={styles.screenshot} alt={"screenshot"} loading="lazy" />
      )}
      <div className="tw-mt-6 tw-flex tw-w-full tw-justify-end">
        <Button variant="secondary" onClick={onDismiss} className={classNames("tw-mr-4", styles.button)} size="40">
          Cancel
        </Button>
        <Button variant="primary" onClick={onConnect} className={styles.button} size="40">
          {app.modal.actionText ? app.modal.actionText : `Connect ${app.name} app`}
        </Button>
      </div>
    </div>
  );
};
