import React from "react";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { deleteInvitation, resendInvitation, updateInvitationRole } from "@frontend/api/invitation.service";
import { deleteMember, updateMemberRole } from "@frontend/api/members.service";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import { RoleNameDropdown } from "@frontend/containers/onboarding/forms/role-name-dropdown";

import { Avatar } from "@components/avatar/avatar";
import { Button, IconButton } from "@components/button";
import { EyeSolidIcon as EyeIcon, TrashIcon, UserPlusIcon, UserSolidIcon as UserIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon";
import { Table } from "@components/table";
import { TableColumn } from "@components/table/table.context";

import { useAccountMembers } from "@core/hooks/use-account-members";
import { useAccounts } from "@core/hooks/use-accounts";
import { AccountMember, Invitation } from "@core/interfaces/account";
import { RoleName } from "@getsubly/common";

import { InviteUserRow } from "./invite-user-row";

export enum MemberColumnId {
  AVATAR = "avatar",
  NAME = "name",
  ROLE = "role",
  ACTIVE_SINCE = "date",
  SEATS = "seats"
}

interface MembersTableProps {
  members?: AccountMember[];
  invitations?: Invitation[];
  isLoading?: boolean;
}

export const MembersTable: React.FC<MembersTableProps> = ({ members = [], invitations = [], isLoading = true }) => {
  const { currentAccount, isAdmin } = useAccounts();
  const { availableSeats } = useAccountMembers();

  invitations = isAdmin ? invitations : [];

  const hasAvailableSeats = availableSeats > 0;

  if (isLoading) {
    return <LoadingMembersTable />;
  }

  const columns: TableColumn[] = [
    { id: MemberColumnId.AVATAR, label: "", width: "auto" },
    { id: MemberColumnId.NAME, label: "Name", width: "1fr", className: "!tw-pl-0 tw-font-semibold" },
    { id: MemberColumnId.ROLE, label: "Role", width: "1fr", className: 'tw-font-semibold' }, // eslint-disable-line
    { id: MemberColumnId.ACTIVE_SINCE,label: "Active Since",  width: "1fr" ,className: 'tw-text-sm tw-font-semibold tw-w-full tw-whitespace-nowrap'} // eslint-disable-line
  ];

  if (isAdmin) {
    columns.push({ label: "Seats", id: MemberColumnId.SEATS, width: 210, className: "tw-text-sm tw-font-semibold" });
  }

  return (
    <Table
      columns={columns}
      items={members}
      className="tw-rounded-none tw-border-0 tw-text-xs tw-font-medium tw-text-neutral-900"
    >
      <Table.Header>
        {(cols) =>
          cols.map((col, i) => (
            <Table.HeaderCell key={i} columnId={col.id} className={col.className}>
              {col.id === "role" && <UserIcon className="tw-mr-1 tw-h-4 tw-w-4" />}
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      {isAdmin ? <InviteUserRow hasAvailableSeats={hasAvailableSeats} /> : null}
      {members.map((m) => (
        <MembersTableRow
          key={m.id}
          member={m}
          hasAvailableSeats={hasAvailableSeats}
          isAdmin={isAdmin}
          isOwner={m.id === currentAccount?.owner}
        />
      ))}
      {invitations.map((i) => (
        <InvitationTableRow invitation={i} key={i.id} hasAvailableSeats={hasAvailableSeats} isAdmin={isAdmin} />
      ))}
    </Table>
  );
};

export const LoadingMembersTable: React.FC = () => {
  return (
    <Table columns={[]} items={[]} className="tw-rounded-none tw-border-l-0 tw-border-r-0">
      <Table.Row className="tw-p-3">
        <Skeleton width="100%" />
      </Table.Row>
      <Table.Row className="tw-p-3">
        <Skeleton width="100%" />
      </Table.Row>
      <Table.Row className="tw-p-3">
        <Skeleton width="100%" />
      </Table.Row>
      <Table.Row className="tw-p-3">
        <Skeleton width="100%" />
      </Table.Row>
    </Table>
  );
};

interface MembersTableRowProps {
  member: AccountMember;
  hasAvailableSeats?: boolean;
  isAdmin: boolean;
  isOwner?: boolean;
}

const MembersTableRow: React.FC<MembersTableRowProps> = ({ member, hasAvailableSeats, isAdmin, isOwner }) => {
  return (
    <Table.Row className="tw-min-h-[66px] odd:tw-bg-neutral-50">
      <Table.Cell columnId={MemberColumnId.AVATAR} className="tw-w-[56px] tw-max-w-[56px] !tw-p-3">
        <Avatar
          initials={getInitials(member.name, true, false)}
          size="32"
          className="tw-text-xs"
          image={member.picturePublic}
          isUseSkeleton={false}
          withBorder={false}
          color="#000"
        />
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.NAME} className="!tw-pl-0">
        <p className="tw-text-sm tw-font-semibold tw-text-neutral-900">{member.name}</p>
        <p className="tw-text-xs tw-font-medium !tw-text-neutral-600">{member.email}</p>
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.ROLE}>
        <MemberRoleCell user={member} type="member" hasAvailableSeats={hasAvailableSeats} />
      </Table.Cell>
      <Table.Cell
        columnId={MemberColumnId.ACTIVE_SINCE}
        className="tw-whitespace-nowrap tw-text-xs tw-font-medium tw-text-neutral-900"
      >
        {format(new Date(member.createdAt), "PPP")}
      </Table.Cell>
      {isAdmin ? (
        <Table.Cell columnId={MemberColumnId.SEATS} className="!tw-p-3 tw-text-right">
          <MemberSeatOptions user={member} isOwner={isOwner} hasAvailableSeats={hasAvailableSeats} />
        </Table.Cell>
      ) : null}
    </Table.Row>
  );
};
interface InvitationTableRowProps {
  invitation: Invitation;
  hasAvailableSeats: boolean;
  isAdmin: boolean;
}
const InvitationTableRow: React.FC<InvitationTableRowProps> = ({ invitation, hasAvailableSeats, isAdmin }) => {
  return (
    <Table.Row className="tw-min-h-[66px] odd:tw-bg-neutral-50">
      <Table.Cell columnId={MemberColumnId.AVATAR} className="tw-w-[56px] tw-max-w-[56px] !tw-p-3">
        <Avatar
          initials={getInitials(invitation.email[0], true, false)}
          size="32"
          className="tw-text-xs"
          image={""}
          isUseSkeleton={false}
          withBorder={false}
          color="#000"
        />
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.NAME} className="!tw-pl-0 tw-text-xs tw-font-medium !tw-text-neutral-600">
        {invitation.email}
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.ROLE}>
        <MemberRoleCell user={invitation} type="invitation" hasAvailableSeats={hasAvailableSeats} />
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.ACTIVE_SINCE}>
        <em className="tw-text-xs tw-font-medium tw-text-neutral-400">Invited</em>
      </Table.Cell>
      {isAdmin ? (
        <Table.Cell columnId={MemberColumnId.SEATS} className="!tw-p-3 tw-text-right">
          <InviteSeatOptions user={invitation} />
        </Table.Cell>
      ) : null}
    </Table.Row>
  );
};

interface MemberRoleCellProps {
  user: AccountMember | Invitation;
  type: "member" | "invitation";
  hasAvailableSeats?: boolean;
}

const MemberRoleCell: React.FC<MemberRoleCellProps> = ({ user, type, hasAvailableSeats }) => {
  const [isLoading, setLoading] = React.useState(false);
  const { currentAccount, isAdmin } = useAccounts();

  const isOwner = user.id === currentAccount?.owner;

  const icon = user.role === RoleName.Viewer ? <EyeIcon width="16px" /> : <UserIcon width="16px" />;

  if (isOwner || !isAdmin) {
    const role = isOwner ? "Owner" : roleToText(user.role);
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-3 tw-py-2">
        {icon}
        <p className="tw-text-xs tw-font-medium">{role}</p>
      </div>
    );
  }

  const onChange = async (role: RoleName) => {
    setLoading(true);

    if (type === "member") {
      await updateMemberRole(user.id, role);
    } else if (type === "invitation") {
      await updateInvitationRole(user.id, role);
    }

    setLoading(false);
  };

  if (isLoading) {
    return <LoadingIcon className="tw-mr-2" />;
  }

  const occupiesSeat = user.role === RoleName.Editor || user.role === RoleName.Admin;

  return (
    <div className="tw-flex tw-items-center">
      <RoleNameDropdown
        value={user.role}
        buttonClassName="!tw-w-[160px] tw-text-xs tw-font-medium"
        canSelectAdmin={hasAvailableSeats || occupiesSeat}
        canSelectManager={hasAvailableSeats || occupiesSeat}
        canSelectEditor={hasAvailableSeats || occupiesSeat}
        onChange={onChange}
      />
    </div>
  );
};

const roleToText = (role: RoleName): string => {
  if (role === RoleName.Admin) {
    return "Admin";
  }

  return role;
};

interface MemberSeatOptionsProps {
  user: AccountMember;
  isOwner?: boolean;
  hasAvailableSeats?: boolean;
}

const MemberSeatOptions: React.FC<MemberSeatOptionsProps> = ({ user, isOwner, hasAvailableSeats }) => {
  const [isLoading, setLoading] = React.useState(false);
  const showAssignSeatButton = user.role === RoleName.Viewer;
  const showRemoveMemberButton = !isOwner;

  if (isOwner) {
    return (
      <em className="tw-ml-auto tw-whitespace-nowrap tw-text-xs tw-font-medium tw-text-neutral-400">
        Owners must have a seat
      </em>
    );
  }

  const onClickAssignSeat = async () => {
    setLoading(true);
    await updateMemberRole(user.id, RoleName.Editor);
    setLoading(false);
  };

  return (
    <div className="tw-flex tw-justify-end tw-gap-2">
      {showAssignSeatButton ? (
        <Button
          variant="secondary"
          className="tw-ml-auto tw-w-fit tw-whitespace-nowrap tw-px-2"
          onClick={onClickAssignSeat}
          loading={isLoading}
          disabled={!hasAvailableSeats}
          size="36"
          icon={<UserPlusIcon className="!tw-h-5 !tw-w-5" />}
        >
          Assign seat
        </Button>
      ) : null}
      {showRemoveMemberButton ? <DeleteMember user={user} /> : null}
    </div>
  );
};

interface InviteSeatOptionsProps {
  user: AccountMember | Invitation;
}

const InviteSeatOptions: React.FC<InviteSeatOptionsProps> = ({ user }) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleResend = async () => {
    setLoading(true);

    await resendInvitation(user.id);

    setLoading(false);
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-text-xs tw-font-medium">
      {isLoading ? (
        <LoadingIcon className="tw-mr-2" />
      ) : (
        <Link to="#" className="tw-text-neutral-900 tw-underline" onClick={handleResend}>
          Resend invite
        </Link>
      )}
      <span className="tw-mx-2 tw-text-neutral-600">|</span>
      <DeleteInvitation user={user} />
    </div>
  );
};

interface DeleteInvitationProps {
  user: Invitation;
}
const DeleteInvitation: React.FC<DeleteInvitationProps> = ({ user }) => {
  const [isLoading, setLoading] = React.useState(false);

  const onDelete = async () => {
    if (confirm("Are you sure you want to delete this invite?")) {
      setLoading(true);
      await deleteInvitation(user.id);
      setLoading(false);
    }
  };

  return (
    <button onClick={onDelete} className="tw-inline-flex tw-h-4 tw-w-4" disabled={isLoading}>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <TrashIcon className="tw-h-4 tw-w-4 tw-text-neutral-900 hover:tw-text-destructive-400" strokeWidth="2" />
      )}
    </button>
  );
};

interface DeleteMemberProps {
  user: AccountMember;
}
const DeleteMember: React.FC<DeleteMemberProps> = ({ user }) => {
  const [isLoading, setLoading] = React.useState(false);

  const onDelete = async () => {
    if (confirm("Are you sure you want to remove this member?")) {
      setLoading(true);
      await deleteMember(user.id);
      setLoading(false);
    }
  };

  return (
    <IconButton
      variant="secondary"
      className="tw-group hover:tw-border-destructive-600 hover:tw-bg-destructive-100"
      onClick={onDelete}
      loading={isLoading}
      icon={
        <TrashIcon
          className="!tw-h-4 !tw-w-4 tw-text-neutral-900 group-hover:tw-text-destructive-600"
          strokeWidth="2"
        />
      }
      size="36"
    />
  );
};
