import React from "react";
import classNames from "classnames";

import { ToolbarSeparator } from "@frontend/components/toolbar-separator/toolbar-separator";
import config from "@frontend/config";
import { DeleteMediaModal } from "@frontend/containers/modals/delete-media-modal";
import { MoveMediaModal } from "@frontend/containers/modals/move-media-modal";
import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import { Button } from "@components/button";
import { CloseIcon, DropdownFolderIcon as FolderIcon, TrashCanOutlinePauseIcon } from "@components/icons";
import { NewBadge } from "@components/new-badge/new-badge";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { ModalType } from "@core/interfaces/modal-type";
import { ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

import styles from "./file-action-toolbar.module.scss";

export interface DashboardFileActionToolBarProps {
  selectedFiles: string[];
  handleDeselectAllTheFiles: () => void;
  onSelectAll: () => void;
  onMakeAccessible: () => void;
}

export const DashboardFileActionToolBar: React.FC<DashboardFileActionToolBarProps> = ({
  selectedFiles,
  handleDeselectAllTheFiles,
  onSelectAll,
  onMakeAccessible
}) => {
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);
  const { currentAccount } = useAccounts();
  const { permissions: mediaPermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);

  const canDeleteMedia = mediaPermissions.Delete;
  const canMoveMedia = mediaPermissions.Move;

  const handleCloseMoveModal = (shouldClear?: boolean) => {
    hideMoveModal();

    if (shouldClear) {
      handleDeselectAllTheFiles();
    }
  };

  const handleCloseDeleteModal = (shouldClear?: boolean) => {
    hideDeleteModal();

    if (shouldClear) {
      handleDeselectAllTheFiles();
    }
  };

  return (
    <div
      className={classNames(styles.toolbar, {
        [styles["toolbar--show"]]: selectedFiles.length
      })}
    >
      <div className={styles["toolbar-contents"]}>
        <div className={styles["selected-container"]}>
          <p>Selected</p>
          <div className={styles.badge}>{selectedFiles.length}</div>
        </div>
        <ToolbarSeparator className={classNames(styles.separator, "tw-min-w-[1px]")} />
        <div className={styles.actions}>
          {canMoveMedia && (
            <ToolTip place="top" text="Move selected files" className={styles.tooltip}>
              <div>
                <Button
                  variant="ghost"
                  className={styles.actionButton}
                  onClick={() => {
                    showMoveModal(<MoveMediaModal mediaIds={selectedFiles} closeModal={handleCloseMoveModal} />);
                  }}
                  size="36"
                >
                  <FolderIcon />
                </Button>
              </div>
            </ToolTip>
          )}

          {canDeleteMedia && (
            <ToolTip place="top" text="Delete selected files" className={styles.tooltip}>
              <div>
                <Button
                  variant="ghost"
                  className={styles.actionButton}
                  onClick={() => {
                    showDeleteModal(<DeleteMediaModal mediaIds={selectedFiles} closeModal={handleCloseDeleteModal} />);
                  }}
                  size="36"
                >
                  <TrashCanOutlinePauseIcon />
                </Button>
              </div>
            </ToolTip>
          )}

          <ToolTip place="top" text="Select all files" className={styles.tooltip}>
            <div>
              <Button variant="ghost" className={styles.actionButton} onClick={onSelectAll} size="40">
                Select all
              </Button>
            </div>
          </ToolTip>

          {config.features.accessibilityFlow && (
            <ToolTip place="top" text="Make files accessible" className={styles.tooltip}>
              <div>
                <Button
                  variant="secondary"
                  className={classNames("hover:!tw-text-neutral-700")}
                  onClick={onMakeAccessible}
                  size="40"
                >
                  Make it accessible <NewBadge variant="aux-soft" label="BETA" />
                </Button>
              </div>
            </ToolTip>
          )}
        </div>
      </div>
      <ToolTip text="Deselect all files" className={styles.tooltip}>
        <Button variant="ghost" className={styles.actionButton} onClick={() => handleDeselectAllTheFiles()} size="36">
          <CloseIcon />
        </Button>
      </ToolTip>
    </div>
  );
};
