import React from "react";

import { usePlan } from "@core/hooks/use-plan";

import { Alert } from "./alert";

interface LegacyPlanAlertProps {
  hasClose?: boolean;
  className?: string;
  children?: React.ReactNode;
}
export const LegacyPlanAlert: React.FC<LegacyPlanAlertProps> = ({
  hasClose = false,
  className,
  children = "You are currently subscribed to a legacy plan, choose your new plan below. With any new plan you can add seats and easily switch to the full-feature Business plan too. Switch now and we will transfer all unused minutes to your new plan."
}) => {
  const { planVersion, isTrial } = usePlan();
  const [hidden, setHidden] = React.useState(false);

  if (planVersion !== "2020-09-21" || isTrial || hidden) {
    return null;
  }

  return (
    <Alert warning closable={hasClose} className={className} onClose={() => setHidden(true)}>
      <span className="tw-text-sm">{children}</span>
    </Alert>
  );
};
