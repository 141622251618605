import { formatTimestamp } from "subtitle";

export const format = (time: number, useOverHourTimestamp?: boolean): string => {
  const srtTime = formatTimestamp(Math.floor(time), { format: "SRT" });
  const [hoursMinsSeconds, milliseconds] = srtTime.split(",");
  return useOverHourTimestamp
    ? `${hoursMinsSeconds}.${milliseconds.slice(0, 2)}`
    : `${hoursMinsSeconds.substring(3)}.${milliseconds.slice(0, 2)}`;
};

interface Time {
  hours: string;
  minutes: string;
  seconds: string;
  milliseconds: string;
}
export const getTimeObject = (timeInSeconds: number): Time => {
  const result = new Date(timeInSeconds * 1000).toISOString().slice(11, 23);

  return {
    hours: result.slice(0, 2),
    minutes: result.slice(3, 5),
    seconds: result.slice(6, 8),
    milliseconds: result.slice(9, 12)
  };
};
