import React from "react";
import { Outlet } from "react-router-dom";

export const OneDriveLayout: React.FC = () => {
  // const { queryParams } = useQuery<{ token: string; state: string }>();

  return (
    <>
      <div className="tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center tw-bg-neutral-50">
        <Outlet />
      </div>
    </>
  );
};
