import axios from "axios";

import config from "@frontend/config";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1/zendesk`;

export interface ZendeskTicketData {
  subject: string;
  body: string;
  requester: {
    name?: string;
    email?: string;
  };
}

export const createZendeskTicket = async ({
  subject,
  body,
  requester
}: ZendeskTicketData): Promise<number | undefined> => {
  try {
    const { status } = await axios.post(
      `/tickets`,
      { subject, body, requester },
      {
        baseURL,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": await getAccessToken()
        }
      }
    );
    return status;
  } catch (e) {
    handleError(e);
  }
};
