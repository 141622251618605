export const AllCombinedOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3189_478022)">
      <path opacity="0.6" d="M1080 0H0V267H1080V0Z" fill="black" />
      <path opacity="0.6" d="M1080 272H0" stroke="white" strokeWidth="12" strokeLinejoin="round" />
      <path
        opacity="0.6"
        d="M768.04 112.58H764.34L759.69 105.86C757.89 103.26 754.93 101.7 751.76 101.7H740.46C737.29 101.7 734.32 103.26 732.52 105.87L727.9 112.58H721.94C716.44 112.58 711.99 117.03 711.99 122.53V155.35C711.99 160.84 716.44 165.3 721.94 165.3H768.03C773.53 165.3 777.98 160.85 777.98 155.35V122.53C777.98 117.04 773.53 112.58 768.03 112.58H768.04ZM744.99 151.74C736.35 151.74 729.35 144.74 729.35 136.1C729.35 127.46 736.35 120.46 744.99 120.46C753.63 120.46 760.63 127.46 760.63 136.1C760.63 144.74 753.63 151.74 744.99 151.74Z"
        fill="white"
        stroke="white"
        strokeWidth="5.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.6">
        <path
          d="M1006.5 116.58C1011.35 116.58 1015.29 112.645 1015.29 107.79C1015.29 102.935 1011.35 99 1006.5 99C1001.65 99 997.71 102.935 997.71 107.79C997.71 112.645 1001.65 116.58 1006.5 116.58Z"
          fill="white"
        />
        <path
          d="M1006.5 142.29C1011.35 142.29 1015.29 138.355 1015.29 133.5C1015.29 128.645 1011.35 124.71 1006.5 124.71C1001.65 124.71 997.71 128.645 997.71 133.5C997.71 138.355 1001.65 142.29 1006.5 142.29Z"
          fill="white"
        />
        <path
          d="M1006.5 168C1011.35 168 1015.29 164.065 1015.29 159.21C1015.29 154.355 1011.35 150.42 1006.5 150.42C1001.65 150.42 997.71 154.355 997.71 159.21C997.71 164.065 1001.65 168 1006.5 168Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.6"
        d="M603.25 112.75H235.89C224.43 112.75 215.14 122.04 215.14 133.5C215.14 144.96 224.43 154.25 235.89 154.25H603.25C614.71 154.25 624 144.96 624 133.5C624 122.04 614.71 112.75 603.25 112.75Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M117.5 184C145.39 184 168 161.39 168 133.5C168 105.61 145.39 83 117.5 83C89.6096 83 67 105.61 67 133.5C67 161.39 89.6096 184 117.5 184Z"
        fill="white"
      />
      <g opacity="0.6">
        <path
          d="M883 157C897.912 157 910 144.912 910 130C910 115.088 897.912 103 883 103C868.088 103 856 115.088 856 130C856 144.912 868.088 157 883 157Z"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
        />
        <path
          d="M903.04 148.09L919.47 164.53"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        opacity="0.6"
        d="M0.00976562 1396.62H815.97C834.76 1396.62 849.99 1381.32 849.99 1362.44V718.58C849.99 668.07 890.75 627.12 941.03 627.12H1080.01V1920.01H0.00976562V1396.63V1396.62Z"
        fill="black"
      />
      <path
        opacity="0.6"
        d="M0.00976562 1390.59H813.58C831 1391.59 844 1379.34 844 1362.46V717.01C844 663.98 886.78 621 939.57 621H1080.01"
        stroke="white"
        strokeWidth="12.03"
        strokeLinejoin="bevel"
      />
      <path
        opacity="0.6"
        d="M84.5 1544C111.286 1544 133 1522.29 133 1495.5C133 1468.71 111.286 1447 84.5 1447C57.7142 1447 36 1468.71 36 1495.5C36 1522.29 57.7142 1544 84.5 1544Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M533.67 1474.6H177.29C173.242 1474.6 169.96 1477.88 169.96 1481.93V1509.74C169.96 1513.79 173.242 1517.07 177.29 1517.07H533.67C537.718 1517.07 541 1513.79 541 1509.74V1481.93C541 1477.88 537.718 1474.6 533.67 1474.6Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M766.73 1455.71H610.17C603.946 1455.71 598.9 1460.76 598.9 1466.98V1523.73C598.9 1529.95 603.946 1535 610.17 1535H766.73C772.954 1535 778 1529.95 778 1523.73V1466.98C778 1460.76 772.954 1455.71 766.73 1455.71Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M1005.66 1742.5H950.34C938.83 1742.5 929.5 1751.83 929.5 1763.34V1818.66C929.5 1830.17 938.83 1839.5 950.34 1839.5H1005.66C1017.17 1839.5 1026.5 1830.17 1026.5 1818.66V1763.34C1026.5 1751.83 1017.17 1742.5 1005.66 1742.5Z"
        fill="white"
        stroke="white"
        strokeWidth="6.76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.6">
        <path
          d="M978.07 940.59C978.07 940.59 980.86 932.22 994.81 928.04C1014.34 925.25 1022.65 940.59 1022.65 940.59C1022.65 940.59 1029.68 951.75 1025.04 964.83C1023.95 967.9 1022.21 970.7 1019.94 973.04C1009.83 983.48 978.08 1012.81 978.08 1012.81"
          fill="white"
        />
        <path
          d="M977.93 1012.82C977.93 1012.82 946.17 983.49 936.07 973.05C933.8 970.71 932.06 967.91 930.97 964.84C926.32 951.76 933.36 940.6 933.36 940.6C933.36 940.6 941.67 925.26 961.2 928.05C975.15 932.23 977.94 940.6 977.94 940.6"
          fill="white"
        />
      </g>
      <path
        opacity="0.6"
        d="M978 840C1016.94 840 1048.5 808.436 1048.5 769.5C1048.5 730.564 1016.94 699 978 699C939.064 699 907.5 730.564 907.5 769.5C907.5 808.436 939.064 840 978 840Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M943.61 1338.07V1265.67C943.61 1264.19 944.81 1263 946.28 1263H1009.71C1011.19 1263 1012.38 1264.2 1012.38 1265.67V1338.07C1012.38 1340.18 1010.05 1341.46 1008.28 1340.33L978 1321.1L947.72 1340.33C945.94 1341.46 943.62 1340.18 943.62 1338.07H943.61Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M930.63 1503C930.63 1503 921.43 1448.92 982.41 1446.62V1431.76C982.41 1429.37 985.25 1428.11 987.02 1429.72L1024.97 1464.09C1026.2 1465.2 1026.17 1467.14 1024.92 1468.22L989.14 1499.07C987.35 1500.61 984.58 1499.34 984.58 1496.98V1485.74C984.58 1485.74 943.29 1477.69 930.63 1503Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M1018.58 1151C1021.72 1145.63 1023.5 1139.57 1023.5 1133.14C1023.5 1111.32 1003.13 1093.64 978 1093.64C952.87 1093.64 932.5 1111.32 932.5 1133.14C932.5 1154.96 952.87 1172.64 978 1172.64V1180.5C978 1181.92 979.42 1182.9 980.75 1182.39C987.79 1179.67 1006.7 1170.92 1017.99 1152C1018.1 1151.83 1018.19 1151.65 1018.3 1151.48C1018.39 1151.32 1018.49 1151.17 1018.58 1151Z"
        fill="white"
      />
      <g opacity="0.6">
        <path
          d="M978.19 1608.97C983.044 1608.97 986.98 1605.03 986.98 1600.18C986.98 1595.33 983.044 1591.39 978.19 1591.39C973.335 1591.39 969.4 1595.33 969.4 1600.18C969.4 1605.03 973.335 1608.97 978.19 1608.97Z"
          fill="white"
        />
        <path
          d="M978.19 1634.68C983.044 1634.68 986.98 1630.74 986.98 1625.89C986.98 1621.04 983.044 1617.1 978.19 1617.1C973.335 1617.1 969.4 1621.04 969.4 1625.89C969.4 1630.74 973.335 1634.68 978.19 1634.68Z"
          fill="white"
        />
        <path
          d="M978.19 1660.38C983.044 1660.38 986.98 1656.44 986.98 1651.59C986.98 1646.74 983.044 1642.8 978.19 1642.8C973.335 1642.8 969.4 1646.74 969.4 1651.59C969.4 1656.44 973.335 1660.38 978.19 1660.38Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.6"
        d="M753.4 1584.99H43.0199C38.9717 1584.99 35.6899 1588.27 35.6899 1592.32V1620.13C35.6899 1624.18 38.9717 1627.46 43.0199 1627.46H753.4C757.448 1627.46 760.73 1624.18 760.73 1620.13V1592.32C760.73 1588.27 757.448 1584.99 753.4 1584.99Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M421.4 1668.75H43.0199C38.9717 1668.75 35.6899 1672.03 35.6899 1676.08V1703.89C35.6899 1707.94 38.9717 1711.22 43.0199 1711.22H421.4C425.448 1711.22 428.73 1707.94 428.73 1703.89V1676.08C428.73 1672.03 425.448 1668.75 421.4 1668.75Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M645.74 1752.26H43.3598C39.3115 1752.26 36.0298 1755.54 36.0298 1759.59V1787.4C36.0298 1791.45 39.3115 1794.73 43.3598 1794.73H645.74C649.788 1794.73 653.07 1791.45 653.07 1787.4V1759.59C653.07 1755.54 649.788 1752.26 645.74 1752.26Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M857.54 1849H39.92C37.4568 1849 35.46 1851 35.46 1853.46V1853.47C35.46 1855.93 37.4568 1857.93 39.92 1857.93H857.54C860.003 1857.93 862 1855.93 862 1853.47V1853.46C862 1851 860.003 1849 857.54 1849Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3189_478022">
        <rect width="1080" height="1920" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FacebookReelOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3122_478278)">
      <path
        opacity="0.7"
        d="M0.419922 1493.36H850.4V1419.4C850.4 1369.12 891.16 1328.36 941.44 1328.36H1080.42V1920H0.419922V1493.36Z"
        fill="black"
      />
      <path
        opacity="0.7"
        d="M0.419922 1487.36H844.4V1417.85C844.4 1365.06 887.19 1322.27 939.98 1322.27H1080.42"
        stroke="white"
        strokeWidth="12"
        strokeLinejoin="bevel"
      />
      <path
        opacity="0.7"
        d="M136.5 1640C163.286 1640 185 1618.29 185 1591.5C185 1564.71 163.286 1543 136.5 1543C109.714 1543 88 1564.71 88 1591.5C88 1618.29 109.714 1640 136.5 1640Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M533 1566.6H208.96C205.094 1566.6 201.96 1569.73 201.96 1573.6V1602.07C201.96 1605.94 205.094 1609.07 208.96 1609.07H533C536.866 1609.07 540 1605.94 540 1602.07V1573.6C540 1569.73 536.866 1566.6 533 1566.6Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M745.73 1543.71H589.17C582.946 1543.71 577.9 1548.76 577.9 1554.98V1611.73C577.9 1617.95 582.946 1623 589.17 1623H745.73C751.954 1623 757 1617.95 757 1611.73V1554.98C757 1548.76 751.954 1543.71 745.73 1543.71Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M841 1685.66H78C74.134 1685.66 71 1688.79 71 1692.66V1721.13C71 1725 74.134 1728.13 78 1728.13H841C844.866 1728.13 848 1725 848 1721.13V1692.66C848 1688.79 844.866 1685.66 841 1685.66Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M477 1771H123.41C94.6911 1771 71.4099 1794.28 71.4099 1823C71.4099 1851.72 94.6911 1875 123.41 1875H477C505.719 1875 529 1851.72 529 1823C529 1794.28 505.719 1771 477 1771Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M916.25 1410.37V1441.47C916.25 1443.33 917.76 1444.84 919.62 1444.84H930.61C932.47 1444.84 933.98 1443.33 933.98 1441.47V1410.37C933.98 1408.51 932.47 1407 930.61 1407H919.62C917.76 1407 916.25 1408.51 916.25 1410.37Z"
          fill="white"
        />
        <path
          d="M933.98 1413.43C933.98 1413.43 937.75 1412.5 939.75 1409.5C940.86 1407.83 942.59 1404.62 943.91 1402.1C945.12 1399.78 945.75 1397.2 945.75 1394.58V1389.17C945.75 1387.52 946.62 1385.98 948.04 1385.13C949.68 1384.15 951.76 1384.25 953.29 1385.4C956.73 1387.98 958.76 1392.03 958.76 1396.34V1402.67C958.76 1403.23 959.22 1403.69 959.78 1403.68L971.29 1403.56C972.93 1403.54 974.56 1403.8 976.12 1404.31L976.65 1404.48C978.57 1405.11 979.98 1406.76 980.31 1408.75C980.6 1410.48 980.03 1412.24 978.79 1413.48L977.77 1414.5L978.69 1414.96C980.58 1415.9 981.77 1417.83 981.77 1419.94C981.77 1422.62 979.85 1424.93 977.22 1425.42L978.38 1426.82C979.26 1427.89 979.62 1429.3 979.35 1430.65C979 1432.39 977.68 1433.78 975.96 1434.21L974.78 1434.5L974.99 1434.81C977 1437.82 975.88 1441.98 972.57 1443.45C972.52 1443.47 972.48 1443.49 972.43 1443.51C971.35 1444.16 970.12 1444.51 968.86 1444.52L947.9 1444.76C943.69 1444.81 939.61 1443.27 936.48 1440.46L934.01 1438.24"
          fill="white"
        />
      </g>
      <path
        opacity="0.7"
        d="M965.69 1530H932.31C924.99 1530 919.06 1535.93 919.06 1543.25V1564.87C919.06 1572.19 924.99 1578.12 932.31 1578.12H932.43V1589.5C932.43 1590.75 933.86 1591.46 934.85 1590.69L951.22 1578.12H965.69C973.01 1578.12 978.94 1572.19 978.94 1564.87V1543.25C978.94 1535.93 973.01 1530 965.69 1530Z"
        fill="white"
        stroke="white"
        strokeWidth="1.07"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.7"
        d="M915.35 1728.7C915.35 1728.7 908.81 1690.29 952.13 1688.66V1678.11C952.13 1676.41 954.14 1675.52 955.4 1676.66L982.36 1701.07C983.23 1701.86 983.22 1703.24 982.32 1704L956.91 1725.91C955.64 1727 953.67 1726.1 953.67 1724.43V1716.45C953.67 1716.45 924.34 1710.73 915.35 1728.71V1728.7Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M927 1836C931.142 1836 934.5 1832.64 934.5 1828.5C934.5 1824.36 931.142 1821 927 1821C922.858 1821 919.5 1824.36 919.5 1828.5C919.5 1832.64 922.858 1836 927 1836Z"
          fill="white"
        />
        <path
          d="M971 1836C975.142 1836 978.5 1832.64 978.5 1828.5C978.5 1824.36 975.142 1821 971 1821C966.858 1821 963.5 1824.36 963.5 1828.5C963.5 1832.64 966.858 1836 971 1836Z"
          fill="white"
        />
        <path
          d="M949 1836C953.142 1836 956.5 1832.64 956.5 1828.5C956.5 1824.36 953.142 1821 949 1821C944.858 1821 941.5 1824.36 941.5 1828.5C941.5 1832.64 944.858 1836 949 1836Z"
          fill="white"
        />
      </g>
      <path opacity="0.7" d="M1080.5 0H0V256.5H1080.5V0Z" fill="black" />
      <path
        opacity="0.7"
        d="M136.16 115H80.84C69.3304 115 60 124.33 60 135.84V191.16C60 202.67 69.3304 212 80.84 212H136.16C147.67 212 157 202.67 157 191.16V135.84C157 124.33 147.67 115 136.16 115Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M587.72 149.99H585.39L582.46 145.75C581.32 144.11 579.45 143.13 577.46 143.13H570.33C568.33 143.13 566.46 144.11 565.32 145.76L562.4 149.99H558.64C555.17 149.99 552.36 152.8 552.36 156.27V176.98C552.36 180.45 555.17 183.26 558.64 183.26H587.73C591.2 183.26 594.01 180.45 594.01 176.98V156.27C594.01 152.8 591.2 149.99 587.73 149.99H587.72ZM573.18 174.7C567.73 174.7 563.31 170.28 563.31 164.83C563.31 159.38 567.73 154.96 573.18 154.96C578.63 154.96 583.05 159.38 583.05 164.83C583.05 170.28 578.63 174.7 573.18 174.7Z"
        fill="white"
        stroke="white"
        strokeWidth="3.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.7">
        <path
          d="M834.97 181.94C847.656 181.94 857.94 171.656 857.94 158.97C857.94 146.284 847.656 136 834.97 136C822.284 136 812 146.284 812 158.97C812 171.656 822.284 181.94 834.97 181.94Z"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
        />
        <path
          d="M852.02 174.36L866 188.34"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        opacity="0.7"
        d="M967 196C985.225 196 1000 181.225 1000 163C1000 144.775 985.225 130 967 130C948.775 130 934 144.775 934 163C934 181.225 948.775 196 967 196Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M718.76 143.94H637.77C626.039 143.94 616.53 153.447 616.53 165.175C616.53 176.903 626.039 186.41 637.77 186.41H718.76C730.491 186.41 740 176.903 740 165.175C740 153.447 730.491 143.94 718.76 143.94Z"
        fill="white"
      />
      <path opacity="0.7" d="M1080 262H0" stroke="white" strokeWidth="12" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_3122_478278">
        <rect width="1080" height="1920" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FacebookStoryOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3122_478279)">
      <path opacity="0.7" d="M1080 0H0V267H1080V0Z" fill="black" />
      <path
        opacity="0.7"
        d="M1024.66 82.5H832.84C823.263 82.5 815.5 90.2634 815.5 99.84V144.66C815.5 154.237 823.263 162 832.84 162H1024.66C1034.24 162 1042 154.237 1042 144.66V99.84C1042 90.2634 1034.24 82.5 1024.66 82.5Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M150.5 124.75C150.5 91.1987 123.301 64 89.75 64C56.1987 64 29 91.1987 29 124.75C29 158.301 56.1987 185.5 89.75 185.5C123.301 185.5 150.5 158.301 150.5 124.75Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M754.25 85.5H197.89C186.43 85.5 177.14 94.7901 177.14 106.25C177.14 117.71 186.43 127 197.89 127H754.25C765.71 127 775 117.71 775 106.25C775 94.7901 765.71 85.5 754.25 85.5Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M1034.85 20.1399H40.15C36.2012 20.1399 33 23.3411 33 27.2899V27.4899C33 31.4387 36.2012 34.6399 40.15 34.6399H1034.85C1038.8 34.6399 1042 31.4387 1042 27.4899V27.2899C1042 23.3411 1038.8 20.1399 1034.85 20.1399Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M454.25 148.06H194.37C182.91 148.06 173.62 157.35 173.62 168.81C173.62 180.27 182.91 189.56 194.37 189.56H454.25C465.71 189.56 475 180.27 475 168.81C475 157.35 465.71 148.06 454.25 148.06Z"
        fill="white"
      />
      <path opacity="0.7" d="M1080 272H0" stroke="white" strokeWidth="12" strokeLinejoin="round" />
      <path opacity="0.7" d="M1080 1734H0V1920H1080V1734Z" fill="black" />
      <path opacity="0.7" d="M0 1728H1080" stroke="white" strokeWidth="11.7" strokeLinejoin="round" />
      <path
        opacity="0.7"
        d="M514.97 1776H84.03C57.5038 1776 36 1797.5 36 1824.03V1826.47C36 1853 57.5038 1874.5 84.03 1874.5H514.97C541.496 1874.5 563 1853 563 1826.47V1824.03C563 1797.5 541.496 1776 514.97 1776Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M935.27 1815.65V1848.89C935.27 1850.88 936.88 1852.49 938.87 1852.49H950.62C952.61 1852.49 954.22 1850.88 954.22 1848.89V1815.65C954.22 1813.66 952.61 1812.05 950.62 1812.05H938.87C936.88 1812.05 935.27 1813.66 935.27 1815.65Z"
          fill="white"
        />
        <path
          d="M954.22 1818.92C954.22 1818.92 958.25 1817.92 960.39 1814.72C961.58 1812.94 963.43 1809.5 964.83 1806.81C966.12 1804.33 966.8 1801.57 966.8 1798.77V1792.99C966.8 1791.22 967.73 1789.59 969.24 1788.68C970.99 1787.63 973.21 1787.74 974.85 1788.97C978.53 1791.73 980.69 1796.06 980.69 1800.66V1807.42C980.69 1808.02 981.18 1808.5 981.78 1808.5L994.08 1808.37C995.83 1808.35 997.57 1808.62 999.24 1809.17L999.8 1809.35C1001.85 1810.02 1003.36 1811.78 1003.71 1813.91C1004.02 1815.76 1003.41 1817.64 1002.09 1818.97L1001 1820.06L1001.98 1820.55C1004 1821.56 1005.27 1823.62 1005.27 1825.87C1005.27 1828.74 1003.22 1831.2 1000.4 1831.72L1001.64 1833.21C1002.58 1834.35 1002.97 1835.86 1002.67 1837.31C1002.3 1839.17 1000.89 1840.65 999.04 1841.11L997.78 1841.42L998 1841.75C1000.15 1844.97 998.95 1849.41 995.41 1850.99C995.36 1851.01 995.31 1851.03 995.26 1851.05C994.11 1851.74 992.79 1852.11 991.45 1852.13L969.05 1852.38C964.55 1852.43 960.19 1850.79 956.85 1847.78L954.21 1845.41"
          fill="white"
        />
      </g>
      <path
        opacity="0.7"
        d="M656.09 1790.84C636.76 1790.84 621.09 1805.48 621.09 1823.54C621.09 1833.65 626 1842.69 633.71 1848.68V1857.81C633.71 1859.97 635.94 1861.41 637.9 1860.52L648.97 1855.56C651.27 1856 653.65 1856.24 656.09 1856.24C675.42 1856.24 691.09 1841.6 691.09 1823.54C691.09 1805.48 675.42 1790.84 656.09 1790.84Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M815.16 1805.94C815.16 1805.94 817.17 1799.92 827.21 1796.9C841.27 1794.89 847.25 1805.94 847.25 1805.94C847.25 1805.94 852.31 1813.97 848.97 1823.39C848.18 1825.6 846.93 1827.61 845.3 1829.3C838.02 1836.82 815.16 1857.93 815.16 1857.93"
          fill="white"
        />
        <path
          d="M815.16 1805.94C815.16 1805.94 817.17 1799.92 827.21 1796.9C841.27 1794.89 847.25 1805.94 847.25 1805.94C847.25 1805.94 852.31 1813.97 848.97 1823.39C848.18 1825.6 846.93 1827.61 845.3 1829.3C838.02 1836.82 815.16 1857.93 815.16 1857.93"
          stroke="white"
          strokeWidth="7.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M815.06 1857.93C815.06 1857.93 792.2 1836.82 784.92 1829.3C783.29 1827.61 782.04 1825.6 781.25 1823.39C777.9 1813.97 782.97 1805.94 782.97 1805.94C782.97 1805.94 788.95 1794.89 803.01 1796.9C813.05 1799.91 815.06 1805.94 815.06 1805.94"
          fill="white"
        />
        <path
          d="M815.06 1857.93C815.06 1857.93 792.2 1836.82 784.92 1829.3C783.29 1827.61 782.04 1825.6 781.25 1823.39C777.9 1813.97 782.97 1805.94 782.97 1805.94C782.97 1805.94 788.95 1794.89 803.01 1796.9C813.05 1799.91 815.06 1805.94 815.06 1805.94"
          stroke="white"
          strokeWidth="7.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3122_478279">
        <rect width="1080" height="1920" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InstagramReelOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.7">
      <path
        d="M958.07 1160C917.16 1160 884 1193.16 884 1234.07V1443.35C884 1449.78 878.78 1455 872.35 1455H0V1920H1080V1160H958.07Z"
        fill="black"
      />
      <path
        d="M1080 1160H958.07C917.16 1160 884 1193.16 884 1234.07V1443.35C884 1449.78 878.78 1455 872.35 1455H0"
        stroke="white"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
    </g>
    <path
      opacity="0.7"
      d="M972.65 147.5C1008.55 147.5 1037.65 118.399 1037.65 82.5C1037.65 46.6015 1008.55 17.5 972.65 17.5C936.752 17.5 907.65 46.6015 907.65 82.5C907.65 118.399 936.752 147.5 972.65 147.5Z"
      fill="black"
      stroke="white"
      strokeWidth="12.58"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.7"
      d="M994.76 59.43H991.21L986.75 52.99C985.02 50.49 982.18 49 979.14 49H968.3C965.26 49 962.41 50.5 960.68 53L956.25 59.43H950.53C945.26 59.43 940.99 63.7 940.99 68.97V100.45C940.99 105.72 945.26 109.99 950.53 109.99H994.75C1000.02 109.99 1004.29 105.72 1004.29 100.45V68.97C1004.29 63.7 1000.02 59.43 994.75 59.43H994.76ZM972.65 97C964.37 97 957.65 90.28 957.65 82C957.65 73.72 964.37 67 972.65 67C980.93 67 987.65 73.72 987.65 82C987.65 90.28 980.93 97 972.65 97Z"
      fill="white"
      stroke="white"
      strokeWidth="5.68"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.7"
      d="M377.75 1614.5H204.86C187.325 1614.5 173.11 1628.71 173.11 1646.25C173.11 1663.79 187.325 1678 204.86 1678H377.75C395.285 1678 409.5 1663.79 409.5 1646.25C409.5 1628.71 395.285 1614.5 377.75 1614.5Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M612.58 1596H451.42C437.381 1596 426 1607.38 426 1621.42V1667.58C426 1681.62 437.381 1693 451.42 1693H612.58C626.619 1693 638 1681.62 638 1667.58V1621.42C638 1607.38 626.619 1596 612.58 1596Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M84.5 1695C112.39 1695 135 1672.39 135 1644.5C135 1616.61 112.39 1594 84.5 1594C56.6096 1594 34 1616.61 34 1644.5C34 1672.39 56.6096 1695 84.5 1695Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M722.5 1736.89H40.69C36.824 1736.89 33.69 1740.02 33.69 1743.89V1776C33.69 1779.87 36.824 1783 40.69 1783H722.5C726.366 1783 729.5 1779.87 729.5 1776V1743.89C729.5 1740.02 726.366 1736.89 722.5 1736.89Z"
      fill="white"
    />
    <g opacity="0.7">
      <path
        d="M971.5 1226.5C971.5 1226.5 973.5 1220.5 983.5 1217.5C997.5 1215.5 1003.46 1226.5 1003.46 1226.5C1003.46 1226.5 1008.5 1234.5 1005.17 1243.88C1004.39 1246.08 1003.14 1248.09 1001.52 1249.77C994.27 1257.26 971.51 1278.28 971.51 1278.28"
        fill="white"
      />
      <path
        d="M971.5 1226.5C971.5 1226.5 973.5 1220.5 983.5 1217.5C997.5 1215.5 1003.46 1226.5 1003.46 1226.5C1003.46 1226.5 1008.5 1234.5 1005.17 1243.88C1004.39 1246.08 1003.14 1248.09 1001.52 1249.77C994.27 1257.26 971.51 1278.28 971.51 1278.28"
        stroke="white"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M971.4 1278.28C971.4 1278.28 948.63 1257.25 941.39 1249.77C939.76 1248.09 938.52 1246.09 937.74 1243.88C934.41 1234.5 939.45 1226.5 939.45 1226.5C939.45 1226.5 945.41 1215.5 959.41 1217.5C969.41 1220.5 971.41 1226.5 971.41 1226.5"
        fill="white"
      />
      <path
        d="M971.4 1278.28C971.4 1278.28 948.63 1257.25 941.39 1249.77C939.76 1248.09 938.52 1246.09 937.74 1243.88C934.41 1234.5 939.45 1226.5 939.45 1226.5C939.45 1226.5 945.41 1215.5 959.41 1217.5C969.41 1220.5 971.41 1226.5 971.41 1226.5"
        stroke="white"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      opacity="0.7"
      d="M1001.1 1414.81C1004.64 1409.31 1006.7 1402.77 1006.7 1395.75C1006.7 1376.28 990.92 1360.5 971.45 1360.5C951.98 1360.5 936.2 1376.28 936.2 1395.75C936.2 1415.22 951.98 1431 971.45 1431C978.28 1431 984.65 1429.05 990.04 1425.69L1006.7 1431L1001.1 1414.81Z"
      fill="white"
      stroke="white"
      strokeWidth="6.76"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.7">
      <path
        d="M970.73 1576.84L963.48 1542.5L937.48 1518.5H1005.42L970.73 1576.84Z"
        fill="white"
        stroke="white"
        strokeWidth="6.76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M963.48 1542.5L1005.42 1518.5"
        stroke="white"
        strokeWidth="6.76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M971.45 1660C974.626 1660 977.2 1657.43 977.2 1654.25C977.2 1651.07 974.626 1648.5 971.45 1648.5C968.274 1648.5 965.7 1651.07 965.7 1654.25C965.7 1657.43 968.274 1660 971.45 1660Z"
        fill="white"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M971.45 1677.71C974.626 1677.71 977.2 1675.14 977.2 1671.96C977.2 1668.78 974.626 1666.21 971.45 1666.21C968.274 1666.21 965.7 1668.78 965.7 1671.96C965.7 1675.14 968.274 1677.71 971.45 1677.71Z"
        fill="white"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M971.45 1695.71C974.626 1695.71 977.2 1693.14 977.2 1689.96C977.2 1686.78 974.626 1684.21 971.45 1684.21C968.274 1684.21 965.7 1686.78 965.7 1689.96C965.7 1693.14 968.274 1695.71 971.45 1695.71Z"
        fill="white"
        stroke="white"
        strokeMiterlimit="10"
      />
    </g>
    <path
      opacity="0.7"
      d="M999.11 1767.5H943.79C932.28 1767.5 922.95 1776.83 922.95 1788.34V1843.66C922.95 1855.17 932.28 1864.5 943.79 1864.5H999.11C1010.62 1864.5 1019.95 1855.17 1019.95 1843.66V1788.34C1019.95 1776.83 1010.62 1767.5 999.11 1767.5Z"
      fill="white"
      stroke="white"
      strokeWidth="6.76"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.7"
      d="M786 1828.69H40.71C36.844 1828.69 33.71 1831.82 33.71 1835.69V1867.8C33.71 1871.67 36.844 1874.8 40.71 1874.8H786C789.866 1874.8 793 1871.67 793 1867.8V1835.69C793 1831.82 789.866 1828.69 786 1828.69Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M374 1514.59H40.57C36.704 1514.59 33.57 1517.72 33.57 1521.59V1553.7C33.57 1557.57 36.704 1560.7 40.57 1560.7H374C377.866 1560.7 381 1557.57 381 1553.7V1521.59C381 1517.72 377.866 1514.59 374 1514.59Z"
      fill="white"
    />
  </svg>
);

export const InstagramStoryOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity="0.7" d="M1080 0H0V184H1080V0Z" fill="black" />
    <path
      opacity="0.7"
      d="M1046.61 71H850.82C843.701 71 837.93 76.771 837.93 83.89V124.11C837.93 131.229 843.701 137 850.82 137H1046.61C1053.73 137 1059.5 131.229 1059.5 124.11V83.89C1059.5 76.771 1053.73 71 1046.61 71Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M551.55 80.05H175.95C162.723 80.05 152 90.7706 152 103.995C152 117.22 162.723 127.94 175.95 127.94H551.55C564.777 127.94 575.5 117.22 575.5 103.995C575.5 90.7706 564.777 80.05 551.55 80.05Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M84 154.5C111.89 154.5 134.5 131.89 134.5 104C134.5 76.1096 111.89 53.5 84 53.5C56.1096 53.5 33.5 76.1096 33.5 104C33.5 131.89 56.1096 154.5 84 154.5Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M1053.79 19.5H24.21C21.0565 19.5 18.5 22.0565 18.5 25.21V28.29C18.5 31.4435 21.0565 34 24.21 34H1053.79C1056.94 34 1059.5 31.4435 1059.5 28.29V25.21C1059.5 22.0565 1056.94 19.5 1053.79 19.5Z"
      fill="white"
    />
    <path opacity="0.7" d="M1080 184H0" stroke="white" strokeWidth="6.16" strokeLinejoin="round" />
  </svg>
);

export const TikTokPostOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3122_478277)">
      <path
        opacity="0.7"
        d="M0.0100098 1399H815.97C834.76 1399 849.99 1383.77 849.99 1364.98V724.05C849.99 673.77 890.75 633.01 941.03 633.01H1080.01V1920.01H0.0100098V1399.01V1399Z"
        fill="black"
      />
      <path
        opacity="0.7"
        d="M0.0100098 1393H813.58C831 1394 844 1381.8 844 1365V722.49C844 669.7 886.78 626.91 939.57 626.91H1080.01"
        stroke="white"
        strokeWidth="12"
        strokeLinejoin="bevel"
      />
      <path
        opacity="0.7"
        d="M527.01 1848.16H66.03C62.164 1848.16 59.03 1851.29 59.03 1855.16V1874.46C59.03 1878.33 62.164 1881.46 66.03 1881.46H527.01C530.876 1881.46 534.01 1878.33 534.01 1874.46V1855.16C534.01 1851.29 530.876 1848.16 527.01 1848.16Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M333.02 1538.88H56.03C52.164 1538.88 49.03 1542.01 49.03 1545.88V1565.18C49.03 1569.05 52.164 1572.18 56.03 1572.18H333.02C336.886 1572.18 340.02 1569.05 340.02 1565.18V1545.88C340.02 1542.01 336.886 1538.88 333.02 1538.88Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M509.02 1616.8H56.0699C52.204 1616.8 49.0699 1619.93 49.0699 1623.8V1643.1C49.0699 1646.97 52.204 1650.1 56.0699 1650.1H509.02C512.886 1650.1 516.02 1646.97 516.02 1643.1V1623.8C516.02 1619.93 512.886 1616.8 509.02 1616.8Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M682.01 1677.8H56.09C52.224 1677.8 49.09 1680.93 49.09 1684.8V1704.1C49.09 1707.97 52.224 1711.1 56.09 1711.1H682.01C685.876 1711.1 689.01 1707.97 689.01 1704.1V1684.8C689.01 1680.93 685.876 1677.8 682.01 1677.8Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M561.01 1739.06H56.08C52.214 1739.06 49.08 1742.19 49.08 1746.06V1765.36C49.08 1769.23 52.214 1772.36 56.08 1772.36H561.01C564.876 1772.36 568.01 1769.23 568.01 1765.36V1746.06C568.01 1742.19 564.876 1739.06 561.01 1739.06Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M115.21 1438.5H74.31C71.1068 1438.5 68.51 1441.1 68.51 1444.3V1483.2C68.51 1486.4 71.1068 1489 74.31 1489H115.21C118.413 1489 121.01 1486.4 121.01 1483.2V1444.3C121.01 1441.1 118.413 1438.5 115.21 1438.5Z"
        fill="white"
        stroke="white"
        strokeWidth="7.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.7">
        <path
          d="M967.57 916.59C967.57 916.59 970.36 908.22 984.31 904.04C1003.84 901.25 1012.15 916.59 1012.15 916.59C1012.15 916.59 1019.18 927.75 1014.54 940.83C1013.45 943.9 1011.71 946.7 1009.44 949.04C999.33 959.48 967.58 988.81 967.58 988.81"
          fill="white"
        />
        <path
          d="M967.44 988.82C967.44 988.82 935.68 959.49 925.58 949.05C923.31 946.71 921.57 943.91 920.48 940.84C915.83 927.76 922.87 916.6 922.87 916.6C922.87 916.6 931.18 901.26 950.71 904.05C964.66 908.23 967.45 916.6 967.45 916.6"
          fill="white"
        />
      </g>
      <path
        opacity="0.7"
        d="M967.5 816C1006.44 816 1038 784.436 1038 745.5C1038 706.564 1006.44 675 967.5 675C928.564 675 897 706.564 897 745.5C897 784.436 928.564 816 967.5 816Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M968.51 1784C1003.86 1784 1032.51 1755.35 1032.51 1720C1032.51 1684.65 1003.86 1656 968.51 1656C933.164 1656 904.51 1684.65 904.51 1720C904.51 1755.35 933.164 1784 968.51 1784Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M933.12 1360.07V1287.67C933.12 1286.19 934.32 1285 935.79 1285H999.22C1000.7 1285 1001.89 1286.2 1001.89 1287.67V1360.07C1001.89 1362.18 999.56 1363.46 997.79 1362.33L967.51 1343.1L937.23 1362.33C935.45 1363.46 933.13 1362.18 933.13 1360.07H933.12Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M920.13 1556C920.13 1556 910.93 1501.92 971.91 1499.62V1484.76C971.91 1482.37 974.75 1481.11 976.52 1482.72L1014.47 1517.09C1015.7 1518.2 1015.67 1520.14 1014.42 1521.22L978.64 1552.07C976.85 1553.61 974.08 1552.34 974.08 1549.98V1538.74C974.08 1538.74 932.79 1530.69 920.13 1556Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M1006.58 1150C1009.72 1144.63 1011.5 1138.57 1011.5 1132.14C1011.5 1110.32 991.13 1092.64 966 1092.64C940.87 1092.64 920.5 1110.32 920.5 1132.14C920.5 1153.96 940.87 1171.64 966 1171.64V1179.5C966 1180.92 967.42 1181.9 968.75 1181.39C975.79 1178.67 994.7 1169.92 1005.99 1151C1006.1 1150.83 1006.19 1150.65 1006.3 1150.48C1006.39 1150.32 1006.49 1150.17 1006.58 1150Z"
        fill="white"
      />
      <g opacity="0.7">
        <path d="M1080 0H0V176H1080V0Z" fill="black" />
        <path d="M1080 176H0" stroke="white" strokeWidth="12" strokeMiterlimit="10" />
      </g>
      <path
        opacity="0.7"
        d="M127.67 38.5H72.35C60.8404 38.5 51.51 47.8304 51.51 59.34V114.66C51.51 126.17 60.8404 135.5 72.35 135.5H127.67C139.18 135.5 148.51 126.17 148.51 114.66V59.34C148.51 47.8304 139.18 38.5 127.67 38.5Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M746.6 44H333.59C313.432 44 297.09 60.3416 297.09 80.5C297.09 100.658 313.432 117 333.59 117H746.6C766.758 117 783.1 100.658 783.1 80.5C783.1 60.3416 766.758 44 746.6 44Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M973.01 108C987.922 108 1000.01 95.9117 1000.01 81C1000.01 66.0883 987.922 54 973.01 54C958.098 54 946.01 66.0883 946.01 81C946.01 95.9117 958.098 108 973.01 108Z"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
        />
        <path
          d="M993.05 99.0901L1009.49 115.53"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3122_478277">
        <rect width="1080" height="1920" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const YouTubeShortsOverlay: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3122_478280)">
      <path
        opacity="0.7"
        d="M1 1574.5H842.98C861.4 1574.5 876.33 1559.57 876.33 1541.15V709.26C876.33 660.65 915.74 621.24 964.35 621.24H1080.02"
        stroke="white"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.7"
        d="M0.00976562 1581H842.2C865.56 1581 882 1566.06 882 1542.7V707.14C882 664.26 919.26 627.49 962.15 627.49H1080.02V1919.99H0.00976562V1580.99V1581Z"
        fill="black"
      />
      <path
        opacity="0.7"
        d="M112.5 1722C139.286 1722 161 1700.29 161 1673.5C161 1646.71 139.286 1625 112.5 1625C85.7142 1625 64 1646.71 64 1673.5C64 1700.29 85.7142 1722 112.5 1722Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M691.85 1658.6H188.85C183.388 1658.6 178.96 1663.03 178.96 1668.49V1691.18C178.96 1696.64 183.388 1701.07 188.85 1701.07H691.85C697.312 1701.07 701.74 1696.64 701.74 1691.18V1668.49C701.74 1663.03 697.312 1658.6 691.85 1658.6Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M748.82 1821.4H71.7298C67.2121 1821.4 63.5498 1825.06 63.5498 1829.58V1855.69C63.5498 1860.21 67.2121 1863.87 71.7298 1863.87H748.82C753.338 1863.87 757 1860.21 757 1855.69V1829.58C757 1825.06 753.338 1821.4 748.82 1821.4Z"
        fill="white"
      />
      <path
        opacity="07"
        d="M1028.64 1749H945.36C936.325 1749 929 1756.32 929 1765.36V1848.64C929 1857.68 936.325 1865 945.36 1865H1028.64C1037.68 1865 1045 1857.68 1045 1848.64V1765.36C1045 1756.32 1037.68 1749 1028.64 1749Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M822.31 1746.61H74.3099C68.8478 1746.61 64.4199 1751.04 64.4199 1756.5V1779.19C64.4199 1784.65 68.8478 1789.08 74.3099 1789.08H822.31C827.772 1789.08 832.2 1784.65 832.2 1779.19V1756.5C832.2 1751.04 827.772 1746.61 822.31 1746.61Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M939.56 733.53V778.58C939.56 781.27 941.74 783.46 944.44 783.46H960.37C963.06 783.46 965.25 781.28 965.25 778.58V733.53C965.25 730.84 963.07 728.65 960.37 728.65H944.44C941.75 728.65 939.56 730.83 939.56 733.53Z"
          fill="white"
        />
        <path
          d="M965.24 737.97C965.24 737.97 970.71 736.62 973.6 732.27C975.21 729.85 977.72 725.2 979.62 721.54C981.38 718.18 982.29 714.44 982.29 710.65V702.81C982.29 700.41 983.55 698.19 985.6 696.96C987.98 695.53 990.98 695.69 993.2 697.35C998.19 701.09 1001.12 706.96 1001.12 713.19V722.36C1001.12 723.17 1001.78 723.83 1002.6 723.82L1019.27 723.65C1021.64 723.63 1024.01 723.99 1026.26 724.73L1027.02 724.98C1029.8 725.89 1031.84 728.28 1032.33 731.16C1032.75 733.67 1031.93 736.22 1030.13 738.02L1028.65 739.5L1029.99 740.17C1032.72 741.54 1034.45 744.33 1034.45 747.39C1034.45 751.28 1031.68 754.61 1027.85 755.32L1029.53 757.34C1030.81 758.89 1031.33 760.93 1030.93 762.89C1030.43 765.41 1028.51 767.42 1026.02 768.04L1024.31 768.47L1024.61 768.92C1027.52 773.29 1025.9 779.3 1021.11 781.44C1021.04 781.47 1020.98 781.5 1020.91 781.53C1019.35 782.47 1017.56 782.97 1015.74 782.99L985.38 783.33C979.28 783.4 973.38 781.18 968.84 777.1L965.26 773.88"
          fill="white"
        />
      </g>
      <path
        opacity="0.7"
        d="M942.52 1406.06C942.52 1406.06 933.88 1355.29 991.13 1353.13V1339.18C991.13 1336.93 993.79 1335.75 995.46 1337.26L1031.09 1369.53C1032.24 1370.57 1032.22 1372.39 1031.04 1373.41L997.45 1402.38C995.77 1403.83 993.17 1402.63 993.17 1400.42V1389.87C993.17 1389.87 954.4 1382.31 942.51 1406.07L942.52 1406.06Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M1034.44 956.52V911.47C1034.44 908.78 1032.26 906.59 1029.56 906.59H1013.63C1010.94 906.59 1008.75 908.77 1008.75 911.47V952.08V956.52C1008.75 959.21 1010.93 961.4 1013.63 961.4H1029.56C1032.25 961.4 1034.44 959.22 1034.44 956.52Z"
          fill="white"
        />
        <path
          d="M1008.76 952.08C1008.76 952.08 1003.29 953.43 1000.4 957.78C998.79 960.2 996.28 964.85 994.38 968.51C992.62 971.87 991.71 975.61 991.71 979.4V987.24C991.71 989.64 990.45 991.86 988.4 993.09C986.02 994.52 983.02 994.36 980.8 992.7C975.81 988.96 972.88 983.09 972.88 976.86V967.69C972.88 966.88 972.22 966.22 971.4 966.23L954.73 966.4C952.36 966.42 949.99 966.06 947.74 965.32L946.98 965.07C944.2 964.16 942.16 961.77 941.67 958.89C941.25 956.38 942.07 953.83 943.87 952.03L945.35 950.55L944.01 949.88C941.28 948.51 939.55 945.72 939.55 942.66C939.55 938.77 942.32 935.44 946.15 934.73L944.47 932.71C943.19 931.16 942.67 929.12 943.07 927.16C943.57 924.64 945.49 922.63 947.98 922.01L949.69 921.58L949.39 921.13C946.48 916.76 948.1 910.75 952.89 908.61C952.96 908.58 953.02 908.55 953.09 908.52C954.65 907.58 956.44 907.08 958.26 907.06L988.62 906.72C994.72 906.65 1000.62 908.87 1005.16 912.95L1008.74 916.17"
          fill="white"
        />
      </g>
      <path
        opacity="0.7"
        d="M939.46 1174.42V1115.51C939.46 1110.75 943.32 1106.88 948.09 1106.88H1025.91C1030.67 1106.88 1034.54 1110.74 1034.54 1115.51V1196.61C1034.54 1200.26 1030.09 1202.04 1027.57 1199.4L1011.97 1183.04H948.09C943.33 1183.04 939.46 1179.18 939.46 1174.41V1174.42Z"
        fill="white"
        stroke="white"
        strokeWidth="1.33"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.7"
        d="M989.62 1538.34C989.62 1538.34 949.77 1531.56 948.08 1576.49H937.13C935.37 1576.49 934.44 1578.58 935.62 1579.89L960.95 1607.86C961.77 1608.77 963.2 1608.75 963.99 1607.82L986.72 1581.46C987.86 1580.14 986.92 1578.1 985.18 1578.1H976.9C976.9 1578.1 970.97 1547.67 989.62 1538.35V1538.34Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M984.38 1635.18C984.38 1635.18 1024.23 1641.96 1025.92 1597.03H1036.87C1038.63 1597.03 1039.56 1594.94 1038.38 1593.63L1013.05 1565.66C1012.23 1564.75 1010.8 1564.77 1010.01 1565.7L987.28 1592.06C986.14 1593.38 987.08 1595.42 988.82 1595.42H997.1C997.1 1595.42 1003.03 1625.85 984.38 1635.17V1635.18Z"
        fill="white"
      />
      <path opacity="0.7" d="M1080 0H0V144.5H1080V0Z" fill="black" />
      <path
        opacity="0.7"
        d="M1074 152.5H6"
        stroke="white"
        strokeWidth="12"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.7"
        d="M868.59 51.5899H865.71L862.1 46.3699C860.7 44.3399 858.39 43.1399 855.93 43.1399H847.14C844.67 43.1399 842.36 44.3499 840.97 46.3799L837.38 51.5999H832.75C828.48 51.5999 825.01 55.0599 825.01 59.3399V84.8599C825.01 89.1299 828.47 92.5999 832.75 92.5999H868.6C872.87 92.5999 876.34 89.1399 876.34 84.8599V59.3399C876.34 55.0699 872.88 51.5999 868.6 51.5999L868.59 51.5899ZM850.66 82.0499C843.94 82.0499 838.5 76.5999 838.5 69.8899C838.5 63.1799 843.95 57.7299 850.66 57.7299C857.37 57.7299 862.82 63.1799 862.82 69.8899C862.82 76.5999 857.38 82.0499 850.66 82.0499Z"
        fill="white"
        stroke="white"
        strokeWidth="4.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.7">
        <path
          d="M710.3 83.82C720.959 83.82 729.6 75.1791 729.6 64.52C729.6 53.8609 720.959 45.22 710.3 45.22C699.641 45.22 691 53.8609 691 64.52C691 75.1791 699.641 83.82 710.3 83.82Z"
          stroke="white"
          strokeWidth="10.08"
          strokeMiterlimit="10"
        />
        <path
          d="M724.62 77.45L736.37 89.2"
          stroke="white"
          strokeWidth="10.08"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M989.5 57C993.09 57 996 54.0899 996 50.5C996 46.9101 993.09 44 989.5 44C985.91 44 983 46.9101 983 50.5C983 54.0899 985.91 57 989.5 57Z"
          fill="white"
        />
        <path
          d="M989.5 76C993.09 76 996 73.0899 996 69.5C996 65.9101 993.09 63 989.5 63C985.91 63 983 65.9101 983 69.5C983 73.0899 985.91 76 989.5 76Z"
          fill="white"
        />
        <path
          d="M989.5 95C993.09 95 996 92.0899 996 88.5C996 84.9101 993.09 82 989.5 82C985.91 82 983 84.9101 983 88.5C983 92.0899 985.91 95 989.5 95Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3122_478280">
        <rect width="1080" height="1920" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
