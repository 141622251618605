import { transcriptionLanguages, translationLanguages } from "@getsubly/common";

// General Settings
export default {
  transcription: {
    languages: transcriptionLanguages
  },
  humanTranscription: {
    languages: [{ languageCode: "en-US", language: "English (US)", countryCode: "us", flagCode: "us" }] // rev.AI
  },
  translation: {
    languages: translationLanguages
  },
  stripe: {
    taxIdTypes: [
      {
        name: "United Arab Emirates (TRN)",
        country: "AE",
        code: "ae_trn",
        format: "123456789012345"
      },
      {
        name: "Australia (ABN)",
        country: "AU",
        code: "au_abn",
        format: "12345678912"
      },
      {
        name: "Brazil (CNPJ)",
        country: "BR",
        code: "br_cnpj",
        format: "01.234.456/5432-10"
      },
      {
        name: "Brazil (CPF)",
        country: "BR",
        code: "br_cpf",
        format: "123.456.789-87"
      },
      {
        name: "Canada (BN)",
        country: "CA",
        code: "ca_bn",
        format: "123456789"
      },
      {
        name: "Canada (QST)",
        country: "CA",
        code: "ca_qst",
        format: "1234567890TQ1234"
      },
      {
        name: "Switzerland (VAT)",
        country: "CH",
        code: "ch_vat",
        format: "CHE-123.456.789 MWST"
      },
      {
        name: "Chile (TIN)",
        country: "CL",
        code: "cl_tin",
        format: "12.345.678-K"
      },
      {
        name: "Spain (CIF)",
        country: "ES",
        code: "es_cif",
        format: "A12345678"
      },
      {
        name: "Austria (VAT)",
        country: "AT",
        code: "eu_vat",
        format: "ATU12345678"
      },
      {
        name: "Belgium (VAT)",
        country: "BE",
        code: "eu_vat",
        format: "BE0123456789"
      },
      {
        name: "Bulgaria (VAT)",
        country: "BG",
        code: "eu_vat",
        format: "BG0123456789"
      },
      {
        name: "Cyprus (VAT)",
        country: "CY",
        code: "eu_vat",
        format: "CY12345678Z"
      },
      {
        name: "Czechia (VAT)",
        country: "CZ",
        code: "eu_vat",
        format: "CZ1234567890"
      },
      {
        name: "Germany (VAT)",
        country: "DE",
        code: "eu_vat",
        format: "DE123456789"
      },
      {
        name: "Denmark (VAT)",
        country: "DK",
        code: "eu_vat",
        format: "DK12345678"
      },
      {
        name: "Estonia (VAT)",
        country: "EE",
        code: "eu_vat",
        format: "EE123456789"
      },
      {
        name: "Spain (VAT)",
        country: "ES",
        code: "eu_vat",
        format: "ESA1234567Z"
      },
      {
        name: "Finland (VAT)",
        country: "FI",
        code: "eu_vat",
        format: "FI12345678"
      },
      {
        name: "France (VAT)",
        country: "FR",
        code: "eu_vat",
        format: "FRAB123456789"
      },
      {
        name: "United Kingdom (VAT)",
        country: "GB",
        code: "gb_vat",
        format: "GB123456789"
      },
      {
        name: "Greece (VAT)",
        country: "GR",
        code: "eu_vat",
        format: "EL123456789"
      },
      {
        name: "Croatia (VAT)",
        country: "HR",
        code: "eu_vat",
        format: "HR12345678912"
      },
      {
        name: "Hungary (VAT)",
        country: "HU",
        code: "eu_vat",
        format: "HU12345678912"
      },
      {
        name: "Ireland (VAT)",
        country: "IE",
        code: "eu_vat",
        format: "IE1234567AB"
      },
      {
        name: "Italy (VAT)",
        country: "IT",
        code: "eu_vat",
        format: "IT12345678912"
      },
      {
        name: "Lithuania (VAT)",
        country: "LT",
        code: "eu_vat",
        format: "LT123456789123"
      },
      {
        name: "Luxembourg (VAT)",
        country: "LU",
        code: "eu_vat",
        format: "LU12345678"
      },
      {
        name: "Latvia (VAT)",
        country: "LV",
        code: "eu_vat",
        format: "LV12345678912"
      },
      {
        name: "Malta (VAT)",
        country: "MT",
        code: "eu_vat",
        format: "MT12345678"
      },
      {
        name: "Netherlands (VAT)",
        country: "NL",
        code: "eu_vat",
        format: "NL123456789B12"
      },
      {
        name: "Poland (VAT)",
        country: "PL",
        code: "eu_vat",
        format: "PL1234567890"
      },
      {
        name: "Portugal (VAT)",
        country: "PT",
        code: "eu_vat",
        format: "PT123456789"
      },
      {
        name: "Romania (VAT)",
        country: "RO",
        code: "eu_vat",
        format: "RO1234567891"
      },
      {
        name: "Sweden (VAT)",
        country: "SE",
        code: "eu_vat",
        format: "SE123456789123"
      },
      {
        name: "Slovenia (VAT)",
        country: "SI",
        code: "eu_vat",
        format: "SI12345678"
      },
      {
        name: "Slovakia (VAT)",
        country: "SK",
        code: "eu_vat",
        format: "SK1234567891"
      },
      {
        name: "Hong Kong (BR)",
        country: "HK",
        code: "hk_br",
        format: "12345678"
      },
      {
        name: "Indonesia (NPWP)",
        country: "ID",
        code: "id_npwp",
        format: "12.345.678.9-012.345"
      },
      {
        name: "India (GST)",
        country: "IN",
        code: "in_gst",
        format: "12ABCDE3456FGZH"
      },
      {
        name: "Japan (CN)",
        country: "JP",
        code: "jp_cn",
        format: "1234567891234"
      },
      {
        name: "Japan (RN)",
        country: "JP",
        code: "jp_rn",
        format: "12345"
      },
      {
        name: "Korea, Republic of",
        country: "KR",
        code: "kr_brn",
        format: "123-45-67890"
      },
      {
        name: "Liechtenstein (UID)",
        country: "LI",
        code: "li_uid",
        format: "CHE123456789"
      },
      {
        name: "Mexico (RFC)",
        country: "MX",
        code: "mx_rfc",
        format: "ABC010203AB9"
      },
      {
        name: "Malaysia (FRP)",
        country: "MY",
        code: "my_frp",
        format: "12345678"
      },
      {
        name: "Malaysia (ITN)",
        country: "MY",
        code: "my_itn",
        format: "C 1234567890"
      },
      {
        name: "Malaysia (SST)",
        country: "MY",
        code: "my_sst",
        format: "A12-3456-78912345"
      },
      {
        name: "Norway (VAT)",
        country: "NO",
        code: "no_vat",
        format: "123456789MVA"
      },
      {
        name: "New Zealand (GST)",
        country: "NZ",
        code: "nz_gst",
        format: "123456789"
      },
      {
        name: "Russian Federation (INN)",
        country: "RU",
        code: "ru_inn",
        format: "1234567891"
      },
      {
        name: "Russian Federation (KPP)",
        country: "RU",
        code: "ru_kpp",
        format: "123456789"
      },
      {
        name: "Saudi Arabia (VAT)",
        country: "SA",
        code: "sa_vat",
        format: "123456789012345"
      },
      {
        name: "Singapore (GST)",
        country: "SG",
        code: "sg_gst",
        format: "M12345678X"
      },
      {
        name: "Singapore (UEN)",
        country: "SG",
        code: "sg_uen",
        format: "123456789F"
      },
      {
        name: "Thailand (VAT)",
        country: "TH",
        code: "th_vat",
        format: "1234567891234"
      },
      {
        name: "Taiwan",
        country: "TW",
        code: "tw_vat",
        format: "12345678"
      },
      {
        name: "US (EIN)",
        country: "US",
        code: "us_ein",
        format: "12-3456789"
      },
      {
        name: "South Africa (VAT)",
        country: "ZA",
        code: "za_vat",
        format: "4123456789"
      }
    ]
  },
  customers: [
    {
      background: "/img/mwulff.jpg",
      businessName: "Podcaster at Digital Love",
      comment:
        "Subly has been a game changer for creating videos. As a podcaster, I wanted to use video to promote my work. I was able to record a video, add text within seconds and upload to social media. It's perfect for creators like me!",
      personName: "Monica Wulff",
      id: 1
    },
    {
      background: "/img/matt.jpg",
      businessName: "Founder of Trust the Process",
      comment:
        "Subly has saved us so much time at our agency. We needed a way to produce branded videos into multiple languages with text embedded for social media posts and adverts. This is the solution we have been waiting for!",
      personName: "Matthew Moseley",
      id: 2
    },
    {
      background: "/img/emma.jpg",
      businessName: "Bespoke Backdrop & Branding",
      comment:
        "Subly is what I've been waiting for! I've wanted to produce and add text to videos for my business but I couldn't find an easy and fast way to do it. Finally there is a way and the videos look awesome!",
      personName: "Emma veiga-malta",
      id: 3
    }
  ]
};
