import React from "react";

import { Textarea } from "@components/form-controls/textarea";
import { NewModal } from "@components/new-modal";

import { FeedbackOption } from "../dashboard/dashboard-feedback/dashboard-feedback";

interface FeedbackModalProps {
  feedbackOption: FeedbackOption;
  closeModal: () => void;
  onSubmit: (message: string) => void;
}
export const FeedbackModal: React.FC<FeedbackModalProps> = ({ closeModal, onSubmit, feedbackOption }) => {
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleInputMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(message);
    setLoading(false);
    closeModal();
  };

  return (
    <NewModal
      onDismiss={closeModal}
      disableBackgroundDismiss
      size="465"
      title={feedbackOption.modalTitle}
      showCloseButton={true}
      primaryAction="Submit"
      onClickPrimary={handleSubmit}
      primaryActionLoading={loading}
    >
      <Textarea
        minRows={5}
        placeholder={feedbackOption.modalTextareaPlaceholder}
        onChange={handleInputMessage}
        className="tw-mb-6 tw-mt-4"
        autoFocus
      />
    </NewModal>
  );
};

interface FeedbackModalProps {
  feedbackOption: FeedbackOption;
  closeModal: () => void;
  onSubmit: (message: string) => void;
}
export const FeedbackSentModal: React.FC<FeedbackModalProps> = ({ closeModal, feedbackOption }) => {
  return (
    <NewModal
      onDismiss={closeModal}
      disableBackgroundDismiss
      size="465"
      title={feedbackOption.successModalTitle}
      description={feedbackOption.successModalDescription}
      showCloseButton={true}
      primaryAction="Continue"
      onClickPrimary={closeModal}
    />
  );
};
