import React from "react";
import classNames from "classnames";

import { mdiPause, mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";

interface PlaybackIconProps {
  isLoaded: boolean;
  isPlaying: boolean;
}

export const PlaybackIcon: React.FC<PlaybackIconProps> = React.memo(({ isLoaded, isPlaying }) => {
  const playbackAnimation = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!isLoaded) {
      return;
    }

    playbackAnimation.current?.animate(
      [
        {
          opacity: 1,
          transform: "scale(1)"
        },
        {
          opacity: 0,
          transform: "scale(1.3)"
        }
      ],
      {
        duration: 500
      }
    );
  }, [isPlaying, isLoaded]);

  return (
    <div
      className={classNames(
        "tw-pointer-events-none tw-absolute tw-left-1/2 tw-top-1/2 tw-z-[1] -tw-ml-10 -tw-mt-10 tw-h-20 tw-w-20 tw-items-center tw-justify-center tw-rounded-full tw-bg-black tw-bg-opacity-60 tw-opacity-0",
        {
          "tw-flex": isLoaded,
          "tw-hidden": !isLoaded
        }
      )}
      ref={playbackAnimation}
    >
      <Icon path={isPlaying ? mdiPlay : mdiPause} size="30px" color="white" />
    </div>
  );
});
