import React from "react";
import classNames from "classnames";

import { BackgroundStyle } from "@getsubly/common";
import defaultImage from "@media-editor/assets/effect-bg.png";

interface EffectItemProps {
  style: BackgroundStyle;
  selected: boolean;
  onClick: (style: BackgroundStyle) => void;
}

export const EffectItem: React.FC<EffectItemProps> = ({ style, selected, onClick }) => {
  return (
    <div
      className="tw-width-[106.5px] tw-group tw-group tw-flex tw-min-w-[106.5px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center"
      onClick={() => onClick(style)}
    >
      <div
        className={classNames(
          "tw-relative tw-flex tw-aspect-[164/154] tw-w-full tw-select-none tw-flex-row tw-items-center tw-justify-center tw-rounded-md tw-border-[3px] tw-border-transparent tw-p-4 tw-opacity-60 group-hover:tw-opacity-80",
          {
            "!tw-border-aux-600 !tw-bg-aux-600 !tw-opacity-100 group-hover:!tw-opacity-100": selected
          }
        )}
        style={{ backgroundImage: defaultImage }}
      >
        <img
          src={defaultImage}
          alt="Effect image"
          className="tw-absolute tw-top-0 tw-h-full tw-w-full tw-overflow-hidden tw-rounded tw-object-cover"
        />

        <div className="tw-absolute tw-bottom-2 tw-flex tw-h-[30px] tw-items-center">
          {style === BackgroundStyle.None ? (
            <span className="tw-text-sm tw-font-semibold tw-text-white">No effects</span>
          ) : null}
          {style === BackgroundStyle.DropShadow ? (
            <span className="tw-text-sm tw-font-semibold tw-text-white [text-shadow:_0px_2.61157px_2.61157px_rgba(0,_0,_0,_0.54)]">
              Drop shadow
            </span>
          ) : null}
          {style === BackgroundStyle.Background ? (
            <span className="tw-bg-black tw-px-1 tw-text-sm tw-font-semibold tw-text-white">Background</span>
          ) : null}
          {style === BackgroundStyle.Outline ? (
            <span className="tw-text-sm tw-font-semibold tw-text-white [text-shadow:_rgb(0,_0,_0)_-1px_1px_0px,_rgb(0,_0,_0)_1px_1px_0px,_rgb(0,_0,_0)_1px_-1px_0px,_rgb(0,_0,_0)_-1px_-1px_0px]">
              Outline
            </span>
          ) : null}
        </div>
        {style === BackgroundStyle.FullWidth ? (
          <span className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-h-8 tw-items-center tw-justify-center tw-rounded-b-md tw-bg-black tw-text-sm tw-font-semibold tw-text-white">
            Full width
          </span>
        ) : null}
      </div>
      <p
        className={classNames("tw-mt-2 tw-w-20 tw-text-center tw-text-xs tw-opacity-60 group-hover:tw-opacity-100", {
          "!tw-opacity-100": selected
        })}
      >
        {style}
      </p>
    </div>
  );
};
