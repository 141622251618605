import React, { useState } from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";

import { Alert } from "@frontend/components/alert/alert";
import { Fieldset } from "@frontend/components/form-controls/fieldset";
import settings from "@frontend/config/settings/settings";
import { isRealFolder } from "@frontend/utils/folder.utils";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { FileButton } from "@components/form-controls/file-input";
import { Tabs } from "@components/tabs/tabs";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useFileUploadQueue } from "@core/hooks/use-file-upload-queue";
import { useMediaInfo } from "@core/hooks/use-media-info";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { uploadStore } from "@core/state/upload";
import { readCsvFile } from "@core/utils/file";
import { ACCEPTED_DROPZONE_FORMATS } from "@core/utils/mime-types";
import { urlPattern } from "@core/utils/regex-patterns";
import { isUrl } from "@core/utils/url";

enum UploadOptionsTab {
  Local = "Local",
  URL = "URL",
  BulkURL = "BulkURL"
}

interface UploadOptionsSectionProps {
  className?: string;
}

export const UploadOptionsSection: React.FC<UploadOptionsSectionProps> = ({ className }) => {
  const [activeTab, setActiveTab] = useState(UploadOptionsTab.Local);

  const tabs = [
    { label: "From local", value: UploadOptionsTab.Local },
    { label: "From URL", value: UploadOptionsTab.URL },
    { label: "From CSV list", value: UploadOptionsTab.BulkURL }
  ];

  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-2", className)}>
      <Tabs className="tw-flex tw-w-full tw-border-b tw-border-neutral-100">
        {tabs.map((tab, index) => (
          <Tabs.Tab
            key={index}
            onClick={() => setActiveTab(tab.value)}
            selected={activeTab === tab.value}
            className="tw-flex-1"
            theme="primary"
          >
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs>
      {activeTab === UploadOptionsTab.Local && <LocalUploadPanel />}
      {activeTab === UploadOptionsTab.URL && <UrlUploadPanel />}
      {activeTab === UploadOptionsTab.BulkURL && <CsvUploadPanel />}
    </div>
  );
};

const LocalUploadPanel = () => {
  const { selectedFolderId } = useDashboard();
  const { isViewer } = useAccounts();
  const { handleAddFilesToUploadQueue } = useFileUploadQueue();

  const folderId = (isRealFolder(selectedFolderId) && selectedFolderId) ?? undefined;

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (files) => handleAddFilesToUploadQueue(files, folderId),
    accept: ACCEPTED_DROPZONE_FORMATS,
    minSize: 0,
    multiple: true,
    noClick: true,
    disabled: isViewer
  });

  return (
    <div className="tw-flex tw-min-h-10 tw-items-center" {...getRootProps()}>
      <input {...getInputProps()} />
      <Button variant="secondary" size="28" onClick={open} disabled={isViewer}>
        Choose media
      </Button>
    </div>
  );
};

type FormData = {
  url: string;
};

const UrlUploadPanel = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
    reset
  } = useForm<FormData>();
  const { convertUrlToUploadFile } = useMediaInfo();
  const { hasPermission: hasProPermission } = usePlanPermissions(SublyPlan.Pro);
  const { isViewer } = useAccounts();
  const { selectedFolderId } = useDashboard();
  const hasInputValue = Boolean(watch().url);

  const [isLoading, setLoading] = React.useState(false);

  const addFileFromURL = async ({ url }: FormData): Promise<void> => {
    try {
      if (!url) {
        return;
      }

      if (!hasProPermission) {
        uploadStore.resetUpload();
      }

      setLoading(true);

      // This shows the "checking file" loading spinner.
      uploadStore.setCheckingFile(true);

      const uploadFile = await convertUrlToUploadFile(url);

      // Get non-virtual folder id.
      const folderId = (isRealFolder(selectedFolderId) && selectedFolderId) ?? undefined;

      if (uploadFile) {
        uploadStore.addFilesToQueue([uploadFile], folderId);
      }

      reset();
    } catch {
      // Do nothing, error is already handled by the getVideoPlatformFileInfo() function
    } finally {
      uploadStore.setCheckingFile(false);
      setLoading(false);
    }
  };

  return (
    <div>
      <Fieldset>
        <form onSubmit={handleSubmit(addFileFromURL)}>
          <div className="tw-flex tw-w-full tw-items-center tw-gap-2">
            <Input
              placeholder="Enter Public URL: Google Drive, Dropbox, Youtube, Vimeo…"
              wrapperClassName="tw-w-full tw-flex-grow"
              className="tw-h-10"
              hasError={Boolean(errors.url)}
              variant="outline"
              {...register("url", { pattern: urlPattern })}
            />

            <Button
              variant="primary"
              type="submit"
              disabled={!isValid || isViewer || !hasInputValue}
              loading={isLoading}
              size="40"
            >
              Import
            </Button>
          </div>
          {errors.url && <span className="tw-text-aux-2-500">Please enter a valid URL</span>}
        </form>
      </Fieldset>
    </div>
  );
};

interface UploadUrl {
  url: string;
  languageCode: string;
  error: null | string;
}

const CsvUploadPanel = () => {
  const { convertUrlToUploadFile } = useMediaInfo();
  const { selectedFolderId } = useDashboard();
  const [csvErrors, setCsvErrors] = React.useState<string[]>([]);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const { isViewer } = useAccounts();
  const [showGuidelines, setShowGuidelines] = React.useState(false);
  const { languages } = settings.transcription;

  const parseRow = (row: string[], index: number): UploadUrl => {
    let [rowUrl, rowLanguageCode] = row;

    rowUrl = rowUrl?.trim() ?? "";
    rowLanguageCode = rowLanguageCode?.trim() ?? "";
    let error: string | null = null;

    if (!rowUrl || !rowLanguageCode) error = `Row ${index}: Missing URL or language code.`;
    if (!error && !isUrl(rowUrl)) error = `Row ${index}: Invalid URL.`;

    const language = languages.find(
      (language) =>
        language.languageCode.toLowerCase() === rowLanguageCode.toLowerCase() ||
        language.language.toLowerCase() === rowLanguageCode.toLowerCase()
    );

    if (!error && !language)
      error = `Row ${index}: Language code "${rowLanguageCode}" not found. Please check guidelines for the supported codes.`;

    return { url: rowUrl, languageCode: language?.languageCode ?? "", error };
  };

  const handleCsvSelect = async (file: File) => {
    setCsvErrors([]);

    const rows = await readCsvFile(file);

    if (!rows) {
      setCsvErrors(["No rows found in csv file."]);
      const fileInput = containerRef.current?.querySelector("input[type=file]") as HTMLInputElement | null;
      if (fileInput) fileInput.value = "";
      return;
    }

    const parsedRows = rows.map(parseRow);
    const uploadUrls = parsedRows.filter((item) => !item.error) as UploadUrl[];
    const uploadUrlErrors = parsedRows.map((item) => item.error).filter(Boolean) as string[];

    if (uploadUrlErrors.length) {
      setCsvErrors([...csvErrors, ...uploadUrlErrors]);
      return;
    }

    // This shows the "checking file" loading spinner.
    uploadStore.setCheckingFile(true);

    // Get non-virtual folder id.
    const folderId = (isRealFolder(selectedFolderId) && selectedFolderId) ?? undefined;

    for await (const uploadUrl of uploadUrls) {
      if (uploadUrl?.url) {
        const uploadFile = await convertUrlToUploadFile(uploadUrl.url, uploadUrl?.languageCode);
        if (uploadFile) {
          uploadStore.addFilesToQueue([uploadFile], folderId);
        }
      }
    }

    uploadStore.setCheckingFile(false);

    const fileInput = containerRef.current?.querySelector("input[type=file]") as HTMLInputElement | null;
    if (fileInput) fileInput.value = "";
  };

  const handleClickViewGuidelines = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowGuidelines(!showGuidelines);
  };

  return (
    <div className="tw-w-flex tw-flex-col" ref={containerRef}>
      <div className="tw-flex tw-min-h-10 tw-items-center">
        <div className="tw-mr-4 tw-text-sm tw-font-medium tw-text-neutral-800">Bulk URL Upload</div>
        <FileButton variant="secondary" accept=".csv" size="28" onChange={handleCsvSelect} disabled={isViewer}>
          Upload CSV file
        </FileButton>
        <div
          className={classNames(
            "tw-ml-auto tw-mt-0.5 tw-self-start tw-text-xs tw-font-normal  hover:tw-text-neutral-900",
            { "tw-text-neutral-900": showGuidelines, "tw-text-neutral-500": !showGuidelines }
          )}
        >
          (
          <a
            className={classNames("tw-cursor-pointer tw-underline hover:tw-underline", {
              "tw-underline": !showGuidelines,
              "hover:tw-underline": !showGuidelines
            })}
            href="#"
            onClick={handleClickViewGuidelines}
          >
            view guidelines
          </a>
          )
        </div>
      </div>
      {csvErrors.length > 0 && (
        <Alert warning className="tw-mb-1 tw-mt-2 tw-border-[1px] tw-border-warning-200 tw-bg-warning-50">
          <ul>
            {csvErrors.map((error, i) => (
              <li key={i} className="tw-text-xs tw-font-medium tw-text-warning-800">
                {error}
              </li>
            ))}
          </ul>
        </Alert>
      )}
      {showGuidelines ? (
        <div className="tw-my-1 tw-list-disc tw-rounded-lg tw-bg-neutral-50 tw-p-3 tw-text-sm">
          <h4 className="tw-font-medium tw-text-neutral-700">CSV structure:</h4>
          <ul className="tw-mb-1 tw-flex tw-list-disc tw-flex-col tw-gap-1 tw-p-3 tw-pl-6 ">
            <li>The CSV file must have two columns.</li>
            <li>The first column should contain the Media URL.</li>
            <li>The second column should contain the spoken Language Code.</li>
          </ul>

          <h4 className="tw-font-medium tw-text-neutral-700">Supported language codes:</h4>
          <ul className="tw-mb-1 tw-flex tw-list-disc tw-flex-col tw-gap-1 tw-p-3 tw-pl-6 ">
            {languages.map((language) => (
              <li key={language.languageCode} className="tw-flex">
                -{" "}
                <div className="tw-ml-1 tw-w-16">
                  <code className="tw-rounded tw-bg-neutral-200 tw-px-1">{language.languageCode}</code>:
                </div>{" "}
                {language.language}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
