import classNames from "classnames";

import config from "@frontend/config";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

interface AccountInfoProps {
  className?: string;
}

export const AccountInfo: React.FC<AccountInfoProps> = ({ className }) => {
  const { currentAccount } = useAccounts();
  const { plan, availableStorage, maxUploadLimit, credit } = usePlan();
  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);

  const bytesToGB = (bytes: number) => {
    const gb = bytes / 1024 / 1024 / 1024;
    return Math.round(gb * 100) / 100;
  };

  if (config.isProduction) {
    return;
  }

  if (!currentAccount) return null;

  return (
    <div
      className={classNames(
        "tw-flex tw-flex-col tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-2 tw-text-sm tw-text-neutral-500",
        className
      )}
    >
      <p>{currentAccount.accountName}</p>
      <p>{currentAccount.role}</p>
      <p>can upload: {Boolean(hasPermission && permissions.Create).toString()}</p>
      <p>
        {plan} ({Boolean(hasPermission).toString()})
      </p>
      <p>
        {bytesToGB(availableStorage)} GB (max upload: {bytesToGB(maxUploadLimit)} GB)
      </p>
      <p>
        {Math.floor((credit?.total || 0) / 60)} mins ({credit.total} sec)
      </p>
    </div>
  );
};
