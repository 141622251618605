import React from "react";
import classNames from "classnames";

import styles from "./divider.module.scss";

interface DividerProps {
  className?: string;
  children?: React.ReactNode;
}
export const Divider: React.FC<DividerProps> = ({ className, children }) => {
  return (
    <div className={classNames(styles.divider, className)}>
      {children && <span className={styles.text}>{children}</span>}
    </div>
  );
};
