import React from "react";

import { ResetPassword } from "@frontend/containers/auth/reset-password";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const ResetPasswordPage: React.FC = () => {
  return (
    <AuthLayout>
      <ResetPassword />
    </AuthLayout>
  );
};
