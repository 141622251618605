import { ModalType } from "@core/interfaces/modal-type";
import { ModalOptions, modalStore } from "@core/state/modal/modal.store";

type UseModal = [(component: JSX.Element) => void, () => void, () => void, (component: JSX.Element) => void];

export const useModal = (type: ModalType, options: ModalOptions = { isEditor: false }): UseModal => {
  const showModal = (component: JSX.Element) => {
    modalStore.showModal(type, component, options);
  };

  const hideModal = () => {
    modalStore.hideModal(type);
  };

  const hideAll = () => {
    modalStore.hideAll();
  };

  const toggleModal = (component: JSX.Element) => {
    modalStore.toggleModal(type, component, options);
  };

  return [showModal, hideModal, hideAll, toggleModal];
};
