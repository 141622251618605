import React from "react";

import { AccountType, getAccount } from "@frontend/api/billing.service";
import config from "@frontend/config";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { IApiAccountDetails, IFullApiDetails, UserAccount } from "@core/interfaces/account";
import { PaymentMethod } from "@core/interfaces/billing";
import { accountQuery } from "@core/state/account";
import { useObservable } from "@mindspace-io/react";

interface APIContext {
  apiAccount?: UserAccount;
  paymentMethods?: PaymentMethod[];
  fullApiDetails?: IFullApiDetails[];
  isApiKeyFetching: boolean;
  usage?: number;
  isLoading: boolean;
  shouldSetup: boolean;
  fetchAccount: (accountId: string) => Promise<void>;
  apiKey?: string;
  bucket?: string;
}

const APIContext = React.createContext<APIContext | null>(null);

interface APIProviderProps {
  children: React.ReactNode;
}
export const APIProvider: React.FC<APIProviderProps> = ({ children }) => {
  const { accounts, currentAccount } = useAccounts();
  const { isEnterprise } = usePlan();

  const [isLoading, setLoading] = React.useState(true);
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>();
  const [usage, setUsage] = React.useState<number>();
  const [shouldSetup, setShouldSetup] = React.useState(false);
  const [apiAccount, setApiAccount] = React.useState<UserAccount>();
  const [apiAccountDetails, setApiAccountDetails] = React.useState<IApiAccountDetails>();
  const [apiKey, setApiKey] = React.useState<string>();

  const [fullApiDetails] = useObservable(accountQuery.select("apiKeyDetails"), accountQuery.getValue().apiKeyDetails);

  React.useEffect(() => {
    setApiAccount(isEnterprise ? currentAccount : accounts.find(({ accountType }) => accountType === AccountType.Api));
  }, [isEnterprise, currentAccount, accounts]);

  React.useEffect(() => {
    setApiAccountDetails(apiAccount?.apiAccountDetails?.find(({ stage }) => stage === config.publicApi.apiStage));
  }, [apiAccount]);

  React.useEffect(() => {
    setApiKey(fullApiDetails?.details.find(({ stage }) => stage === config.publicApi.apiStage)?.apiKey);
  }, [fullApiDetails]);

  const fetchAccount = async (accountId: string) => {
    setLoading(true);
    const data = await getAccount(accountId);
    setPaymentMethods(data.customer.paymentMethods);
    setUsage(data.account.postPaidSeconds);
    setLoading(false);
  };

  React.useEffect(() => {
    if (!apiAccount) {
      setLoading(false);
      return;
    }

    fetchAccount(apiAccount.accountId);
  }, [apiAccount]);

  React.useEffect(() => {
    setShouldSetup(isEnterprise && !apiAccount?.apiAccountDetails?.length);
  }, [isEnterprise, apiAccount]);

  return (
    <APIContext.Provider
      value={{
        apiAccount,
        paymentMethods,
        fullApiDetails: fullApiDetails?.details,
        isApiKeyFetching: Boolean(fullApiDetails?.isLoading),
        usage,
        isLoading,
        shouldSetup,
        fetchAccount,
        apiKey,
        bucket: apiAccountDetails?.apiBucket
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export const useAPI = (): APIContext => {
  const context = React.useContext(APIContext);

  if (context == null) {
    throw new Error("useAPI must be used within a APIProvider");
  }

  return context;
};
