import React from "react";

import { NewModal } from "@components/new-modal";

import { DownloadMenu } from "@media-editor/components/download-dropdown/download-menu";
import { DownloadDropdownProvider } from "@media-editor/contexts/download-dropdown.context";

interface DownloadMediaModalProps {
  closeModal: (shouldClear?: boolean) => void;
}
export const DownloadMediaModal: React.FC<DownloadMediaModalProps> = ({ closeModal }) => {
  return (
    <NewModal onDismiss={closeModal} title="Download" showCloseButton disableBackgroundDismiss>
      <DownloadDropdownProvider>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <DownloadMenu closeModal={closeModal} />
        </div>
      </DownloadDropdownProvider>
    </NewModal>
  );
};
