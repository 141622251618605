import React from "react";

import {
  getAvailableDiscoveryDomains,
  getConfiguredDiscoveryDomains,
  updateAccountSettings,
  updateDiscoveryDomains
} from "@frontend/api/account.service";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Button } from "@components/button";
import { Toggle } from "@components/form-controls";
import { LoadingIcon } from "@components/loading-icon";
import { Select } from "@components/select";

import { useAccounts } from "@core/hooks/use-accounts";
import { accountStore } from "@core/state/account";

export const WorkspaceDiscoveryCard: React.FC = () => {
  const [availableDomains, setAvailableDomains] = React.useState<string[]>([]);
  const [selectedDomains, setSelectedDomains] = React.useState<string[]>([]);
  const [enabled, setEnabled] = React.useState<boolean>(useAccounts().settings?.isDiscoverable ?? false);
  const [loadingInitialDate, setLoadingInitialData] = React.useState<boolean>(true);
  const { user } = useAccounts();

  // onMount: retrieve initial data
  React.useEffect(() => {
    const accountId = getAccountId();
    (async () => {
      const promises = Promise.all([getConfiguredDiscoveryDomains(accountId), getAvailableDiscoveryDomains(accountId)]);
      const [selectedDomains, availableDomains] = await promises;
      setSelectedDomains(selectedDomains.domains);
      setAvailableDomains(availableDomains.domains);
      setLoadingInitialData(false);
    })();
  }, []);

  const currentUserEmailDomain = React.useMemo(() => user?.email.split("@")?.[1] ?? "", [user]);

  const handleSave = async (options: { enabled: boolean; selectedDomains: string[] }) => {
    await Promise.all([
      updateAccountSettings({ isDiscoverable: options.enabled }),
      updateDiscoveryDomains(getAccountId(), options.selectedDomains)
    ]);

    accountStore.updateAccountSettings({ isDiscoverable: options.enabled });

    setSelectedDomains(options.selectedDomains);
    setEnabled(options.enabled);
  };

  if (loadingInitialDate) {
    return (
      <SettingsCard>
        <SettingsCard.Header>Allowed email domains</SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <LoadingIcon className="tw-mr-2" />
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  return (
    <WorkspaceDiscoveryCardContent
      enabled={enabled}
      isFeatureAvailable={availableDomains.length > 0}
      availableDomains={availableDomains}
      selectedDomains={selectedDomains}
      currentUserEmailDomain={currentUserEmailDomain}
      onSave={handleSave}
    />
  );
};

type WorkspaceDiscoveryCardContentProps = {
  enabled: boolean;
  isFeatureAvailable: boolean;
  availableDomains: string[];
  selectedDomains: string[];
  currentUserEmailDomain: string;
  onSave: (options: { enabled: boolean; selectedDomains: string[] }) => void;
};
export const WorkspaceDiscoveryCardContent: React.FC<WorkspaceDiscoveryCardContentProps> = (props) => {
  const [localEnabled, setLocalEnabled] = React.useState(props.enabled);
  const [localSelectedDomains, setLocalSelectedDomains] = React.useState<string[]>(props.selectedDomains);
  const [saving, setSaving] = React.useState(false);

  const isDirty = React.useMemo(() => {
    const toggledDiscoverability = props.enabled !== localEnabled;
    const adjustedSelectedDomains = JSON.stringify([...props.selectedDomains].sort()) !== JSON.stringify([...localSelectedDomains].sort()); // eslint-disable-line
    return toggledDiscoverability || adjustedSelectedDomains;
  }, [props.enabled, localEnabled, props.selectedDomains, localSelectedDomains]);

  // Actions

  const saveChanges = async () => {
    setSaving(true);
    await props.onSave({ enabled: localEnabled, selectedDomains: localSelectedDomains });
    setSaving(false);
  };

  return (
    <SettingsCard>
      <SettingsCard.Header className="tw-flex tw-flex-col !tw-items-start tw-p-4 tw-text-lg tw-font-semibold">
        <div className="tw-mb-1">Allowed emails domains</div>
        <p className="tw-text-sm tw-font-normal tw-text-neutral-600">
          Anyone with email addresses at these domains can automatically join your workspace.
        </p>
      </SettingsCard.Header>

      <SettingsCard.Footer className="tw-space-y-4">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
          <div>
            <label htmlFor="discovery-toggle" className="tw-text-sm">
              Allow joining workspace by email domain
            </label>

            {!props.isFeatureAvailable && (
              <div className="tw-text-sm tw-text-neutral-500">
                Your account is registered with a free email domain ({props.currentUserEmailDomain}). To enable custom
                email domains, please use a work or paid email address for your account.
              </div>
            )}
          </div>
          <Toggle
            id="discovery-toggle"
            size={24}
            checked={localEnabled}
            onChange={() => setLocalEnabled(!localEnabled)}
            aria-label="Toggle workspace discovery"
            disabled={!props.isFeatureAvailable}
          />
        </div>

        {localEnabled && (
          <div className="tw-space-y-4">
            <Select
              variant="outline"
              placeholder="Select approved domains"
              value={localSelectedDomains}
              options={props.availableDomains.map((domain) => ({ label: domain, value: domain }))}
              onChange={(selectedDomains) => setLocalSelectedDomains(selectedDomains)}
              multiple
              aria-label="Select approved email domains"
            />

            <p className="tw-text-sm tw-text-neutral-500">
              You can only add email domains of people who are part of your workspace.
            </p>
          </div>
        )}
        {props.isFeatureAvailable && (
          <div className="tw-flex tw-justify-end">
            <Button
              onClick={saveChanges}
              disabled={!isDirty}
              aria-label="Save domain changes"
              size="32"
              loading={saving}
            >
              Save
            </Button>
          </div>
        )}
      </SettingsCard.Footer>
    </SettingsCard>
  );
};
