import React from "react";
import { AxiosError, isAxiosError } from "axios";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import smoothscroll from "smoothscroll-polyfill";

import config from "@frontend/config";

import { akitaConfig, akitaDevtools, enableAkitaProdMode } from "@datorama/akita";
import { enableElfProdMode } from "@ngneat/elf";
import { devTools } from "@ngneat/elf-devtools";
import * as Sentry from "@sentry/react";
import { Event, EventHint } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ErrorEvent } from "@sentry/types";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "@frontend/styles/index.scss";

if (config.isProduction) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: config.isProduction ? "production" : "development",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
      "ResizeObserver loop limit exceeded",
      "AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22"
    ],
    beforeSend: handleSentryBeforeSend
  });

  // Improve axios error logging based on: https://github.com/getsentry/sentry-javascript/issues/4377#issuecomment-2017757609

  function handleSentryBeforeSend(event: ErrorEvent, hint: EventHint) {
    return addAxiosContextRecursive(event, hint?.originalException);
  }

  function addAxiosContextRecursive(event: Event, error: unknown) {
    if (isAxiosError(error)) {
      // If the `/api/v1/auth/refresh-token` request is missing a refresh token, the user is logged out.
      // This is expected behavior, so the error can be safely ignored.
      if (error.response?.data?.code === "CookieMissing" && error.response?.data?.message === "Refresh token missing") {
        return null;
      }
      return addAxiosContext(event, error);
    } else if (error instanceof Error && error.cause) {
      return addAxiosContextRecursive(event, error.cause);
    }
    return event;
  }

  function addAxiosContext(event: Event, error: AxiosError) {
    if (error.response) {
      const contexts = { ...event.contexts };
      contexts.Axios = { Response: error.response };
      event.contexts = contexts;
    }
    return event;
  }
}

// Google Tag manager
const tagManagerArgs = {
  gtmId: config.gtmId
};

TagManager.initialize(tagManagerArgs);

if (config.isProduction) {
  enableAkitaProdMode();
  enableElfProdMode();
} else {
  akitaDevtools({ name: "Subly Akita" });
  devTools({ name: "Subly Elf" });
}

akitaConfig({ resettable: true });

// This function transforms the --vh css var when there is a resize of the viewport. Handy to
// handle the 100vh in mobile browsers. We have a postcss transforming all vh in css to use
// this var, but it is backword compatible.
// https://github.com/Faisal-Manzer/postcss-viewport-height-correction
function setViewportProperty(doc: HTMLElement) {
  let prevClientHeight: number;

  function handleResize() {
    const clientHeight = doc.clientHeight;

    if (clientHeight === prevClientHeight) return;

    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty("--vh", clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }

  handleResize();

  return handleResize;
}

window.addEventListener("resize", setViewportProperty(document.documentElement));

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();

smoothscroll.polyfill();
