import { Observable, of, switchMap } from "rxjs";

import { Query } from "@datorama/akita";

import { ModalState, ModalStore, modalStore, VisibleModal } from "./modal.store";

export class ModalQuery extends Query<ModalState> {
  get visibleModals(): VisibleModal[] {
    return this.getValue().visibleModals?.filter((t) => !t.options?.isEditor);
  }

  constructor(protected store: ModalStore) {
    super(store);
  }

  selectVisibleModals(): Observable<VisibleModal[]> {
    return this.select("visibleModals").pipe(switchMap((modals) => of(modals.filter((t) => !t.options?.isEditor))));
  }

  selectVisibleEditorModals(): Observable<VisibleModal[]> {
    return this.select("visibleModals").pipe(switchMap((modals) => of(modals.filter((t) => t.options?.isEditor))));
  }
}

export const modalQuery = new ModalQuery(modalStore);
