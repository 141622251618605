import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { IGlossary } from "@core/interfaces/glossary";
import { accountStore } from "@core/state/account";
import { sortByLanguage } from "@core/utils/sort";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface GlossaryRulesResponse {
  glossaryRules: IGlossary[];
}

export const getGlossaryRules = async (): Promise<void> => {
  try {
    accountStore.update({ loading: true });

    const { data } = await axios.get<GlossaryRulesResponse>(`${getAccountId()}/glossary`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const { glossaryRules } = data;

    accountStore.update((s) => ({
      ...s,
      glossary: sortByLanguage(glossaryRules),
      loading: false
    }));
  } catch (error) {
    handleError(error);
  }
};

interface CreateGlossaryRuleParams {
  sourceLanguage: string;
  sourceWords: string;
  targetLanguage: string;
  targetWords: string;
}

export const createGlossaryRule = async (params: CreateGlossaryRuleParams): Promise<void> => {
  try {
    const accountId = getAccountId();
    accountStore.update({ loading: true });

    const { data } = await axios.post(`/${accountId}/glossary/add`, params, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.update((s) => ({
      ...s,
      glossary: sortByLanguage(data.glossaryRules),
      loading: false
    }));
  } catch (e) {
    handleError(e);
  }
};

type UpdateGlossaryRuleParams = {
  sourceLanguage: string;
  sourceWords: string;
  targetLanguage: string;
  targetWords: string;
};

export const updateGlossaryRule = async (ruleId: string, params: UpdateGlossaryRuleParams): Promise<void> => {
  try {
    const accountId = getAccountId();

    const { data } = await axios.put(`/${accountId}/glossary/${ruleId}`, params, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.update((s) => ({
      ...s,
      glossary: sortByLanguage(data.glossaryRules)
    }));
  } catch (e) {
    handleError(e);
  }
};
export const deleteGlossaryRule = async (ruleId: string): Promise<void> => {
  try {
    const accountId = getAccountId();
    const { data } = await axios.delete(`/${accountId}/glossary/${ruleId}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.update((s) => ({
      ...s,
      glossary: sortByLanguage(data.glossaryRules)
    }));
  } catch (e) {
    handleError(e);
  }
};
