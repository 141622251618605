import React from "react";

import { Cue, MediaSpeaker } from "@getsubly/common";

interface SpeakerRecognitionContext {
  currentSpeaker?: MediaSpeaker;
  setCurrentSpeaker: React.Dispatch<React.SetStateAction<MediaSpeaker | undefined>>;
  speakerChosenReload: number;
  setSpeakerChosenReload: React.Dispatch<React.SetStateAction<number>>;
  speakerDropdownVisible: boolean;
  setSpeakerDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  speakerModalToggle: boolean;
  setSpeakerModalToggle: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCue: Cue | undefined;
  setSelectedCue: React.Dispatch<React.SetStateAction<Cue | undefined>>;
}

const SpeakerRecognitionContext = React.createContext<SpeakerRecognitionContext | null>(null);

interface SpeakerRecognitionProviderProps {
  children: React.ReactNode;
}
export const SpeakerRecognitionProvider: React.FC<SpeakerRecognitionProviderProps> = ({ children }) => {
  const [currentSpeaker, setCurrentSpeaker] = React.useState<MediaSpeaker>();
  const [speakerChosenReload, setSpeakerChosenReload] = React.useState<number>(0);
  const [speakerDropdownVisible, setSpeakerDropdownVisible] = React.useState(false);
  const [speakerModalToggle, setSpeakerModalToggle] = React.useState(false);
  const [selectedCue, setSelectedCue] = React.useState<Cue>();

  return (
    <SpeakerRecognitionContext.Provider
      value={{
        currentSpeaker,
        setCurrentSpeaker,
        speakerChosenReload,
        setSpeakerChosenReload,
        speakerDropdownVisible,
        setSpeakerDropdownVisible,
        speakerModalToggle,
        setSpeakerModalToggle,
        selectedCue,
        setSelectedCue
      }}
    >
      {children}
    </SpeakerRecognitionContext.Provider>
  );
};

export const useSpeakerRecognition = (): SpeakerRecognitionContext => {
  const context = React.useContext(SpeakerRecognitionContext);

  if (context === null) {
    throw new Error("useSpeakerRecognition must be used within a SpeakerRecognitionProvider");
  }

  return context;
};
