import React from "react";

import { Button } from "@components/button";
import { NewModal } from "@components/new-modal";

interface PromptProps {
  placeholder?: string;
  onConfirm: (value: string) => void;
  onCancel: () => void;
}
export const AttachUserGroupConfirmation: React.FC<PromptProps> = ({ placeholder, onConfirm, onCancel }) => {
  return (
    <NewModal
      onDismiss={onCancel}
      disableBackgroundDismiss
      size="465"
      className="tw-top-1/2 tw--translate-y-1/2 !tw-px-2 !tw-pb-2 !tw-pt-4"
      headerClassName="tw-px-4"
      title="Are you sure?"
      isCentred
    >
      <AttachUserGroupConfirmationContainer placeholder={placeholder} onConfirm={onConfirm} onCancel={onCancel} />
    </NewModal>
  );
};

const AttachUserGroupConfirmationContainer: React.FC<PromptProps> = ({ onConfirm, onCancel }) => {
  return (
    <div className="tw-flex tw-flex-col tw-p-4 tw-pt-0 tw-text-center">
      <p className="tw-text-sm tw-text-neutral-600">
        The content of this team folder will only be visible to a specific group.
      </p>
      <p className="tw-text-sm tw-font-semibold tw-text-neutral-900">
        All non-members of the group will lose access to it.
      </p>
      <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-2">
        <Button variant="secondary" onClick={onConfirm}>
          <span className="tw-text-destructive-600">Make it group folder</span>
        </Button>
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
