import React from "react";

import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownHeader, DropdownItem, DropdownMenu } from "@components/dropdown";
import { Checkbox } from "@components/form-controls";

import { useActiveMediaConfigState } from "@core/state/editor/editor.hooks";
import { MediaSnippet } from "@getsubly/common";
import { useDownloadDropdown } from "@media-editor/contexts/download-dropdown.context";

export const SnippetsDropdown: React.FC = () => {
  const { snippets, toggleSnippet } = useDownloadDropdown();
  const mediaConfig = useActiveMediaConfigState();

  if (!mediaConfig?.snippets) {
    return null;
  }

  const hasSelected = (snippet: MediaSnippet): boolean => {
    return snippets.some((s) => s.id === snippet.id);
  };

  const renderOptions = Object.values(mediaConfig?.snippets).map((snippet) => {
    const isSelected = hasSelected(snippet);

    return (
      <DropdownItem key={snippet.id} className="!tw-p-0" keepOpenOnClick>
        <Checkbox
          className="tw-w-full tw-px-6 tw-py-3"
          checked={isSelected}
          onChange={() => toggleSnippet(snippet)}
          large
          position="right"
        >
          <div className="tw-flex tw-w-full tw-items-center">{snippet.name}</div>
        </Checkbox>
      </DropdownItem>
    );
  });

  const getLabel = (): string => {
    if (snippets.length === 0) {
      return "Select snippet(s)";
    } else if (snippets.length === 1) {
      return snippets[0].name;
    }

    return `${snippets.length} snippets selected`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary">
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[360px]" placement="bottom-start">
        <DropdownHeader>Select one or more snippets</DropdownHeader>
        <div className="tw-max-h-[380px] tw-overflow-y-auto">{renderOptions}</div>
        <DropdownItem className="hover:!tw-bg-[transparent]">
          <Button variant="primary" className="tw-w-full" size="36">
            Apply
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
