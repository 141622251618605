import React, { useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { Button } from "@components/button";
import { CloseIcon } from "@components/icons";

type NewModalSize = "930" | "608" | "465" | "full";

export interface NewModalProps {
  showCloseButton?: boolean;
  disableBackgroundDismiss?: boolean;
  className?: string;
  isCentred?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  closeIconClassName?: string;
  headerClassName?: string;
  size?: NewModalSize;
  onDismiss?: () => void;
  // primary action
  primaryAction?: string;
  isPrimaryWarning?: boolean;
  primaryActionClassName?: string;
  primaryActionLoading?: boolean;
  primaryActionDisabled?: boolean;
  toPrimary?: string;
  onClickPrimary?: () => void;
  // secondary action
  secondaryAction?: string;
  secondaryActionDisabled?: boolean;
  onClickSecondary?: () => void;
  // tertiary action
  tertiaryAction?: string;
  tertiaryActionDisabled?: boolean;
  onClickTertiary?: () => void;
}
export const NewModal: React.FC<NewModalProps> = ({
  showCloseButton,
  disableBackgroundDismiss,
  className,
  isCentred,
  title,
  description,
  children,
  closeIconClassName,
  headerClassName,
  size = "465",
  onDismiss,
  // primary action
  primaryAction,
  isPrimaryWarning = false,
  primaryActionClassName,
  primaryActionLoading,
  primaryActionDisabled,
  toPrimary,
  onClickPrimary,
  // secondary action
  secondaryAction,
  secondaryActionDisabled,
  onClickSecondary,
  // tertiary action
  tertiaryAction,
  tertiaryActionDisabled,
  onClickTertiary
}) => {
  const containerEl = document.getElementById("portal");

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss && onDismiss();
      }
    };

    document.body.classList.add("tw-overflow-hidden");
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.body.classList.remove("tw-overflow-hidden");
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onDismiss]);

  if (!containerEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "new-modal tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1001] tw-flex tw-flex-col tw-items-center tw-overflow-auto",
        { "tw-text-center": isCentred }
      )}
      onClick={() => !disableBackgroundDismiss && onDismiss?.()}
    >
      <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-inline-block tw-bg-[#1e1e1e99]" />
      <div
        className={classNames(
          "tw-relative tw-top-[50px] tw-z-50 tw-flex tw-max-h-[80vh] tw-w-full tw-flex-col tw-overflow-y-auto tw-rounded-[10px] tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-p-6 tw-shadow-md",
          className,
          "tw-max-w-[calc(100vw_-_48px)]",
          {
            "!tw-w-[465px]": size === "465",
            "!tw-w-[608px]": size === "608",
            "!tw-w-[930px]": size === "930",
            "!tw-w-full": size === "full"
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <NewModalHeader
          showCloseButton={showCloseButton}
          isCentred={isCentred}
          title={title}
          description={description}
          closeIconClassName={closeIconClassName}
          className={headerClassName}
          onDismiss={onDismiss}
        />
        {children}
        <NewModalFooter
          isCentred={isCentred}
          // primary
          primaryAction={primaryAction}
          isPrimaryWarning={isPrimaryWarning}
          primaryActionClassName={primaryActionClassName}
          primaryActionLoading={primaryActionLoading}
          primaryActionDisabled={primaryActionDisabled}
          toPrimary={toPrimary}
          onClickPrimary={onClickPrimary}
          // secondary
          secondaryAction={secondaryAction}
          secondaryActionDisabled={secondaryActionDisabled}
          onClickSecondary={onClickSecondary}
          // tertiary
          tertiaryAction={tertiaryAction}
          tertiaryActionDisabled={tertiaryActionDisabled}
          onClickTertiary={onClickTertiary}
        />
      </div>
    </div>,
    containerEl
  ) as React.ReactPortal;
};

export interface NewModalHeaderProps {
  showCloseButton?: boolean;
  isCentred?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  closeIconClassName?: string;
  onDismiss?: () => void;
  className?: string;
}
export const NewModalHeader: React.FC<NewModalHeaderProps> = ({
  showCloseButton,
  isCentred,
  title,
  description,
  closeIconClassName,
  onDismiss,
  className
}) => {
  return (
    <header className={classNames("tw-mb-2", className)}>
      <div className="tw-flex tw-items-center">
        {showCloseButton && (
          <CloseIcon
            className={classNames(
              "tw-absolute tw-right-6 tw-top-7 tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500",
              closeIconClassName
            )}
            onClick={onDismiss}
            cursor="pointer"
          />
        )}
        {title && (
          <p
            className={classNames("tw-inline-block tw-text-lg tw-font-semibold", {
              "tw-mx-auto tw-inline-block tw-text-lg tw-font-semibold": isCentred
            })}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </div>
      {description && (
        <p
          className={classNames("tw-text-pretty tw-text-sm tw-text-neutral-600", {
            "tw-mt-1": Boolean(title)
          })}
        >
          {description}
        </p>
      )}
    </header>
  );
};

export interface NewModalFooterProps {
  isCentred?: boolean;
  // primary
  primaryAction?: string;
  isPrimaryWarning?: boolean;
  primaryActionClassName?: string;
  primaryActionLoading?: boolean;
  primaryActionDisabled?: boolean;
  toPrimary?: string;
  onClickPrimary?: () => void;
  // secondary
  secondaryAction?: string;
  secondaryActionDisabled?: boolean;
  onClickSecondary?: () => void;
  // tertiary
  tertiaryAction?: string;
  tertiaryActionDisabled?: boolean;
  onClickTertiary?: () => void;
}
export const NewModalFooter: React.FC<NewModalFooterProps> = ({
  isCentred,
  // primary
  primaryAction,
  isPrimaryWarning = false,
  primaryActionClassName,
  primaryActionLoading,
  primaryActionDisabled,
  toPrimary,
  onClickPrimary,
  // secondary
  secondaryAction,
  secondaryActionDisabled,
  onClickSecondary,
  // tertiary
  tertiaryAction,
  tertiaryActionDisabled,
  onClickTertiary
}) => {
  return (
    <div
      className={classNames("tw-flex tw-justify-end tw-gap-3", {
        "tw-flex tw-flex-col-reverse": isCentred
      })}
    >
      {tertiaryAction && (
        <Button
          type="button"
          variant="secondary"
          size="32"
          onClick={onClickTertiary}
          disabled={tertiaryActionDisabled}
          className={classNames({ "tw-mr-auto": !isCentred })}
        >
          {tertiaryAction}
        </Button>
      )}
      {secondaryAction && (
        <Button
          type="button"
          variant="secondary"
          size="32"
          onClick={onClickSecondary}
          disabled={secondaryActionDisabled}
        >
          {secondaryAction}
        </Button>
      )}
      {primaryAction && (
        <Button
          type={toPrimary ? "link" : "button"}
          variant="primary"
          size="32"
          className={classNames(primaryActionClassName, {
            "!tw-flex !tw-rounded-md !tw-border !tw-border-destructive-500 !tw-bg-destructive-100 !tw-text-destructive-500 hover:!tw-bg-destructive-500 hover:!tw-text-white":
              isPrimaryWarning
          })}
          disabled={primaryActionDisabled || primaryActionLoading}
          loading={primaryActionLoading}
          onClick={onClickPrimary}
          to={toPrimary as any}
        >
          {primaryAction}
        </Button>
      )}
    </div>
  );
};
