import { AspectRatio } from "@getsubly/common";

export const calculateSize = (baseWidth: number, ratio: AspectRatio, videoRatio: number, isLandscape: boolean) => {
  switch (ratio) {
    case AspectRatio.Square:
      return [baseWidth, baseWidth];
    case AspectRatio.Landscape:
      return [baseWidth, baseWidth / (16 / 9)];
    case AspectRatio.Portrait:
      return [baseWidth / (16 / 9), baseWidth];
    case AspectRatio.Vertical:
      return [baseWidth / (5 / 4), baseWidth];
    default: {
      if (isLandscape) {
        return [baseWidth, baseWidth * (1 / videoRatio)];
      } else {
        return [baseWidth * videoRatio, baseWidth];
      }
    }
  }
};
