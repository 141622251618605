import React from "react";
import numeral from "numeral";

import { PaymentMethod } from "@core/interfaces/billing";
import { getCardImage } from "@core/utils/card-images";

interface CardPreviewProps {
  card: PaymentMethod;
}

export const CardPreview: React.FC<CardPreviewProps> = ({ card }) => {
  const expiryMonth = numeral(card.exp_month).format("00");
  const cardImage = getCardImage(card.brand);

  return (
    <div className="tw-flex tw-items-start">
      {cardImage && <img src={cardImage} alt={card.brand} className="tw-mr-4" height="60" />}
      <div>
        <h5 className="tw-text-h5 tw-font-normal tw-capitalize tw-text-black">
          {card.brand} **** {card.last4}
        </h5>
        <p className="tw-mb-0">
          Expires {expiryMonth}/{card.exp_year}
        </p>
      </div>
    </div>
  );
};
