import React from "react";

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import { Input } from "@components/form-controls";

import { hexToRgba, isValidHex, rgbaToHex } from "@getsubly/common/dist/assParser/colors";

interface ColorInputsProps {
  color: string;
  onChange: ({ color, isValid }: { color: string; isValid: boolean }) => void;
  showAlpha?: boolean;
  defaultSystem?: ColorSystem;
}
export const ColorInputs: React.FC<ColorInputsProps> = ({
  color,
  onChange,
  showAlpha,
  defaultSystem = ColorSystem.HEX
}) => {
  const [colorSystem, setColorSystem] = React.useState<ColorSystem>(defaultSystem);
  const [hex, setHex] = React.useState<string>(color);
  const [rgba, setRgba] = React.useState(hexToRgba(color));
  const [opacity, setOpacity] = React.useState<number>(hexToRgba(color).a || 1);

  const handleChange = (hexColor: string) => {
    onChange({ color: hexColor, isValid: isValidHex(hexColor) });
  };

  const handleChangeHex = (color: string) => {
    setHex(color);
    setRgba(hexToRgba(color));
    handleChange(color);
  };

  const handleChangeRgb = (type: "r" | "g" | "b", value: number) => {
    const updatedRgba = {
      ...rgba,
      [type]: value,
      a: showAlpha ? opacity : 1
    };

    setRgba(updatedRgba);
    setHex(rgbaToHex(updatedRgba));
    handleChange(rgbaToHex(updatedRgba));
  };

  const handleChangeOpacity = (value: number) => {
    setOpacity(value / 100);

    const updatedRgba = {
      ...rgba,
      a: showAlpha ? value / 100 : 1
    };

    setRgba(updatedRgba);
    setHex(rgbaToHex(updatedRgba));
    handleChange(rgbaToHex(updatedRgba));
  };

  React.useEffect(() => {
    setHex(color);
    setRgba(hexToRgba(color));
    setOpacity(hexToRgba(color).a ?? 1);
  }, [color]);

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
      <ColorSystemDropdown system={colorSystem} onChange={setColorSystem} className="!tw-px-0.5 tw-py-0" />

      {colorSystem === ColorSystem.HEX && (
        <Input
          type="text"
          sizeH="32"
          value={hex.slice(0, 7)}
          onChange={(c) => handleChangeHex(c.target.value)}
          maxLength={7}
          className="tw-h-7 tw-px-1.5 tw-text-xs focus:!tw-px-1.5 "
        />
      )}
      {colorSystem === ColorSystem.RGB && (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.r}
            onChange={({ target }) => {
              handleChangeRgb("r", Number(target.value));
            }}
            min={0}
            max={255}
          />
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.g}
            onChange={({ target }) => handleChangeRgb("g", Number(target.value))}
            min={0}
            max={255}
          />
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.b}
            onChange={({ target }) => handleChangeRgb("b", Number(target.value))}
            min={0}
            max={255}
          />
        </div>
      )}
      {showAlpha && (
        <Input
          type="number"
          sizeH="32"
          className="!tw-px-1"
          value={Math.round(opacity * 100)}
          onChange={({ target }) => handleChangeOpacity(Number(target.value))}
          min={0}
          max={100}
        />
      )}
    </div>
  );
};

export enum ColorSystem {
  HEX = "HEX",
  RGB = "RGB"
}

interface ColorSystemProps {
  system: ColorSystem;
  onChange: (system: ColorSystem) => void;
  className?: string;
}
export const ColorSystemDropdown: React.FC<ColorSystemProps> = ({ system, onChange, className }) => {
  return (
    <Dropdown>
      <DropdownButton className={className} size="32">
        <p className="tw-text-xs">{system}</p>
      </DropdownButton>
      <DropdownMenu className="tw-w-full tw-overflow-y-auto">
        {Object.values(ColorSystem).map((s) => (
          <DropdownItem
            key={s}
            onClick={() => onChange(s)}
            selected={s === system}
            className="tw-flex tw-items-center tw-justify-between"
          >
            <p className="tw-text-sm tw-font-normal">{s}</p>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
