import React from "react";
import classNames from "classnames";

import styles from "./color-input-button.module.scss";

interface ColorInputButtonProps {
  className?: string;
  disabled?: boolean;
  isOpenClassName?: string;
  children: React.ReactNode;
  isOpen?: boolean;
  onClick?: () => void;
}

export const ColorInputButton: React.FC<ColorInputButtonProps> = ({
  className,
  disabled = false,

  isOpenClassName,
  children,
  onClick,
  isOpen
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    onClick?.();
  };

  return (
    <div
      className={classNames(
        className,
        styles["colorInputButton"],
        {
          [styles["opened"]]: isOpen,
          [styles["disabled"]]: disabled,
          [styles["bordered"]]: true
        },
        isOpen && isOpenClassName
      )}
      onClick={(event) => handleClick(event)}
    >
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center">{children}</div>
    </div>
  );
};
