import React from "react";
import classNames from "classnames";

import { ChevronDownIcon, ChevronUpIcon } from "@components/icons";

import styles from "./accordion.module.scss";

interface AccordionProps {
  children: React.ReactNode;
  className?: string;
}
const AccordionContainer: React.FC<AccordionProps> = ({ children, className }) => {
  return <div className={classNames("tw-flex tw-w-full tw-flex-col", className)}>{children}</div>;
};

interface AccordionTabProps {
  title: string;
  titleIcon?: React.ReactElement;
  titleClassName?: string;
  contentClassName?: string;
  titleDarker?: boolean;
  open?: boolean;
  titleBadge?: React.ReactElement;
  disabled?: boolean;
  disableTabIndex?: boolean;
  children: React.ReactNode;
}
const AccordionTab: React.FC<AccordionTabProps> = ({
  title,
  titleIcon,
  titleClassName,
  contentClassName,
  titleDarker,
  open,
  titleBadge,
  disabled,
  children,
  disableTabIndex
}) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const icon = isOpen ? (
    <ChevronUpIcon className="tw-h-4 tw-w-4" strokeWidth="3" />
  ) : (
    <ChevronDownIcon className="tw-h-4 tw-w-4" strokeWidth="3" />
  );

  const tabIndex = disableTabIndex ? -1 : 0;

  const onClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <button
        className={classNames(
          "tw-h tw-flex tw-w-full tw-cursor-pointer tw-select-none tw-items-center tw-justify-between tw-gap-4 tw-rounded-t-md tw-px-5 tw-py-4 tw-text-left focus-visible:tw-border focus-visible:tw-border-primary-500",
          styles.title,
          {
            "tw-bg-neutral-50": titleDarker,
            [styles["disabled"]]: disabled
          },
          titleClassName
        )}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          {titleIcon}
          <p className="tw-flex-grow tw-font-semibold tw-text-neutral-900">{title}</p>
          {titleBadge}
        </div>
        {icon}
      </button>
      {isOpen && <div className={classNames("tw-px-5 tw-pb-6", contentClassName)}>{children}</div>}
      <div className="tw-mx-4 tw-border-b tw-border-neutral-200 last:tw-border-b-0"></div>
    </>
  );
};

export const Accordion = Object.assign(AccordionContainer, {
  AccordionTab
});
