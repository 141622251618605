import { downloadQueueQuery, downloadQueueStore, QueueFile } from "@core/state/download-queue";
import { useObservable } from "@mindspace-io/react";

interface UseDownloadQueue {
  files: QueueFile[];
  addFiles: (batchId: string, files: QueueFile[]) => void;
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  batchId?: string;
  notifyUserId?: string;
  hasFile: (hash: string) => boolean;
}

export function useDownloadQueue(mediaId = ""): UseDownloadQueue {
  const [files] = useObservable(downloadQueueQuery.selectQueueFiles(mediaId), []);
  const [isVisible] = useObservable(downloadQueueQuery.selectIsVisible(mediaId), false);
  const [batchId] = useObservable(downloadQueueQuery.selectEntity(mediaId, "batchId"));

  const [notifyUserId] = useObservable(downloadQueueQuery.selectEntity(mediaId, "notifyUserId"));

  const addFiles = (batchId: string, files: QueueFile[]) => {
    downloadQueueStore.addFiles({ mediaId, batchId, files });
  };

  const setVisible = (visible: boolean) => {
    downloadQueueStore.setVisible(mediaId, visible);
  };

  const hasFile = (hash: string): boolean => {
    const state = downloadQueueQuery.getEntity(mediaId);

    return state?.files?.some((f) => f.batchFile?.hash === hash) ?? false;
  };

  return {
    files,
    addFiles,
    isVisible,
    setVisible,
    batchId,
    notifyUserId,
    hasFile
  };
}
