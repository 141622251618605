import { VirtualFolderId } from "@core/state/dashboard/dashboard.store";

/**
 * Checks if the provided folderId corresponds to a real folder stored in the database.
 *
 * This function differentiates between actual folders and DefaultFolderId values, which are keywords
 * used for special media retrieval methods (e.g., retrieving all accessible media, all shared media, etc.).
 *
 * @param {null | string | VirtualFolderId} folderId - The ID of the folder to check. This can be null, a string, or a VirtualFolderId.
 * @returns {null | string} - Returns null if the folderId is null or if it corresponds to a default folder. Otherwise, returns the folderId.
 */
export const isRealFolder = (folderId?: null | string | VirtualFolderId): null | string => {
  if (folderId == null) return null;

  const defaultFolders = [VirtualFolderId.All, VirtualFolderId.AllPrivate, VirtualFolderId.AllShared];
  if (defaultFolders.includes(folderId as VirtualFolderId)) return null;

  return folderId;
};
