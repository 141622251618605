import React from "react";
import classNames from "classnames";

import { handleError } from "@frontend/api/handle-error";
import { makeMediaAccessible } from "@frontend/api/media.service";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Checkbox } from "@components/form-controls";
import { NewBadge } from "@components/new-badge/new-badge";
import { NewModal } from "@components/new-modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { PaymentMethod } from "@core/interfaces/billing";
import { MediaListItem } from "@core/interfaces/media";
import { LinesOption } from "@media-editor/components/editor-sidebar/panes/guidelines/guidelines";
import { RiFilmLine } from "@remixicon/react";
import { useStripe } from "@stripe/react-stripe-js";

function secondsToHms(d: number) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + " hour " : "";
  const mDisplay = m > 0 ? m + " min " : "";
  const sDisplay = s > 0 ? s + " sec" : "";
  return hDisplay + mDisplay + sDisplay;
}

interface AccessibilityModal {
  mediaList: MediaListItem[];
  onDismiss: () => void;
  onComplete: () => void;
}

export const AccessibilityModal: React.FC<AccessibilityModal> = ({ mediaList, onDismiss, onComplete }) => {
  const stripe = useStripe();

  if (!stripe) return;

  // values
  const [spokenLanguage, setSpokenLanguage] = React.useState<string>("en-uk");
  const [lineCount, setLineCount] = React.useState<number>(1);

  // pricing

  const { currency, currencySymbol, billing, paymentMethods: paymentCards = [] } = useAccounts();

  const price = { USD: 7, EUR: 6.49, GBP: 6, AUD: 10.59 }[currency];

  const paymentCard = paymentCards[0];

  const stripeCredit = billing?.details?.balance ?? 0;

  const totalMinutes = React.useMemo<number>(() => {
    return mediaList.reduce((duration, media) => duration + media.duration, 0);
  }, [mediaList]);

  const subtotalCost = React.useMemo<number>(() => {
    const units = Math.ceil(totalMinutes / 60);
    return units * price;
  }, [totalMinutes]);

  const stripeCreditDeduction = Math.min(stripeCredit, price);

  const tax = React.useMemo<number>(() => {
    return subtotalCost * 0.2;
  }, [totalMinutes]);

  const totalCost = React.useMemo<number>(() => {
    return subtotalCost + tax - stripeCreditDeduction;
  }, [totalMinutes, stripeCreditDeduction]);

  const estimatedDeliveryTime = "3 days";

  // state
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [panel, setPanel] = React.useState<"default" | "payment" | "success">("default");

  // handlers
  const handlePay = () => {
    setIsSubmitting(true);

    makeMediaAccessible({
      mediaIds: mediaList.map((media) => media.mediaId),
      spokenLanguage: spokenLanguage,
      guidelinesLines: lineCount,
      translationLanguages: [],
      subtitle: false,
      colourBlindness: false
    })
      .then(async (response) => {
        if (response?.status === "requires_action" && response.nextAction) {
          const { error } = await stripe.confirmCardPayment(response.nextAction.clientSecret, {
            payment_method: response.nextAction.payment
          });

          if (error?.message) {
            handleError(error);
            throw new Error(error.message);
          }
        }

        setPanel("success");
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <NewModal
      onDismiss={onDismiss}
      showCloseButton
      size="608"
      className="tw-overflow-y-auto tw-py-4"
      closeIconClassName="!tw-top-5"
    >
      {panel === "success" && <div className="tw-absolute tw-top-0 tw--ml-6 tw-h-2 tw-w-full tw-bg-success-600" />}
      {panel === "default" && (
        <DefaultPanel
          mediaList={mediaList}
          spokenLanguage={spokenLanguage}
          onChangeSpokenLanguage={setSpokenLanguage}
          lineCount={lineCount}
          subtotalCost={subtotalCost}
          currencySymbol={currencySymbol}
          price={price}
          onChangeLineCount={setLineCount}
          onSubmit={() => setPanel("payment")}
          estimatedDeliveryTime={estimatedDeliveryTime}
        />
      )}
      {panel === "payment" && (
        <PaymentPanel
          totalMinutes={totalMinutes}
          subtotalCost={subtotalCost}
          tax={tax}
          price={price}
          totalCost={totalCost}
          currencySymbol={currencySymbol}
          isSubmitting={isSubmitting}
          stripeCredit={stripeCredit}
          stripeCreditDeduction={stripeCreditDeduction}
          paymentCard={paymentCard}
          onPay={handlePay}
          onBack={() => setPanel("default")}
          onDismiss={onDismiss}
        />
      )}
      {panel === "success" && <SuccessPanel onClose={onComplete} estimatedDeliveryTime={estimatedDeliveryTime} />}
    </NewModal>
  );
};

type DefaultPanelProps = {
  spokenLanguage: string;
  mediaList: MediaListItem[];
  currencySymbol: string;
  lineCount: number;
  estimatedDeliveryTime: string;
  subtotalCost: number;
  price: number;
  onChangeLineCount: (lineCount: number) => void;
  onSubmit?: () => void;
  onChangeSpokenLanguage?: (languageCode: string) => void;
};

const DefaultPanel: React.FC<DefaultPanelProps> = ({
  spokenLanguage,
  mediaList,
  subtotalCost,
  lineCount,
  estimatedDeliveryTime,
  currencySymbol,
  price,
  onChangeLineCount,
  onSubmit,
  onChangeSpokenLanguage
}) => {
  return (
    <>
      <h3 className="tw-mb-2 tw-text-lg tw-font-semibold tw-text-neutral-900">
        Make it accessible <NewBadge variant="aux-soft" label="BETA" className="tw-inline-flex" />
      </h3>
      <p className="tw-mb-4 tw-text-sm tw-text-neutral-600">
        Easily check if your video is accessible with the people in your team folders or if it's accessible to people
        with limitations.
      </p>

      <SelectedMedia mediaList={mediaList} className="tw-mb-6" />

      <InputLanguage value={spokenLanguage} onChange={onChangeSpokenLanguage} className="tw-mb-6" />

      <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
        <h5 className="tw-text-md tw-font-medium tw-text-neutral-900">Row counts</h5>
        <LinesOption init={lineCount} onChange={onChangeLineCount} />
      </div>

      <Section className="tw-relative tw-mb-4">
        <SectionHeader className="tw-relative !tw-text-lg tw-font-semibold">
          What you get
          <div className="tw-absolute tw-right-1 tw-top-1 tw-text-xs">
            <span className="tw-font-sm tw-font-medium tw-text-neutral-900">
              {currencySymbol}
              {price}
            </span>
            <span className="tw-text-xs tw-font-normal tw-text-neutral-600">/minute</span>
          </div>
        </SectionHeader>
        <SectionContent className="tw-flex tw-flex-col tw-gap-2" indented={false}>
          <p className="tw-text-sm tw-text-neutral-900">
            <strong className="tw-font-semibold">Subtitles and Captions:</strong>
            <br />
            Accurate, timestamped dialogue and non-verbal sound identification.
          </p>
          <p className="tw-text-sm tw-text-neutral-900">
            <strong className="tw-font-semibold">Descriptive Transcript:</strong>
            <br />
            Full transcript with speaker recognition.
          </p>
          <p className="tw-text-sm tw-text-neutral-900">
            <strong className="tw-font-semibold">Colour Contrast:</strong>
            <br />
            White subtitles on a black background for optimal readability.
          </p>
        </SectionContent>
      </Section>

      <div className="tw-flex tw-justify-between tw-text-sm tw-text-neutral-600">
        <div>Estimated time:</div>
        <div className="tw-font-medium tw-text-neutral-900">{estimatedDeliveryTime}</div>
      </div>
      <div className="tw-mb-4 tw-flex tw-justify-between tw-text-sm tw-text-neutral-600">
        <div>Total cost:</div>
        <div className="tw-font-medium tw-text-neutral-900">
          {currencySymbol}
          {subtotalCost}
        </div>
      </div>
      <div className="tw-flex tw-w-full">
        <Button variant="primary" className="tw-w-full" onClick={onSubmit}>
          Proceed
        </Button>
      </div>
    </>
  );
};

type PaymentPanelProps = {
  isPaying?: boolean;
  totalMinutes: number;
  isSubmitting: boolean;
  subtotalCost: number;
  tax: number;
  totalCost: number;
  currencySymbol: string;
  price: number;
  stripeCredit: number;
  stripeCreditDeduction: number;
  paymentCard?: PaymentMethod;
  onPay?: () => void;
  onBack?: () => void;
  onDismiss?: () => void;
};

const PaymentPanel: React.FC<PaymentPanelProps> = ({
  isPaying,
  totalMinutes,
  isSubmitting,
  subtotalCost,
  tax,
  totalCost,
  currencySymbol,
  price,
  stripeCredit,
  stripeCreditDeduction,
  paymentCard,
  onPay,
  onBack,
  onDismiss
}) => {
  const handleSubmit = () => onPay?.();

  return (
    <>
      <h3 className="tw-mb-2 tw-text-lg tw-font-semibold tw-text-neutral-900">Pay</h3>

      <table className="tw-mb-4">
        <thead>
          <tr>
            <td className="tw-text-sm tw-font-semibold">Item</td>
            <td className="tw-text-sm tw-font-semibold">Quantity</td>
            <td className="tw-text-sm tw-font-semibold">Price</td>
            <td className="tw-text-right tw-text-sm tw-font-semibold">Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tw-text-sm">Accessibility fixes / per minute</td>
            <td className="tw-text-sm">
              {Math.ceil(totalMinutes / 60)} ({secondsToHms(totalMinutes)})
            </td>
            <td className="tw-text-sm">
              {currencySymbol}
              {price}
            </td>
            <td className="tw-text-right tw-text-sm">
              {currencySymbol}
              {subtotalCost.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <hr className="tw-my-4" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="tw-text-center">
              <strong className="tw-text-sm tw-font-semibold">Subtotal</strong>
            </td>
            <td className="tw-text-right">
              <span className="tw-text-neutral-900">
                {currencySymbol}
                {subtotalCost.toFixed(2)}
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="tw-text-center">
              <strong className="tw-text-sm tw-font-semibold">Tax (20%)</strong>
            </td>
            <td className="tw-text-right">
              <span className="tw-text-neutral-900">
                {currencySymbol}
                {tax.toFixed(2)}
              </span>
            </td>
          </tr>
          {stripeCredit > 0 && (
            <tr>
              <td></td>
              <td></td>
              <td className="tw-text-center">
                <strong className="tw-text-sm tw-font-semibold">
                  Stripe credit ({currencySymbol}
                  {stripeCredit})
                </strong>
              </td>
              <td className="tw-text-right">
                <span className="tw-text-neutral-900">
                  -{currencySymbol}
                  {stripeCreditDeduction.toFixed(2)}
                </span>
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td className="tw-text-center">
              <strong className="tw-text-sm tw-font-bold">TOTAL</strong>
            </td>
            <td className="tw-text-right">
              <span className="tw-font-bold tw-text-neutral-900">
                {currencySymbol}
                {totalCost.toFixed(2)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <hr className="tw-mb-4" />

      {Boolean(paymentCard) && (
        <p className="tw-mb-4 tw-text-sm tw-text-neutral-600">
          Payment using card ending in {paymentCard?.last4 ?? ""}
        </p>
      )}

      <hr className="tw-mb-4" />

      <div className="tw-flex tw-w-full tw-gap-8">
        <Button variant="secondary" className="tw-flex-1" onClick={onBack}>
          Back
        </Button>
        {Boolean(paymentCard) ? (
          <Button
            variant="primary"
            className="tw-flex-1"
            loading={isSubmitting}
            onClick={handleSubmit}
            disabled={!Boolean(paymentCard)}
          >
            {isPaying ? "Processing payment" : "Purchase"}
          </Button>
        ) : (
          <Button variant="primary" className="tw-flex-1" type="link" to={SETTINGS_BILLING_PATH} onClick={onDismiss}>
            Add payment method
          </Button>
        )}
      </div>
    </>
  );
};

type SuccessPanelProps = {
  estimatedDeliveryTime: string;
  onClose?: () => void;
};

const SuccessPanel: React.FC<SuccessPanelProps> = ({ estimatedDeliveryTime, onClose }) => {
  return (
    <>
      <h3 className="tw-mb-2 tw-text-lg tw-font-semibold tw-text-neutral-900">Your files have been submitted</h3>
      <p className="tw-mb-4 tw-text-sm tw-text-neutral-600">
        Your files have been submitted it will take approx [{estimatedDeliveryTime}]
      </p>

      <Section className="tw-relative tw-mb-4">
        <SectionHeader>You will receive an (email or via Subly)</SectionHeader>
        <SectionContent>
          <h4 className="tw-text-sm tw-font-medium tw-text-neutral-900">Captions</h4>
          <p className="tw-text-sm tw-text-neutral-900">
            Check if the video contains captions.
            <br />
            Place here all what is included and how the video will be processed.
            <br />
            Good for hearing impairment.
          </p>
        </SectionContent>
      </Section>

      <div className="tw-flex tw-w-full">
        <Button variant="primary" className="tw-w-full" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
};

type SectionProps = {
  children: React.ReactNode;
  className?: string;
};

const Section: React.FC<SectionProps> = ({ children, className }) => {
  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-bg-neutral-50 tw-p-2", className)}>
      {children}
    </div>
  );
};

type SectionHeader = {
  id?: string;
  name?: string;
  checkable?: boolean;
  checked?: boolean;
  children: React.ReactNode;
  className?: string;
  onChange?: (checked: boolean) => void;
};

const SectionHeader: React.FC<SectionHeader> = ({ id, name, children, checkable, checked, className, onChange }) => {
  return (
    <div className={className}>
      {checkable ? (
        <Checkbox
          name={name}
          id={id}
          checked={checked}
          onChange={() => onChange?.(!checked)}
          className="tw-w-full tw-cursor-pointer tw-select-none !tw-gap-2"
          large
        >
          <div className="tw-pointer tw-text-sm tw-font-medium tw-leading-6">{children}</div>
        </Checkbox>
      ) : (
        <div className={classNames("tw-pointer tw-text-sm tw-font-medium", className)}>{children}</div>
      )}
    </div>
  );
};

type SectionContentProps = {
  children: React.ReactNode;
  className?: string;
  indented?: boolean;
  disabled?: boolean;
};

const SectionContent: React.FC<SectionContentProps> = ({ disabled, children, className, indented = true }) => {
  return (
    <div
      className={classNames(className, {
        "tw-pl-7": indented,
        "tw-pointer-events-none tw-select-none tw-opacity-50": disabled
      })}
    >
      {children}
    </div>
  );
};

type InputLanguageProps = {
  value: string;
  className?: string;
  onChange?: (value: string) => void;
};

const InputLanguage: React.FC<InputLanguageProps> = ({ value, className, onChange }) => {
  const languageOptions = [
    { id: "en-uk", flag: "fi-gb", label: "English (UK)" },
    { id: "en-us", flag: "fi-us", label: "English (US)" }
  ];
  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-2", className)}>
      <h5 className="tw-text-md tw-font-medium tw-text-neutral-900">What language is being spoken?</h5>
      <div className="tw-flex tw-items-center tw-gap-2 ">
        {languageOptions.map((option, i) => (
          <div key={i}>
            <button
              name="spoken-language"
              onClick={() => onChange?.(option.id)}
              className={classNames(
                "tw-inline-flex tw-h-7 tw-w-fit tw-items-center tw-gap-1 tw-whitespace-nowrap tw-rounded-md tw-border tw-px-2 tw-text-sm tw-font-medium",
                {
                  "tw-w-full tw-cursor-pointer tw-select-none tw-border-neutral-200 tw-bg-white tw-text-sm hover:tw-border-neutral-300 hover:tw-bg-neutral-100":
                    value !== option.id,
                  "tw-border-transparent tw-bg-primary-500 tw-text-sm tw-text-white": value === option.id
                }
              )}
            >
              <span className={`fi ${option.flag} tw-w-4 tw-rounded`} />
              {option.label}
            </button>
          </div>
        ))}
        <p className="tw-text-xs">Currently we only support English videos</p>
      </div>
    </div>
  );
};

type SelectedMediaProps = {
  mediaList: MediaListItem[];
  className?: string;
};

const SelectedMedia: React.FC<SelectedMediaProps> = ({ mediaList, className }) => {
  const totalFiles = mediaList.length;
  const totalMinutes = mediaList.reduce((duration, media) => duration + media.duration, 0);

  return (
    <div className={classNames("tw-gap-2 tw-rounded-6 tw-border tw-border-neutral-200", className)}>
      {totalFiles > 1 && (
        <div className="tw-flex tw-items-center tw-justify-between tw-px-2 tw-pt-2">
          <div className="tw-font-medium">{totalFiles} files </div>
          <div className="tw-text-xs tw-text-neutral-500">{secondsToHms(totalMinutes)}</div>
        </div>
      )}
      <ul className="tw-flex tw-flex-col">
        {mediaList.map((media, i) => (
          <li key={i}>
            <div className="tw-flex tw-items-center tw-justify-between tw-p-2 tw-text-sm">
              <div className="tw-flex tw-items-center tw-font-medium">
                <div className="tw-mr-2 tw-inline-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded tw-bg-neutral-50">
                  <RiFilmLine className="tw-h-4 tw-w-4 tw-shrink-0" />{" "}
                </div>
                {media.name}
              </div>
              <div className="tw-text-xs tw-text-neutral-500">{secondsToHms(media.duration)}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
