import {
  AspectRatio,
  BurnQuality,
  FileLanguage,
  FileType,
  JobStatus,
  JobType,
  MediaConfig,
  MediaStatus
} from "@getsubly/common";

import { SublyPlan } from "./billing";

export interface Media {
  // shared with MediaListItem
  mediaId: string;
  userId: string;
  folderId?: string;
  name: string;
  status: MediaStatus;
  reason?: MediaErrorReason;
  languageCode: string;
  subtitleLanguageCodes: string[];
  transcriptionLanguageCodes: string[];
  sharedWithMe?: boolean;
  settings?: MediaSettings;
  createdAt: string;
  updatedAt: string;

  // Unique to Media (not shared with MediaListItem)
  assConfig: MediaConfig;
  accountId: string;
  thumbnail: string;
  files: MediaFile[];
  current: string;
  originalFileId?: string;
  jobs: MediaJob[];
  lastBurnHash?: string;
  subtitlesHash?: string;
  original_file_name: string;
  publicShareId?: string;
  groupId?: string;
  owner: { name: string; email: string };
  plan?: SublyPlan;
  sharedUsers?: MediaInvite[];
  allLanguages: string[];
}

export interface MediaTranscriptionMap {
  originalSubtitlesId: string;
  originalTranscriptionId: string;
  originalSubtitles?: FileLanguage;
  originalTranscription?: FileLanguage;
  subtitlesTranslationsIds: string[];
  subtitlesTranslations: FileLanguage[];
  transcriptionTranslationsIds: string[];
  transcriptionTranslations: FileLanguage[];
}

export interface EnrichedMedia extends Media {
  id: string;
  videoUrl: string;
  mainFile?: MediaFile;
  posterUrl: string;
  type: FileType;
  latestJob?: MediaJob;
  isBurning?: boolean;
  isTranscribing: boolean;
  isHumanTranscribing?: boolean;
  isHumanTranscription?: boolean;
  isReplacing?: boolean;
  replaceProgress?: number;
  burningTasks?: BurningTask[];
  burnProgress?: number;
  isUploadingAudioDescription?: boolean;
  uploadAudioDescriptionProgress?: number;
  duration?: number;
  fileSize?: number;
  transcriptions: MediaTranscriptionMap;
}

export interface MediaActiveJob {
  id: string;
  type: JobType;
  status: JobStatus;
  percentage?: number;
  startedAt: string;
}

export type EnrichedMediaListItem = MediaListItem & {
  latestJob?: MediaActiveJob;
  isTranscribing: boolean;
  isAiTranscribing: boolean;
  isHumanTranscribing: boolean;
  isReplacing: boolean;
  plan?: SublyPlan;
};

export interface MediaSettings {
  skippedTranscription?: boolean;
}
export interface EnrichedMediaExtras {
  sharedWithMe?: boolean;
  plan?: SublyPlan;
}

export interface MediaJob {
  id: string;
  type: JobType;
  message: string;
  startDate: string;
  endDate: string;
  inputs: string[];
  outputs: string[];
  status: JobStatus;
  percentage?: number;
  batchId?: string;
  notifyUserId?: string;
  metadata?: {
    name: string;
    hash: string;
    ratio: AspectRatio;
    quality: BurnQuality;
    subtitleFileId: string;
    type: "medium";
  };
}

export interface MediaListItem {
  // shared with Media
  mediaId: string;
  userId: string;
  folderId?: string;
  name: string;
  status: MediaStatus;
  reason?: MediaErrorReason;
  languageCode: string;
  subtitleLanguageCodes: string[];
  transcriptionLanguageCodes: string[];
  audioDescriptionLanguageCode?: string;
  textColorContrastRatio?: number;
  sharedWithMe?: boolean;
  settings?: MediaSettings;
  createdAt: string;
  updatedAt: string;

  // Unique to MediaListItem (not shared with Media)
  assConfig?: MediaConfig;
  translationLanguages: string[];
  thumbnailUrl: string;
  storageBytes: number;
  duration: number;
  type: FileType;
  editorMode: "subtitles" | "transcription";
  activeJobs: MediaActiveJob[];
  s3_file_is_missing?: boolean;
}

export interface MediaFile {
  extension: string;
  size_bytes: number;
  uploadedDate: string;
  type: FileType;
  url: string;
  publicUrl?: string;
  filename: string;
  original_filename: string;
  width: number;
  id: string;
  key: string;
  durationSeconds?: number;
  sizeBytes?: number;
  burnt?: boolean;
  burnHash?: string;
  overridden?: boolean;
  height?: number;
  language?: string;
  translated?: boolean;
  downloadName?: string;
  languageId?: string;
  metadata?: {
    burnt?: boolean;
    burnHash?: string;
    downloadName?: string;
    browserTabId?: number;
    isAudioDescription?: boolean;
    language?: string;
  };
}

export enum ProgressProcess {
  FetchingFiles = "Fetching files.",
  PreparingFiles = "Preparing files for edit.",
  ModifyingVideo = "Modifying video.",
  Uploading = "Uploading processed video.",
  CleanUp = "Clean up."
}

export interface BurningTask {
  process: ProgressProcess;
  isBurnTask: false;
  progress: number;
}

export enum MediaErrorReason {
  InsufficientFunds = "InsufficientFunds",
  MediaLost = "MediaLost",
  UploadCancelled = "UploadCancelled",
  ProcessingCancelled = "ProcessingCancelled"
}

export interface MediaInvite {
  shareId: string;
  name: string;
  email: string;
  permission: MediaSharePermission;
  isOwner?: boolean;
  message?: string;
}

export enum MediaSharePermission {
  Edit = "edit",
  View = "view"
}

export interface MediaSharedAsset {
  id: string;
  url: string;
}

export enum SubtitleOrTranscriptionFormat {
  srt = "srt",
  vtt = "vtt",
  ttml = "ttml",
  xml = "xml",
  txt = "txt",
  docx = "docx"
}

export type AudioFormats = "mp3";

export interface MediaComment {
  id: string;
  text: string;
  time: number;
  isResolved: boolean;
  author: {
    id: string;
    name: string;
    picture: string;
  };
  createdAt: string;
  replies: Reply[];
}

export interface Reply {
  id: string;
  text: string;
  author: {
    id: string;
    name: string;
    picture: string;
  };
  createdAt: string;
}
