export const getOsEnv = (key: string, defaultValue?: string): string => {
  const envValue = process.env[key];

  return envValue ? envValue : defaultValue || "";
};

export const getOsEnvBool = (key: string, defaultValue?: boolean): boolean => {
  const strVal = getOsEnv(key);

  return strVal ? strVal === "true" : Boolean(defaultValue);
};

export const getOsEnvInt = (key: string, defaultValue: number): number => {
  const strVal = getOsEnv(key);

  return strVal ? parseInt(strVal, 10) : defaultValue;
};

export const getOsEnvArray = (key: string): string[] => {
  return getOsEnv(key, "").split(",");
};
