import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { QueryEntity } from "@datorama/akita";

import { DownloadQueueState, downloadQueueStore, QueueFile, QueueFileStatus } from "./download-queue.store";

export class DownloadQueueQuery extends QueryEntity<DownloadQueueState> {
  isCanceledJob(mediaId: string, jobId: string): boolean {
    const batch = this.getEntity(mediaId);
    const job = batch?.files?.find((f) => f.id === jobId);

    if (!job) {
      return false;
    }

    return [QueueFileStatus.Cancelled, QueueFileStatus.Cancelling].includes(job.status);
  }

  selectQueueFiles(mediaId: string): Observable<QueueFile[]> {
    return this.selectEntity(mediaId, "files").pipe(
      switchMap((files) => {
        if (!files) {
          return of([]);
        }

        return of(files);
      })
    );
  }

  selectIsVisible(mediaId: string): Observable<boolean> {
    return this.selectEntity(mediaId, "visible").pipe(
      switchMap((visible) => {
        if (visible == null) {
          return of(false);
        }

        return of(visible);
      })
    );
  }
}

export const downloadQueueQuery = new DownloadQueueQuery(downloadQueueStore);
