import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Order } from "@getsubly/common";

export interface OrdersState extends EntityState<Order, string> {
  count: number; // total orders in db
  loading: boolean; // is loading orders
  hasLoaded: boolean; // has loaded some orders
}

@StoreConfig({ name: "orders" })
export class OrdersStore extends EntityStore<OrdersState> {
  constructor() {
    super({ count: 0, loading: false, hasLoaded: false });
  }

  updateOrder(id: string, params: Partial<Order>): void {
    this.update(id, { ...params });
  }
}

export const ordersStore = new OrdersStore();
