/**
 * This hook determines whether a blur event was cause by clicking or tabbing.
 * @attribution https://stackoverflow.com/a/71334836/18133925
 * @license https://creativecommons.org/licenses/by-sa/4.0/
 */

import { KeyboardEvent, RefObject, useCallback, useEffect, useRef, useState } from "react";

export interface UseHowYouBlurProps {
  onTabAway?: () => void;
}

export const useBlur = <T = unknown>({ onTabAway }: UseHowYouBlurProps): RefObject<T> => {
  const [isBlurByTab, setIsBlurByTab] = useState<boolean>(false);
  const ref = useRef<T>(null);

  const keydownHandler = useCallback((e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Tab") {
      setIsBlurByTab(true);
      onTabAway?.();
    }
  }, []);

  const blurHandler = useCallback(() => {
    setIsBlurByTab(false);
  }, [isBlurByTab]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const node = ref.current as any;
    node?.addEventListener("keydown", keydownHandler, true);
    return (): void => node?.removeEventListener("keydown", keydownHandler, true);
  }, [keydownHandler]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const node = ref.current as any;
    node?.addEventListener("blur", blurHandler, true);
    return (): void => node?.removeEventListener("blur", blurHandler, true);
  }, [blurHandler]);

  return ref;
};
