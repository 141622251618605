import React, { useEffect } from "react";
import classNames from "classnames";
import Color from "color";
import Skeleton from "react-loading-skeleton";
import { best } from "wcag-color";

export type AvatarRounded = "none" | "rounded" | "full";
export type AvatarSize = "16" | "20" | "24" | "32" | "40" | "48" | "64";

interface AvatarProps {
  color?: string;
  initials?: string;
  withBorder?: boolean;
  rounded?: AvatarRounded;
  size?: AvatarSize;
  className?: string;
  image?: string;
  isUseSkeleton?: boolean;
  children?: React.ReactNode;
}
export const Avatar: React.FC<AvatarProps> = ({
  color = "#fff",
  withBorder = true,
  initials,
  rounded = "full",
  size = "16",
  className,
  image,
  isUseSkeleton = true,
  children
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(true);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    image && setImageLoaded(false);
  }, [image]);

  const textColor = best(
    Color<string>(color ?? "")
      .darken(0.8)
      .hex(),
    "#FFFFFF",
    color
  );
  const style: React.CSSProperties = {
    background: color,
    color: textColor
  };

  if (withBorder) {
    const borderColor = color === "#fff" ? "#000000" : Color(color).darken(0.3).hex();
    style.border = `1px solid ${borderColor}`;
  }

  if (rounded === "full") {
    style.borderRadius = `50%`;
  }

  switch (size) {
    case "16":
      style.width = "16px";
      style.height = "16px";
      style.fontSize = "6px";
      if (rounded === "rounded") {
        style.borderRadius = "4px";
      }
      break;
    case "20":
      style.width = "20px";
      style.height = "20px";
      style.fontSize = "9px";
      if (rounded === "rounded") {
        style.borderRadius = "4px";
      }
      break;
    case "24":
      style.width = "24px";
      style.height = "24px";
      style.fontSize = "9px";
      if (rounded === "rounded") {
        style.borderRadius = "4px";
      }
      break;
    case "32":
      style.width = "32px";
      style.height = "32px";
      style.fontSize = "14px";
      if (rounded === "rounded") {
        style.borderRadius = "8px";
      }
      break;
    case "40":
      style.width = "40px";
      style.height = "40px";
      style.fontSize = "16px";
      if (rounded === "rounded") {
        style.borderRadius = "12px";
      }
      break;
    case "48":
      style.width = "48px";
      style.height = "48px";
      style.fontSize = "18px";
      if (rounded === "rounded") {
        style.borderRadius = "12px";
      }
      break;
    case "64":
      style.width = "64px";
      style.height = "64px";
      style.fontSize = "24px";
      if (rounded === "rounded") {
        style.borderRadius = "12px";
      }
      break;
  }

  if (size) {
  }

  return (
    <div
      className={classNames(
        "tw-inline-flex tw-h-4 tw-w-4 tw-shrink-0 tw-items-center tw-justify-center tw-overflow-hidden tw-text-[6px] tw-font-medium tw-leading-none",
        className
      )}
      style={style}
    >
      <span className="tw-top-px tw-relative">
        {children ? (
          children
        ) : (
          <>
            {!imageLoaded && isUseSkeleton && <Skeleton height={style.height} width={style.width} baseColor="#aaa" />}
            {image ? (
              <img
                src={image}
                alt={initials}
                className={classNames({ "tw-hidden": !imageLoaded && isUseSkeleton })}
                onLoad={handleImageLoad}
              />
            ) : (
              initials
            )}
          </>
        )}
      </span>
    </div>
  );
};
