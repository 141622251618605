import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { unpauseSubscription } from "@frontend/api/billing.service";
import { EN } from "@frontend/assets/i18n/en";

import { Button } from "@components/button";
import { Modal } from "@components/modal";

import { usePlan } from "@core/hooks/use-plan";

import { notificationError, notificationSuccess } from "../notification";

import styles from "./cancel-modal.module.scss";

interface UnpauseModalProps {
  subscriptionId: string;
  onDismiss: () => void;
}

export const UnpauseModal: React.FC<UnpauseModalProps> = (props) => {
  return (
    <Modal
      onDismiss={props.onDismiss}
      className="!tw-min-h-[279px] !tw-w-full !tw-max-w-[620px] !tw-overflow-y-auto !tw-overflow-x-hidden !tw-p-6"
    >
      <UnpauseModalContainer {...props} />
    </Modal>
  );
};

const UnpauseModalContainer: React.FC<UnpauseModalProps> = ({ onDismiss, subscriptionId }) => {
  const [loading, setLoading] = React.useState(false);
  const { plan, pausedUntil, isPaused, pausedAt } = usePlan();
  const pausedUntilText = pausedUntil ? format(new Date(pausedUntil), "MMMM dd, yyyy") : "";

  const pausedAtText = pausedAt ? format(new Date(pausedAt), "MMM dd, yyyy") : "";

  const unpause = async (subscriptionId: string) => {
    try {
      setLoading(true);
      await unpauseSubscription(subscriptionId);
      notificationSuccess("Your plan has been unpaused");
    } catch (e) {
      notificationError(EN.error.defaultMessage);
      console.error(e);
    } finally {
      setLoading(false);
      onDismiss();
    }
  };
  return (
    <>
      <h5 className={classNames(styles.title, "tw-mb-5")}>Unpause your account</h5>
      <div className="tw-w-[300px] tw-text-sm tw-text-neutral-600">
        {isPaused ? (
          <p className="tw-text-sm">
            Your {plan} account is paused until <b>{pausedUntilText}</b>
          </p>
        ) : (
          <p className="tw-text-sm">
            Your {plan} is scheduled to pause on <b>{pausedAtText}</b> until <b>{pausedUntilText}</b>
          </p>
        )}

        <br />
        <p className="tw-text-sm">
          While your account is paused, Premium features are accessible, but your account balance will be 0 minutes.
        </p>
        <br />

        {isPaused ? (
          <p className="tw-text-sm">Would you like to unpause and reactivate your {plan}?</p>
        ) : (
          <p className="tw-text-sm">Would you like to cancel your scheduled pause on your {plan}?</p>
        )}

        <br />
        <p className="tw-text-sm">
          Note: Subly's 30 day pause is a one time offer. You will not be able to pause your account again.
        </p>
      </div>
      <div className={classNames(styles.perksImage, styles.unpauseImage)} />

      <div className="tw-mt-8 tw-flex tw-items-center tw-justify-between">
        <Button variant="secondary" className={classNames("tw-mr-3", styles.buttonV3)} onClick={onDismiss} size="36">
          Cancel
        </Button>
        <Button
          variant="primary"
          className="tw-px-5 tw-py-2"
          loading={loading}
          onClick={() => unpause(subscriptionId)}
          size="36"
        >
          {isPaused ? "Unpause now" : "Cancel pause now"}
        </Button>
      </div>
    </>
  );
};
