import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { ForgotPasswordParams } from "@frontend/api/auth.service";
import { EN } from "@frontend/assets/i18n/en";
import { Alert } from "@frontend/components/alert/alert";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { LOGIN_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { Logo } from "@components/logo";

import { emailPattern } from "@core/utils/regex-patterns";

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState<string | JSX.Element>("");
  const { forgotPassword } = useAuthProvider();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordParams>();

  const onSubmit = async (form: ForgotPasswordParams) => {
    setLoading(true);

    const { success, alertMessage } = await forgotPassword(form);
    if (!success) {
      setAlertMessage(alertMessage || EN.error.defaultMessage);
    }
    if (success) {
      setSubmittedEmail(form.email);
    }
    setSuccess(success);
    setLoading(false);
  };

  const handleClose = () => {
    setAlertMessage("");
  };

  if (success) {
    return (
      <div className="tw-w-full tw-max-w-[400px]">
        <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
        <h2 className="tw-mb-3 tw-text-center tw-text-h2 tw-font-semibold">Check your inbox!</h2>
        <p className="tw-mb-6 tw-text-center tw-text-md tw-text-neutral-700">
          We have sent you an email to {submittedEmail} with instructions on how to reset your password.
        </p>
        <Button className="tw-w-full" variant="primary" type="link" to={LOGIN_PATH}>
          Go back
        </Button>
      </div>
    );
  }

  return (
    <div id="forgot-password" className="tw-w-full tw-max-w-[400px]">
      <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
      <h2 className="tw-mb-3 tw-text-center tw-text-h2 tw-font-semibold">Forgot your password?</h2>
      {alertMessage && (
        <Alert danger title="Something went wrong" closable onClose={handleClose}>
          {alertMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="tw-mb-6 tw-text-center tw-text-md tw-text-neutral-700">
          Please enter your email to receive instructions to reset your password.
        </p>
        <div className="tw-mb-4">
          <Input
            type="email"
            placeholder="Enter your email address here"
            hasError={!!errors.email}
            {...register("email", { required: true, pattern: emailPattern })}
          />
          {errors.email && <span style={{ color: "var(--danger, red)" }}>A valid email is required</span>}
        </div>

        <Button className="tw-w-full" variant="primary" type="submit" loading={loading}>
          Continue
        </Button>
      </form>

      <p className="tw-mt-16 tw-text-center tw-text-sm tw-font-semibold tw-text-primary-500 hover:tw-text-primary-700">
        <Link to={LOGIN_PATH + "?withPassword=true"}>Back to Log in</Link>
      </p>
    </div>
  );
};
