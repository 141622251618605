import React from "react";

import { IZoomRecordingSummary } from "@frontend/api/apps.service";

import { ZoomMeetingCard } from "./zoom-meeting-card";

interface IZoomRecordingListProps {
  recordingSummary: IZoomRecordingSummary;
  selected: string[];
  onChange: (id: string) => void;
}
export const ZoomRecordingList: React.FC<IZoomRecordingListProps> = ({ recordingSummary, selected, onChange }) => {
  return (
    <div>
      {recordingSummary.items.map((recording) => (
        <ZoomMeetingCard key={recording.meetingId} recording={recording} selected={selected} onChange={onChange} />
      ))}
    </div>
  );
};
