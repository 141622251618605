import React from "react";
import { Channel, ChannelAuthorizationCallback } from "pusher-js";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { authorisePusher } from "@frontend/api/pusher.service";
import { notificationWarning } from "@frontend/components/notification";
import config from "@frontend/config";
import { TopBannerProvider } from "@frontend/contexts/top-banner.context";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { useAccounts } from "@core/hooks/use-accounts";
import { ModalType } from "@core/interfaces/modal-type";
import { accountStore } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { parseCreditToText } from "@core/utils/plans";
import { PusherProvider } from "@harelpls/use-pusher";

interface NotificationsProviderProps {
  children: React.ReactNode;
}
export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
  const { currentAccount } = useAccounts();

  const credit = currentAccount?.credit;

  const showUpgradeModal = credit?.showUpgradeModal;

  // Show upgrade modal
  React.useEffect(() => {
    if (!showUpgradeModal) {
      return;
    }

    accountStore.resetUpgradeModal();

    switch (showUpgradeModal) {
      case ModalType.UpgradeReach80: {
        const totalCredit = credit?.total ?? 0;
        const balanceText = parseCreditToText(totalCredit);

        notificationWarning(
          <>
            <h6 className="tw-text-h6">
              Wow you've used 80% of your minutes{" "}
              <span role="img" aria-labelledby="strong">
                💪
              </span>
            </h6>
            <p className="tw-mb-0">
              You only have <strong>{balanceText}</strong> left this month.
            </p>
            <p>
              Don't stop making great content.{" "}
              <Link
                to={{
                  pathname: SETTINGS_BILLING_PATH,
                  search: "checkout=true"
                }}
              >
                Upgrade
              </Link>{" "}
              to get more minutes today.
            </p>
          </>,
          {
            toastId: "credit-warning"
          }
        );
        return;
      }
    }
  }, [showUpgradeModal]);

  return (
    <TopBannerProvider>
      <PusherProvider
        clientKey={config.pusher.clientKey}
        cluster={config.pusher.cluster}
        authorizer={({ name }: Channel) => ({
          authorize: async (socketId: string, callback: ChannelAuthorizationCallback) => {
            if (!authQuery.accessToken) {
              return callback(null, {
                auth: ""
              });
            }

            const auth = await authorisePusher(name, socketId);

            if (!auth) {
              return callback(null, {
                auth: ""
              });
            }
            return callback(null, auth);
          }
        })}
      >
        {children}
      </PusherProvider>

      <ToastContainer />
    </TopBannerProvider>
  );
};
