import { CurrencyCode } from "@core/interfaces/billing";
import { getOsEnvArray } from "@core/utils/env";

const config = {
  isDevelopment: process.env.REACT_APP_NODE_ENV === "dev",
  isProduction: process.env.REACT_APP_NODE_ENV === "production",
  latestReleaseVersion: "2.6.0",
  // Api
  apiUrl: process.env.REACT_APP_API_URL,
  // Pusher
  pusher: {
    clientKey: process.env.REACT_APP_PUSHER_KEY || "",
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
    authEndpoint: `${process.env.REACT_APP_API_URL}/api/v1/pusher/auth`
  },
  // Analytics
  gtmId: process.env.REACT_APP_GTM_ID || "",
  mixpanel: process.env.REACT_APP_MIXPANEL_API || "",
  amplitude: process.env.REACT_APP_AMPLITUDE_API || "",
  hubspot: process.env.REACT_APP_HUBSPOT_API || "",

  // Sentry
  sentry: process.env.REACT_APP_SENTRY_DSN,

  // FingerPrint
  fingerprint: {
    enabled: process.env.REACT_APP_FINGERPRINT_ENABLED === "true",
    apiKey: process.env.REACT_APP_FINGERPRINT_API_KEY || "",
    region: process.env.REACT_APP_FINGERPRINT_REGION || "eu"
  },

  // Defined below
  oAuth2: {
    google: "",
    microsoft: ""
  },

  // Stripe
  stripe: {
    clientKey: process.env.REACT_APP_STRIPE_CLIENT_KEY,
    products: {
      "2024-01-02": {
        personal: process.env.REACT_APP_BILLING_PERSONAL_PRODUCT || "",
        business: process.env.REACT_APP_BILLING_BUSINESS_PRODUCT || "",
        enterprise: process.env.REACT_APP_BILLING_ENTERPRISE_PRODUCT || ""
      },
      perUser: {
        pro: process.env.REACT_APP_STRIPE_PRO_PRODUCT || "",
        premium: process.env.REACT_APP_STRIPE_PREMIUM_PRODUCT || "",
        business: process.env.REACT_APP_STRIPE_BUSINESS_PRODUCT || "",
        payg: process.env.REACT_APP_STRIPE_PAYG_PRODUCT || ""
      },
      "2020-09-21": {
        pro: process.env.REACT_APP_STRIPE_OLD_PRO_PRODUCT || "",
        proYearly: process.env.REACT_APP_STRIPE_OLD_PRO_YEARLY_PRODUCT || "",
        premium: process.env.REACT_APP_STRIPE_OLD_PREMIUM_PRODUCT || "",
        premiumYearly: process.env.REACT_APP_STRIPE_OLD_PREMIUM_YEARLY_PRODUCT || "",
        businessYearly: process.env.REACT_APP_STRIPE_OLD_BUSINESS_YEARLY_PRODUCT || ""
      }
    },
    prices: {
      pro: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_PRO_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_PRO_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_PRO_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_PRO_PRICE_AUD || ""
      },
      proYearly: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_AUD || ""
      },
      premium: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_AUD || ""
      },
      premiumYearly: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_AUD || ""
      },
      business: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_BUSINESS_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_BUSINESS_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_BUSINESS_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_BUSINESS_PRICE_AUD || "",
        custom20: process.env.REACT_APP_BUSINESS_CUSTOM_20MIN_MTH_PRICE || "",
        custom40: process.env.REACT_APP_BUSINESS_CUSTOM_40MIN_MTH_PRICE || "",
        custom60: process.env.REACT_APP_BUSINESS_CUSTOM_60MIN_MTH_PRICE || ""
      },
      businessYearly: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_AUD || "",
        custom240: process.env.REACT_APP_BUSINESS_CUSTOM_240MIN_YR_PRICE || "",
        custom480: process.env.REACT_APP_BUSINESS_CUSTOM_480MIN_YR_PRICE || "",
        custom720: process.env.REACT_APP_BUSINESS_CUSTOM_720MIN_YR_PRICE || ""
      },
      PAYG: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_PAYG_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_PAYG_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_PAYG_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_PAYG_PRICE_AUD || ""
      },
      minutesMonthly: process.env.REACT_APP_STRIPE_MINUTES_MONTHLY_PRICE || "",
      minutesYearly: process.env.REACT_APP_STRIPE_MINUTES_YEARLY_PRICE || "",
      seatsMonthly: process.env.REACT_APP_STRIPE_SEATS_MONTHLY_PRICE || "",
      seatsYearly: process.env.REACT_APP_STRIPE_SEATS_YEARLY_PRICE || "",
      storage: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_STORAGE_MONTHLY_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_STORAGE_MONTHLY_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_STORAGE_MONTHLY_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_STORAGE_MONTHLY_PRICE_AUD || ""
      },
      storageYearly: {
        [CurrencyCode.USD]: process.env.REACT_APP_STRIPE_STORAGE_YEARLY_PRICE_USD || "",
        [CurrencyCode.GBP]: process.env.REACT_APP_STRIPE_STORAGE_YEARLY_PRICE_GBP || "",
        [CurrencyCode.EUR]: process.env.REACT_APP_STRIPE_STORAGE_YEARLY_PRICE_EUR || "",
        [CurrencyCode.AUD]: process.env.REACT_APP_STRIPE_STORAGE_YEARLY_PRICE_AUD || ""
      },
      old: {
        pro: {
          monthly: process.env.REACT_APP_STRIPE_PRO_MONTHLY_PRICE_OLD,
          yearly: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_OLD
        },
        premium: {
          monthly: process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_OLD,
          yearly: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_OLD
        },
        business: {
          monthly: process.env.REACT_APP_STRIPE_BUSINESS_MONTHLY_PRICE_OLD,
          yearly: process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_OLD
        }
      }
    }
  },
  // Maintenance mode message
  maintenanceMode: {
    message: process.env.REACT_APP_MAINTENANCE_MODE_MESSAGE
  },
  reCAPTCHA_V3: process.env.REACT_APP_RECAPTCHA_KEY_V3 || "",

  useDataAnalytics: process.env.REACT_APP_USE_MIXPANEL === "true",
  previewBaseUrl: process.env.REACT_APP_PREVIEW_API_URL || "",

  usePusherDebug: false,

  // Feature flags
  features: {
    showDevIndicator: process.env.REACT_APP_SHOW_DEV_INDICATOR === "true",
    templatesPanel: process.env.REACT_APP_TEMPLATES_PANE === "true",
    enhanceTranslation: process.env.REACT_APP_ENHANCE_TRANSLATION === "true",
    glossary: process.env.REACT_APP_GLOSSARY === "true",
    stylesPerCue: process.env.REACT_APP_STYLES_PER_CUE === "true",
    stylesPerWord: process.env.REACT_APP_STYLES_PER_WORD === "true",
    ttml: process.env.REACT_APP_TTML === "true",
    zapier: process.env.REACT_APP_ZAPIER === "true",
    lowConfidence: process.env.REACT_APP_SHOW_LOW_CONFIDENCE === "true",
    waveform: process.env.REACT_APP_SHOW_WAVEFORM === "true",
    teams: process.env.REACT_APP_TEAMSPACES === "true",
    accessibilityFlow: process.env.REACT_APP_ACCESSIBILITY_FLOW === "true",
    ignoreWords: process.env.REACT_APP_IGNORE_WORDS === "true",
    showDisclaimer: process.env.REACT_APP_SHOW_DISCLAIMER === "true",
    usageStats: process.env.REACT_APP_USAGE_STATS === "true",
    apiInProduct: process.env.REACT_APP_API_IN_PRODUCT === "true",
    audioDescriptionPanel: process.env.REACT_APP_AUDIO_DESCRIPTION_PANEL === "true",
    summaryPanel: process.env.REACT_APP_SUMMARY_PANEL === "true",
    humanServices: process.env.REACT_APP_HUMAN_SERVICES === "true",
    allowAudioDescriptionUpload: process.env.REACT_APP_AUDIO_DESCRIPTION_UPLOAD === "true",
    showDownloadFromDashboard: process.env.REACT_APP_SHOW_DOWNLOAD_FROM_DASHBOARD === "true",
    showCouponDiscountInput: process.env.REACT_APP_SHOW_COUPON_DISCOUNT_INPUT === "true"
  },

  lowConfidenceThreshold: process.env.REACT_APP_LOW_CONFIDENCE_THRESHOLD || 0.6,

  zoom: {
    clientId: process.env.REACT_APP_ZOOM_CLIENT_ID,
    redirectUri: process.env.REACT_APP_ZOOM_REDIRECT_URI || ""
  },

  publicApi: {
    apiStage: process.env.REACT_APP_API_STAGE || "sit"
  },

  // Education discount exemptions
  discountExemptions: getOsEnvArray("REACT_APP_DISCOUNT_EXEMPTIONS"),

  trialDownloadsLimit: 2,
  simultaneousTranslationsLimit: 10
};

// Fix for `public-preview` app which is a nextjs and nested import this file and complaints about the window not being defined
if (typeof window !== "undefined") {
  config.oAuth2 = {
    google: `${window?.location?.origin}/auth/google/callback`,
    microsoft: `${window?.location?.origin}/auth/microsoft/callback`
  };
}

export default config;
