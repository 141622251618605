import React from "react";

import blackLg from "./assets/logo-black-lg.svg";
import blackMd from "./assets/logo-black-md.svg";
import blackSm from "./assets/logo-black-sm.svg";
import greyLg from "./assets/logo-grey-lg.svg";
import greyMd from "./assets/logo-grey-md.svg";
import greySm from "./assets/logo-grey-sm.svg";
import purpleLg from "./assets/logo-purple-lg.svg";
import purpleMd from "./assets/logo-purple-md.svg";
import purpleSm from "./assets/logo-purple-sm.svg";
import whiteLg from "./assets/logo-white-lg.svg";
import whiteMd from "./assets/logo-white-md.svg";
import whiteSm from "./assets/logo-white-sm.svg";

interface LogoProps {
  size: "lg" | "md" | "sm";
  color: "purple" | "black" | "white" | "grey";
  className?: string;
  alt?: string;
}
export const Logo: React.FC<LogoProps> = ({ size = "lg", color = "purple", alt = "Subly logo", className }) => {
  const logo = React.useMemo(() => {
    if (size === "lg") {
      if (color === "black") return blackLg;
      if (color === "white") return whiteLg;
      if (color === "grey") return greyLg;
      return purpleLg;
    } else if (size === "md") {
      if (color === "black") return blackMd;
      if (color === "white") return whiteMd;
      if (color === "grey") return greyMd;
      return purpleMd;
    } else if (size === "sm") {
      if (color === "black") return blackSm;
      if (color === "white") return whiteSm;
      if (color === "grey") return greySm;
      return purpleSm;
    }

    return purpleMd;
  }, [size, color]);

  return <img src={logo} alt={alt} className={className} />;
};
