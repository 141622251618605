import React from "react";
import Skeleton from "react-loading-skeleton";

import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { SETTINGS_BILLING_INVOICES_PATH } from "@frontend/routes";

import { Button } from "@components/button";

import { accountQuery } from "@core/state/account";
import { useObservable } from "@mindspace-io/react";

import { InvoiceTable } from "./invoice-table";

interface InvoicesCardProps {
  className?: string;
}
export const InvoicesCard: React.FC<InvoicesCardProps> = ({ className }) => {
  const [{ invoices, loading }] = useObservable(accountQuery.select(["loading", "invoices"]), {
    loading: true,
    invoices: []
  });

  if (loading) {
    return (
      <SettingsCard className={className}>
        <SettingsCard.Header>Invoices</SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <Skeleton height={150} />
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  if (!invoices || !invoices.length) {
    return (
      <SettingsCard>
        <SettingsCard.Header>Invoices</SettingsCard.Header>
        <SettingsCard.Body>No invoices yet.</SettingsCard.Body>
      </SettingsCard>
    );
  }

  return (
    <SettingsCard>
      <SettingsCard.Header>Invoices</SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        <InvoiceTable skipDescription limit={3} className="tw-mb-4" />
      </SettingsCard.Body>
      <SettingsCard.Footer>
        <Button variant="secondary" type="link" to={SETTINGS_BILLING_INVOICES_PATH} size="32">
          See more
        </Button>
      </SettingsCard.Footer>
    </SettingsCard>
  );
};
