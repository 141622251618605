import { User } from "@core/interfaces/user";

export const getStripeId = (user?: User): string | undefined => {
  const accountId = user?.accountId;

  if (!accountId) {
    return;
  }

  const account = user.accounts?.find((a) => a.accountId === accountId);

  return account?.billing?.stripeId;
};
