import { Store, StoreConfig } from "@datorama/akita";
import { RoleName } from "@getsubly/common";

export interface PresenceUser {
  username: string;
  name?: string;
  photo?: string;
  mediaId?: string;
  joinedAt?: Date;
  role?: RoleName;
}

export interface UserPresenceState {
  users: PresenceUser[]; // editors or admins
}

export const createInitialState = (): UserPresenceState => ({
  users: []
});

@StoreConfig({ name: "user-presence", resettable: true })
export class UserPresenceStore extends Store<UserPresenceState> {
  constructor() {
    super(createInitialState());
  }

  updateUser = (username: string, data: Partial<PresenceUser>): void => {
    this.update((s) => {
      const user = s.users.find((u) => u.username === username);

      const updatedUser: PresenceUser = {
        username,
        ...user,
        ...data
      };

      const filteredUsers = s.users.filter((u) => u.username !== username);
      const users = [...filteredUsers, updatedUser];

      return { users };
    });
  };

  removeUser = (username: string): void => {
    this.update((state) => ({
      users: [...state.users.filter((u) => u.username !== username)]
    }));
  };
}

export const userPresenceStore = new UserPresenceStore();
