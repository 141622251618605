import React from "react";
import classNames from "classnames";

import { PRIVACY_URL, TERMS_URL } from "@frontend/routes";

import { Checkbox } from "@components/form-controls";

interface TermsCheckboxProp {
  accepted: boolean;
  onAccepted: (accepted: boolean) => void;
  showError: boolean;
}

export const TermsCheckbox: React.FC<TermsCheckboxProp> = ({ accepted, onAccepted, showError }) => {
  return (
    <Checkbox checked={accepted} onChange={(checked) => onAccepted(checked)}>
      <p
        className={classNames("tw-mb-0 tw-text-sm", {
          "tw-text-black": !showError,
          "tw-text-destructive-600": showError
        })}
      >
        By clicking on Sign up, I agree to the{" "}
        <a
          href={TERMS_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({
            "tw-text-destructive-600": showError
          })}
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={PRIVACY_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({
            "tw-text-destructive-600": showError
          })}
        >
          Privacy Policy.
        </a>
      </p>
    </Checkbox>
  );
};
