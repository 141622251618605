import React from "react";
import classNames from "classnames";

interface ProgressRingProps {
  progress: number;
  stroke?: number;
  radius?: number;
  labelled?: boolean;
  animated?: boolean;
  rounded?: boolean;
  success?: boolean;
  children?: React.ReactNode;
  className?: string;
  theme?: "primary" | "aux";
}

export const ProgressRing: React.FC<ProgressRingProps> = ({
  progress,
  stroke = 12,
  radius = 32,
  labelled = true,
  animated,
  rounded,
  success,
  className,
  theme = "aux"
}) => {
  const normalizedRadius = radius;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div
      className={classNames(
        "tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full",
        className
      )}
    >
      <svg style={{ width: radius * 2, height: radius * 2 }}>
        <circle
          className={classNames({
            "!tw-text-neutral-200": progress === 0,
            "tw-text-success-100": success,
            "tw-text-aux-100": theme === "aux" && !success,
            "tw-text-primary-100": theme === "primary" && !success
          })}
          strokeWidth={stroke}
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={classNames("tw-origin-center tw-transition-all", {
            "tw-animate-spin-slow": animated,
            "tw-rotate-[270deg]": !animated,
            "tw-text-success-500": success,
            "tw-text-aux-500": !success,
            "tw-text-primary-500": theme === "primary" && !success
          })}
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap={rounded ? "round" : "butt"}
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      {labelled && Boolean(progress) && <span className="tw-absolute tw-text-sm">{progress.toString()}%</span>}
    </div>
  );
};
