import React, { useState } from "react";
import classNames from "classnames";

import { setUserSettings, userLanguage } from "@frontend/config/settings/settings.service";

import { Fieldset } from "@components/form-controls/fieldset";
import { ISelectOption, Select } from "@components/select";

import { useAccounts } from "@core/hooks/use-accounts";
import { Language } from "@getsubly/common";

interface LanguagePickerProps {
  showRequiredError?: boolean;
  languages: Language[];
  onSelect: (languageCode: string) => void;
  className?: string;
  showInPortal?: boolean;
}

export const LanguagePickerSection: React.FC<LanguagePickerProps> = ({
  showRequiredError,
  languages,
  onSelect,
  className,
  showInPortal
}) => {
  const { isViewer } = useAccounts();

  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(userLanguage());

  const handleSelectedLanguage = (languageCode?: string) => {
    if (languageCode) {
      onSelect(languageCode);
      setSelectedLanguageCode(languageCode);
      setUserSettings({ language: languageCode });
    }
  };

  const formatLabel = (option: ISelectOption) => (
    <>
      <span className={`fi fi-${option.extra.flagCode} tw-shrink-0`} />
      {option.label}
    </>
  );

  return (
    <div className={className}>
      <Fieldset title="Language spoken" className={classNames({ "tw-text-destructive-600": showRequiredError })}>
        <Select
          variant="outline"
          placeholder="Select language"
          defaultValue={selectedLanguageCode}
          options={languages.map((language) => ({
            label: language.language,
            value: language.languageCode,
            extra: { flagCode: language.flagCode }
          }))}
          formatOptionLabel={(option) => formatLabel(option)}
          formatInputSelectedLabel={(option) => formatLabel(option)}
          onChange={handleSelectedLanguage}
          hasError={showRequiredError}
          dropdownClassName="!tw-min-w-[280px] tw-max-h-[300px]"
          showInPortal={showInPortal}
          disabled={isViewer}
        />
        {showRequiredError && <p className="tw-text-sm tw-text-destructive-600">Please pick a language</p>}
      </Fieldset>
    </div>
  );
};
