import { isEqual } from "lodash-es";

import { GuidelineOptions } from "@frontend/api/media.service";

export interface GuidelineTemplate {
  presetName: string;
  guideline: GuidelineOptions;
}

export const NetflixGuidelinePreset: GuidelineTemplate = {
  presetName: "Netflix",
  guideline: {
    maxLines: 2,
    maxCharsLine: 42,
    maxCharsSec: 20,
    maxCueDuration: 7000,
    maxLineWords: 7
  }
};

const BBCGuidelinePreset: GuidelineTemplate = {
  presetName: "BBC",
  guideline: {
    maxLines: 2,
    maxCharsLine: 42,
    maxCharsSec: 21,
    maxCueDuration: 7000,
    maxLineWords: 7
  }
};

const TEDGuidelinePreset: GuidelineTemplate = {
  presetName: "TED",
  guideline: {
    maxLines: 2,
    maxCharsLine: 38,
    maxCharsSec: 21,
    maxCueDuration: 7000,
    maxLineWords: 7
  }
};

const YouTubeGuidelinePreset: GuidelineTemplate = {
  presetName: "YouTube",
  guideline: {
    maxLines: 2,
    maxCharsLine: 39,
    maxCharsSec: 18,
    maxCueDuration: 6000,
    maxLineWords: 6
  }
};

export const GUIDELINE_TEMPLATES = [
  NetflixGuidelinePreset,
  BBCGuidelinePreset,
  TEDGuidelinePreset,
  YouTubeGuidelinePreset
];

export const getGuidelineName = (guideline?: GuidelineTemplate): string => {
  if (!guideline) {
    return "Custom";
  }

  const preset = GUIDELINE_TEMPLATES.find((g) => {
    return isEqual(g.guideline, guideline.guideline);
  });

  return preset?.presetName || "Custom";
};

export const calculateCPS = (start: number, end: number, length: number): number => {
  if (length === 0) {
    return 0;
  }
  return Math.round(length / ((end - start) / 1000));
};
