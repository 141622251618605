import React from "react";
import classNames from "classnames";
import AutoSizeTextarea from "react-textarea-autosize";

type IntrinsicInputProps = JSX.IntrinsicElements["textarea"];

interface TextAreaProps extends IntrinsicInputProps {
  size?: "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
  label?: string;
  helper?: string;
  labelClassName?: string;
  hasError?: boolean;
  autoFocus?: boolean;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  disabled?: boolean;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      labelClassName,
      className,
      size = "md",
      label,
      helper,
      hasError,
      autoFocus,
      minRows,
      maxRows,
      placeholder,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <label
        className={classNames(
          "tw-mb-0 tw-flex tw-flex-col tw-gap-1",
          { "tw-text-neutral-300": disabled },
          labelClassName
        )}
        htmlFor={props.id}
      >
        {label && <p className="tw-text-sm tw-font-medium">{label}</p>}

        <div className="tw-relative tw-flex tw-items-center">
          <AutoSizeTextarea
            id={props.id}
            value={props.value}
            className={classNames(
              "tw-w-full tw-rounded-lg tw-border tw-border-transparent",
              {
                // Size
                "tw-min-h-[48px] tw-px-4 tw-py-3": size === "xl",
                "tw-min-h-[44px] tw-px-4 tw-py-3": size === "lg",
                "tw-min-h-[40px] tw-px-3 tw-py-2": size === "md",
                "tw-min-h-[36px] tw-px-2 tw-py-2": size === "sm",
                "tw-min-h-[32px] tw-px-1 tw-py-2": size === "xs",
                "tw-min-h-[28px] tw-px-1 tw-py-2": size === "xxs",

                // Font size
                "tw-text-md": ["xl", "lg", "md"].includes(size),
                "tw-text-sm": ["sm", "xs", "xxs"].includes(size),

                // Default
                "tw-bg-neutral-50 placeholder:tw-text-neutral-400": !hasError,
                // Default & not disabled
                "placeholder:hover:tw-text-neutral-600 focus:tw-border-neutral-300 focus:tw-outline-none placeholder:focus:tw-text-neutral-600 focus-visible:tw-shadow-focus-ring":
                  !disabled && !hasError,
                // Error
                "tw-bg-destructive-50 placeholder:tw-text-destructive-400": hasError,
                // Error & not disabled
                "tw-text-destructive-500 placeholder:hover:tw-text-destructive-600 focus:tw-border-destructive-200 focus:tw-outline-none placeholder:focus:tw-text-destructive-600 focus-visible:tw-shadow-focus-ring":
                  !disabled && hasError,
                // Disabled
                "tw-opacity-80": disabled
              },
              className
            )}
            autoFocus={autoFocus}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            disabled={disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
            name={props.name}
            ref={ref}
          />
        </div>
        {helper && (
          <p
            className={classNames("tw-mb-0 tw-mt-1 tw-text-sm", {
              "tw-text-neutral-500": !hasError && !disabled,
              "tw-text-destructive-500": hasError
            })}
          >
            {helper}
          </p>
        )}
      </label>
    );
  }
);
