// From https://www.webtips.dev/webtips/react-hooks/element-in-viewport

import React from "react";

export const useIntersection = (element: HTMLElement | null, rootMargin: string, callback?: () => void): boolean => {
  const [isVisible, setState] = React.useState(false);

  React.useEffect(() => {
    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin, threshold: 0 }
    );

    element && observer.observe(element);

    return () => observer.unobserve(element);
  }, [element]);

  React.useEffect(() => {
    if (isVisible && callback) {
      callback();
    }
  }, [isVisible]);

  return isVisible;
};
