import React from "react";

import { FloatingFocusManager, FloatingPortal } from "@floating-ui/react";

import { useSidecar } from "./sidecar.context";

interface SidecarProps {
  show?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

export const Sidecar: React.FC<SidecarProps> = ({ show, children, onClose }) => {
  const { setFloating, floatingStyles, context, getFloatingProps, showSidecar } = useSidecar();

  const lastShowState = React.useRef(showSidecar);

  React.useEffect(() => {
    if (lastShowState.current === true && !showSidecar) {
      onClose?.();
    } else {
      lastShowState.current = showSidecar;
    }
  }, [showSidecar]);

  if (!show) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context} modal={false} disabled>
        <div
          ref={setFloating}
          className="tw-z-10 tw-overflow-hidden tw-rounded-md tw-border-neutral-200 tw-bg-white tw-shadow-lg"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};
