import { usePlan } from "@core/hooks/use-plan";
import { SublyPlan } from "@core/interfaces/billing";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { isInferiorPlan } from "@core/utils/plans";

interface usePlanPermissions {
  hasPermission: boolean;
}

export function usePlanPermissions(minPermission: SublyPlan = SublyPlan.Pro): usePlanPermissions {
  const { plan: currentPlan, hasPaymentOverdue, isPayg } = usePlan();

  const mediaPlan = editorStateRepository.plan;

  const permission = minPermission;

  if (mediaPlan) {
    const hasPermission = isInferiorPlan(permission, mediaPlan);

    return {
      hasPermission
    };
  }

  if (hasPaymentOverdue) {
    return { hasPermission: false };
  }

  const plan = isPayg ? SublyPlan.Premium : currentPlan;

  const hasPermission = isInferiorPlan(permission, plan);

  return {
    hasPermission
  };
}
