import React from "react";
import { useLocation } from "react-router-dom";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Modal } from "@components/modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { SublyPlan } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { modalStore } from "@core/state/modal/modal.store";
import { getPlanNameLabel } from "@core/utils/plans";

import { UnpauseModal } from "../plans/unpause-modal";

import styles from "./upgrade-modal.module.scss";

interface PlaneUpgradeModalProps {
  upgradePlan: SublyPlan;
  closeModal: () => void;
}
export const PlanUpgradeModal: React.FC<PlaneUpgradeModalProps> = ({ upgradePlan = SublyPlan.Pro, closeModal }) => {
  const { pathname: redirect } = useLocation();
  const { planVersion, isFree, isPaused } = usePlan();

  const { subscription } = useAccounts();
  const planName = [SublyPlan.Business, SublyPlan.Enterprise, SublyPlan.API].includes(upgradePlan)
    ? getPlanNameLabel(upgradePlan)
    : "";

  const [showUnpauseModal, hideUnpauseModal] = useModal(ModalType.UnpauseModal);

  const handleStartTrialUpgrade = () => {
    closeModal();
    modalStore.hideAll();
  };

  const handleUnpause = () => {
    if (!subscription) {
      return;
    }
    showUnpauseModal(<UnpauseModal subscriptionId={subscription.id} onDismiss={hideUnpauseModal} />);
    closeModal();
  };

  if (isPaused) {
    return (
      <Modal onDismiss={closeModal} className={styles["modal"]} showCloseButton>
        <h4 className="tw-text-center tw-text-h4">Your account is currently paused</h4>
        <p className="tw-text-center">
          Your account is paused, to work on something new you need to unpause your account.
        </p>

        <div className="tw-mt-6 tw-flex tw-items-center tw-justify-between">
          <Button variant="secondary" className="tw-mr-4 tw-block tw-flex-auto" onClick={closeModal} size="36">
            Not now
          </Button>
          <Button variant="primary" className="tw-block tw-flex-auto" onClick={handleUnpause} size="36">
            Unpause now
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} showCloseButton>
      {Boolean(isFree || planName) && (
        <>
          <h4 className="tw-text-center tw-text-h4">
            You've discovered a {isFree ? `paid feature` : `${planName} feature`}
          </h4>
          <p className="tw-text-center">Upgrade now to unlock this feature and more</p>
        </>
      )}
      {!Boolean(isFree || planName) && (
        <>
          <h5 className="tw-text-center tw-text-h4">Upgrade now to unlock this feature and more</h5>
          {planVersion !== "2024-01-02" && <p className="tw-text-center">You are currently on a legacy plan</p>}
        </>
      )}

      <div className="tw-mt-6 tw-flex tw-items-center tw-justify-between">
        <Button variant="secondary" className="tw-mr-4 tw-flex-grow" onClick={closeModal} size="36">
          Not now
        </Button>
        <Button
          variant="primary"
          className="tw-flex-grow"
          type="link"
          to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
          linkState={{ redirect }}
          onClick={handleStartTrialUpgrade}
          size="36"
        >
          Upgrade now
        </Button>
      </div>
    </Modal>
  );
};
