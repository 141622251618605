import Cookies from "universal-cookie";

import { authQuery } from "@core/state/auth/auth.query";

const cookies = new Cookies();

export const getAccountId = (): string => {
  return authQuery.accountId;
};

interface UserSettings {
  language: string;
  workspaceId?: string;
}

export const getUserSettings = (): UserSettings => {
  return cookies.get("userSettings") || {};
};

export const userLanguage = (): string => {
  return getUserSettings().language ?? "";
};

export const setUserSettings = (userSettings: Partial<UserSettings>): void => {
  cookies.set("userSettings", { ...getUserSettings(), ...userSettings }, { path: "/" });
};
