import { useCallback } from "react";

import { MediaSnippet } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";

import { editorUiStateRepository, editorUiStore, SavingStatus } from "./editor-ui.state";

export const useMediaSelectedSnippetState = () => {
  const [selectedSnippet] = useObservable(editorUiStore.pipe(select((state) => state.snippets.selectedSnippet)));

  const setSelectedSnippet = useCallback(
    (snippet?: MediaSnippet) => {
      editorUiStateRepository.updateSelectedSnippet(snippet);
    },
    [selectedSnippet]
  );

  return { selectedSnippet, setSelectedSnippet };
};

export const useMediaActiveSnippetIdState = () => {
  const { selectedSnippet } = useMediaSelectedSnippetState();

  return selectedSnippet?.id;
};

export const useMediaCheckedSnippetsState = () => {
  const [checkedSnippetsState] = useObservable(
    editorUiStore.pipe(select((state) => state.snippets.checkedSnippetsIds))
  );

  const checkedSnippetsIds = checkedSnippetsState ?? [];

  const setCheckedSnippets = useCallback(
    (snippets: string[]) => {
      editorUiStateRepository.updateCheckedSnippet(snippets);
    },
    [checkedSnippetsIds]
  );

  return { checkedSnippetsIds, setCheckedSnippets };
};

export const useMediaHoveredSnippetState = () => {
  const [hoveredSnippet] = useObservable(editorUiStore.pipe(select((state) => state.snippets.hoveredSnippet)));

  const setHoveredSnippet = useCallback(
    (snippetId?: string) => {
      editorUiStateRepository.updateHoveredSnippet(snippetId);
    },
    [hoveredSnippet]
  );

  return { hoveredSnippet, setHoveredSnippet };
};

export const useActiveMediaSelectionsState = () => {
  const [selections] = useObservable(editorUiStore.pipe(select((state) => state.selections)));

  return selections ?? {};
};

export const useActiveMediaHoveredCueIdState = () => {
  const [hoveredCueId] = useObservable(editorUiStore.pipe(select((state) => state.hoveredCueId)));

  return hoveredCueId ?? undefined;
};

export const useActiveMediaSavingStatusState = () => {
  const [savingStatus] = useObservable(editorUiStore.pipe(select((state) => state.savingStatus)));

  return savingStatus ?? SavingStatus.Saved;
};

export const useActiveMediaSettingsPresetsState = () => {
  const [presets] = useObservable(editorUiStore.pipe(select((state) => state.accountSettings?.templates)));

  return presets ?? [];
};
