import React from "react";

type BasePlayerProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
  isAudio: boolean;
};
export const BasePlayer = React.forwardRef<HTMLVideoElement, BasePlayerProps>(({ isAudio, ...props }, ref) => {
  if (isAudio) {
    return <audio {...props} ref={ref} controls={false} />;
  }

  return <video {...props} ref={ref} controls={false} disablePictureInPicture playsInline={true} />;
});

export default BasePlayer;
