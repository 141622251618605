import React, { useState } from "react";

import {
  autoUpdate,
  flip,
  offset,
  ReferenceType,
  shift,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";

import { ISelectOption } from "./select";

interface SelectContext {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedOptions: ISelectOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<ISelectOption[]>>;
  activeOption?: ISelectOption;
  setActiveOption: React.Dispatch<React.SetStateAction<ISelectOption | undefined>>;
  floatingStyles: React.CSSProperties;
  context?: any;
  setReference: (node: ReferenceType | null) => void;
  setFloating: (node: HTMLElement | null) => void;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
}

export const SelectContext = React.createContext<SelectContext | null>(null);

interface SelectProviderProps {
  children?: React.ReactNode;
}

export const SelectProvider: React.FC<SelectProviderProps> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [selectedOptions, setSelectedOptions] = React.useState<ISelectOption[]>([]);
  const [activeOption, setActiveOption] = React.useState<ISelectOption | undefined>();

  const { refs, context, x, y, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    placement: "bottom-start",
    middleware: [offset({ mainAxis: 8 }), flip(), shift()],
    whileElementsMounted: autoUpdate
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  return (
    <SelectContext.Provider
      value={{
        isOpen,
        setOpen,
        searchQuery,
        setSearchQuery,
        selectedOptions,
        setSelectedOptions,
        activeOption,
        setActiveOption,
        floatingStyles: {
          left: x ?? undefined,
          top: y ?? undefined,
          position: strategy,
          width: (refs.domReference?.current as HTMLElement)?.offsetWidth + "px"
        },
        context,
        setReference: refs.setReference,
        setFloating: refs.setFloating,
        getFloatingProps: getFloatingProps
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};

export const useSelect = (): SelectContext => {
  const context = React.useContext(SelectContext);

  if (context === null) {
    throw new Error("useSelect must be used within a SelectProvider");
  }

  return context;
};
