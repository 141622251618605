import React, { useState } from "react";

import { MediaComment } from "@core/interfaces/media";

import { useComments } from "../hooks/use-comments";
import { CommentsSortType } from "../types";

interface CommentsContext {
  showResolved: boolean;
  setShowResolved: React.Dispatch<React.SetStateAction<boolean>>;
  showAddComment: boolean;
  setShowAddComment: React.Dispatch<React.SetStateAction<boolean>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  sort: CommentsSortType;
  setSort: React.Dispatch<React.SetStateAction<CommentsSortType>>;
  sortDesc: boolean;
  setSortDesc: React.Dispatch<React.SetStateAction<boolean>>;
  comments: MediaComment[];
}

export const CommentsContext = React.createContext<CommentsContext | null>(null);

interface CommentsProviderProps {
  children: React.ReactNode;
}
export const CommentsProvider: React.FC<CommentsProviderProps> = ({ children }) => {
  const allComments = useComments();
  const [showResolved, setShowResolved] = useState(false);
  const [showAddComment, setShowAddComment] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState(CommentsSortType.Recent);
  const [sortDesc, setSortDesc] = useState(true);
  const comments = React.useMemo(() => {
    let comments = [...allComments];

    switch (sort) {
      case CommentsSortType.Recent:
        comments.sort((a, b) =>
          sortDesc
            ? Date.parse(b.createdAt) - Date.parse(a.createdAt)
            : Date.parse(a.createdAt) - Date.parse(b.createdAt)
        );
        break;
      case CommentsSortType.Timeline:
        comments.sort((a, b) => (sortDesc ? b.time - a.time : a.time - b.time));
        break;
    }

    if (!showResolved) {
      comments = comments.filter((c) => !c.isResolved);
    }
    if (searchQuery) {
      comments = comments.filter((c) => {
        const searchRegEx = new RegExp(searchQuery, "i");
        if (c.text.match(searchRegEx)) {
          return true;
        }
        return c.replies.filter((r) => r.text.match(searchRegEx)).length > 0;
      });
    }
    return comments;
  }, [allComments, sort, showResolved, searchQuery, sortDesc]);

  return (
    <CommentsContext.Provider
      value={{
        showResolved,
        setShowResolved,
        showAddComment,
        setShowAddComment,
        searchQuery,
        setSearchQuery,
        sort,
        setSort,
        sortDesc,
        setSortDesc,
        comments
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export const useCommentsContext = (): CommentsContext => {
  const context = React.useContext(CommentsContext);

  if (context === null) {
    throw new Error("useCommentsContext must be used within a CommentsProvider");
  }

  return context;
};
