import { createStore, withProps } from "@ngneat/elf";

type MediaPlayerProps = {
  isMuted: boolean;
  isPlaying: boolean;
  playAudioDescription: boolean;
  durationValue: number;
  currentTime: number;
  mediaLoaded: boolean;
  isFullscreen: boolean;
};

const INITIAL_STATE: MediaPlayerProps = {
  isMuted: false,
  isPlaying: false,
  playAudioDescription: false,
  durationValue: 0,
  currentTime: 0,
  mediaLoaded: false,
  isFullscreen: false
};

export const mediaPlayerStore = createStore({ name: "media-player" }, withProps<MediaPlayerProps>(INITIAL_STATE));

class MediaPlayerRepository {
  get currentTime() {
    return mediaPlayerStore.getValue().currentTime;
  }

  get durationInSec() {
    return mediaPlayerStore.getValue().durationValue;
  }

  get durationInMs() {
    return this.durationInSec * 1000;
  }

  updateState = (props: Partial<MediaPlayerProps>) => {
    mediaPlayerStore.update((state) => ({ ...state, ...props }));
  };

  resetState = () => {
    mediaPlayerStore.reset();
  };
}

export const mediaPlayerRepository = new MediaPlayerRepository();
