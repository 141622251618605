import React from "react";

import { requestNewRole } from "@frontend/api/members.service";

import { NewModal } from "@components/new-modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { RoleName } from "@getsubly/common";

type RequestSeatModalProps = {
  onDismiss: () => void;
  onRequestSent?: () => void;
};
export const RequestSeatModal: React.FC<RequestSeatModalProps> = ({ onDismiss, onRequestSent }) => {
  const { currentAccount } = useAccounts();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestEditorSeat = async () => {
    if (!currentAccount?.owner) {
      return;
    }
    setIsLoading(true);
    await requestNewRole(currentAccount.accountId, RoleName.Editor);
    setIsLoading(false);
    onDismiss();
    onRequestSent?.();
  };

  return (
    <NewModal
      onDismiss={onDismiss}
      title="Request for an editor seat"
      secondaryAction="Cancel"
      onClickSecondary={onDismiss}
      primaryAction="Request for editor seat"
      primaryActionLoading={isLoading}
      onClickPrimary={handleRequestEditorSeat}
      size="608"
    >
      <div className="tw-mb-8 tw-text-sm">
        Send a notification to your account admin to grant you an editor seat. Once the admin grants you an editor seat,
        you will be notified via email, and you will have full upload, editing, and download controls.
      </div>
    </NewModal>
  );
};
