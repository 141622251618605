import React from "react";
import classNames from "classnames";

import { useEditorPanelState } from "@media-editor/state/media-editor.hooks";
import { EditorPanel } from "@media-editor/types";

interface NoSubsWarningProps {
  className?: string;
}

export const NoSubsDownloadWarning: React.FC<NoSubsWarningProps> = ({ className }) => {
  return (
    <NoSubsWarningContainer className={className} title="Add subtitles first">
      Add subtitles inside the <SubtitlesTabLabel /> first to download subtitles.
    </NoSubsWarningContainer>
  );
};

export const NoTranscriptionDownloadWarning: React.FC<NoSubsWarningProps> = ({ className }) => {
  return (
    <NoSubsWarningContainer className={className} title="Add subtitles first">
      Add transcription inside the <TranscriptionTabLabel /> first to download transcription.
    </NoSubsWarningContainer>
  );
};

export const SwitchSubsStyleWarning: React.FC<NoSubsWarningProps> = ({ className }) => {
  return (
    <NoSubsWarningContainer className={className} title="Switch subtitle to style">
      There is no transcription for this language. Switch to other transcription first to style subtitles.
    </NoSubsWarningContainer>
  );
};

export const NoSubsStyleWarning: React.FC<NoSubsWarningProps> = ({ className }) => {
  return (
    <NoSubsWarningContainer className={className} title="There are no subtitles to style">
      Add transcriptions inside the <SubtitlesTabLabel /> first to style subtitles.
    </NoSubsWarningContainer>
  );
};

export const NoSubsTranslationWarning: React.FC<NoSubsWarningProps> = ({ className }) => {
  return (
    <NoSubsWarningContainer className={className} title="There are no subtitles to translate">
      Add transcriptions inside the <SubtitlesTabLabel /> first to translate.
    </NoSubsWarningContainer>
  );
};

interface NoSubsWarningContainerProps {
  children: React.ReactNode;
  title: string;
  className?: string;
}

const NoSubsWarningContainer: React.FC<NoSubsWarningContainerProps> = ({ children, title, className }) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-flex-col tw-items-start tw-gap-2 tw-rounded-md tw-border tw-border-warning-200 tw-bg-warning-50 tw-px-5 tw-py-3",
        className
      )}
    >
      <p className="tw-text-sm tw-font-medium tw-text-warning-800">{title}</p>
      <p className="tw-text-sm tw-font-normal tw-text-warning-700">{children}</p>
    </div>
  );
};

const SubtitlesTabLabel: React.FC = () => {
  const { editorPanel, setEditorPanel } = useEditorPanelState();

  if (editorPanel === EditorPanel.Subtitles) {
    return <>subtitles tab</>;
  }

  return (
    <a
      className="tw-cursor-pointer tw-text-sm tw-font-normal tw-text-warning-700 tw-underline"
      onClick={() => setEditorPanel(EditorPanel.Subtitles)}
    >
      subtitles tab
    </a>
  );
};

const TranscriptionTabLabel: React.FC = () => {
  const { editorPanel, setEditorPanel } = useEditorPanelState();

  if (editorPanel === EditorPanel.Transcription) {
    return <>transcription tab</>;
  }

  return (
    <a
      className="tw-cursor-pointer tw-text-sm tw-font-normal tw-text-warning-700 tw-underline"
      onClick={() => setEditorPanel(EditorPanel.Transcription)}
    >
      transcription tab
    </a>
  );
};
