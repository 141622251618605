import React from "react";

import { HandleOAuth2Callback } from "@frontend/containers/auth/handle-oauth2-callback";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const OAuth2MicrosoftCallbackPage: React.FC<{
  callbackPath: string;
}> = ({ callbackPath }) => {
  return (
    <AuthLayout>
      <HandleOAuth2Callback redirectUrl={`${window?.location?.origin}${callbackPath}`} />
    </AuthLayout>
  );
};
