import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { UserVerification } from "@frontend/containers/auth/user-verification";
import { AuthLayout } from "@frontend/layouts/auth.layout";

import { useQuery } from "@core/hooks/use-query";
import { isBase64 } from "@core/utils/links";

interface LocationState {
  hasStateParsed?: boolean;
}

interface Params {
  state?: string;
}

interface VerifyUserPageProps {
  external?: boolean;
}
export const VerifyUserPage: React.FC<VerifyUserPageProps> = ({ external = false }) => {
  const { pathname, state: locationState } = useLocation();
  const { queryParams } = useQuery<Params>();

  if (!(locationState as LocationState)?.hasStateParsed && isBase64(queryParams.state)) {
    const searchParams = new URLSearchParams();
    const search = searchParams.toString();
    return <Navigate to={{ pathname, search }} state={{ hasStateParsed: true }} />;
  }

  return (
    <AuthLayout>
      <UserVerification external={external} />
    </AuthLayout>
  );
};
