import React, { useEffect, useState } from "react";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { Subscription, SubscriptionStatus } from "@core/interfaces/billing";
import { RoleName } from "@getsubly/common";

export enum BannerType {
  Viewer = "Viewer",
  PaymentOverdue = "PaymentOverdue",
  Free = "Free",
  Trial = "Trial",
  StorageLow = "StorageLow",
  SpeakerStyleCreated = "SpeakerStyleCreated",
  ScheduleAccountDeletion = "ScheduleAccountDeletion"
}
interface TopBannerContext {
  isTopBannerVisible: boolean;
  setTopBannerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  bannerType?: BannerType;
}

const TopBannerContext = React.createContext<TopBannerContext | null>(null);

interface TopBannerProviderProps {
  children: React.ReactNode;
}
export const TopBannerProvider: React.FC<TopBannerProviderProps> = ({ children }) => {
  const { isFree, isTrial, isStorageLow } = usePlan();
  const { subscription, isLoaded, currentAccount } = useAccounts();

  const bannerType = getBannerType({
    isViewer: currentAccount?.role === RoleName.Viewer,
    isFree,
    isTrial,
    subscription,
    isStorageLow,
    scheduleAccountDeletion: Boolean(currentAccount?.scheduledDeletionDate)
  });

  const [isTopBannerVisible, setTopBannerVisible] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setTopBannerVisible(Boolean(bannerType));
    }
  }, [isLoaded, bannerType]);

  return (
    <TopBannerContext.Provider
      value={{
        isTopBannerVisible,
        setTopBannerVisible,
        bannerType
      }}
    >
      {children}
    </TopBannerContext.Provider>
  );
};

export const useTopBanner = (): TopBannerContext => {
  const context = React.useContext(TopBannerContext);

  if (context === null) {
    throw new Error("useTopBanner must be used within a TopBannerProvider");
  }

  return context;
};

interface GetBannerType {
  isViewer: boolean;
  isFree: boolean;
  isTrial: boolean;
  subscription?: Subscription;
  isStorageLow?: boolean;
  scheduleAccountDeletion?: boolean;
}
const getBannerType = ({
  isViewer,
  isFree,
  isTrial,
  subscription,
  isStorageLow,
  scheduleAccountDeletion
}: GetBannerType): BannerType | undefined => {
  if (isViewer) {
    return BannerType.Viewer;
  }

  if (scheduleAccountDeletion) {
    return BannerType.ScheduleAccountDeletion;
  }

  if (isFree) {
    return BannerType.Free;
  }

  if (subscription?.status === SubscriptionStatus.PastDue || subscription?.status === SubscriptionStatus.Unpaid) {
    return BannerType.PaymentOverdue;
  }

  if (isTrial) {
    return BannerType.Trial;
  }

  if (!isFree && isStorageLow) {
    return BannerType.StorageLow;
  }

  return undefined;
};
