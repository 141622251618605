import { Asset } from "@core/interfaces/asset";
import { assetsQuery } from "@core/state/assets/assets.query";
import { assetsStore } from "@core/state/assets/assets.store";
import { useObservable } from "@mindspace-io/react";

interface IUseAssets {
  artworkAssets: Asset[];
  logoAssets: Asset[];
  fontAssets: Asset[];
  backgroundAssets: Asset[];
  findAssetById: (assetId: string) => Asset | undefined;
  addAsset: (asset: Asset) => void;
}

export const useAssets = (): IUseAssets => {
  const [artworkAssets] = useObservable<Asset[]>(assetsQuery.selectArtworkAssets(), assetsQuery.artworkAssets);

  const [logoAssets] = useObservable<Asset[]>(assetsQuery.selectLogoAssets(), assetsQuery.logoAssets);

  const [fontAssets] = useObservable<Asset[]>(assetsQuery.selectFontAssets(), assetsQuery.fontAssets);

  const [backgroundAssets] = useObservable<Asset[]>(assetsQuery.selectBackgroundAssets(), assetsQuery.backgroundAssets);

  const [assets] = useObservable<Asset[]>(assetsQuery.selectAll(), assetsQuery.assets);

  const findAssetById = (id: string): Asset | undefined => {
    return assets.find((asset) => asset.id === id);
  };

  const addAsset = (asset: Asset) => {
    assetsStore.add(asset);
  };

  return {
    artworkAssets,
    logoAssets,
    fontAssets,
    backgroundAssets,
    findAssetById,
    addAsset
  };
};
