import React from "react";

import { PlanInterval } from "@core/interfaces/billing";

interface PricingIntervalToggleContext {
  pricingToggle: PlanInterval;
  setPricingToggle: React.Dispatch<React.SetStateAction<PlanInterval>>;
}

const PricingIntervalToggleContext = React.createContext<PricingIntervalToggleContext | null>(null);

interface PricingIntervalToggleProviderProps {
  children: React.ReactNode;
}
export const PricingIntervalToggleProvider: React.FC<PricingIntervalToggleProviderProps> = ({ children }) => {
  const [pricingToggle, setPricingToggle] = React.useState(PlanInterval.Yearly);

  return (
    <PricingIntervalToggleContext.Provider
      value={{
        pricingToggle,
        setPricingToggle
      }}
    >
      {children}
    </PricingIntervalToggleContext.Provider>
  );
};

export const usePricingIntervalToggle = (): PricingIntervalToggleContext => {
  const context = React.useContext(PricingIntervalToggleContext);

  if (context === null) {
    throw new Error("usePricingIntervalToggle must be used within a PricingIntervalToggleProvider");
  }

  return context;
};
