import React from "react";
import classNames from "classnames";
import { PlacesType } from "react-tooltip";

import { ToolTip } from "@frontend/containers/tooltip/tooltip";

interface AvatarSymbolProps {
  text: string;
  size: "12" | "16" | "20" | "24";
  className?: string;
  tooltip?: string;
  tooltipPlacement?: PlacesType;
  children?: React.ReactNode;
}

export const AvatarSymbol: React.FC<AvatarSymbolProps> = ({
  text,
  size,
  className,
  tooltip,
  tooltipPlacement = "top",
  children
}) => {
  const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  const sizeClass = {
    "12": "tw-h-3 tw-text-[7px] tw-border-[1.5px] tw-w-3 tw-h-3",
    "16": "tw-h-4 tw-text-[10px] tw-border-[1px] tw-w-4 tw-h-4",
    "20": "tw-h-5 tw-text-[12px] tw-border-[1.5px] tw-w-5 tw-h-5",
    "24": "tw-h-6 tw-text-[12px] tw-border-[1.5px] tw-w-6 tw-h-6"
  }[size];

  return (
    <div className="tw-flex">
      <span
        className={classNames(
          "tw-box-content tw-inline-flex tw-shrink-0 tw-select-none tw-items-center tw-justify-center tw-overflow-hidden tw-whitespace-nowrap tw-rounded-full tw-border-white tw-bg-[#efefef] tw-text-center tw-font-medium tw-text-primary-900",
          sizeClass,
          className
        )}
        data-tooltip-id={`flag-${uniqueId}-more`}
      >
        {children ?? text}
      </span>
      {tooltip && (
        <ToolTip text={tooltip} place={tooltipPlacement} anchorId={`flag-${uniqueId}-more`} delayShow={500} />
      )}
    </div>
  );
};
