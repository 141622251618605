import React from "react";

import { EmailVerification } from "@frontend/containers/auth/email-verification";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const VerifyEmailPage: React.FC = () => (
  <AuthLayout>
    <EmailVerification />
  </AuthLayout>
);
