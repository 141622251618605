import React, { useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { duplicateMedia } from "@frontend/api/media.service";
import { Fieldset } from "@frontend/components/form-controls/fieldset";
import { MEDIA_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Checkbox, Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

import { useDashboard } from "@core/hooks/use-dashboard";
import { usePlan } from "@core/hooks/use-plan";
import { authQuery } from "@core/state/auth/auth.query";
import { editorStateRepository } from "@core/state/editor/editor.state";

import { ProjectLocationDropdown } from "../dashboard/upload-modal/project-location-dropdown";

interface DuplicateMediaModalProps {
  closeModal: () => void;
  mediaId: string;
  mediaName: string;
  mediaFolderId?: string;
  mediaFileSize?: number;
  isEditor?: boolean;
}
export const DuplicateMediaModal: React.FC<DuplicateMediaModalProps> = (props) => {
  return (
    <NewModal onDismiss={props.closeModal} disableBackgroundDismiss showCloseButton title="Copy project">
      <DuplicateMediaModalContainer {...props} />
    </NewModal>
  );
};

interface DuplicateMediaModalContainerProps {
  closeModal: () => void;
  mediaId: string;
  mediaName: string;
  mediaFolderId?: string;
  mediaFileSize?: number;
  isEditor?: boolean;
}

const DuplicateMediaModalContainer: React.FC<DuplicateMediaModalContainerProps> = ({
  closeModal,
  mediaId,
  mediaName,
  mediaFolderId,
  mediaFileSize,
  isEditor
}) => {
  const { getFolderById, selectFolder } = useDashboard();
  const currentLocation = getFolderById(mediaFolderId);
  const [projectName, setProjectName] = useState(`${mediaName} - copy`);
  const [folder, setFolder] = useState(currentLocation);
  const [shareMedia, setShareMedia] = useState(false);
  const [copyComments, setCopyComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { availableStorage } = usePlan();
  const hasInsufficientStorage = (mediaFileSize ?? 0) > availableStorage;
  const media = editorStateRepository.media;
  const isMediaOwner = authQuery.accountId === media?.accountId;

  const handleDuplicate = async () => {
    setIsLoading(true);
    const newMedia = await duplicateMedia(mediaId, projectName, shareMedia, copyComments, folder?.id);

    const newMediaId = newMedia?.id;

    if (isEditor) {
      navigate(`${MEDIA_PATH}/${newMediaId}`);
    } else {
      if (folder) {
        selectFolder(folder.id);
      }
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <div className="tw-grid tw-gap-4">
      <div>
        <Fieldset title="Name">
          <Input type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
        </Fieldset>
        <Fieldset title="Folder">
          <ProjectLocationDropdown folder={folder} setFolder={(f) => setFolder(f)} />
        </Fieldset>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <div
          className={classNames("tw-flex tw-items-center", {
            "tw-hidden": !isMediaOwner
          })}
        >
          <Checkbox
            onChange={() => setShareMedia((s) => !s)}
            checked={shareMedia}
            label="Share project with the same people"
          />
        </div>
        <div className="tw-flex tw-items-center">
          <Checkbox onChange={() => setCopyComments((s) => !s)} checked={copyComments} label="Copy comments" />
        </div>
      </div>
      {hasInsufficientStorage && (
        <div className="tw-flex tw-flex-col tw-text-destructive-600">
          <p className="">You don't have enough storage left.</p>
          <Link onClick={closeModal} to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}>
            Purchase more here.
          </Link>
        </div>
      )}
      <div className="tw-flex tw-justify-end tw-gap-3">
        <Button
          variant="primary"
          onClick={handleDuplicate}
          disabled={hasInsufficientStorage}
          loading={isLoading}
          size="40"
        >
          Make a copy
        </Button>
      </div>
    </div>
  );
};
