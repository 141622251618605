import React from "react";

import { AccountMember } from "@core/interfaces/account";
import { AdditionalItem, SchedulePhase } from "@core/interfaces/billing";
import { RoleName } from "@getsubly/common";

import { useAccounts } from "./use-accounts";

interface UseAccountMembers {
  adminCount: number;
  editorCount: number;
  viewerCount: number;
  userCount: number;
  nextSchedulePhase?: SchedulePhase;
  totalFilledSeats: number;
  totalPossibleSeats: number;
  totalSeats: number;
  seatsToBeRemoved: number;
  availableSeats: number;
  availableSeatsToBeRemoved: number;
}
export const useAccountMembers = (): UseAccountMembers => {
  const { accountTeam, currentAccount, subscription } = useAccounts();

  const { adminCount, editorCount, viewerCount, userCount } = React.useMemo(() => {
    const count = (members: AccountMember[], type: RoleName) => {
      return members.filter((i) => i.role === type).length || 0;
    };
    const adminCount = count(accountTeam?.members ?? [], RoleName.Admin);
    const editorCount = count(accountTeam?.members ?? [], RoleName.Editor);
    const viewerCount = count(accountTeam?.members ?? [], RoleName.Viewer);
    const userCount = adminCount + editorCount + viewerCount;
    return { adminCount, editorCount, viewerCount, userCount };
  }, [accountTeam?.members]);

  const planTeamCapacity = currentAccount?.planTeamCapacity ?? 0;
  const additionalSeats = currentAccount?.additionalSeats ?? 0;
  const totalSeats = planTeamCapacity + additionalSeats;

  const nextSchedulePhase = subscription?.schedule?.next.sort((a, b) => +a.start - +b.start)?.[0];
  const nextSchedulePlanSeatsItem = nextSchedulePhase?.items.find((i) => i.product === AdditionalItem.Seats);
  const scheduledPlanUnits = nextSchedulePlanSeatsItem?.units ?? totalSeats;
  const seatsToBeRemoved = Math.max(totalSeats - scheduledPlanUnits - 1, 0);

  const totalFilledSeats = adminCount + editorCount;
  const availableSeats = totalSeats - totalFilledSeats - seatsToBeRemoved;
  const availableSeatsToBeRemoved = totalSeats - totalFilledSeats;
  // Possible seats to be used for a teammate
  const totalPossibleSeats = totalSeats - seatsToBeRemoved;

  return {
    adminCount,
    editorCount,
    viewerCount,
    userCount,
    nextSchedulePhase,
    totalPossibleSeats,
    totalSeats,
    totalFilledSeats,
    seatsToBeRemoved,
    availableSeats,
    availableSeatsToBeRemoved
  };
};
