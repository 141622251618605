import React from "react";
import { differenceInDays, format } from "date-fns";
import { Link, useLocation } from "react-router-dom";

import { OverdueInvoiceModal } from "@frontend/components/overdue-invoice-modal/overdue-invoice-modal";
import { SubscriptionModal } from "@frontend/containers/modals/subscription-modal";
import { CancelDeletionModal } from "@frontend/containers/settings/profile-card/profile-card";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { BannerType, useTopBanner } from "@frontend/contexts/top-banner.context";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { LoadingIcon } from "@components/loading-icon";
import { SingleLineBanner } from "@components/single-line-banner/single-line-banner";

import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { ModalType } from "@core/interfaces/modal-type";
import { parseCreditToText } from "@core/utils/plans";
import { RiDeleteBinLine, RiErrorWarningLine, RiFlashlightFill, RiUser3Line } from "@remixicon/react";

export const DashboardBanner: React.FC = () => {
  const { bannerType } = useTopBanner();
  const { isLoaded } = useAccounts();

  if (BannerType.Viewer === bannerType) {
    return <ViewerBanner />;
  }

  if (!isLoaded) {
    return;
    return <LoadingBanner />;
  }

  switch (bannerType) {
    case BannerType.ScheduleAccountDeletion:
      return <ScheduledAccountDeletionBanner />;

    case BannerType.Free:
      return <FreeBanner />;

    case BannerType.Trial:
      return <TrialBanner />;

    case BannerType.PaymentOverdue:
      return <PaymentOverdueBanner />;

    case BannerType.StorageLow:
      return <StorageLowBanner />;

    default:
      return null;
  }
};

const LoadingBanner: React.FC = () => {
  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center"
      align="center"
      hideClose
      icon={<LoadingIcon />}
    />
  );
};

const PaymentOverdueBanner: React.FC = () => {
  const { hasUserClosedOverduePaymentPopUp } = useAuthProvider();
  const { hasPaymentOverdue } = usePlan();

  const [showModal, hideModal] = useModal(ModalType.OverdueInvoiceModal);

  if (!hasPaymentOverdue || !hasUserClosedOverduePaymentPopUp) {
    return null;
  }

  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center"
      theme="soft-red"
      align="center"
      hideClose
      icon={<RiErrorWarningLine className="tw-mr-4 " />}
    >
      <div>
        It seems that the latest payment hasn’t been processed. Please{" "}
        <span
          className="tw-cursor-pointer tw-font-semibold hover:tw-underline"
          onClick={() => showModal(<OverdueInvoiceModal closeAlert={hideModal} />)}
        >
          click here to confirm payment
        </span>{" "}
        before accessing your Subly account.
      </div>
    </SingleLineBanner>
  );
};

const ViewerBanner: React.FC = () => {
  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center"
      theme="soft-yellow"
      align="center"
      hideClose
      icon={<RiUser3Line className="tw-mr-4 tw-text-warning-600" />}
    >
      <div>You are a viewer.</div>
    </SingleLineBanner>
  );
};

const FreeBanner: React.FC = () => {
  const { isFree } = usePlan();
  const { pathname } = useLocation();
  const inPlansPage = pathname === SETTINGS_BILLING_PATH;
  if (!isFree || inPlansPage) {
    return null;
  }

  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center"
      align="center"
      hideClose
      icon={<RiFlashlightFill className="tw-mr-4 tw-text-primary-800" />}
    >
      <div>
        <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="!tw-underline">
          Upgrade now
        </Link>{" "}
        to unlock downloads.
      </div>
    </SingleLineBanner>
  );
};

const TrialBanner: React.FC = () => {
  const { isTrial, trialEndDate, credit, isPayg, isFree } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const [showModal, hideModal] = useModal(ModalType.Subscription);

  const paygCredit = credit?.payg ?? 0;
  const totalCredit = credit?.total ?? 0;
  const totalCreditWithoutPayG = totalCredit - paygCredit;

  const balanceText = isFree && !isPayg ? "0 mins" : parseCreditToText(totalCreditWithoutPayG, false, false);

  if (!isTrial || !trialEndDate) {
    return null;
  }

  const humanEndDate = trialEndDate ? format(new Date(trialEndDate), "dd MMM") : "";

  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center tw-justify-center"
      align="center"
      hideClose
      icon={<RiFlashlightFill className="tw-mr-4 tw-text-primary-800" />}
    >
      <div>
        <strong className="tw-font-medium tw-text-primary-800">{`Your trial will end on ${humanEndDate}.`}</strong>{" "}
        <span className="tw-text-primary-800">You have {balanceText} remaining </span>
      </div>
      <Button
        variant="secondary"
        onClick={() => {
          trackEventWithAuth("Dashboard / Upgrade now on trial banner");
          showModal(<SubscriptionModal hideModal={hideModal} />);
        }}
        size="32"
        className="tw-ml-4 !tw-no-underline hover:!tw-text-black"
      >
        Upgrade now
      </Button>
    </SingleLineBanner>
  );
};

const StorageLowBanner: React.FC = () => {
  const { storageUsagePercentage } = usePlan();
  const { pathname } = useLocation();
  const inAccountPage = pathname === SETTINGS_BILLING_PATH;

  if (inAccountPage) return null;

  return (
    <SingleLineBanner
      rounded={false}
      className="!tw-border-none"
      innerClassName="tw-flex tw-items-center"
      align="center"
      hideClose
      icon={<RiFlashlightFill className="tw-mr-4 tw-text-primary-800" />}
    >
      <div>
        You’re at {storageUsagePercentage}% of your storage limit.{" "}
        <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="!tw-underline">
          Increase
        </Link>{" "}
        your storage to continue using Subly.
      </div>
    </SingleLineBanner>
  );
};

const ScheduledAccountDeletionBanner: React.FC = () => {
  const { currentAccount } = useAccounts();
  const { isFree } = usePlan();
  const { pathname, search } = useLocation();
  const [showCancelDeletionModal, hideCancelDeletionModal] = useModal(ModalType.CancelDeletionModal);

  const handleCancelDeletion = () => {
    showCancelDeletionModal(<CancelDeletionModal onDismiss={hideCancelDeletionModal} />);
  };

  if (!currentAccount?.scheduledDeletionDate) return null;
  if (pathname === SETTINGS_BILLING_PATH && new URLSearchParams(search).get("checkout") === "true") return null;

  const deletionDate = new Date(currentAccount?.scheduledDeletionDate);
  const daysLeft = differenceInDays(deletionDate, new Date());

  return (
    <>
      <SingleLineBanner
        rounded={false}
        theme="soft-red"
        className="!tw-border-none tw-bg-destructive-50"
        innerClassName="tw-flex tw-items-center"
        align="center"
        hideClose
        icon={<RiDeleteBinLine className="tw-mr-4 tw-text-destructive-700" />}
      >
        <div className="tw-text-destructive-700">
          This account will be deleted {daysLeft >= 1 ? `in ${daysLeft} days` : "today"}
          {isFree ? (
            <Button
              className="tw-ml-4"
              variant="secondary"
              size="32"
              type="link"
              to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
            >
              Cancel deletion
            </Button>
          ) : (
            <Button className="tw-ml-4" variant="secondary" size="32" onClick={handleCancelDeletion}>
              Cancel deletion
            </Button>
          )}
        </div>
      </SingleLineBanner>
    </>
  );
};
