import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { AppId, unlinkApp } from "@frontend/api/apps.service";
import { ZapierContainer } from "@frontend/containers/zapier/zapier-container";
import { ZoomContainer } from "@frontend/containers/zoom/zoom-container";
import { SETTINGS_APPS_PATH } from "@frontend/routes";

import { Button } from "@components/button";

export const AppPage: React.FC = () => {
  const { appId } = useParams();
  const navigate = useNavigate();

  const unlink = async (): Promise<void> => {
    if (!appId) {
      return;
    }

    const unlinked = await unlinkApp(appId as AppId);

    if (unlinked) {
      navigate(SETTINGS_APPS_PATH);
    }
  };

  return (
    <div>
      <div className="tw-flex">
        <Button variant="secondary" className="tw-ml-auto" onClick={unlink} size="36">
          Unlink app
        </Button>
      </div>
      <AppContainer appId={appId} />
    </div>
  );
};

interface AppContainerProps {
  appId?: string;
}
const AppContainer: React.FC<AppContainerProps> = ({ appId }) => {
  switch (appId) {
    case "zapier":
      return <ZapierContainer />;
    case "zoom":
      return <ZoomContainer />;
    default:
      return <Navigate to={SETTINGS_APPS_PATH} />;
  }
};
