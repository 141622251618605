import React, { useEffect } from "react";

import { getCustomer } from "@frontend/api/billing.service";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { AddBillingDetailsForm } from "@frontend/containers/settings/billing-details-card/add-billing-details-form";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { accountQuery } from "@core/state/account";

export const AddBillingDetailsPage: React.FC = () => {
  const { user } = useAuthProvider();

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer(getAccountId(), { force: false });
  }, [user]);

  if (!user) {
    return null;
  }

  const hasBillingDetails = accountQuery.hasBillingDetails;
  const title = hasBillingDetails ? "Edit billing details" : "Add billing details";

  return (
    <>
      <SettingsPageHeader title={title} backUrl={SETTINGS_BILLING_PATH} className="tw-mb-6" />
      <AddBillingDetailsForm />
    </>
  );
};
