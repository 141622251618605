import { isNil, omitBy } from "lodash-es";
import slugify from "slugify";

import config from "@frontend/config";

export const makeShareUrl = ({
  previewBaseUrl,
  publicShareId,
  name
}: {
  previewBaseUrl: string;
  publicShareId: string;
  name?: string;
}): string => `${previewBaseUrl}/preview/${publicShareId}${name && slugify(name) ? `/${slugify(name)}` : ""}`;

/**
 * Clean the search string if it exists.
 *
 * `cleanSearchString("?param1=value&param2=value")`
 * `param1=value&param2=value`
 */
export const cleanSearchString = (search = "", append = ""): string => {
  const splitSearch = search.split("?");
  const newSearch = splitSearch.length > 1 ? splitSearch[1] : search;

  return newSearch ? `${append}${newSearch}` : "";
};

export const encodeOAuth2CallbackState = (params: Record<string, any>): string => {
  return btoa(JSON.stringify(params));
};

export const decodeOAuth2CallbackState = (encodedState: string): Record<string, any> => {
  try {
    return JSON.parse(atob(encodedState));
  } catch (error) {
    return {};
  }
};

export const isBase64 = (encoded?: string): boolean => {
  if (!encoded || encoded.trim() === "" || encoded === "undefined") {
    return false;
  }

  try {
    return btoa(atob(encoded)) === encoded;
  } catch (err) {
    return false;
  }
};

export const updateUrlParams = (params: string, value?: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (value) {
    searchParams.set(params, value);
  } else {
    searchParams.delete(params);
  }

  const search = searchParams.toString();
  const newUrl = `${window.location.origin}${window.location.pathname}${search ? "?" + search : ""}`;

  window.history.pushState({}, "", newUrl);
};

export const getSSOHref = (provider: "Google" | string, params?: Record<string, string | number>): string => {
  const redirect_uri = provider === "Google" ? config.oAuth2.google : config.oAuth2.microsoft;

  let href = `${config.apiUrl}/api/v1/auth/${provider}/authorize?redirect_uri=${redirect_uri}`;

  params = omitBy(params ?? {}, isNil); // remove undefined fields from object

  href += `&state=${encodeOAuth2CallbackState(params)}`;

  return href;
};

export const getGoogleSignInHref = (): string => {
  return `${config.apiUrl}/api/v1/auth/Google/authorize?redirect_uri=${config.oAuth2.google}`;
};
