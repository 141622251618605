import React from "react";

import { Testimonial, TESTIMONIALS } from "@frontend/components/community-spotlight/community-testimonials";

import { getRandomInt } from "@core/utils/index";

interface TestimonialContext {
  testimonial: Testimonial;
}

const TestimonialContext = React.createContext<TestimonialContext | null>(null);

interface TestimonialProviderProps {
  children: React.ReactNode;
}
export const TestimonialProvider: React.FC<TestimonialProviderProps> = ({ children }) => {
  const [testimonial] = React.useState(TESTIMONIALS[getRandomInt(TESTIMONIALS.length)]);

  return <TestimonialContext.Provider value={{ testimonial }}>{children}</TestimonialContext.Provider>;
};

export const useTestimonial = (): TestimonialContext => {
  const context = React.useContext(TestimonialContext);

  if (context === null) {
    throw new Error("useTestimonial must be used within a TestimonialProvider");
  }

  return context;
};
