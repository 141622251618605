import { AspectRatio } from "@getsubly/common";

export type DownloadAspectRatio = AspectRatio | DownloadAspectRatioOption;

export enum DownloadAspectRatioOption {
  None = "None"
}

export const ratioLabel = (ratio?: DownloadAspectRatio, isOriginal = false): string => {
  if (!ratio) {
    return "Original";
  }

  if (ratio === DownloadAspectRatioOption.None) {
    return ratio;
  }

  const label = calculateRatioLabelTitle(ratio);

  if (!isOriginal || ratio === AspectRatio.Original) {
    return `${label}`;
  }

  return `${label} (Original)`;
};

export const calculateRatioLabelTitle = (ratio: AspectRatio): string => {
  switch (ratio) {
    case AspectRatio.Landscape:
      return `Landscape ${ratio}`;
    case AspectRatio.Portrait:
      return `Portrait ${ratio}`;
    case AspectRatio.Square:
      return `Square ${ratio}`;
    case AspectRatio.Vertical:
      return `Vertical ${ratio}`;

    default:
      return ratio;
  }
};

export const ratioHelper = (ratio?: DownloadAspectRatio): string | null => {
  if (!ratio) {
    return null;
  }

  switch (ratio) {
    case AspectRatio.Landscape:
      return "YouTube, Facebook, Instagram, TikTok, LinkedIn";
    case AspectRatio.Portrait:
      return "YouTube Shorts, Tiktok, Stories, Snapchat, Pinterest.";
    case AspectRatio.Square:
      return "Twitter, Instagram, TikTok, Pinterest";
    case AspectRatio.Vertical:
      return "Instagram";

    default:
      return null;
  }
};
