import { Asset } from "@core/interfaces/asset";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface AssetsState extends EntityState<Asset, string> {
  isUploading: boolean;
}

@StoreConfig({ name: "assets" })
export class AssetsStore extends EntityStore<AssetsState> {
  constructor() {
    super({ isUploading: false, loading: false });
  }
}

export const assetsStore = new AssetsStore();
