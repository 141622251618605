import React, { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { getCustomer, getIntent } from "@frontend/api/billing.service";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

interface AddPaymentMethodModalProps {
  closeModal: (saved?: boolean) => void;
  disableBackgroundDismiss?: boolean;
}

export const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = ({
  closeModal,
  disableBackgroundDismiss = true
}) => {
  return (
    <NewModal size="465" onDismiss={closeModal} showCloseButton disableBackgroundDismiss={disableBackgroundDismiss}>
      <AddPaymentMethodModalContainer closeModal={closeModal} />
    </NewModal>
  );
};

interface PaymentMethodForm {
  cardholderName: string;
}

export const AddPaymentMethodModalContainer: React.FC<Omit<AddPaymentMethodModalProps, "disableBackgroundDismiss">> = ({
  closeModal
}) => {
  const { paymentMethods = [] } = useAccounts();
  const [focus, setFocus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const hasCard = paymentMethods.length > 0;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<PaymentMethodForm>({
    mode: "onChange"
  });

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (data: PaymentMethodForm) => {
    if (!stripe) {
      setError("There is an issue please send an email to support@getsubly.com.");
      return;
    }

    if (!elements) {
      setError("There is an issue please contact support@getsubly.com");
      return;
    }

    const canAddCard = isValid && complete;

    if (!canAddCard) {
      setError("Some information is missing");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("There is an issue, please contact support@getsubly.com");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const cardToken = await stripe.createToken(cardElement);

      if (cardToken.token?.card?.funding === "prepaid") {
        setError("We don't accept prepaid cards.");
        return;
      }

      const {
        data: { intent }
      } = await getIntent();

      const { error, setupIntent } = await stripe.confirmCardSetup(intent, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: data.cardholderName
          }
        }
      });

      if (error) {
        setError(error.message);
        return;
      }

      if (!setupIntent || !setupIntent.payment_method) {
        setError("There was an unknown error");
        return;
      }

      await getCustomer(getAccountId(), { force: true });
      closeModal();
    } catch (error) {
      setError("There was an error. Please try again or contact support.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h6 className="tw-mb-6 tw-text-h6 tw-font-bold">{hasCard ? "Change" : "Add"} payment method</h6>
      <form className="edit-account-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-mb-4">
          <label className="tw-font-medium tw-text-neutral-900">Name on card</label>
          <Input
            type="text"
            hasError={!!errors.cardholderName}
            {...register("cardholderName", { required: true })}
            disabled={loading}
          />
        </div>
        <div className="tw-mb-6">
          <label className="tw-font-medium tw-text-neutral-900">Credit / debit card number</label>
          <CardElement
            className={classNames(
              "tw-flex tw-h-10 tw-flex-col tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-3 tw-py-2 tw-text-sm tw-leading-7 tw-text-black tw-shadow-none tw-transition-all tw-duration-200 tw-ease-in hover:tw-border-aux-500 focus:tw-ring-2 focus:tw-ring-aux-200 focus:tw-ring-opacity-50",
              {
                focused: focus
              }
            )}
            options={{ hidePostalCode: true }}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            onChange={(e) => setComplete(e.complete)}
          />
        </div>
        {error && (
          <div className="tw-flex">
            <span className="has-error tw-text-sm tw-text-destructive-500">{error}</span>
          </div>
        )}

        <div className="tw-flex tw-flex-row tw-justify-end">
          <Button variant="secondary" onClick={closeModal} className="tw-mr-3" size="32">
            Cancel
          </Button>
          <Button type="submit" variant="primary" loading={loading} size="32">
            Save card
          </Button>
        </div>
      </form>
    </>
  );
};
