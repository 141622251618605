import React from "react";

interface BrowserTabContext {
  browserTabId: number;
}

export const BrowserTabContext = React.createContext<BrowserTabContext | null>(null);

interface BrowserTabProviderProps {
  children: React.ReactNode;
}
export const BrowserTabProvider: React.FC<BrowserTabProviderProps> = ({ children }) => {
  const browserTabId = React.useMemo(() => Math.floor(Math.random() * 1000000), []);

  return <BrowserTabContext.Provider value={{ browserTabId }}>{children}</BrowserTabContext.Provider>;
};

export const useBrowserTab = (): BrowserTabContext => {
  const context = React.useContext(BrowserTabContext);

  if (context === null) {
    throw new Error("useBrowserTab must be used within a BrowserTabProvider");
  }

  return context;
};
