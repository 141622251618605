import { BorderStyle } from "@getsubly/common";

export interface BorderProps {
  border?: BorderStyle;
  scale: number;
}

export const Border: React.FC<BorderProps> = ({ border, scale }) => {
  const hasBorder = border?.width && Boolean(border?.width);

  if (!hasBorder) {
    return null;
  }

  return (
    <div
      className="tw-absolute tw-flex tw-h-full tw-w-full"
      style={{
        borderWidth: border.width * scale,
        borderColor: border?.color ?? "#000000",
        borderStyle: "solid",
        transition: "all"
      }}
    />
  );
};
