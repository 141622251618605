import { ISelectGroup, ISelectOption } from "../select";

// TRANSFORMERS

export const flattenInput = (options: ISelectOption[] | ISelectGroup[]): ISelectOption[] => {
  let flattenedOptions: ISelectOption[] = [];
  for (const option of options) {
    if ("options" in option) {
      flattenedOptions = [...flattenedOptions, ...option.options];
    } else {
      flattenedOptions = [...flattenedOptions, option];
    }
  }
  return flattenedOptions;
};

export const transformInputToGroups = (input: ISelectOption[] | ISelectGroup[]): ISelectGroup[] => {
  let groups: ISelectGroup[] = [];
  let defaultGroup: ISelectGroup = { label: null, id: "", options: [] };

  for (const item of input) {
    if ("options" in item) {
      groups = [...groups, item];
    } else {
      defaultGroup = {
        ...defaultGroup,
        options: [...defaultGroup.options, item]
      };
    }
  }

  if (defaultGroup.options.length) {
    groups = [defaultGroup, ...groups];
  }

  return groups;
};

// FILTERS

export const filterOptions = (
  options: ISelectOption[],
  config: {
    excludeList?: ISelectOption[];
    valueIncludeList?: string[];
    query?: string;
  }
): ISelectOption[] => {
  const { excludeList = [], valueIncludeList, query = "" } = config;

  const filterExcludeList = (option: ISelectOption) => {
    return !excludeList.find((excludeListItem) => excludeListItem.value === option.value);
  };

  const filterValueIncludeList = (option: ISelectOption) => {
    return valueIncludeList?.find((value) => value === option.value);
  };

  const filterSearchQuery = (option: ISelectOption) => {
    return option.label.toLowerCase().includes(query.toLowerCase());
  };

  if (excludeList.length) {
    options = options.filter(filterExcludeList);
  }

  if (valueIncludeList) {
    options = options.filter(filterValueIncludeList);
  }

  if (query) {
    options = options.filter(filterSearchQuery);
  }

  return options;
};

export const filterGroups = (
  groups: ISelectGroup[],
  config: { excludeList: ISelectOption[]; query: string }
): ISelectGroup[] => {
  const filteredGroups: ISelectGroup[] = [];

  for (const group of groups) {
    const options = filterOptions(group.options, config);
    filteredGroups.push({ ...group, options });
  }

  const filterEmptyGroups = (group: ISelectGroup) => {
    return group.options.length > 0;
  };

  return filteredGroups.filter(filterEmptyGroups);
};

// NAVIGATION

export const getCurrentActiveIndex = (options: ISelectOption[], activeOption?: ISelectOption) => {
  if (activeOption) {
    return options.findIndex((option) => activeOption.value === option.value);
  }
  return 0;
};

export const getPrevActiveIndex = (options: ISelectOption[], currentIndex: number) => {
  const prevIndex = options
    .slice(0, currentIndex)
    .reverse()
    .findIndex((option) => !option.disabled);
  if (prevIndex === -1) {
    return -1;
  } else {
    return currentIndex - prevIndex - 1;
  }
};

export const getNextActiveIndex = (options: ISelectOption[], currentIndex: number) => {
  return options.findIndex((option, i) => i > currentIndex && !option.disabled);
};

export const getPrevActiveOption = (options: ISelectOption[], activeOption: ISelectOption): ISelectOption | null => {
  const activeIndex = getCurrentActiveIndex(options, activeOption);

  if (activeIndex > 0) {
    const prevIndex = getPrevActiveIndex(options, activeIndex);

    if (typeof prevIndex === "number") {
      return options[prevIndex];
    }
  }

  return null;
};

export const getNextActiveOption = (options: ISelectOption[], activeOption: ISelectOption): ISelectOption | null => {
  const activeIndex = getCurrentActiveIndex(options, activeOption);

  if (activeIndex < options.length - 1) {
    const nextIndex = getNextActiveIndex(options, activeIndex);

    if (typeof nextIndex === "number") {
      return options[nextIndex];
    }
  }

  return null;
};

export const isSelected = (options: ISelectOption[], option: ISelectOption) => {
  return Boolean(options.find((_option) => _option.value === option.value));
};

export const findFirstAvailableOption = (options: ISelectOption[]) => {
  return options.find((option) => !option.disabled);
};
