import React from "react";
import classNames from "classnames";

interface BaseBannerProps {
  message: string;
}

export const BaseBanner: React.FC<BaseBannerProps> = ({ message }) => (
  <div
    className={classNames(
      "tw-z-[1] tw-flex tw-min-h-[52px] tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-bg-neutral-900 tw-px-1.5 tw-py-2"
    )}
  >
    <p className="tw-mb-0 tw-px-2 tw-text-center tw-text-sm tw-text-white">{message}</p>
  </div>
);
