import { formatDuration, intervalToDuration } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

export const START_DATE = zonedTimeToUtc("2020-09-08T08:00:00.000", "Europe/London");
export const END_DATE = zonedTimeToUtc("2020-10-01T08:00:00.000", "Europe/London");

type DurationKey = "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds";
export const getDurationKey = (duration: Duration, key: DurationKey): number => {
  const value = duration[key];

  if (!value) {
    return 0;
  }

  return value;
};

export const durationLessThan1Min = (duration: Duration): boolean => {
  const keys: DurationKey[] = ["years", "months", "weeks", "days", "hours", "minutes"];

  return keys.some((k) => getDurationKey(duration, k) > 0);
};

export const getDateBySeconds = (seconds: number): Date => {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(seconds);
  return t;
};

export const getDaysLeft = (start: Date): number => {
  if (!start) {
    return 0;
  }

  const duration = intervalToDuration({
    start: new Date(start),
    end: new Date()
  });

  return getDurationKey(duration, "days");
};

export const formatTimeString = (isoTimeString: string, timezone = "UTC"): string =>
  formatInTimeZone(new Date(isoTimeString), timezone, "yyyy-MM-dd HH:mm zzz", {
    locale: enGB
  });

export const getAgoDate = (date: string): string => {
  const duration = intervalToDuration({
    start: new Date(date),
    end: new Date()
  });

  if (getDurationKey(duration, "years") > 0) {
    const formatted = formatDuration(duration, { format: ["years"] });
    return `${formatted} ago`;
  }

  if (getDurationKey(duration, "months") > 0) {
    const formatted = formatDuration(duration, { format: ["months"] });
    return `${formatted} ago`;
  }

  if (getDurationKey(duration, "days") > 0) {
    const formatted = formatDuration(duration, { format: ["days"] });
    return `${formatted} ago`;
  }

  if (getDurationKey(duration, "hours") > 0) {
    const formatted = formatDuration(duration, { format: ["hours"] });
    return `${formatted} ago`;
  }

  if (getDurationKey(duration, "minutes") > 0) {
    const formatted = formatDuration(duration, { format: ["minutes"] });
    return `${formatted} ago`;
  }

  if (getDurationKey(duration, "seconds") > 0) {
    const formatted = formatDuration(duration, { format: ["seconds"] });
    return `${formatted} ago`;
  }

  return "just now";
};
