import React from "react";
import { format } from "date-fns";

import { getOrder } from "@frontend/api/orders.service";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import { MEDIA_PATH, ORDERS_PATH } from "@frontend/routes";

import { Avatar } from "@components/avatar/avatar";
import { Button } from "@components/button";
import { NewBadge } from "@components/new-badge/new-badge";
import { NewModal } from "@components/new-modal";

import { useOrders } from "@core/hooks/use-orders";
import { Media } from "@core/interfaces/media";
import { User } from "@core/interfaces/user";
import { languageLookup, Order, OrderStatus, transcriptionLanguages, translationLanguages } from "@getsubly/common";

interface OrderModalProps {
  onClose: () => void;
  onCancelled?: () => void;
  orderId: string;
  showCancelButton?: boolean;
  showMediaOrdersButton?: boolean;
  showOpenMediaButton?: boolean;
}
export const OrderModal: React.FC<OrderModalProps> = ({
  onClose,
  onCancelled,
  orderId,
  showCancelButton,
  showMediaOrdersButton,
  showOpenMediaButton
}) => {
  const { cancelOrderRequest } = useOrders();
  const [isCancelling, setCancelling] = React.useState<boolean>();
  const [order, setOrder] = React.useState<Order>();

  const loadOrder = (orderId: string) => {
    const { response } = getOrder(orderId);
    return response.then(setOrder);
  };

  React.useEffect(() => {
    loadOrder(orderId);
  }, []);

  const handleCancel = async () => {
    if (!order) return;
    setCancelling(true);
    await cancelOrderRequest(order.id);
    await loadOrder(orderId);
    await onCancelled?.();
    setCancelling(false);
  };

  return (
    <NewModal onDismiss={onClose} title="Order Detail" showCloseButton={true} size="608">
      {order ? (
        <OrderModalInner
          onClose={onClose}
          onCancel={handleCancel}
          order={order}
          showCancelButton={showCancelButton}
          showMediaOrdersButton={showMediaOrdersButton}
          showOpenMediaButton={showOpenMediaButton}
          isCancelling={isCancelling}
        />
      ) : (
        <div className="tw-flex tw-justify-center tw-py-20">
          <div className="tw-h-12 tw-w-12 tw-animate-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-neutral-500 tw-border-t-transparent"></div>
        </div>
      )}
    </NewModal>
  );
};

interface OrderModalInnerProps {
  onClose: () => void;
  onCancel?: () => void;
  order: Order<User, Media>;
  showCancelButton?: boolean;
  showMediaOrdersButton?: boolean;
  showOpenMediaButton?: boolean;
  isCancelling?: boolean;
}
export const OrderModalInner: React.FC<OrderModalInnerProps> = ({
  onClose,
  onCancel,
  order,
  showCancelButton,
  showMediaOrdersButton,
  showOpenMediaButton,
  isCancelling
}) => {
  const humanUpdatedAt = React.useMemo(() => {
    return format(new Date(order.updatedAt), "HH:mm d MMMM yyyy");
  }, [order.updatedAt]);
  const humanCreatedAt = React.useMemo(() => {
    return format(new Date(order.createdAt), "HH:mm d MMMM yyyy");
  }, [order.updatedAt]);

  const sourceLanguage = React.useMemo(() => {
    return order.media.languageCode ? languageLookup(transcriptionLanguages, order.media.languageCode)?.language : "";
  }, [order.media.languageCode]);

  const mediaId = order.mediaId;

  const lookupLanguages = [...transcriptionLanguages, ...translationLanguages];

  return (
    <>
      <hr />
      <div className="tw-my-6 tw-text-[20px] tw-font-medium tw-uppercase tw-text-neutral-900">#{order.reference}</div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">File name</div>
        <div className="tw-text-sm tw-text-neutral-700">{order.media.name}</div>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Created by</div>
        <div className="tw-flex tw-items-center tw-text-sm tw-text-neutral-900">
          <Avatar
            size="16"
            initials={getInitials(order.createdBy.name, true, false)}
            className="tw-mr-2.5"
            withBorder={false}
            color="#ededed"
          />
          {order.createdBy.name}
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Date created</div>
        <div className="tw-text-sm tw-text-neutral-700">{humanCreatedAt}</div>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Media language</div>
        <div className="tw-text-sm tw-text-neutral-700">{sourceLanguage}</div>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Status</div>
        <div className="tw-text-sm tw-text-neutral-700">
          <>
            {order.status === OrderStatus.Pending && (
              <NewBadge className="tw-min-w-[94px]" size="24" variant="primary-soft" label="Requested" />
            )}
            {order.status === OrderStatus.InProgress && (
              <NewBadge className="tw-min-w-[94px]" size="24" variant="warning-soft" label="In progress" />
            )}
            {order.status === OrderStatus.Completed && (
              <NewBadge className="tw-min-w-[94px]" size="24" variant="success-soft" label="Completed" />
            )}
            {order.status === OrderStatus.Cancelled && (
              <NewBadge className="tw-min-w-[94px]" size="24" variant="ghost" label="Cancelled" />
            )}
            {order.status === OrderStatus.Rejected && (
              <NewBadge className="tw-min-w-[94px]" size="24" variant="destructive-soft" label="Rejected" />
            )}
          </>
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Status date</div>
        <div className="tw-text-sm tw-text-neutral-700">{humanUpdatedAt}</div>
      </div>
      <hr className="tw-my-6" />
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1.5 ">
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Services</div>
        <div className="tw-text-sm tw-font-medium tw-text-neutral-500">Target languages</div>
      </div>
      {order.details.services.map((service) => {
        const languages = (service.languages ?? [])
          .map((languageCode) => (languageCode ? languageLookup(lookupLanguages, languageCode) : null))
          .map((language) => language?.language);

        return (
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1 " key={service.type}>
            <div className="tw-text-sm tw-font-medium tw-text-neutral-900">{service.name}</div>
            <div className="tw-text-sm tw-text-neutral-900">{languages.join(", ")}</div>
          </div>
        );
      })}
      {(showCancelButton || showMediaOrdersButton) && (
        <div className="tw-mt-8 tw-flex tw-justify-between">
          {order.status === OrderStatus.Pending && showCancelButton && (
            <Button
              size="32"
              variant="destructive-outline"
              onClick={onCancel}
              loading={isCancelling}
              disabled={isCancelling}
            >
              {isCancelling ? "Cancelling order" : "Cancel order"}
            </Button>
          )}
          {showMediaOrdersButton && (
            <Button
              size="32"
              variant="secondary"
              to={ORDERS_PATH + "?mediaId=" + order.mediaId}
              type="link"
              onClick={onClose}
              className="tw-ml-auto"
            >
              All orders
            </Button>
          )}
          {showOpenMediaButton && mediaId && (
            <Button
              size="32"
              variant="secondary"
              to={`${MEDIA_PATH}/${mediaId}`}
              type="link"
              onClick={onClose}
              className="tw-ml-auto"
            >
              Open media
            </Button>
          )}
        </div>
      )}
    </>
  );
};
