import React, { useState } from "react";

import { moveFolder } from "@frontend/api/folders.service";
import { notificationSuccess } from "@frontend/components/notification";
import { ConfirmMoveToPrivateModal } from "@frontend/containers/modals/confirm-move-to-private-modal";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { MagnifyingGlassIcon } from "@components/icons";
import { NewModal } from "@components/new-modal";

import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { ModalType } from "@core/interfaces/modal-type";
import { Folder } from "@core/state/dashboard/dashboard.store";

import { FolderTree } from "../dashboard/dashboard-sidebar/drives/folder-tree";

interface MoveFolderModalProps {
  folder: Folder | Folder;
  closeModal: () => void;
  onBeforeSave: () => void;
  onCompleteSave: () => void;
  isEditor?: boolean;
}
export const MoveFolderModal: React.FC<MoveFolderModalProps> = ({
  folder,
  closeModal,
  onBeforeSave,
  onCompleteSave,
  isEditor = false
}) => {
  return (
    <NewModal onDismiss={closeModal} disableBackgroundDismiss>
      <MoveMediaModalContainer
        folder={folder}
        closeModal={closeModal}
        onBeforeSave={onBeforeSave}
        onCompleteSave={onCompleteSave}
        isEditor={isEditor}
      />
    </NewModal>
  );
};

const MoveMediaModalContainer: React.FC<MoveFolderModalProps> = ({
  folder,
  closeModal,
  onBeforeSave,
  onCompleteSave
}) => {
  const { activeFolder, privateDrive, teamspacesDrive, folders } = useDashboard();
  const [selectedFolder, setSelectedFolder] = useState(activeFolder);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const searchedFolder = React.useMemo(() => {
    return folders
      .filter((folder) => {
        if (!searchQuery) return false;
        return folder.name.toLowerCase().includes(searchQuery.toLowerCase());
      })
      .map((folder) => ({ ...folder, children: [] }));
  }, [searchQuery]);

  if (!teamspacesDrive || !privateDrive) return null;

  const confirmMoveToPrivate = async () => {
    return await new Promise((resolve) => {
      const [showConfirmMoveToPrivateModal, hideConfirmMoveToPrivateModal] = useModal(ModalType.RenameFolder);
      showConfirmMoveToPrivateModal(
        <ConfirmMoveToPrivateModal
          type="folder"
          onConfirm={() => {
            resolve(true);
            hideConfirmMoveToPrivateModal();
          }}
          onCancel={() => {
            resolve(false);
            hideConfirmMoveToPrivateModal();
          }}
        />
      );
    });
  };

  const handleMove = async () => {
    if (!selectedFolder) return;
    try {
      setLoading(true);

      const isMovingFromPublicToPrivate = folder.isPublic && !selectedFolder.isPublic;

      if (isMovingFromPublicToPrivate) {
        if ((await confirmMoveToPrivate()) === false) {
          setLoading(false);
          return;
        }
      }

      await onBeforeSave();
      await moveFolder(folder.id, selectedFolder.id);
      notificationSuccess(`Moved to "${selectedFolder?.name}"`);
      await onCompleteSave();
      closeModal();

      setLoading(false);
    } catch (error) {
      // Handle or log the error as needed
      console.error(error);
      setLoading(false);
      await onCompleteSave();
      closeModal();
    }
  };

  const preparedFolders = Boolean(searchQuery) ? searchedFolder : [teamspacesDrive, privateDrive];

  return (
    <div className="tw-flex tw-flex-col">
      <Input
        placeholder="Search folders"
        leftIcon={<MagnifyingGlassIcon className="tw-w-5" />}
        value={searchQuery}
        onChange={({ target }) => setSearchQuery(target.value)}
        wrapperClassName="tw-mb-4"
      />
      {Boolean(preparedFolders.length) && (
        <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-0.25 tw-overflow-hidden">
          {preparedFolders.map((folder, i) => (
            <FolderTree
              folder={folder}
              selectedFolderId={selectedFolder?.id || null}
              onSelectFolder={setSelectedFolder}
              noIndent={searchedFolder.length > 0}
              showOptions={false}
              defaultExpanded
              key={i}
            />
          ))}
        </div>
      )}
      {!Boolean(preparedFolders.length) && (
        <div className="tw-mb-6 tw-mt-4 tw-overflow-hidden tw-rounded-md tw-text-sm tw-text-neutral-700">
          <span className="tw-italic">No folders found</span>
        </div>
      )}

      <div className="tw-flex tw-justify-end tw-gap-2">
        <Button variant="ghost" onClick={closeModal} size="36">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleMove} disabled={!selectedFolder} loading={isLoading} size="36">
          Move
        </Button>
      </div>
    </div>
  );
};
