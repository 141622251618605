export interface IGlossary {
  id: string;
  sourceLanguage: string;
  targetLanguage: string;
  sourceWords: string;
  targetWords: string;
  createdAt: Date;
}

export enum GlossaryLanguageType {
  DE = "German",
  EN = "English",
  ES = "Spanish",
  FR = "Franch",
  JA = "Japanese",
  IT = "Italian",
  PL = "Polish",
  NL = "Dutch",
  ZH = "Chineese (simplified)",
  RU = "Russian",
  PT = "Portuguese"
}
