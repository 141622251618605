import React from "react";
import classNames from "classnames";

import styles from "./auth.module.scss";

export const EmbeddedWebviewMsg: React.FC = () => (
  <div className={classNames(styles.webView, "tw-p-1", "tw-mb-4")}>
    <p className="tw-mb-0 tw-px-2 tw-text-center tw-text-sm tw-text-white">
      For the best experience with Subly please open outside of this app in your preferred browser.
    </p>
  </div>
);
