import React, { useState } from "react";

import {
  AlignedPlacement,
  autoUpdate,
  flip,
  offset,
  ReferenceType,
  shift,
  Strategy,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";

interface DropdownContext {
  isOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canCloseOnClickOutside: boolean;
  setCanCloseOnClickOutside: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownPositionStyles: {
    left: number | undefined;
    top: number | undefined;
    position: Strategy;
  };
  buttonRef: (node: ReferenceType | null) => void;
  getButtonRefProps: (userProps?: React.HTMLProps<Element>) => Record<string, unknown>;
  dropdownRef: (node: HTMLElement | null) => void;
  getDropdownRefProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
  setPlacement: React.Dispatch<React.SetStateAction<AlignedPlacement>>;
  setMainAxis: React.Dispatch<React.SetStateAction<number>>;
  setCrossAxis: React.Dispatch<React.SetStateAction<number>>;
}

export const DropdownContext = React.createContext<DropdownContext>({
  isOpen: false,
  toggleOpen: () => null,
  canCloseOnClickOutside: false,
  setCanCloseOnClickOutside: () => null,
  setPlacement: () => null,
  setMainAxis: () => null,
  setCrossAxis: () => null,
  dropdownPositionStyles: { left: 0, top: 0, position: "absolute" },
  buttonRef: () => null,
  getButtonRefProps: () => ({}),
  dropdownRef: () => null,
  getDropdownRefProps: () => ({})
});

interface DropdownProviderProps {
  children?: React.ReactNode;
}

export const DropdownProvider: React.FC<DropdownProviderProps> = ({ children }) => {
  const [isOpen, toggleOpen] = useState(false);
  const [canCloseOnClickOutside, setCanCloseOnClickOutside] = useState(true);
  const [placement, setPlacement] = useState<AlignedPlacement>("top-start");
  const [mainAxis, setMainAxis] = useState<number>(8);
  const [crossAxis, setCrossAxis] = useState<number>(0);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: toggleOpen,
    placement: placement,
    middleware: [offset({ mainAxis, crossAxis }), flip(), shift()],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)]);

  return (
    <DropdownContext.Provider
      value={{
        isOpen,
        toggleOpen,
        canCloseOnClickOutside,
        setCanCloseOnClickOutside,
        dropdownPositionStyles: {
          left: x ?? undefined,
          top: y ?? undefined,
          position: strategy
        },
        buttonRef: refs.setReference,
        getButtonRefProps: getReferenceProps,
        dropdownRef: refs.setFloating,
        getDropdownRefProps: getFloatingProps,
        setPlacement,
        setMainAxis,
        setCrossAxis
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = (): DropdownContext => {
  const context = React.useContext(DropdownContext);

  if (context === null) {
    throw new Error("useDropdown must be used within a DropdownProvider");
  }

  return context;
};
