import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  deleteUser,
  editUserProfile,
  EditUserProfileParams,
  editUserProfilePicture,
  removeUserProfilePicture
} from "@frontend/api/account.service";
import {
  forgotPassword,
  ForgotPasswordParams,
  getAccessToken,
  getUser,
  loginWithMagicLinkToken,
  loginWithOAuth2,
  loginWithPassword,
  requestMagicLink,
  resetPassword,
  ResetPasswordParams,
  SignInResponse,
  signUpWithMagicLink,
  SignUpWithMagicLinkParams,
  triggerEmailVerification,
  verifyEmailCode,
  verifyUser,
  VerifyUserParams
} from "@frontend/api/auth.service";
import config from "@frontend/config";
import { getUserSettings } from "@frontend/config/settings/settings.service";
import { useAnalytics } from "@frontend/contexts/analytics.context";
import { DASHBOARD_PATH } from "@frontend/routes";

import { SublyPlan, Subscription } from "@core/interfaces/billing";
import { UnknownObject } from "@core/interfaces/types";
import { User } from "@core/interfaces/user";
import { accountQuery, accountStore } from "@core/state/account";
import { assetsStore } from "@core/state/assets/assets.store";
import { authQuery } from "@core/state/auth/auth.query";
import { authStore } from "@core/state/auth/auth.store";
import { commentsStore } from "@core/state/comments";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { notificationsStore } from "@core/state/notifications/notifications.store";
import { getStripeId } from "@core/utils/accounts";
import { getSSOHref } from "@core/utils/links";
import { resetStores } from "@datorama/akita";
import { useObservable } from "@mindspace-io/react";
import * as Sentry from "@sentry/react";

import { useFingerprint } from "./fingerprint.context";

export interface Params {
  state?: string;
  redirect?: string;
  invite?: string;
}

interface QueryResult {
  success: boolean;
  loading: boolean;
  useAlert: boolean;
  alertMessage: string;
  status: number;
  data?: UnknownObject;
  user?: User;
}

interface AuthContext {
  loginWithPassword: (params: { email: string; password: string; restore?: boolean }) => Promise<QueryResult>;
  loginWithMagicLink: (params: { email: string; token: string }) => Promise<QueryResult>;
  signUpWithMagicLink: (params: SignUpWithMagicLinkParams) => Promise<QueryResult>;
  requestMagicLink: (params: {
    email: string;
    restore?: boolean;
    redirect?: string;
    sso?: boolean;
  }) => Promise<QueryResult>;
  getUser: () => Promise<void>;
  loginWithOAuth2: (params: { code: string; redirectUrl?: string }) => Promise<QueryResult>;
  signOut: (redirectUrl?: string) => void;
  updateProfile: (values: EditUserProfileParams) => Promise<void>;
  uploadProfilePicture: (file: File) => Promise<QueryResult>;
  resetPassword: (values: ResetPasswordParams) => Promise<QueryResult>;
  forgotPassword: (values: ForgotPasswordParams) => Promise<QueryResult>;
  verifyUser: (values: VerifyUserParams) => Promise<QueryResult>;
  clearPhoneVerification: (userId: string) => Promise<QueryResult>;
  verifyEmail: (code: string) => Promise<QueryResult>;
  deleteUser: () => Promise<QueryResult>;
  saveAuthInfo: (data: SignInResponse) => User;
  isAuthenticated?: boolean;
  user?: User;
  setUser: (user?: User) => void;
  userPicture: string;
  isGoogleAuth: boolean;
  plan: SublyPlan;
  isProOrHigher: boolean;
  isPro: boolean;
  subscription?: Subscription;
  showUpgradeWelcome: boolean;
  setShowUpgradeWelcome: (show: boolean) => void;
  hasUserClosedOverduePaymentPopUp?: boolean;
  setHasUserClosedOverduePaymentPopUp: (hasClosed: boolean) => void;
  hasShownOverduePaymentPopUp?: boolean;
  setHasShownOverduePaymentPopUp: (hasClosed: boolean) => void;
}

const AuthContext = React.createContext<AuthContext | null>(null);

const authURL = `${config.apiUrl}/api/v1/auth`;

interface AuthProviderProps {
  children: React.ReactNode;
}
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(authQuery.user);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const [isProOrHigher] = useObservable(accountQuery.selectIsProOrHigher(), accountQuery.isProOrHigher);
  const [isPro] = useObservable(accountQuery.selectIsPro(), accountQuery.isPro);
  const [plan] = useObservable(accountQuery.selectCurrentPlan(), accountQuery.currentPlan);

  const [showUpgradeWelcome, setShowUpgradeWelcome] = useState(false);
  const [subscription] = useObservable(accountQuery.select("subscription"));
  const { analyticsIdentify } = useAnalytics();
  const { getFingerprint } = useFingerprint();

  const navigate = useNavigate();
  const { hsSignUpIdentify } = useAnalytics();

  const saveUserProfile = (updatedUser: User) => {
    const newUserDetails = {
      ...user,
      ...updatedUser,
      accounts: user?.accounts || []
    };
    setUser(newUserDetails);
    authStore.setUser(newUserDetails);
    accountStore.updateTeamMember({ email: updatedUser.email, picturePublic: updatedUser.picturePublic });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCatchError = (error: any): QueryResult => {
    const message = error?.response?.data?.message;

    return {
      success: false,
      loading: false,
      useAlert: true,
      alertMessage: message || error.message,
      status: error?.response?.status
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = (data: any, status: number): QueryResult => {
    if (status !== 200) {
      throw new Error(data.message);
    }

    return {
      success: true,
      loading: false,
      useAlert: false,
      alertMessage: "",
      status: 200,
      data
    };
  };

  const handleSaveAuthInfo = ({ data }: SignInResponse): User => {
    try {
      const userAuth = data.auth;
      const userDetails = data.user;

      setUser(userDetails);

      const { workspaceId } = getUserSettings();
      const hasWorkspaceId = userDetails.accountIds.some((id) => id === workspaceId);
      const accountId = hasWorkspaceId ? (workspaceId as string) : userDetails.accountId;

      authStore.setAccountId(accountId);

      const userAccount = userDetails.accounts?.find((a) => a.accountId === accountId) ?? userDetails.accounts[0];

      if (userAccount) {
        accountStore.update(userAccount);
      }

      authStore.setAccessToken(userAuth.accessToken);
      authStore.setUser(userDetails);

      //this needs to be last, or SecureRoute will render without the info it needs (eg accountId)
      setIsAuthenticated(true);

      return userDetails;
    } catch (err) {
      setIsAuthenticated(false);
      throw err;
    }
  };

  const handleSetUser = (user?: User) => {
    setUser(user);
    authStore.setUser(user);
  };

  const handleLoginWithPassword = async (params: {
    email: string;
    password: string;
    restore?: boolean;
  }): Promise<QueryResult> => {
    try {
      const { data, status } = await loginWithPassword(params);

      const { id, email } = handleSaveAuthInfo(data);
      const stripeId = getStripeId(data?.data?.user);

      analyticsIdentify({ userId: id, stripeId, email });

      return handleSuccess({}, status);
    } catch (e) {
      const responseData = e?.response?.data;
      if (responseData?.action === "redirect" && responseData?.provider) {
        const href = getSSOHref(responseData.provider);
        setTimeout(() => {
          window.location.href = href;
        }, 2000);
      }
      return handleCatchError(e);
    }
  };

  const handleLoginWithMagicLink = async (params: { email: string; token: string }): Promise<QueryResult> => {
    try {
      const { data, status } = await loginWithMagicLinkToken(params);

      const { id, email } = handleSaveAuthInfo(data);
      const stripeId = getStripeId(data?.data?.user);

      analyticsIdentify({ userId: id, stripeId, email });

      return handleSuccess({}, status);
    } catch (e) {
      const responseData = e?.response?.data;
      if (responseData?.action === "redirect" && responseData?.provider) {
        const href = getSSOHref(responseData.provider);
        setTimeout(() => {
          window.location.href = href;
        }, 2000);
      }
      return handleCatchError(e);
    }
  };

  const handleSignUpWithMagicLink = async (params: SignUpWithMagicLinkParams): Promise<QueryResult> => {
    try {
      try {
        hsSignUpIdentify(params.email);
      } catch (err) {}
      const res = await signUpWithMagicLink(params);
      const { data, status } = res;
      return handleSuccess(data, status);
    } catch (e) {
      if (e?.response?.status === 500) {
        Sentry.captureException(e);
      }
      return handleCatchError(e);
    }
  };

  const handleRequestMagicLink = async (params: {
    email: string;
    restore?: boolean;
    redirect?: string;
    sso?: boolean;
  }): Promise<QueryResult> => {
    try {
      const res = await requestMagicLink(params);
      const { data, status } = res;
      return handleSuccess(data, status);
    } catch (e) {
      const responseData = e?.response?.data;
      if (responseData?.action === "redirect" && responseData?.provider) {
        const href = getSSOHref(e.response.data.provider, {});
        setTimeout(() => {
          window.location.href = href;
        }, 2000);
      }

      if (e?.response?.status === 500) {
        Sentry.captureException(e);
      }
      return handleCatchError(e);
    }
  };

  const handleClearPhoneVerification = async (userId: string): Promise<QueryResult> => {
    try {
      const { status } = await triggerEmailVerification(userId);

      return handleSuccess({}, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleGetUser = async (): Promise<void> => {
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        authStore.reset();
        navigate(DASHBOARD_PATH);
      }

      if (!authQuery.user) {
        await getUser();
      }

      // We use the same data schema as that used by signIn
      // effectively we're doing the work of signIn (ie getting and saving accessToken / user) on every browser refresh
      const authData: SignInResponse = {
        data: {
          auth: { accessToken: accessToken || "" },
          user: authQuery.user as User
        }
      };
      //sign out user if user is deleted
      if (authQuery.user?.settings?.deleted) {
        await handleSignOut();
        return;
      }
      const { id, email } = handleSaveAuthInfo(authData);

      const stripeId = getStripeId(authData.data.user);
      analyticsIdentify({ userId: id, stripeId, email });

      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
      Sentry.captureException(e);
    }
  };

  const handleLoginWithOAuth2 = async ({ code, redirectUrl }: { code: string; redirectUrl?: string }) => {
    try {
      const fingerprint = await getFingerprint();

      const { data, status } = await loginWithOAuth2({
        code,
        redirect_uri: redirectUrl || config.oAuth2.google,
        fingerprint
      });

      const { id, isNewUser, email } = handleSaveAuthInfo(data);
      const stripeId = getStripeId(data.data.user);
      analyticsIdentify({ userId: id, stripeId, email });

      return handleSuccess({ isNewUser: Boolean(isNewUser) }, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleSignOut = async (redirectUrl?: string): Promise<void> => {
    try {
      // We sign out on the server to remove the refresh token
      // It's a httpOnly cookie, so we can't do it in javascript
      try {
        await axios.get("/signout", {
          baseURL: authURL,
          headers: { "x-access-token": await getAccessToken() },
          withCredentials: true
        });
      } catch (err) {
        // do nothing
      }

      authStore.reset();
      setUser(undefined);
      setIsAuthenticated(false);

      // TODO: There is a bug in Akita and it doesn't reset well Entity Stores
      // Issue raised to Akita: https://github.com/datorama/akita/issues/634
      resetStores({
        exclude: ["media", "assets", "folders", "comments", "notifications"]
      });

      // TODO: This will clean the media and assets on store
      editorStateRepository.resetState();
      assetsStore.set([]);
      commentsStore.set([]);
      notificationsStore.set([]);

      navigate(redirectUrl ? redirectUrl : DASHBOARD_PATH);
      location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateProfile = async (values: EditUserProfileParams): Promise<void> => {
    try {
      if (!user?.email) {
        throw new Error("User not found");
      }

      const { data } = await editUserProfile({
        ...values,
        email: user?.email,
        name: `${values.givenName} ${values.familyName}`
      });

      saveUserProfile(data.data.user);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleUploadProfilePicture = async (file: File): Promise<QueryResult> => {
    try {
      await removeUserProfilePicture();
    } catch (error) {
      // Ignore error of removing picture
      console.error("There was an error deleting profile picture");
    }

    try {
      const { data, status } = await editUserProfilePicture(file);

      const result = handleSuccess(data, status);

      saveUserProfile(data.data.user);

      return result;
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleResetPassword = async (params: ResetPasswordParams) => {
    try {
      const { data, status } = await resetPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleForgotPassword = async (params: ForgotPasswordParams) => {
    try {
      const { data, status } = await forgotPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      const responseData = e?.response?.data;
      if (responseData?.action === "redirect" && responseData?.provider) {
        const href = getSSOHref(responseData.provider);
        setTimeout(() => {
          window.location.href = href;
        }, 2000);
      }

      return handleCatchError(e);
    }
  };

  const handleVerifyUser = async (params: { code: string; id: string }): Promise<QueryResult> => {
    try {
      const { data, status } = await verifyUser(params);

      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleVerifyEmail = async (code: string): Promise<QueryResult> => {
    try {
      const { data, status } = await verifyEmailCode(code);

      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleDeleteUser = async (): Promise<QueryResult> => {
    try {
      if (!user?.id) {
        throw new Error("User not found");
      }
      const { data, status } = await deleteUser();
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const userPicture = user?.picturePublic ?? user?.picture ?? "";

  const isGoogleAuth = (user?.id ?? "").slice(0, 6) === "Google";

  const [hasShownOverduePaymentPopUp, setHasShownOverduePaymentPopUp] = useState(false);
  const [hasUserClosedOverduePaymentPopUp, setHasUserClosedOverduePaymentPopUp] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        loginWithPassword: handleLoginWithPassword,
        loginWithMagicLink: handleLoginWithMagicLink,
        loginWithOAuth2: handleLoginWithOAuth2,
        signUpWithMagicLink: handleSignUpWithMagicLink,
        requestMagicLink: handleRequestMagicLink,
        getUser: handleGetUser,
        updateProfile: handleUpdateProfile,
        uploadProfilePicture: handleUploadProfilePicture,
        signOut: handleSignOut,
        resetPassword: handleResetPassword,
        forgotPassword: handleForgotPassword,
        verifyUser: handleVerifyUser,
        verifyEmail: handleVerifyEmail,
        deleteUser: handleDeleteUser,
        saveAuthInfo: handleSaveAuthInfo,
        isAuthenticated,
        user,
        setUser: handleSetUser,
        userPicture,
        isGoogleAuth,
        plan,
        isProOrHigher,
        isPro,
        subscription,
        showUpgradeWelcome,
        setShowUpgradeWelcome,
        clearPhoneVerification: handleClearPhoneVerification,
        hasShownOverduePaymentPopUp,
        setHasShownOverduePaymentPopUp,
        hasUserClosedOverduePaymentPopUp,
        setHasUserClosedOverduePaymentPopUp
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };

export const useAuthProvider = (): AuthContext => {
  const context = React.useContext(AuthContext);

  if (context === null) {
    throw new Error("useAuthProvider must be used within a AuthProvider");
  }

  return context;
};
