import { UserGroup } from "@core/interfaces/user-group";
import { createStore, withProps } from "@ngneat/elf";
import { getAllEntities, withEntities } from "@ngneat/elf-entities";

interface GroupsState {
  loading: boolean;
}

export const userGroupsStore = createStore(
  { name: "userGroups" },
  withEntities<UserGroup>(),
  withProps<GroupsState>({ loading: false })
);

class UserGroupsRepository {
  get userGroups() {
    return userGroupsStore.query(getAllEntities());
  }

  updateState = (props: Partial<GroupsState>) => {
    userGroupsStore.update((s) => ({ ...s, ...props }));
  };

  resetState = () => {
    userGroupsStore.reset();
  };
}

export const userGroupsRepository = new UserGroupsRepository();
