import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Alert } from "@frontend/components/alert/alert";

import { EyeIcon } from "@components/icons";

import styles from "./upgrade-button.module.scss";

interface UpgradeButtonProps {
  className?: string;
  text?: string;
  path?: string;
  successMessage?: string;
  showSuccessMessage?: boolean;
  onClick?: () => void;
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  className,
  text = "Upgrade",
  path,
  successMessage,
  showSuccessMessage,
  onClick
}) => {
  return (
    <div className={classNames(className, styles.viewerContainer)}>
      <EyeIcon className={styles.eyeIcon} strokeWidth="2.2" />
      <span>Viewer</span>
      {!showSuccessMessage ? (
        <BadgeButton path={path} text={text} onClick={onClick} />
      ) : (
        <Alert success className="tw-mb-0 tw-rounded-24 tw-p-0">
          <span className="tw-flex tw-px-2 !tw-text-xs">{successMessage}</span>
        </Alert>
      )}
    </div>
  );
};

interface BadgeButtonProps {
  text?: string;
  path?: string;
  onClick?: () => void;
}
export const BadgeButton: React.FC<BadgeButtonProps> = ({ text, path, onClick }) => {
  return (
    <>
      {path ? (
        <Link className={styles.badge} to={path}>
          {text}
        </Link>
      ) : (
        <div className={styles.badge} onClick={onClick}>
          {text}
        </div>
      )}
    </>
  );
};
