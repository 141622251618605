import { QueryEntity } from "@datorama/akita";
import { Order } from "@getsubly/common";

import { OrdersState, OrdersStore, ordersStore } from "./orders.store";

export class OrdersQuery extends QueryEntity<OrdersState> {
  get isLoading(): boolean {
    return this.getValue().loading;
  }

  get orders(): Order[] {
    return this.getAll();
  }

  constructor(protected store: OrdersStore) {
    super(store);
  }

  updateState = (props: Partial<OrdersState>) => {
    ordersStore.update((state) => ({ ...state, ...props }));
  };
}

export const ordersQuery = new OrdersQuery(ordersStore);
