import React, { useState } from "react";

import { saveSurveyResult } from "@frontend/api/survey.service";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { Modal } from "@components/modal";

import { SurveyQuestion } from "@core/interfaces/survey";
import { Survey, SurveyType } from "@getsubly/common/dist/interfaces/survey";

interface SuggestGuidelineModalProps {
  onClose: () => void;
}

export const SuggestGuidelineModal: React.FC<SuggestGuidelineModalProps> = ({ onClose }) => {
  return (
    <Modal onDismiss={onClose} showCloseButton className="tw-flex tw-flex-col tw-gap-5">
      <SuggestGuidelineModalContainer onClose={onClose} />
    </Modal>
  );
};

const SuggestGuidelineModalContainer: React.FC<SuggestGuidelineModalProps> = ({ onClose }) => {
  const [suggestion, setSuggestion] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!suggestion) return;

    try {
      setLoading(true);

      const surveyResults: Survey = {
        surveyId: SurveyQuestion.GuidelineSuggestion,
        surveyType: SurveyType.Text,
        result: suggestion
      };

      await saveSurveyResult(surveyResults);
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <p className="tw-text-lg tw-font-medium">Suggest a guideline </p>

      <p className="tw-text-sm tw-font-normal">
        Please be as detailed as possible as to the type of subtitle guideline you would like to see inside Subly.
      </p>

      <Input
        type="text"
        placeholder="Guideline suggestion...."
        value={suggestion}
        onChange={({ target }) => {
          setSuggestion(target.value);
        }}
        autoFocus
      />

      <div className="tw-flex tw-items-center tw-justify-end tw-gap-3">
        <Button variant="secondary" disabled={loading} onClick={onClose} size="36">
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={loading || suggestion.length < 5}
          loading={loading}
          onClick={handleSubmit}
          size="36"
        >
          Submit feedback
        </Button>
      </div>
    </>
  );
};
