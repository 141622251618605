import { IGlossary } from "@core/interfaces/glossary";
import { Media } from "@core/interfaces/media";

export const sortMedia = (a: Media, b: Media): number => {
  if (!a || !a.createdAt || !b || !b.createdAt) {
    return 1;
  }

  return +new Date(b.createdAt) - +new Date(a.createdAt);
};

export const sortByLanguage = (unsortedGlossary: IGlossary[]) => {
  const sortedGlossary = unsortedGlossary.sort((a, b) => {
    if (a.sourceLanguage === b.sourceLanguage) {
      return a.targetLanguage.localeCompare(b.targetLanguage);
    }
    if (a.sourceLanguage === "EN") {
      return -1;
    }
    if (b.sourceLanguage === "EN") {
      return 1;
    }
    return a.sourceLanguage.localeCompare(b.sourceLanguage);
  });

  return sortedGlossary;
};
