import { Observable, of, switchMap } from "rxjs";

import { Query } from "@datorama/akita";

import { UploadFileType, UploadingStatus, UploadState, uploadStore } from "./upload.store";

const errorsWarningFirstSort = (a: UploadFileType, b: UploadFileType) => {
  if (a?.error) {
    return -1;
  }
  if (b?.error) {
    return 1;
  }
  if (a?.warning) {
    return -1;
  }
  if (b?.warning) {
    return 1;
  }
  return 0;
};

export class UploadQuery extends Query<UploadState> {
  get state(): UploadState {
    return this.getValue();
  }

  get isCheckingFile(): boolean {
    return this.isCheckingFile;
  }

  get uploadQueueLength(): number {
    return this.state.queue.length;
  }

  get isUploading(): boolean {
    return this.state.isUploading;
  }

  selectUploadQueueLength(): Observable<number> {
    return this.select((state) => state.queue.length);
  }

  selectIsUploading(): Observable<boolean> {
    return this.select((state) => state.isUploading);
  }

  selectIsChecking(): Observable<boolean> {
    return this.select((s) => s.isCheckingFile);
  }

  selectTotalCount(): Observable<number> {
    return this.select((s) => s.queue.length);
  }

  selectTotalDuration(): Observable<number> {
    return this.select((s) => s.queue.reduce((t, p) => t + (p.mediaInfo?.duration ?? 0), 0) ?? 0);
  }

  selectTotalSize(): Observable<number> {
    return this.select((s) => s.queue.reduce((t, p) => t + (p.mediaInfo?.fileSize ?? 0), 0) ?? 0);
  }

  selectSortedQueue(): Observable<UploadFileType[]> {
    return this.select((state) => state.queue.sort(errorsWarningFirstSort));
  }

  // staged files are files in states before submitting for upload
  selectStagedQueue(): Observable<UploadFileType[]> {
    const STAGED_STATES = [UploadingStatus.Analyzing, UploadingStatus.AnalysisError, UploadingStatus.Staged];
    return this.select((state) => {
      const stagedQueue = state.queue.filter((file) => STAGED_STATES.includes(file.uploadingStatus));
      return stagedQueue.sort(errorsWarningFirstSort);
    });
  }

  // upload files are files in states after pressing upload
  selectUploadQueue(): Observable<UploadFileType[]> {
    const UPLOAD_STATES = [
      UploadingStatus.Queued,
      UploadingStatus.Uploading,
      UploadingStatus.Uploaded,
      UploadingStatus.Complete
    ];
    return this.select((state) => {
      const uploadQueue = state.queue.filter((file) => UPLOAD_STATES.includes(file.uploadingStatus));
      return uploadQueue.sort(errorsWarningFirstSort);
    });
  }

  selectUploadingMediaId(): Observable<string | undefined> {
    return this.select().pipe(
      switchMap((state) => {
        const queue = state.queue;

        if (state.isUploading) {
          return of(queue[0]?.mediaId);
        }

        return of(undefined);
      })
    );
  }
}

export const uploadQuery = new UploadQuery(uploadStore);
