import { of, switchMap } from "rxjs";

import { useObservable } from "@mindspace-io/react";

import { accountQuery } from "./account.query";

export const useAccountSettingsAudioTemplatesState = () => {
  const [audioTemplates] = useObservable(
    accountQuery.select((state) => state?.settings?.audioTemplates),
    []
  );

  return audioTemplates ?? [];
};

export const useAccountSettingsGuidelineSettingsState = () => {
  const [guidelineSettings] = useObservable(accountQuery.select((state) => state?.settings?.guidelineSettings));

  return guidelineSettings;
};

export const useAccountSettingsShowTranslationTourState = () => {
  const [showTranslationTour] = useObservable(
    accountQuery
      .select((state) => state?.settings?.hasSeenTranslationTour)
      .pipe(
        switchMap((hasSeenTranslationTour) => {
          return of(!Boolean(hasSeenTranslationTour));
        })
      ),
    false
  );

  return showTranslationTour;
};

export const useAccountTrialDownloadsState = () => {
  const [trialDownloads] = useObservable(accountQuery.selectTrialDownloads(), 0);

  return trialDownloads;
};

export const useAccountSettingsAspectRatioColorsState = () => {
  const [aspectRatioColors] = useObservable(accountQuery.selectAspectRatioColors(), []);

  return aspectRatioColors ?? [];
};

export const useAccountSettingsBorderColorsState = () => {
  const [borderColors] = useObservable(accountQuery.selectBorderColors(), []);

  return borderColors ?? [];
};

export const useAccountSettingsPrimaryColorsState = () => {
  const [primaryColors] = useObservable(accountQuery.selectPrimaryColors(), []);

  return primaryColors ?? [];
};

export const useAccountSettingsOutlineColorsState = () => {
  const [outlineColors] = useObservable(accountQuery.selectOutlineColors(), []);

  return outlineColors ?? [];
};
