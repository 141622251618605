import React from "react";
import classNames from "classnames";

interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  large?: boolean;
  heading?: string;
  description?: string;
  bold?: boolean;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ children, className, ...props }, ref) => {
  const { heading, description, large, bold, disabled } = props;

  return (
    <label
      htmlFor={props.id}
      className={classNames(
        "tw-m-0 tw-flex tw-flex-row tw-gap-2",
        {
          "tw-items-center": !heading,
          "tw-cursor-pointer": !disabled
        },
        className
      )}
    >
      <input
        type="radio"
        className={classNames(
          "[&:not(:checked)]:tw-border-1 tw-appearance-none tw-rounded-full tw-border tw-border-neutral-200 checked:tw-border-primary-500",
          {
            "tw-h-5 tw-w-5 checked:tw-border-[7px]": !large,
            "tw-h-6 tw-w-6 checked:tw-border-[8px]": large,
            "tw-cursor-pointer": !disabled,
            "tw-bg-neutral-50": disabled,
            "tw-mt-1": heading
          }
        )}
        ref={ref}
        {...props}
      />
      {Boolean(children || heading || description) && (
        <div
          className={classNames("tw-text-md", {
            "tw-text-neutral-900": !disabled,
            "tw-text-neutral-400": disabled
          })}
        >
          {Boolean(children) && children}
          {heading && <p className="tw-font-medium ">{heading}</p>}
          {description && (
            <p
              className={classNames({
                "tw-font-normal tw-text-neutral-500": heading,
                "tw-font-medium": bold && !heading,
                "tw-text-neutral-400": disabled
              })}
            >
              {description}
            </p>
          )}
        </div>
      )}
    </label>
  );
});
