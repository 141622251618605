import React from "react";

import { ExclamationCircleIcon } from "@components/icons";

import { useQuery } from "@core/hooks/use-query";

interface Params {
  code: string;
}

export const OneDriveErrorPage: React.FC = () => {
  const { queryParams } = useQuery<Params>();

  const errors = React.useMemo(() => {
    if (!queryParams?.code) {
      return [
        "We’re sorry, but we couldn’t complete your request. Please try again, and if the issue persists, contact support for assistance."
      ];
    }

    const codes = [...new Set(queryParams.code.split(","))];

    if (!codes.length) {
      return [
        "We’re sorry, but we couldn’t complete your request. Please try again, and if the issue persists, contact support for assistance."
      ];
    }

    return codes.map((code) => {
      switch (code) {
        case "abort":
          return "The job was aborted";
        case "error":
          return "There was an error processing the job";
        default:
          return "There was an error processing the job";
      }
    });
  }, [queryParams?.code]);

  return (
    <div className="tw-flex tw-max-w-[500px] tw-flex-row tw-gap-3 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
      <div className="tw-flex tw-h-7 tw-items-center">
        <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-destructive-500" />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-1">
        <p className="tw-text-lg tw-font-medium">Action Could Not Be Completed</p>
        <div className="tw-flex tw-flex-col">
          {errors?.map((error, i) => (
            <p key={i} className="tw-text-neutral-500">
              {error}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
