import React from "react";

import { useAnalytics } from "@frontend/contexts/analytics.context";

import { Button } from "@components/button";
import { GoogleIcon } from "@components/icons";

import { getGoogleSignInHref } from "@core/utils/links";

interface GoogleButtonProps {
  action: "sign-in" | "sign-up";
  className?: string;
  disabled?: boolean;
}
export const GoogleButton: React.FC<GoogleButtonProps> = ({ action, className, disabled }) => {
  const { trackEvent } = useAnalytics();

  const href = getGoogleSignInHref();

  const onClick = () => {
    switch (action) {
      case "sign-up":
        trackEvent("Sign up Google");
        break;
      default:
        trackEvent("Sign in Google");
        break;
    }
  };

  return (
    <Button
      variant="secondary"
      type="link"
      to={href}
      className={className}
      icon={<GoogleIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {action === "sign-up" ? "Sign up with Google" : "Continue with Google"}
    </Button>
  );
};
