import { AspectRatio } from "@getsubly/common";

import {
  AllCombinedOverlay,
  FacebookReelOverlay,
  FacebookStoryOverlay,
  InstagramReelOverlay,
  InstagramStoryOverlay,
  TikTokPostOverlay,
  YouTubeShortsOverlay
} from "./overlay-icons";

export enum SocialMediaOverlayId {
  NoOverlay = "NoOverlay",
  AllCombined = "AllCombined",
  InstagramReel = "InstagramReel",
  InstagramStory = "InstagramStory",
  TikTokStory = "TikTokStory",
  TikTokPost = "TikTokPost",
  FacebookReel = "FacebookReel",
  FacebookStory = "FacebookStory",
  YouTubeShorts = "YouTubeShorts"
}

export type SocialMediaOverlay = {
  id: SocialMediaOverlayId;
  name: string;
  ratio: AspectRatio | null;
};

export type SocialMediaOverlaysMap = {
  [key in SocialMediaOverlayId]: SocialMediaOverlay;
};

export const socialMediaOverlaysMap: SocialMediaOverlaysMap = {
  [SocialMediaOverlayId.NoOverlay]: {
    id: SocialMediaOverlayId.NoOverlay,
    name: "No overlay",
    ratio: null
  },
  [SocialMediaOverlayId.AllCombined]: {
    id: SocialMediaOverlayId.AllCombined,
    name: "All Combined",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.InstagramReel]: {
    id: SocialMediaOverlayId.InstagramReel,
    name: "Instagram Reel",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.InstagramStory]: {
    id: SocialMediaOverlayId.InstagramStory,
    name: "Instagram Story",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.TikTokStory]: {
    id: SocialMediaOverlayId.TikTokStory,
    name: "Tiktok Story",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.TikTokPost]: {
    id: SocialMediaOverlayId.TikTokPost,
    name: "Tiktok Post",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.FacebookReel]: {
    id: SocialMediaOverlayId.FacebookReel,
    name: "Facebook Reel",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.FacebookStory]: {
    id: SocialMediaOverlayId.FacebookStory,
    name: "Facebook Story",
    ratio: AspectRatio.Portrait
  },
  [SocialMediaOverlayId.YouTubeShorts]: {
    id: SocialMediaOverlayId.YouTubeShorts,
    name: "Youtube Shorts",
    ratio: AspectRatio.Portrait
  }
};

// TODO: Do we need to scaled this?
export const Overlay: React.FC<{
  overlay?: SocialMediaOverlay;
  ratio: AspectRatio;
}> = ({ overlay, ratio }) => {
  if (!overlay) return null;

  if (overlay.ratio !== ratio) return null;

  switch (overlay.id) {
    case SocialMediaOverlayId.InstagramReel:
      return <InstagramReelOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.InstagramStory:
      return <InstagramStoryOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.TikTokPost:
      return <TikTokPostOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.FacebookReel:
      return <FacebookReelOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.FacebookStory:
      return <FacebookStoryOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.YouTubeShorts:
      return <YouTubeShortsOverlay className="tw-max-h-full tw-max-w-full" />;
    case SocialMediaOverlayId.AllCombined:
      return <AllCombinedOverlay className="tw-max-h-full tw-max-w-full" />;
    default:
      return null;
  }
};
