import React from "react";
import classNames from "classnames";

import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import { ProgressBar } from "@frontend/components/progress-bar/progress-bar";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { SETTINGS_BILLING_PATH, SETTINGS_PROFILE_PATH } from "@frontend/routes";

import { Avatar } from "@components/avatar/avatar";
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from "@components/dropdown";
import { NewBadge } from "@components/new-badge/new-badge";
import { TextButton } from "@components/text-button";

import { useAccounts } from "@core/hooks/use-accounts";
import { usePlan } from "@core/hooks/use-plan";
import { parseCreditToText } from "@core/utils/plans";
import { formatBytes } from "@core/utils/storage-size";
import { RoleName } from "@getsubly/common";

export const AccountMenu: React.FC = () => {
  const { user, currentAccount } = useAccounts();
  const { signOut } = useAuthProvider();
  const { isPayg } = usePlan();
  const [isOpen, setIsOpen] = React.useState(false);

  if (!user) {
    return null;
  }

  return (
    <Dropdown>
      <DropdownButton
        size="wrap"
        variant="ghost"
        className={classNames(
          "focus:!tw-bg-neutral:200 tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-3 tw-rounded-lg tw-border-t tw-border-t-neutral-200 tw-px-3 tw-py-2 hover:!tw-bg-neutral-200  focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:!tw-bg-neutral-300 active:!tw-outline-transparent",
          isOpen && "!tw-bg-neutral-200"
        )}
        showArrow={false}
      >
        <Avatar
          size="40"
          initials={getInitials(user.name, true, false)}
          image={user?.picturePublic}
          withBorder={false}
          color="#000"
          className="tw-shrink-0"
        />
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-gap-1 tw-overflow-hidden tw-text-left">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h3 className="tw-overflow-hidden tw-text-ellipsis tw-text-sm tw-font-semibold tw-leading-none tw-text-neutral-900">
              {user.name}
            </h3>
            {currentAccount?.role === RoleName.Viewer && (
              <NewBadge corner="rounded" variant="warning-soft" size="20" label="Viewer" className="tw-ml-1" />
            )}
          </div>
          <h4 className="tw-overflow-hidden tw-text-ellipsis tw-text-xs tw-leading-none tw-text-neutral-600">
            {user.email}
          </h4>
        </div>
      </DropdownButton>

      <DropdownMenu
        className="!tw-w-[320px]"
        placement="right-end"
        mainAxis={10}
        crossAxis={0}
        showInPortal
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        animate="up"
      >
        <DropdownItem className="tw-gap-3" to={SETTINGS_PROFILE_PATH}>
          <Avatar
            size="32"
            initials={getInitials(user.name, true, false)}
            image={user?.picturePublic}
            withBorder={false}
            color="#000"
          />
          <div>
            <h4 className="tw-text-sm tw-font-medium tw-text-neutral-700">{user.name}</h4>
          </div>
        </DropdownItem>
        <DropdownDivider />
        {!isPayg && (
          <>
            <BalanceMenuItem />
            <DropdownDivider />
          </>
        )}
        <DropdownItem to={SETTINGS_PROFILE_PATH} className="tw-font-medium">
          View profile
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem className="tw-font-medium" onClick={() => signOut()}>
          Sign out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export const BalanceMenuItem: React.FC = () => {
  const { user } = useAccounts();
  const { credit, isFree } = usePlan();

  if (!user) {
    return null;
  }

  const totalCredit = credit?.total ?? 0;

  const balanceText = isFree ? "0 mins" : parseCreditToText(totalCredit, false, false);

  return (
    <DropdownItem
      className="tw-flex !tw-cursor-auto tw-flex-col !tw-items-start hover:!tw-bg-transparent"
      keepOpenOnClick
    >
      <p className="tw-text-sm tw-text-neutral-600">
        <span className="tw-font-medium">Balance:</span> {balanceText}
      </p>
      <p className="tw-text-xs tw-text-neutral-600">Add minutes + editors to your plan.</p>
      <TextButton to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} type="link">
        Add more
      </TextButton>
    </DropdownItem>
  );
};

export const StorageMenuItem: React.FC = () => {
  const { user, billing, paymentMethods } = useAccounts();
  const { isPayg, availableStorage, totalStorage } = usePlan();

  if (!user) {
    return null;
  }

  const showUpgradeStorageButton = Boolean(billing && paymentMethods?.length && !isPayg);
  const availableStorageBytes = formatBytes(availableStorage ?? 0);
  const availableStorageText = `${availableStorageBytes.size} ${availableStorageBytes.units}`;

  const totalStorageBytes = formatBytes(totalStorage);
  const totalStorageText = `${totalStorageBytes.size} ${totalStorageBytes.units}`;

  const progress = (availableStorage / totalStorage) * 100;

  return (
    <DropdownItem
      className="tw-flex !tw-cursor-auto tw-flex-col !tw-items-start hover:!tw-bg-transparent"
      keepOpenOnClick
    >
      <p className="tw-mb-3 tw-text-sm tw-text-neutral-600">
        <span className="tw-font-medium">Storage available:</span> {availableStorageText} / {totalStorageText}
      </p>
      <ProgressBar progress={progress} className="tw-mb-3" theme="blue" />
      {showUpgradeStorageButton && (
        <TextButton to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} type="link">
          Increase storage
        </TextButton>
      )}
    </DropdownItem>
  );
};
