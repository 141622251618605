import { formatTimestamp, parseTimestamp } from "subtitle";

import { pluralize } from "./strings";

export const format = (time: number, useOverHourTimestamp?: boolean): string => {
  const srtTime = formatTimestamp(Math.floor(time), { format: "SRT" });
  const [hoursMinsSeconds, milliseconds] = srtTime.split(",");
  const millisecondsTo2DecimalPlaces = String(Math.round(parseInt(milliseconds) / 10)).padStart(2, "0");

  return useOverHourTimestamp
    ? `${hoursMinsSeconds}.${millisecondsTo2DecimalPlaces}`
    : `${hoursMinsSeconds.substring(3)}.${millisecondsTo2DecimalPlaces}`;
};

export const parse = (formattedTime: string, isAnHourLong: boolean): number => {
  const [hoursMinsSeconds, milliseconds] = formattedTime.split(".");
  const split = hoursMinsSeconds.split(":");

  if (isAnHourLong && split.length === 3) {
    const [hours, mins, secs] = split;
    return parseTimestamp(`${hours}:${mins}:${secs}.${milliseconds}0`);
  } else {
    const [mins, secs] = split;
    return parseTimestamp(`${mins}:${secs}.${milliseconds}0`);
  }
};

export const isValidFormat = (formattedTime: string, isAnHourLong: boolean): boolean => {
  const formattedTimeRegex = isAnHourLong ? /^[\d]{2}:[\d]{2}:[\d]{2}.[\d]{2}?$/ : /^[\d]{2}:[\d]{2}.[\d]{2}?$/;

  return formattedTimeRegex.test(formattedTime);
};

export const parseSecondsToText = (duration?: number, usePlurals = true, useAbbreviation = false): string => {
  if (duration === 0) {
    return useAbbreviation ? "< 1 sec" : "< 1 second";
  }

  if (!duration) {
    return "";
  }

  if (duration < 60) {
    const label = useAbbreviation ? "sec" : usePlurals ? pluralize(duration, "second") : "second";
    return `${duration} ${label}`;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  const minutesLabel = useAbbreviation ? "min" : usePlurals ? pluralize(minutes, "minute") : `minute`;

  if (!seconds) {
    return `${minutes} ${minutesLabel}`;
  }

  const secondsLabel = useAbbreviation ? "sec" : usePlurals ? pluralize(seconds, "second") : `second`;

  return `${minutes} ${minutesLabel} and ${seconds} ${secondsLabel}`;
};

export interface Time {
  hours: string;
  minutes: string;
  seconds: string;
  milliseconds: string;
}
export const getTimeObject = (timeInSeconds: number): Time => {
  const result = new Date(timeInSeconds * 1000).toISOString().slice(11, 23);

  return {
    hours: result.slice(0, 2),
    minutes: result.slice(3, 5),
    seconds: result.slice(6, 8),
    milliseconds: result.slice(9, 12)
  };
};

export const formatTime = (time: Time, showHours = false): string => {
  const { hours, minutes, seconds } = time;

  if (showHours) {
    return `${hours}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
};

export const durationToString = ({ hours, minutes, seconds }: Duration): string => {
  const hr = `${hours ? `${hours} ${pluralize(hours, "hr", "hrs")} ` : ``}`;
  const min = `${minutes ? `${minutes} ${pluralize(minutes, "min", "mins")} ` : ``}`;

  if (!hr) {
    const sec = `${seconds ? `${seconds} ${pluralize(seconds, "sec", "secs")} ` : ``}`;

    return `${min}${sec}`;
  }

  return `${hr}${min}`;
};

export const secToMs = (time?: number): number => (time ? Math.round(time * 1000) : 0);

export const msToSec = (time?: number): number => {
  const seconds = time ? time / 1000 : 0;

  // Bug fix: In the ASS transcription, times are rounded to 2 decimal places,
  // whereas here, they were rounded to 3. This caused the subtitle to not
  // always show when playing the start of the cue. Because for example..  a cue
  // with a start of 1.088 here was rounded to 1.09 in the ASS cue.

  return parseFloat(seconds.toFixed(2));
};
