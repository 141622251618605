import { useCallback } from "react";

import { getTimeObject } from "@media-player/utils/time";
import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";

import { mediaPlayerStore } from "./media-player.state";

export const useMediaPlayerHasHoursState = () => {
  const [hasHours] = useObservable(
    mediaPlayerStore.pipe(
      select((state) => {
        const time = getTimeObject(state.durationValue);
        return +time.hours > 0;
      })
    ),
    false
  );

  return hasHours;
};

export const useMediaPlayerPlayingState = () => {
  const [isPlaying] = useObservable(mediaPlayerStore.pipe(select((state) => state.isPlaying)), false);

  return isPlaying;
};

export const useMediaPlayerMutedState = () => {
  const [isMuted] = useObservable(mediaPlayerStore.pipe(select((state) => state.isMuted)), false);

  const setMuted = useCallback(
    (isMuted: boolean) => {
      mediaPlayerStore.update((state) => ({ ...state, isMuted }));
    },
    [isMuted]
  );

  return { isMuted, setMuted };
};

export const useMediaPlayerPlayAudioDescriptionState = () => {
  const [playAudioDescription] = useObservable(
    mediaPlayerStore.pipe(select((state) => state.playAudioDescription)),
    false
  );

  const setPlayAudioDescription = useCallback(
    (playAudioDescription: boolean) => {
      mediaPlayerStore.update((state) => ({ ...state, playAudioDescription }));
    },
    [playAudioDescription]
  );

  return { playAudioDescription, setPlayAudioDescription };
};

export const useMediaPlayerCurrentTimeState = () => {
  const [currentTime] = useObservable(mediaPlayerStore.pipe(select((state) => state.currentTime)), 0);

  return currentTime;
};

export const useMediaPlayerDurationState = () => {
  const [durationValue] = useObservable(mediaPlayerStore.pipe(select((state) => state.durationValue)), 0);

  return durationValue;
};

export const useMediaPlayerDurationMSState = () => {
  const durationValue = useMediaPlayerDurationState();
  return durationValue * 1000;
};

export const useMediaPlayerLoadedState = () => {
  const [mediaLoaded] = useObservable(mediaPlayerStore.pipe(select((state) => state.mediaLoaded)), false);

  return mediaLoaded;
};

export const useMediaPlayerFullscreenState = () => {
  const [isFullscreen] = useObservable(mediaPlayerStore.pipe(select((state) => state.isFullscreen)), false);

  return isFullscreen;
};
