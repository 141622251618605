import { cloneDeep } from "lodash-es";
import { Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";

import { MediaConfig } from "@getsubly/common";
import { TranscriptionMap } from "@media-editor/types";
import { createStore, setProps, withProps } from "@ngneat/elf";
import { stateHistory } from "@ngneat/elf-state-history";

interface TranscriptionProps {
  mediaConfig?: Omit<MediaConfig, "snippets">;
  transcriptions?: TranscriptionMap;
}

export const mediaHistoryStore = createStore({ name: "editor-history" }, withProps<TranscriptionProps>({}));

class MediaHistoryRepository {
  transcriptionUpdates$ = new Subject<TranscriptionMap>();
  configUpdates$ = new Subject<MediaConfig>();
  trackTranscriptionChanges$ = this.transcriptionUpdates$.pipe(debounceTime(600), tap(this.updateTranscriptions));
  trackConfigChanges$ = this.configUpdates$.pipe(debounceTime(300), tap(this.updateMediaConfig));

  get transcriptions(): TranscriptionMap {
    return mediaHistoryStore.getValue().transcriptions ?? {};
  }

  get mediaConfig(): MediaConfig | undefined {
    return mediaHistoryStore.getValue().mediaConfig;
  }

  initState(transcriptions?: TranscriptionMap, mediaConfig?: MediaConfig) {
    if (!transcriptions) {
      return;
    }

    mediaHistoryStore.update(setProps({ mediaConfig, transcriptions }));

    // This will clear the mediaHistory history with a new cloned version
    // of the current transcription and mediaConfig.
    mediaHistory.clear(() => ({
      present: {
        mediaConfig: cleanMediaConfig(mediaConfig),
        transcriptions: cloneDeep(transcriptions)
      },
      past: [],
      future: []
    }));
  }

  resetState() {
    mediaHistoryStore.reset();
  }

  updateTranscriptions(transcriptions?: TranscriptionMap) {
    if (!transcriptions) {
      return;
    }

    mediaHistoryStore.update(setProps({ transcriptions: cloneDeep(transcriptions) }));
  }

  updateMediaConfig(mediaConfig?: MediaConfig) {
    mediaHistoryStore.update(setProps({ mediaConfig: cleanMediaConfig(mediaConfig) }));
  }
}

export const mediaHistoryRepository = new MediaHistoryRepository();

export const mediaHistory = stateHistory(mediaHistoryStore);

const cleanMediaConfig = (mediaConfig?: MediaConfig): Omit<MediaConfig, "snippets"> | undefined => {
  if (!mediaConfig) {
    return;
  }

  const updatedMediaConfig = cloneDeep(mediaConfig);

  if (updatedMediaConfig.snippets) {
    delete updatedMediaConfig.snippets;
  }

  return updatedMediaConfig;
};
