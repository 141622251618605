import React from "react";

import { Button } from "@components/button";
import { NewModal } from "@components/new-modal";

interface ConfirmMoveToPrivateModalProps {
  type: "folder" | "media";
  onConfirm: () => void;
  onCancel: () => void;
}
export const ConfirmMoveToPrivateModal: React.FC<ConfirmMoveToPrivateModalProps> = ({ onConfirm, onCancel, type }) => {
  return (
    <NewModal
      onDismiss={onCancel}
      disableBackgroundDismiss
      size="465"
      className="tw-top-1/2 tw--translate-y-1/2 !tw-pt-4"
    >
      <ConfirmMoveToPrivateModalContainer onConfirm={onConfirm} onCancel={onCancel} type={type} />
    </NewModal>
  );
};

const ConfirmMoveToPrivateModalContainer: React.FC<ConfirmMoveToPrivateModalProps> = ({
  onCancel,
  onConfirm,
  type
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-text-center">
      <h3 className="tw-mb-1 tw-text-lg tw-font-semibold tw-text-neutral-900">Are you sure?</h3>
      <p className="tw-mb-6 tw-text-sm">
        <span className="tw-text-neutral-600">Do you want to move the {type} to private?</span>
        <br />
        <span className="tw-font-semibold tw-text-neutral-900">Only you will be able to access it.</span>
      </p>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Button variant="destructive-outline" onClick={onConfirm}>
          Move to private
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
