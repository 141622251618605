import React from "react";
import { useForm } from "react-hook-form";

import { updateFolder } from "@frontend/api/folders.service";
import { Fieldset } from "@frontend/components/form-controls/fieldset";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { NewModal } from "@components/new-modal";

import { ModalType } from "@core/interfaces/modal-type";
import { Folder } from "@core/state/dashboard/dashboard.store";

interface RenameFolderModalProps {
  folder: Folder;
  onBeforeSave?: () => Promise<void> | void;
  onCompleteSave?: () => Promise<void> | void;
  hideModal: (modal: ModalType) => void;
}

export const RenameFolderModal: React.FC<RenameFolderModalProps> = ({
  folder,
  onBeforeSave,
  onCompleteSave,
  hideModal
}) => {
  const closeModal = () => hideModal(ModalType.RenameFolder);

  return (
    <NewModal onDismiss={closeModal} showCloseButton disableBackgroundDismiss title="Name:">
      <RenameFolderFolderContainer
        folder={folder}
        closeModal={closeModal}
        onBeforeSave={onBeforeSave}
        onCompleteSave={onCompleteSave}
      />
    </NewModal>
  );
};

type RenameForm = { name: string };
interface RenameFolderFolderContainerProps {
  folder: Folder;
  onBeforeSave?: () => Promise<void> | void;
  onCompleteSave?: () => Promise<void> | void;
  closeModal: () => void;
}
const RenameFolderFolderContainer: React.FC<RenameFolderFolderContainerProps> = ({
  folder,
  onBeforeSave,
  onCompleteSave,
  closeModal
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm<RenameForm>({
    defaultValues: { name: folder.name }
  });

  const handleSave = async ({ name }: RenameForm) => {
    setLoading(true);
    await onBeforeSave?.();
    await updateFolder({ ...folder, name });

    await onCompleteSave?.();

    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Fieldset>
        <Input {...register("name", { required: true })} autoFocus />
      </Fieldset>

      <div className="tw-mt-6 tw-flex tw-justify-end">
        <Button variant="secondary" onClick={closeModal} size="36">
          Cancel
        </Button>
        <Button variant="primary" className="tw-ml-2 tw-px-6" loading={isLoading} type="submit" size="36">
          Save
        </Button>
      </div>
    </form>
  );
};
