import React, { useMemo } from "react";
import classNames from "classnames";

import { AlignmentStyle, assColorToRgba } from "@getsubly/common";

export interface FullWidthBackgroundProps {
  scale: number;
  hasBackground: boolean;
  alignment: AlignmentStyle | number;
  fullWidth: boolean;
  backgroundColor?: string;
  boxHeight: number;
}
export const FullWidthBackground: React.FC<FullWidthBackgroundProps> = ({
  scale,
  hasBackground,
  alignment,
  fullWidth,
  backgroundColor,
  boxHeight
}) => {
  if (!hasBackground || !fullWidth) {
    return null;
  }

  let position = "bottom";
  switch (alignment) {
    case AlignmentStyle.TopLeft:
    case AlignmentStyle.TopCenter:
    case AlignmentStyle.TopRight:
      position = "top";
      break;
    case AlignmentStyle.CenterLeft:
    case AlignmentStyle.CenterCenter:
    case AlignmentStyle.CenterRight:
      position = "center";
      break;
    default:
      position = "bottom";
      break;
  }

  const backgroundRGBColor = useMemo(() => {
    if (!backgroundColor) {
      return "transparent";
    }
    const { r, g, b, a } = assColorToRgba(backgroundColor);

    return `rgba(${r},${g},${b},${a})`;
  }, [backgroundColor]);

  const style = React.useMemo(() => {
    return {
      height: boxHeight * scale,
      backgroundColor: backgroundRGBColor
    };
  }, [boxHeight, scale, backgroundRGBColor]);

  return (
    <div
      className={classNames("tw-flex tw-h-full tw-w-full", {
        "tw-items-start": position === "top",
        "tw-items-center": position === "center",
        "tw-items-end": position === "bottom"
      })}
    >
      <div className="abc tw-w-full" style={style} />
    </div>
  );
};
