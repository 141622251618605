import { ModalType } from "@core/interfaces/modal-type";
import { Store, StoreConfig } from "@datorama/akita";

export interface VisibleModal {
  type: ModalType;
  component: JSX.Element;
  options?: ModalOptions;
}

export interface ModalOptions {
  // Some modals in the editor need access to the editor providers so we render these modals
  // differently within the tree inside the MediaEditor container
  isEditor?: boolean;
}

export interface ModalState {
  visibleModals: Array<VisibleModal>;
}

@StoreConfig({ name: "modal", resettable: true })
export class ModalStore extends Store<ModalState> {
  constructor() {
    super({ visibleModals: [] });
  }

  showModal(type: ModalType, component: JSX.Element, options: ModalOptions = { isEditor: false }): void {
    const isModalVisible = this.getValue().visibleModals.some((m) => m.type === type);

    if (isModalVisible) {
      return;
    }

    this.update((s) => ({
      visibleModals: [...s.visibleModals, { type, component, options }]
    }));
  }

  hideModal(modal: ModalType): void {
    const visibleModals = this.getValue().visibleModals.filter((m) => m.type !== modal);

    this.update({ visibleModals });
  }

  toggleModal(type: ModalType, component: JSX.Element, options: ModalOptions = { isEditor: false }): void {
    const isModalVisible = this.getValue().visibleModals.some((m) => m.type === type);

    if (isModalVisible) {
      this.hideModal(type);
    } else {
      this.showModal(type, component, options);
    }
  }

  hideAll(): void {
    this.update({ visibleModals: [] });
  }
}

export const modalStore = new ModalStore();
