import React from "react";
import { Rifm } from "rifm";

const parseDigits = (value: string) => (value.match(/\d+/g) || []).join("");

const formatTime = (value: string, maxLength: number) => {
  const digits = parseDigits(value);
  const chars = digits.split("");

  return chars
    .reduce((r, v, index) => {
      if (index === 2 || (maxLength !== 8 && index === 4)) {
        return `${r}:${v}`;
      }
      if (index === 4 || index === 6) {
        return `${r}.${v}`;
      }
      return `${r}${v}`;
    }, "")
    .slice(0, 11);
};

const formatTimeWithAppend = (value: string, maxLength: number) => {
  const res = formatTime(value, maxLength);

  if (value.endsWith(":")) {
    if (res.length === 2) {
      return `${res}:`;
    }

    if (res.length === 5) {
      return `${res}:`;
    }
  }
  return res;
};

const appendColon = (value: string) => (value.length === 2 || value.length === 5 ? `${value}:` : value);

interface RifmRenderArgs<E> {
  value: string;
  onChange: React.ChangeEventHandler<E>;
}

export interface TimeRifmProps {
  children: (args: RifmRenderArgs<HTMLInputElement>) => React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  maxLength: number;
}

export const TimeRifm: React.FC<TimeRifmProps> = ({ children, value, onChange, maxLength }) => (
  <Rifm
    accept={/[\d]/g}
    mask={false}
    format={(value) => formatTimeWithAppend(value, maxLength)}
    append={appendColon}
    value={value}
    onChange={onChange}
  >
    {children}
  </Rifm>
);
