export enum WCAGLevel {
  AA = "AA",
  AAA = "AAA",
  NonCompliant = "NonCompliant"
}

const CONTRAST_THRESHOLDS = {
  [WCAGLevel.AAA]: 7.0,
  [WCAGLevel.AA]: 4.5
} as const;

export const getWcagLevel = (contrastRatio: number): WCAGLevel => {
  if (contrastRatio >= CONTRAST_THRESHOLDS[WCAGLevel.AAA]) return WCAGLevel.AAA;
  if (contrastRatio >= CONTRAST_THRESHOLDS[WCAGLevel.AA]) return WCAGLevel.AA;
  return WCAGLevel.NonCompliant;
};
