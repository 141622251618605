import React from "react";
import { Link } from "react-router-dom";

import { UserGroupsContainer } from "@frontend/containers/settings/team/user-groups/user-groups-container";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { usePlan } from "@core/hooks/use-plan";

export const GroupsPage: React.FC = () => {
  const { isEnterprise } = usePlan();

  return (
    <>
      <SettingsPageHeader
        title={isEnterprise ? "Groups" : "Upgrade to create groups"}
        description={
          isEnterprise ? (
            "Set up groups of users and control permissions"
          ) : (
            <>
              Upgrade to{" "}
              <Link to={SETTINGS_BILLING_PATH} className="tw-text-aux-500">
                Enterprise plan
              </Link>{" "}
              to set up group of users
              <br />
              and control permissions
            </>
          )
        }
        className="tw-mb-6"
      />
      <UserGroupsContainer />
    </>
  );
};
