import React from "react";
import classNames from "classnames";

interface SettingsCardProps {
  className?: string;
  children: React.ReactNode;
}

export const Container: React.FC<SettingsCardProps> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-[258px] tw-max-w-[540px] tw-flex-col tw-rounded-8 tw-border tw-border-neutral-200",
        className
      )}
    >
      <div>{children}</div>
    </div>
  );
};

interface SettingsCardHeaderProps {
  className?: string;
  children: React.ReactNode;
}
export const Header: React.FC<SettingsCardHeaderProps> = ({ className, children }) => {
  return (
    <div className={classNames("tw-flex tw-flex-row tw-items-center tw-p-4 tw-text-lg tw-font-semibold", className)}>
      {children}
    </div>
  );
};

interface SettingsCardBodyProps {
  className?: string;
  children: React.ReactNode;
}
export const Body: React.FC<SettingsCardBodyProps> = ({ className, children }) => {
  return <div className={classNames("tw-p-4 tw-pt-2", className)}>{children}</div>;
};

interface SettingsCardFooterProps {
  className?: string;
  children: React.ReactNode;
}
export const Footer: React.FC<SettingsCardFooterProps> = ({ className, children }) => {
  return <div className={classNames("tw-border-t tw-border-neutral-200 tw-p-4", className)}>{children}</div>;
};

export const SettingsCard = Object.assign(Container, { Header, Body, Footer });
