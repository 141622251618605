import React from "react";
import { useLocation } from "react-router-dom";

import { authorizeOneDrive } from "@frontend/api/apps.service";

import { LoadingIcon } from "@components/loading-icon";

export const OneDriveLoginPage: React.FC = () => {
  const { search } = useLocation();
  const [error, setError] = React.useState("");

  const processLogin = async (search: string) => {
    try {
      const redirectUrl = await authorizeOneDrive(search);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error("ERROR", error);
      setError(error?.message);
    }
  };

  React.useEffect(() => {
    if (!search) {
      return;
    }

    processLogin(search);
  }, []);

  return (
    <div className="tw-flex tw-max-w-[720px] tw-flex-col tw-gap-6 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <LoadingIcon />
          <p className="tw-text-lg tw-font-medium">Logging in...</p>
        </div>
        {Boolean(error) && <p className="tw-text-sm tw-text-destructive-500">{error}</p>}
      </div>
    </div>
  );
};
