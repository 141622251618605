import React from "react";
import classNames from "classnames";

import { BackgroundStyle } from "@getsubly/common";

import { EffectItem } from "./effect-item";

export interface EffectStyleProps {
  style: BackgroundStyle;
  outline: number;
}

const DEFAULT_EFFECT: EffectStyleProps = {
  style: BackgroundStyle.None,
  outline: 1
};

interface EffectPickerProps {
  effect?: EffectStyleProps;
  onChange: (effect: EffectStyleProps) => void;
  className?: string;
  includeFullWidthEffect?: boolean;
}
export const EffectPicker: React.FC<EffectPickerProps> = ({
  effect: propsEffect,
  onChange,
  className,
  includeFullWidthEffect = true
}) => {
  const [activeStyle, setStyle] = React.useState(propsEffect?.style || DEFAULT_EFFECT.style);

  React.useEffect(() => {
    if (propsEffect?.style && propsEffect.style !== activeStyle) {
      setStyle(propsEffect.style);
    }
  }, [propsEffect?.style]);

  const outline = React.useMemo(() => {
    return propsEffect?.outline || DEFAULT_EFFECT.outline;
  }, [propsEffect?.outline]);

  const handleSelect = (value: BackgroundStyle) => {
    if (value === activeStyle) {
      return;
    }

    setStyle(value);
    onChange({ style: value, outline });
  };

  const { effects, containerWidth } = React.useMemo(() => {
    const effects = [
      BackgroundStyle.None,
      BackgroundStyle.DropShadow,
      BackgroundStyle.Background,
      BackgroundStyle.Outline
    ];

    if (includeFullWidthEffect) {
      effects.push(BackgroundStyle.FullWidth);
    }

    const GAP = 8;
    const ITEM_WIDTH = 106.5;
    const ITEMS_WIDTH = effects.length * ITEM_WIDTH;
    const GAPS_WIDTH = (effects.length - 1) * GAP;

    const containerWidth = ITEMS_WIDTH + GAPS_WIDTH;

    return { effects, containerWidth };
  }, []);

  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-3 tw-overflow-x-auto", className)}>
      <div className="tw-relative tw-flex tw-gap-2 tw-overflow-hidden" style={{ width: containerWidth + "px" }}>
        {effects.map((effect, i) => (
          <EffectItem key={i} style={effect} selected={activeStyle === effect} onClick={handleSelect} />
        ))}
      </div>
    </div>
  );
};
